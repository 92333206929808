import React, { Component } from "react";
import {
  Button,
  Grid,
  TextField,
  Drawer,
  Divider,
  Typography,
  MenuItem,
  Paper,
} from "@material-ui/core";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";
import { getSuppliers } from "../../services/queues/FlightQueuesService";
import { getAirlines } from "../../services/promocode/PromoCodeService";
import { reconcilationUpload, uploadReconcileFile } from "../../services/reconciliation/ReconciliationService";

class FlightReconciliation extends Component {
  receiptUrl = undefined;
  uploadFile = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpen: false,
      suppliers: [],
      airlinesList: [],
      carrier: "",
      supplierid: "",
      searchObj: {
        from: moment().format("YYYY-MM-DD"),
        to: moment().add(1, "days").format("YYYY-MM-DD")
      }
    };
  }

  async componentDidMount() {
    await this.getSuppliersList();
    await this.getAirlinesList();
  }

  handleChange = (val, name) => {
    this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: val
      }
    }));
  };

  handleInputChange = e => {
    this.setState({        
      [e.target.name]: e.target.value
    });
  };

  openCardDrawer = isShow => {
    this.setState({
      isDrawerOpen: isShow
    });
  };

  getSuppliersList = async () => {
    this.props.showLoading(true);
    let res = await getSuppliers();
    this.setState({
      suppliers: res
    });
    this.props.showLoading(false);
  };

  getAirlinesList = async () => {
    this.props.showLoading(true);
    let res = await getAirlines();
    this.setState({
      airlinesList: res
    });
    this.props.showLoading(false);
  };

  submitUploadReconcileFile = async () => {
    this.props.showLoading(true);
    let supDetail = this.state.supplierid ? this.state.supplierid.split("#") : "";
    let reqObj = {
      carrier: this.state.carrier,
      supplierid: supDetail ? supDetail[0] : "",
      suppliername: supDetail ? supDetail[1] : "",
      excelpath: this.receiptUrl,
    }
    let res = await uploadReconcileFile(reqObj);
    if (res) {
      if (res.ResponseStatus.status === "OK") {
        this.props.showSnackBar(res.ResponseStatus.message, "success");
        this.openCardDrawer(false);
      } else {
        this.props.showSnackBar(res.ResponseStatus.message, "error");
      }
    } else {
      this.props.showSnackBar("Failed to upload reconcile file. Please try again.", "error");
    }
    this.props.showLoading(false);
  }

  handleUploadReceipt = event => {
    var eve = { ...event };
    this.uploadFile = eve.target.files[0];
  };

  handleUploadAction = async () => {
    this.props.showLoading(true);
    if (this.uploadFile !== undefined) {
      let formData = new FormData();
      formData.append("authtoken", "");
      formData.append("fullpath", this.uploadFile, this.uploadFile.name);
      const uploadResponse = await reconcilationUpload(formData);
      this.receiptUrl = uploadResponse.ApiStatus.Status === "OK" ? uploadResponse.ApiStatus.Result : undefined;
      if (this.receiptUrl === undefined) {
        this.props.showSnackBar("Failed to upload the attachment. Please try again.", "error");
      } else {
        this.props.showSnackBar("Attachment has been uploaded successfully.", "success");
      }
    } else {
      this.props.showSnackBar("Please browse attachment and click on upload button", "error");
    }
    this.props.showLoading(false);
  };

  render() {
    return (
      <Grid container spacing={16}>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <DayPickerInput
            className="full-width"
            value={this.state.searchObj.from}
            onDayChange={day => this.handleChange(day, "from")}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <DayPickerInput
            className="full-width"
            value={this.state.searchObj.to}
            onDayChange={day => this.handleChange(day, "to")}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.doSearch(this.state.searchObj)}
          >
            Search
          </Button>
          <Button
            className="right"
            variant="contained"
            size="small"
            color="primary"
            onClick={() => this.openCardDrawer(true)}
          >
            Upload Reconcile File
          </Button>
        </Grid>

        <Drawer
          className="custom-drawer"
          anchor="right"
          open={this.state.isDrawerOpen}
        >
          <Grid container spacing={16}>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography variant="h6">Upload Reconcile File</Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                variant="outlined"
                color="secondary"
                className="right"
                onClick={() => this.openCardDrawer(false)}
              >
                Close
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider />
              <br />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                name="carrier"
                label="Carrier"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Carrier"
                select
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.carrier}
                onChange={this.handleInputChange}
              >
                <MenuItem value="all">All</MenuItem>
                {this.state.airlinesList.map((carrier, index) => (
                  <MenuItem key={index} value={carrier.airline_name}>
                    {`${carrier.airline_code} - ${carrier.airline_name}`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                name="supplierid"
                label="SupplierId"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="SupplierId"
                select
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.supplierid}
                onChange={this.handleInputChange}
              >
                {this.state.suppliers.map((sup, index) => (
                  <MenuItem key={index} value={`${sup.supplier_id}#${sup.supplier_name}`}>
                    {`${sup.supplier_name} (${sup.supplier_id})`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <input name="file" id="file" type="file" onChange={this.handleUploadReceipt} />&nbsp;&nbsp;
                <Button 
                  onClick={this.handleUploadAction} 
                  variant="contained" 
                  color="primary" 
                  size="small"
                >
                  Upload
                </Button>
              </Paper>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                onClick={this.submitUploadReconcileFile}
                variant="contained"
                color="secondary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </Grid>
    );
  }
}

export default FlightReconciliation;
