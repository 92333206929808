import React from "react";
import { Typography, TextField, Button } from "@material-ui/core";
import { apiPost } from "../../services/common/CommonService";
import { withRouter } from "react-router-dom";
import { Loading } from "../common/Loading";

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      login: {
        username: "",
        password: ""
      },
      isLoading: false
    };
  }

  handleChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      login: {
        ...prevState.login,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  handleLogin = async event => {
    event.preventDefault();
    this.setState({
      isLoading: true
    });
    let obj = {
      opid: "TG-LOGINUSER",
      jsondata: JSON.stringify({ LoginRequest: this.state.login })
    };
    let res = await apiPost(obj);

    if (res.LoginResponse.wsstatus.Status === "NOK") {
      // this.setState({
      //   isLoading: false,
      //   messageText: res.wsStatus.Message,
      //   msgType: "error",
      //   isOpen: true,
      // });
      this.setState({
        isLoading: false
      });
      alert("Failed to login");
    } else {
      localStorage.setItem(
        "tgAdminAuth",
        JSON.stringify(res.LoginResponse.userauth)
      );
      localStorage.setItem(
        "tgAdminProfile",
        JSON.stringify(res.LoginResponse.profilemaster)
      );
      this.props.showMenu(true);

      this.setState(
        {
          isLoading: false
        },
        () => {
          this.props.history.push({
            pathname: "/home"
          });
        }
      );
    }
  };

  render() {
    return (
      <form onSubmit={this.handleLogin} name="login-form" id="login_form">
        {this.state.isLoading && <Loading />}
        <Typography align="center" variant="h6">
          Login
        </Typography>
        <br />
        <TextField
          fullWidth
          name="username"
          type="text"
          label="Username / Email"
          required
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          value={this.state.login.username}
          onChange={this.handleChange}
        />
        <br />
        <br />
        <TextField
          fullWidth
          variant="outlined"
          name="password"
          type="password"
          label="Password"
          required
          InputLabelProps={{
            shrink: true
          }}
          value={this.state.login.password}
          onChange={this.handleChange}
        />
        <br />
        <br />
        <div className="text-center">
          <Button variant="contained" color="primary" type="submit">
            Login
          </Button>
        </div>
      </form>
    );
  }
}

export default withRouter(Login);
