import React, { Component } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { addRuleMasters, updateRuleMasters } from "../../services/rulemasters/RuleMastersService";

class AddOrUpdatePromoCode extends Component {
  isEdit = false;
  constructor(props) {
    super(props);
    this.isEdit = props.isEdit;
    this.state = {
      ruleMastersObj: props.ruleMastersObj,
      isError: false
    };
  }

  handleInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      ruleMastersObj: {
        ...prevState.ruleMastersObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  isValidForm = () => {
    let isError = false;
    Object.keys(this.state.ruleMastersObj).forEach(key => {
      if (this.state.ruleMastersObj[key] === "") {
        this.setState({
          isError: true
        });
        isError = true;
      }
    });
    return isError;
  };

  editRuleMasters = async () => {
    let isError = this.isValidForm();
    if (!isError) {
      this.props.showLoading(true);
      let res = await updateRuleMasters(this.state.ruleMastersObj);
      if (res) {
        if (res.ResponseStatus.status === "OK") {
          this.props.showSnackBar(res.ResponseStatus.message, "success");
          this.props.closeDrawer();
          this.props.getRuleMastersList();
        } else {
          this.props.showSnackBar(res.ResponseStatus.message, "error");
        }
      } else {
        this.props.showSnackBar(
          "Failed to update the rule. Please try again.",
          "error"
        );
      }
      this.props.showLoading(false);
    }
  };

  addRule = async () => {
    let isError = this.isValidForm();
    if (!isError) {
      this.props.showLoading(true);
      let res = await addRuleMasters(this.state.ruleMastersObj);
      if (res) {
        if (res.ResponseStatus.status === "OK") {
          this.props.showSnackBar(res.ResponseStatus.message, "success");
          this.props.closeDrawer();
          await this.props.getRuleMastersList();
        } else {
          this.props.showSnackBar(res.ResponseStatus.message, "error");
        }
      } else {
        this.props.showSnackBar(
          "Failed to add rule. Please try again.",
          "error"
        );
      }
      this.props.showLoading(false);
    }
  };

  render() {
    const { ruleMastersObj } = this.state;
    return (
      <Drawer open={true} anchor="right" className="cred-key add-promo">
        <Grid container spacing={16}>
          <Grid item lg={6} md={6} sm={6} xs={8}>
          {this.isEdit === true ? (
            <Typography variant="h6"> Update Rule Masters </Typography>
          ) : (
            <Typography variant="h6"> Add Rule Masters </Typography>
          )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={4}>
            <Button
              onClick={this.props.closeDrawer}
              className="right"
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <hr className="full-width" />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="carrier"
              variant="outlined"
              size="small"
              label="Carrier"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.carrier}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="carriername"
              variant="outlined"
              label="Carrier Name"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.carriername}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="origin"
              variant="outlined"
              label="Origin"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.origin}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="destination"
              variant="outlined"
              label="Destination"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.destination}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="classofbooking"
              variant="outlined"
              label="Class Of Booking"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.classofbooking}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="specialinstructions"
              variant="outlined"
              label="Special Instructions"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.specialinstructions}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              select
              required
              name="ruletype"
              variant="outlined"
              label="Rule Type"
              className="select"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.ruletype}
              onChange={this.handleInputChange}
            >
              <MenuItem value="timebased">Timebased</MenuItem>
              <MenuItem value="overall">Overall</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              select
              required
              name="cabinclass"
              variant="outlined"
              label="Cabin Class"
              className="select"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.cabinclass}
              onChange={this.handleInputChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="economy">Economy</MenuItem>
              <MenuItem value="business">Business</MenuItem>
              <MenuItem value="premiumeconomy">Premium Economy</MenuItem>
              <MenuItem value="firstclass">First Class</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              select
              required
              name="canxresc"
              variant="outlined"
              label="Cancellation / Reschedule"
              className="select"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.canxresc}
              onChange={this.handleInputChange}
            >
              <MenuItem value="cancellation">Cancellation</MenuItem>
              <MenuItem value="reschedule">Reschedule</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              select
              required
              name="domint"
              variant="outlined"
              label="Domint"
              className="select"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.domint}
              onChange={this.handleInputChange}
            >
              <MenuItem value="all">all</MenuItem>
              <MenuItem value="domestic">domestic</MenuItem>
              <MenuItem value="international">international</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              select
              required
              name="faretype"
              variant="outlined"
              label="Fare Type"
              className="select"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.faretype}
              onChange={this.handleInputChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="normal">Normal</MenuItem>
              <MenuItem value="coupon">Coupon</MenuItem>
              <MenuItem value="special">Special</MenuItem>
              <MenuItem value="corporate">Corporate</MenuItem>
              <MenuItem value="handbaggage">Hand Baggage</MenuItem>
              <MenuItem value="sme">SME</MenuItem>
              <MenuItem value="tactical">Tactical</MenuItem>
              <MenuItem value="corporateconnect">Corporate Connect</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="fromtime"
              variant="outlined"
              label="From Time"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.fromtime}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="totime"
              variant="outlined"
              label="To Time"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.totime}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="adultfee"
              variant="outlined"
              type="number"
              label="Adult Fee"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.adultfee}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="childfee"
              variant="outlined"
              type="number"
              label="Child Fee"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.childfee}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <TextField
              fullWidth
              required
              name="infantfee"
              variant="outlined"
              type="number"
              label="Infant Fee"
              InputLabelProps={{ shrink: true }}
              value={ruleMastersObj.infantfee}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {this.isEdit === true ? (
              <Button
                onClick={this.editRuleMasters}
                variant="contained"
                color="primary"
              >
                Update Rule Masters
              </Button>
            ) : (
              <Button
                onClick={this.addRule}
                variant="contained"
                color="primary"
              >
                Add Rule Masters
              </Button>
            )}
            <br />
            <br />
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default AddOrUpdatePromoCode;
