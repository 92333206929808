import React from "react";
import "./HotelBookingUpdatePage.css";
import {
  getHotelInvoice,
  updateHotelBooking
} from "../../../services/queues/HotelQueuesService";
import { Loading } from "../../../components/common/Loading";
import {
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Paper,
  Grid,
  TextField,
  Button
} from "@material-ui/core";
import CustomSnackBar from "../../../components/common/CustomSnackBar";

class HotelBookingUpdatePage extends React.Component {
  txId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hotelInvoice: undefined,
      updateObj: {
        confirmationNo: "",
        voucherInfo: "",
        bookingStatus: "",
        adminRemarks: ""
      },
      snackBar: {
        message: "",
        isOpen: false,
        variant: ""
      }
    };
    this.txId = window.location.pathname.split("/").pop();
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    let res = await getHotelInvoice(this.txId);
    if (res) {
      this.setState({
        isLoading: false,
        hotelInvoice: res,
        updateObj: {
          confirmationNo: res.confirmationno,
          voucherInfo: res.voucherinfo,
          bookingStatus: res.booking_status,
          adminRemarks: res.Adminremarks
        }
      });
    }
  }

  handleChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      updateObj: {
        ...prevState.updateObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  handleUpdateQueue = async () => {
    this.setState({
      isLoading: true
    });
    let obj = {
      txid: this.txId,
      confirmationno: this.state.updateObj.confirmationNo,
      voucherinfo: this.state.updateObj.voucherInfo,
      booking_status: this.state.updateObj.bookingStatus,
      adminremarks: this.state.updateObj.adminRemarks
    };
    let res = await updateHotelBooking(obj);
    let msg = "",
      type = "success";
    if (res) {
      msg = res.ResponseStatus.message;
      type = "success";
    } else {
      msg = "Failed to update the booking. Please try again.";
      type = "error";
    }
    this.setState({
      isLoading: false,
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };
  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Typography variant="h6" className="mb-8"> Update Hotel Queue </Typography>
        {this.state.hotelInvoice ? (
          <Grid container spacing={16}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6">Ticket Details</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Transaction Id</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.state.hotelInvoice.txid}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Check-in Date</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>
                      {this.state.hotelInvoice.checkin_time}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Check-out Date</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>
                      {this.state.hotelInvoice.checkout_time}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Agent Id</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.state.hotelInvoice.agentid}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Pax. Contact</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>
                      {this.state.hotelInvoice.leadpassenger_phone}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Pax. EMail</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>
                      {this.state.hotelInvoice.leadpassenger_email}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6">Agent Details</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="caption">Hotel Name</Typography>
                    <Typography>
                      {this.state.hotelInvoice.hotel_name}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="caption">Room Type</Typography>
                    <Typography>{this.state.hotelInvoice.room_type}</Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography variant="caption">Hotel Id</Typography>
                    <Typography>{this.state.hotelInvoice.hotel_id}</Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography variant="caption">No. of Rooms</Typography>
                    <Typography>
                      {this.state.hotelInvoice.noof_rooms}
                    </Typography>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography variant="caption">Passenger Name</Typography>
                    <Typography>
                      {`${this.state.hotelInvoice.leadpassenger_firstname} ${
                        this.state.hotelInvoice.leadpassenger_lastname
                      }`}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      name="confirmationNo"
                      label="Confirmation No"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      value={this.state.updateObj.confirmationNo}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      fullWidth
                      name="voucherInfo"
                      label="Voucher Info"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      value={this.state.updateObj.voucherInfo}
                      onChange={this.handleChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <InputLabel>Booking Status</InputLabel>
                    <Select
                      fullWidth
                      name="bookingStatus"
                      displayEmpty
                      variant="outlined"
                      value={this.state.updateObj.bookingStatus}
                      onChange={this.handleChange}
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="confirm">Confirm</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      fullWidth
                      name="adminRemarks"
                      label="Admin Remarks"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      value={this.state.updateObj.adminRemarks}
                      onChange={this.handleChange}
                    />
                  </Grid>
                </Grid>
                <br />
                <Button variant="contained" color="primary" onClick={this.handleUpdateQueue}> Update Queue </Button>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <div>
            <Typography align="center" variant="h6">
              No booking found for the Transaction No: {this.txId}
            </Typography>
          </div>
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HotelBookingUpdatePage;
