import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Drawer,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  MenuItem,
} from "@material-ui/core";
import { debounce } from "../../../components/common/Utils";
import TGSelect from "../../common/tg-select/TGSelect";
import {
  addVisaEntryType,
  updateVisaEntryType,
  getAllVisaEntryTypeList,
} from "../../../services/inventory/VisaService";

class VisaEntryType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonObj: undefined,
      recordsList: [],
      filteredList: [],
      visaTypes: props.visaTypes,
      isOpenDrawer: false,
      showLoading: props.showLoading,
      showSnackBar: props.showSnackBar,
      recordid: "",
      isEdit : false,
    };
  }

  async componentDidMount() {
    this.initJsonObject();
    await this.allVisaEntryType();
  }

  handleSearchChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.recordsList.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredList: data
    });
  }, 500);

  initJsonObject = () => {
    this.setState({
      jsonObj: {
        country: "",
        visaentry_type: "",
        description: "",
      }
    });
  };

  openDrawer = flag => {
    if (flag === false) {
      this.setState({
        isEdit: false,
        recordid: "",
      });
      this.initJsonObject();
    }
    this.setState({
      isOpenDrawer: flag
    });
  };

  allVisaEntryType = async () => {
    this.state.showLoading(true);
    let res = await getAllVisaEntryTypeList();
    if(res){
      this.setState({
        recordsList: res,
        filteredList: res,
      })
    }
    this.state.showLoading(false);
  }

  handleSaveOrUpdate = async isUpdate => {
    let res = undefined;
    let errMsg = "";
    let jsonObjError = 0;
    const alertMsg = "Please enter value for ";
    const { jsonObj } = this.state;
    const country = jsonObj.country.value ? jsonObj.country.value : "";
    const reqJsonObj = {...jsonObj, country: country}

    Object.keys(reqJsonObj).some(key => {
      if (reqJsonObj[key] === "") {
        window.alert(alertMsg + key);
        jsonObjError = 1;
        return true;
      }
      return false;
    });

    if(jsonObjError === 1){
      return false;
    }

    this.state.showLoading(true);

    if (isUpdate) {
      res = await updateVisaEntryType({...reqJsonObj, recordid: this.state.recordid});
      errMsg = "Failed to update a visa entry type. Please try again.";
    } else {
      res = await addVisaEntryType(reqJsonObj);
      errMsg = "Failed to add a visa entry type. Please try again.";
    }
    if (res) {
      if (res.ResponseStatus.status === "OK") {
        this.state.showSnackBar(res.ResponseStatus.message, "success");
      } else {
        this.state.showSnackBar(res.ResponseStatus.message, "error");
      }
    } else {
      this.state.showSnackBar(errMsg, "error");
    }
    this.openDrawer(false);
    this.state.showLoading(false);
    await this.allVisaEntryType();
  };

  handleInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      jsonObj: {
        ...prevState.jsonObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  handleCountryChange = (name, selectedCountry) => {
    this.setState(prevState => ({
      jsonObj: {
        ...prevState.jsonObj,
        [name]: selectedCountry !== null ? selectedCountry : "",
      },
    }));
  };

  showEdit = (obj) => {
    this.openDrawer(true);
    const countryObj = this.props.countriesList.filter(r => r["value"] === obj.country);
    this.setState({
      isEdit: true,
      recordid: obj.recordid,
      jsonObj: {
        country: countryObj,
        visaentry_type: obj.visaentry_type,
        description: obj.description,
      }
    });
  };

  noRecordsFound = () => <div>No records found. Click on <kbd>Add Visa Entry Type</kbd> button on the top right corner to add a visa entry type.</div>;

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={16} className="mb-8">
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Typography variant="h6"> Visa Entry Type </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Button
              className="right"
              variant="contained"
              size="small"
              color="primary"
              onClick={() => this.openDrawer(true)}
            >
              Add Visa Entry Type
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              fullWidth
              name="searchTerm"
              onChange={this.handleSearchChange}
              variant="outlined"
              label="Search"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Hidden smDown>
          <Paper className="padding-16">
            <Table className="auto-table">
              <TableHead>
                <TableRow>
                  <TableCell>Visa Entry Type</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filteredList &&
                  this.state.filteredList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.visaentry_type}</TableCell>
                      <TableCell>{item.country}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => this.showEdit(item)}
                          className="blue-btn"
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.filteredList &&
                  this.state.filteredList.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="8">{this.noRecordsFound()}</TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden mdUp>
          {this.state.filteredList &&
            this.state.filteredList.map((item, index) => (
              <Paper className="padding-16 mb-8 relative" key={index}>
                <Grid container spacing={8}>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Visa Entry Type
                    </Typography>
                    <Typography>{item.visaentry_type}</Typography>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Country
                    </Typography>
                    <Typography>{item.country}</Typography>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Description
                    </Typography>
                    <Typography>{item.description}</Typography>
                  </Grid>
                </Grid>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => this.showEdit(item)}
                  className="blue-btn"
                >
                  Edit
                </Button>
              </Paper>
            ))}
          {this.state.filteredList && this.state.filteredList.length === 0 && (
            <div>
              <Typography align="center">{this.noRecordsFound()}</Typography>
            </div>
          )}
          <Typography />
        </Hidden>
        <Drawer
          className="cabs-drawer"
          open={this.state.isOpenDrawer}
          anchor="right"
        >
          {this.state.jsonObj && (
            <React.Fragment>
              <Grid container spacing={8}>
                <Grid item lg={6} md={6} sm={6} xs={8}>
                  <Typography variant="h6">Add Visa Entry Type</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={4}>
                  <Button
                    className="right"
                    onClick={() => this.openDrawer(false)}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <hr className="full-width" />
                </Grid>
              </Grid>
              <Grid container spacing={16}>
                <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
                <Grid item xs={12}>
                  <TGSelect
                    className="mt-16"
                    name="country"
                    label="Select Country"
                    options={this.props.countriesList}
                    defaultValue={this.state.jsonObj.country}
                    onChange={this.handleCountryChange}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    select
                    fullWidth
                    required
                    label="Visa Entry Type"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    name="visaentry_type"
                    value={this.state.jsonObj.visaentry_type}
                    onChange={this.handleInputChange}
                  >
                    {this.state.visaTypes.map((item, i) => (
                      <MenuItem key={i} value={item}>{item}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    label="Description"
                    name="description"
                    InputLabelProps={{ shrink: true }}
                    value={this.state.jsonObj.description}
                    onChange={this.handleInputChange}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSaveOrUpdate(this.state.isEdit)}
                  >
                    {this.state.isEdit ? "Update" : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Drawer>
      </React.Fragment>
    );
  }
}

export default VisaEntryType;
