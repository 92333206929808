import React, { Component } from "react";
import { Grid, Hidden, Typography, Button, Tabs, Tab, Drawer, TextField, Divider } from "@material-ui/core";
import AccountCheck from "mdi-material-ui/AccountCheck";
import SearchInCorporateTravellers from "./SearchInCorporateTravellers";

class Travellers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      travellerTab: 0,
      searchTerm: "",
      perList: this.props.perList || [],
      corpList: this.props.corpList || [],
    };
  }

  handleTravellerTabChagne = (event, value) => {
    this.setState({
      travellerTab: value,
    });
  };

  searchTravellers = event => {
    let data = [];
    const val = event.target.value;
    if (this.state.travellerTab === 0) {
      data = this.props.perList.filter(x => `${x.firstname} ${x.lastname}`.toLowerCase().indexOf(val.toLowerCase()) > -1);
      this.setState({
        perList: data,
        searchTerm: val,
      });
    } else {
      data = this.props.corpList.filter(x => `${x.first_name} ${x.last_name}`.toLowerCase().indexOf(val.toLowerCase()) > -1);
      this.setState({
        corpList: data,
        searchTerm: val,
      });
    }
  };

  setSearchResults = corpList => {
    this.setState({
      corpList,
    });
  };

  clearSearchResults = () => {
    this.setState({
      corpList: this.props.corpList,
    });
  };

  render() {
    const { travellerTab, perList, corpList } = this.state;
    return (
      <Drawer className="full-width-drawer" open={true} anchor="right">
        <div>
          <Grid container spacing={16}>
            <Grid item lg={12} md={12} sm={12} xs={12} className="traveller-tabs">
              <Tabs value={travellerTab} onChange={this.handleTravellerTabChagne}>
                <Tab label="Corporate Travellers" />
                <Tab label="Personal Travellers" />
              </Tabs>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {((perList.length === 0 && this.state.travellerTab === 1) || (corpList.length === 0 && this.state.travellerTab === 0)) && (
                <div className="text-center">
                  <AccountCheck className="mdi-3x" />
                  <Typography> No travellers been added to this account yet. Please add a traveller.</Typography>
                </div>
              )}
              {this.state.travellerTab === 1 && (
                <div>
                  <Grid container spacing={16} className="mb-16 mt-8">
                    <Grid item lg={6} md={6} sm={6} xs={8}>
                      <TextField
                        variant="outlined"
                        name="searchTerm"
                        label="Search Name"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={this.state.searchTerm}
                        onChange={this.searchTravellers}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={4}>
                      <Button className="right mt-8" onClick={this.props.closeTvl} color="secondary" variant="outlined">
                        Close
                      </Button>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  {this.state.perList.length > 0 &&
                    this.state.perList.map((tc, index) => {
                      return (
                        <Grid key={index} container spacing={8}>
                          <Hidden xsDown>
                            <Grid item lg={5} md={5} sm={5}>
                              <Typography>
                                {tc.firstname} {tc.lastname} ({tc.nickname})
                              </Typography>
                            </Grid>
                            <Grid item lg={5} md={5} sm={5}>
                              <Typography>{tc.email}</Typography>
                            </Grid>
                          </Hidden>
                          <Hidden smUp>
                            <Grid item xs={7}>
                              <Typography>
                                {tc.firstname} {tc.lastname}
                              </Typography>
                              <Typography>{tc.email}</Typography>
                            </Grid>
                          </Hidden>
                          <Grid item lg={2} md={2} sm={2} xs={5}>
                            <Button className="primary-bg border-slid" onClick={() => this.props.handleTvlSelect(tc)}>
                              Select
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                </div>
              )}
              {this.state.travellerTab === 0 && (
                <div>
                  <Grid container spacing={16} className="mb-16 mt-8">
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                      <SearchInCorporateTravellers setSearchResults={this.setSearchResults} clearSearchResults={this.clearSearchResults} customerId={this.props.customerId} />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Button className="right mt-8" onClick={this.props.closeTvl} color="secondary" variant="outlined">
                        Close
                      </Button>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  {this.state.corpList.length > 0 &&
                    this.state.corpList.map((tc, index) => {
                      return (
                        <Grid key={index} container spacing={8}>
                          <Hidden xsDown>
                            <Grid item lg={5} md={5} sm={5}>
                              <Typography>
                                {tc.fn} {tc.ln}
                              </Typography>
                            </Grid>
                            <Grid item lg={5} md={5} sm={5}>
                              <Typography>{tc.em}</Typography>
                            </Grid>
                          </Hidden>
                          <Hidden smUp>
                            <Grid item xs={7}>
                              <Typography>
                                {tc.fn} {tc.ln}
                              </Typography>
                              <Typography>{tc.em}</Typography>
                            </Grid>
                          </Hidden>
                          <Grid item lg={2} md={2} sm={2} xs={5}>
                            <Button className="primary-bg border-slid" onClick={() => this.props.handleTvlSelect(tc)}>
                              Select
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </Drawer>
    );
  }
}

export default Travellers;
