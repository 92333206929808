import React, { Component } from "react";
import Search from "../../components/search/Search";

class SearchPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Search />
      </React.Fragment>
    );
  }
}

export default SearchPage;
