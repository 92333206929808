import React from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { approveCardBalanceRequest } from "../../../services/inventory/CardsService";

class BalanceApproval extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      remarks: "",
      amount: this.props.request.deposited_amount
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleApprove = async () => {
    if (
      this.state.remarks === "" ||
      this.state.amount === 0 ||
      this.state.amount === ""
    ) {
      this.setState({
        isError: true
      });
    } else {
      this.setState({
        isError: false
      });
      let obj = {
        remarks: this.state.remarks,
        amount: this.state.amount,
        requestid: this.props.request.requestid,
        corporateid: this.props.request.corporateid
      };
      let res = await approveCardBalanceRequest(obj);
      if (res) {
        this.props.showSnackBar(
          "Amount has been approved successfully",
          "success"
        );
        this.props.openBRDrawer(false, undefined);
      } else {
        this.props.showSnackBar(
          "Failed to approve amount. Please try again.",
          "error"
        );
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={16}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography variant="h6"> Approve Balance </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Button
              variant="outlined"
              color="secondary"
              className="right"
              onClick={() => this.props.openBRDrawer(false, undefined)}
            >
              Close
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider />
            <br />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography variant="caption"> Request ID </Typography>
            <Typography variant="subtitle1">
              {this.props.request.requestid}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography variant="caption"> Requested Amount </Typography>
            <Typography variant="subtitle1">
              {this.props.request.deposited_amount}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <br />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              name="amount"
              label="Approval Amount"
              InputLabelProps={{ shrink: true }}
              value={this.state.amount}
              onChange={this.handleChange}
            />
            {this.state.isError &&
              (this.state.amount === 0 || this.state.amount === "") && (
                <FormHelperText className="component-error-text">
                  Please enter the approval amount
                </FormHelperText>
              )}
          </Grid>
          <Grid item lg={9} md={9} sm={9} xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              name="remarks"
              label="Remarks"
              InputLabelProps={{ shrink: true }}
              value={this.state.remarks}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.remarks === "" && (
              <FormHelperText className="component-error-text">
                Please enter the approval remarks
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleApprove}
            >
              Approve Amount
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default BalanceApproval;
