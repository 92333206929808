import React, { Component } from "react";
import FlightBillingMain from "../../components/offline-billing/flight-billing/FlightBillingMain";
import "./common.css";

class FlightBillingPage extends Component {
  render() {
    return (
      <FlightBillingMain />
    );
  }
}

export default FlightBillingPage;
