import React, { Component } from "react";
import ETicket from "../../../components/queues/train/ETicket";
import "./TrainBookingTicketPage.css";

class TrainBookingTicketPage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <ETicket />
      </React.Fragment>
    );
  }
}

export default TrainBookingTicketPage;
