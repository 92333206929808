import { apiPost } from "../common/CommonService";

export const offlineTrainBooking = async (obj, pnr) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "offlinetrainbooking",
      jsondata: obj.jsondata,
      pnr: pnr,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};