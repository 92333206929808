import React, { Component } from "react";
import {
  Grid,
} from "@material-ui/core";
import CustomSnackBar from "../common/CustomSnackBar";
import { Loading } from "../common/Loading";
import SearchForm from "./SearchForm";
import SearchTable from "./SearchTable";
import { searchTransaction } from "../../services/search/SearchService";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tripList: [],
      serviceType: "",
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  showLoading = loading => {
    this.setState({
      isLoading: loading
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  doSearch = async searchObj => {
    this.setState({ showLoading: true });
    let res = await searchTransaction(searchObj);
    if (res) {
      let response = Array.isArray(res.GenericTrip)
        ? res.GenericTrip
        : [res.GenericTrip];
      this.setState({
        tripList: response,
        serviceType: response[0].servicetype,
      })
    } else {
      this.showSnackBar("No record found!", "error");
    }
    this.setState({ showLoading: false });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <SearchForm
              doSearch={this.doSearch}
              showLoading={this.showLoading}
              showSnackBar={this.showSnackBar}
            />
            <br/>
            <SearchTable 
              tripList={this.state.tripList}
              showLoading={this.showLoading}
              showSnackBar={this.showSnackBar}
              serviceType={this.state.serviceType}
            />
          </Grid>
        </Grid>
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Search;
