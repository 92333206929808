import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Hidden,
  Button,
} from "@material-ui/core";
import FirstPageIcon from "mdi-material-ui/PageFirst";
import KeyboardArrowLeft from "mdi-material-ui/ChevronLeft";
import KeyboardArrowRight from "mdi-material-ui/ChevronRight";
import LastPageIcon from "mdi-material-ui/PageLast";
import { withRouter } from "react-router-dom";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

class UserTravellerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: props.pagenum,
      rowsPerPage: 100,
      isLoading: false,
      travellersList: props.travellersList,
      sortType: "asc",
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
    this.props.getTravellersListFn(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handleEdit = async (cid) => {
    this.props.showLoading(true);
    this.props.showLoading(false);
  };

  componentDidUpdate(prevProps) {
    if (this.props.travellersList !== prevProps.travellersList) {
      this.setState({
        travellersList: this.props.travellersList,
      });
    }
  }

  sortBy = (property, type, sortType) => {
    let data = [...this.state.travellersList];
    if (type === "string") {
      if (this.state.sortType === "asc") {
        data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
        sortType = "desc";
      } else {
        data.sort((a, b) => (b[property] < a[property] ? -1 : 1));
        sortType = "asc";
      }
    } else {
      if (this.state.sortType === sortType) {
        data.sort((a, b) => new Date(b[property]) - new Date(a[property]));
        sortType = "desc";
      } else {
        data.sort((a, b) => new Date(a[property]) - new Date(b[property]));
        sortType = "asc";
      }
    }
    this.setState({
      travellersList: data,
      sortType: sortType,
    });
  };

  render() {
    const { travellersList, rowsPerPage, page } = this.state;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Paper className="padding-16">
            <Table className="queue-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("pid", "string", "asc")}
                    >
                      ProfileId
                    </Button>
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("cty", "string", "asc")}
                    >
                      City
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("sts", "string", "asc")}
                    >
                      Status
                    </Button>
                  </TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.travellersList &&
                  this.state.travellersList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((corporateUserRecord, index) => (
                      <TableRow key={index}>
                        <TableCell>{corporateUserRecord.pid}</TableCell>
                        <TableCell>
                          {`${corporateUserRecord.ti}
                        ${corporateUserRecord.fn}
                        ${corporateUserRecord.ln}`}
                        </TableCell>
                        <TableCell>{corporateUserRecord.em}</TableCell>
                        <TableCell>{corporateUserRecord.mo}</TableCell>
                        <TableCell>{corporateUserRecord.cty}</TableCell>
                        <TableCell>{corporateUserRecord.sts}</TableCell>
                        <TableCell>{corporateUserRecord.role}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              this.handleEdit(corporateUserRecord.pid)
                            }
                            className="blue-btn"
                          >
                            View/Edit
                          </Button>{" "}
                          &nbsp;&nbsp;
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              this.handleEdit(corporateUserRecord.pid)
                            }
                            className="blue-btn"
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                {this.state.travellersList &&
                  this.state.travellersList.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="14">No results found.</TableCell>
                    </TableRow>
                  )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[100]}
                    colSpan={12}
                    count={travellersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Hidden>
      </React.Fragment>
    );
  }
}

UserTravellerList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(UserTravellerList));
