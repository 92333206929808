import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import CustomSnackBar from "../common/CustomSnackBar";
import { Loading } from "../common/Loading";
import ReceiptForm from "./ReceiptForm";
import ReceiptTable from "./ReceiptTable";
import { getReceiptTransactions, getPendingReceiptQueues } from "../../services/receipt/ReceiptService";

class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      receiptList: [],
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  showLoading = loading => {
    this.setState({
      isLoading: loading
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  doSearch = async searchObj => {
    this.setState({ showLoading: true });
    let res = await getReceiptTransactions(searchObj);
    if(res["com.nextra.api.vobs.Receipt"]){
      this.setState({
        receiptList: res["com.nextra.api.vobs.Receipt"],
      })
    } else {
      this.showSnackBar("No record found!", "error");
    }
    this.setState({ showLoading: false });
  }

  pendingReceiptQueues = async () => {
    this.setState({ showLoading: true });
    let res = await getPendingReceiptQueues();
    if(res){
      this.setState({
        receiptList: res,
      })
    } else {
      this.setState({
        receiptList: [],
      })
      this.showSnackBar("No record found!", "error");
    }
    this.setState({ showLoading: false });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ReceiptForm
              doSearch={this.doSearch}
              pendingReceiptQueues={this.pendingReceiptQueues}
              showLoading={this.showLoading}
              showSnackBar={this.showSnackBar}
            />
            <br/>
            <ReceiptTable 
              receiptList={this.state.receiptList}
              showLoading={this.showLoading}
              showSnackBar={this.showSnackBar}
            />
          </Grid>
        </Grid>
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Receipt;
