import { apiPost, getUserAuth } from "../common/CommonService";

export const searchTransaction = async obj => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "searchtransaction",
      agentid: userAuth.corporateid,
      ...obj,
    };

    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null") || res.list[0] === "") {
      return undefined;
    } else {
      return res.list[0];
    }
  } catch (err) {
    return undefined;
  }
};
