import React, { Component } from "react";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Button
} from "@material-ui/core";
import { getUserSettings, addUserSettings } from "../../services/user/UserService";
import { Loading } from "../../components/common/Loading";
import CustomSnackBar from "../../components/common/CustomSnackBar";

class UserSettingsPage extends Component {
  txId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settingsList: [],
      addSettings: {
        cid: "",
        user_key: "",
        user_value: "",
      },
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  async componentDidMount() {
    this.txId = window.location.pathname.split("/").pop();
    await this.getUserSettingsCall(this.txId);
  }

  getUserSettingsCall = async (txId) => {
    this.setState({
      isLoading: true
    });

    this.setState(prevState => ({
      addSettings: {
        ...prevState.addSettings,
        cid: txId
      }
    }));

    let res = await getUserSettings(txId);
    if (res) {
      this.setState({
        isLoading: false,
        settingsList: res
      });
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  showSnackBar = async (msg, variant) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: variant
      }
    });
    if (variant === "success") {
      await this.getUserSettingsCall(this.txId);
    }
  };

  handelChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      addSettings: {
        ...prevState.addSettings,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  handleAddSettings = async event => {
    event.preventDefault();
    let isValid = true;
    Object.keys(this.state.addSettings).forEach(property => {
      if (this.state.addSettings[property] === "") {
        isValid = false;
      }
    });
    if (isValid) {
      this.setState({
        isLoading: true
      });

      let res = await addUserSettings(this.state.addSettings);
      
      if (res) {
        if (res.ApiStatus.Status === "NOK") {
          this.showSnackBar(res.ApiStatus.Message, "error");
        } else {
          this.showSnackBar(res.ApiStatus.Message, "success");
        }
      } else {
        this.showSnackBar(
          "Failed to add User Settings. Please try again.",
          "error"
        );
      }
      this.setState({
        isLoading: false
      });
    } 
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Typography variant="h6" className="mb-8">
          User Settings
        </Typography>

        <Grid container spacing={16}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Paper className="padding-16">
              <form onSubmit={this.handleAddSettings}>
              <Grid container spacing={16}>
                <Typography variant="subtitle1" className="bold">
                  Add New Settings
                </Typography>
                <hr className="full-width mb-16" />
                
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Key"
                    name="user_key"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.addSettings.user_key}
                    onChange={this.handelChange}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Value"
                    name="user_value"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.addSettings.user_value}
                    onChange={this.handelChange}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <Button
                    className="left"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Add Settings
                  </Button>
                </Grid>
              </Grid>
              </form>
            </Paper>
          </Grid>
          

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Paper className="padding-16">
            {this.state.settingsList.length > 0 && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="subtitle1" className="bold">
                  Settings List
                </Typography>
                <hr className="full-width mb-16" />
              </Grid>
            )}
            {this.state.settingsList && this.state.settingsList.map((eachRecord, index) => (
              <Grid container spacing={16} key={index}>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Key"
                    name="uaddsettingkey"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={eachRecord.settingkey}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Value"
                    name="uaddsettingvalue"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={eachRecord.settingvalue}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>

                  <Button
                    className="left"
                    variant="contained"
                    color="primary"
                  >
                    Edit Settings
                  </Button>
                  {"  "}
                  <Button
                    className="left"
                    variant="contained"
                    color="secondary"
                  >
                    Delete Settings
                  </Button>

                </Grid>
              </Grid>
            ))}
            {this.state.settingsList.length === 0 && (
              <div>
                <Typography align="center" variant="h6">
                  No settings found for the Transaction No: {this.txId}
                </Typography>
              </div>
            )}
            </Paper>
          </Grid>
        </Grid>  
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default UserSettingsPage;
