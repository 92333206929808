import React, { Component } from "react";
import HotelBillingMain from "../../components/offline-billing/hotel-billing/HotelBillingMain";
import "./common.css";

class HotelBillingPage extends Component {
  render() {
    return (
      <HotelBillingMain />
    );
  }
}

export default HotelBillingPage;
