import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CreditCard from "mdi-material-ui/CreditCard";
import Cash from "mdi-material-ui/Cash";
import CardsMain from "../../../components/inventory/cards/CardsMain";
import BalanceRequestsMain from "../../../components/inventory/cards/BalanceRequestsMain";
import "./CardsPage.css";
class CardsPage extends Component {
  constructor(props) {
    super(props);
    let num = 1;
    let tab = Number(window.location.hash.split("_").pop());
    if (tab) num = tab;
    this.state = {
      selectedItem: num,
      anchorEl: null
    };
  }

  handleItemClick(itemId, txt) {
    this.setState({ selectedItem: itemId, anchorEl: null });
    window.location.hash = txt;
  }

  renderSwitch(comp) {
    switch (comp) {
      case 1:
        return <CardsMain />;
      case 2:
        return <BalanceRequestsMain />;
      default:
        return <div>Invalid</div>;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={16} className="mb-8">
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <MenuItem
              className={`${this.state.selectedItem === 1 && "active-menu"}`}
              onClick={() => this.handleItemClick(1, "cards_1")}
            >
              <ListItemIcon>
                <CreditCard />
              </ListItemIcon>
              <ListItemText primary="Cards" />
            </MenuItem>
            <MenuItem
              className={`${this.state.selectedItem === 2 && "active-menu"}`}
              onClick={() => this.handleItemClick(2, "balance-requests_2")}
            >
              <ListItemIcon>
                <Cash />
              </ListItemIcon>
              <ListItemText primary="Balance Requests" />
            </MenuItem>
          </Grid>
          <Grid item lg={9} md={9} sm={9} xs={12}>
            <BrowserRouter>
              {this.renderSwitch(this.state.selectedItem)}
            </BrowserRouter>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default CardsPage;
