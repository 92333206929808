import { apiPost, getUserAuth, getUserProfile } from "../common/CommonService";

export const getHotelBookingQueues = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "gethotelbookingqueues"
    };
    let res = await apiPost(reqObj);
    if (res.list[0].HotelInvoice) {
      return Array.isArray(res.list[0].HotelInvoice) ? res.list[0].HotelInvoice : [res.list[0].HotelInvoice];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getHotelCancellationQueues = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "gethotelcancellationqueues",
      agentid: getUserProfile().corporateid
    };
    let res = await apiPost(reqObj);
    if (res.list[0].HotelCancellation) {
      return res.list[0].HotelCancellation;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const getHotelInvoice = async txId => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "gethotelinvoice",
      txid: txId
    };
    let res = await apiPost(reqObj);
    if (res.HotelInvoice) {
      return res.HotelInvoice;
    } else {
      return undefined;
    }
  } catch (err) {
    return undefined;
  }
};

export const updateHotelBooking = async obj => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatehotelbooking",
      ...obj
    };
    let res = await apiPost(reqObj);
    if (res.ResponseStatus.status === "OK") {
      return res;
    }
    return undefined;
  } catch (err) {
    return undefined;
  }
};

export const performHotelRefund = async obj => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "performhotelrefund",
      txid: obj.txid,
      adminid: userAuth.corporateid,
      agentid: obj.agentid,
      reftxid: obj.refTxId,
      refundamount_ota: obj.refundAmount,
      refundamount_sup: obj.canxChargeSup,
      canxcharge_sup: obj.canxChargeSup,
      canxcharge_ota: obj.canxChargeOTA,
      adminremarks: obj.adminRemarks,
      sup_canxref: obj.txid,
      sup_bookingref: obj.refTxId
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const cancelHotelTicket = async txId => {
  var reqObj = {
    profileid: getUserProfile().profileid,
    corporateid: getUserProfile().corporateid,
    opid: "TG-HOTELCANCELLATIONREQUEST",
    authtoken: getUserAuth().authtoken,
    reftxid: txId,
    remarks: `Cancelling Ticket for txId ${txId}`,
    roomindexes: "all"
    // tcprofileid: getProfile().profileid,
  };
  let jsonData = JSON.stringify(reqObj);
  let obj = {
    ...reqObj,
    jsondata: jsonData
  };
  let res = await apiPost(obj);
  return res;
};

export const rollbackHotelTicket = async (txId, remarks) => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "rollbackticket",
      txtype: "hotel",
      txid: txId,
      agentid: userAuth.corporateid,
      remarks: remarks,
    };
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    }
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getTransactionLog = async txId => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getbookinglogs",
      txid: txId
    };
    let res = await apiPost(reqObj);
    if (res.list[0] === "") {
      return [];
    } else {
      return Array.isArray(res.list[0].TransactionLogger) ? res.list[0].TransactionLogger : [res.list[0].TransactionLogger];
    }
  } catch (err) {
    return [];
  }
};

export const updateHotelFare = async (txnId, fares, remarks) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatehotelfare",
      jsondata: JSON.stringify({
        HotelRoomList: {
          txid: txnId,
          remark: remarks,
          hotelroomlist: [
            {
              HotelRoomDetail: fares,
            },
          ],
        },
      }),
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getApiBookingLog = async txId => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getapibookinglog",
      txid: txId
    };
    let res = await apiPost(reqObj);
    if (res.list[0]) {
      return res.list[0];
    } else {
      return undefined;
    }
  } catch (err) {
    return undefined;
  }
};

export const rejectHotelCancellation = async (txId, adminRemarks) => {
  try {
    let userAuth = getUserAuth();
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "rejecthotelcancellation",
      agentid: userAuth.corporateid,
      txid: txId,
      remarks: adminRemarks,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};
