import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

class FareBreakUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fareObj: props.fareObj
    };
  }

  handleChange = event => {
    let eve = { ...event };
    this.setState(
      prevState => ({
        fareObj: {
          ...prevState.fareObj,
          [eve.target.name]: eve.target.value
        }
      }),
      () => this.updateTotalInvoice()
    );
  };

  updateTotalInvoice = () => {
    const { tax, basefare, servicefee } = this.state.fareObj;
    this.setState(
      prevState => ({
        fareObj: {
          ...prevState.fareObj,
          totalinvoice: Number(tax) + Number(basefare) + Number(servicefee),
        }
      }),
      () => this.props.updateFare(this.state.fareObj)
    );
  }

  render() {
    return (
      <Paper className="padding-16 farebreakup-form">
        <Typography variant="subtitle1" className="bold">
          Fare Breakup
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              required
              type="number"
              name="basefare"
              label="Base Fare (Rs)"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.fareObj.basefare}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              required
              name="servicefee"
              type="number"
              label="Service Fee (Rs)"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.fareObj.servicefee}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              required
              name="tax"
              type="number"
              label="Tax (Rs)"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.fareObj.tax}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
            <TextField
              variant="outlined"
              readOnly
              required
              fullWidth
              name="totalinvoice"
              type="number"
              label="Total Invoice (Rs)"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.fareObj.totalinvoice}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default FareBreakUp;
