import React, { Component } from "react";
import { MenuItem, Select, Button, Grid, Divider } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";

class CTSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchObj: {
        type: "getflighttransactions",
        from: moment()
          
          .format("YYYY-MM-DD"),
        to: moment().add(1, "days").format("YYYY-MM-DD")
      },
      tabValue: 0,
      travelTtype: "gettodaystravelflights",
    };
  }

  handleChange = (val, name) => {
    this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: val
      }
    }));
  };

  handleTabChange = (event, value) => {
    if(this.state.tabValue !== value){
      if(value === 1){
        this.props.searchTodayBooking(this.state.travelTtype);
      } else if(value === 0){
        this.props.doSearch(this.state.searchObj);
      }
    }
    this.setState({
      tabValue: value,
    });
  };

  handleTodayBookingChange = (event) => {
    this.setState({
      travelTtype: event.target.value,
    });
    this.props.searchTodayBooking(event.target.value);
  };

  render() {
    return (
      <React.Fragment>
        <Tabs 
          value={this.state.tabValue} 
          onChange={this.handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          centered
        >
          <Tab label="All Transactions" />
          <Tab label="Today's Bookings" />
        </Tabs>
        <Divider />
        <br/>
        { this.state.tabValue === 0 && (
          <Grid container spacing={16}>
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Select
                fullWidth
                value={this.state.searchObj.type}
                onChange={event => this.handleChange(event.target.value, "type")}
                name="type"
              >
                <MenuItem value="getflighttransactions"> Flight Bookings</MenuItem>
                <MenuItem value="getflighttransactionspending"> Pending/Failed Flight Bookings</MenuItem>
                <MenuItem value="gethoteltransactions"> Hotel Bookings</MenuItem>
                <MenuItem value="getbustransactions"> Bus Bookings</MenuItem>
                <MenuItem value="gettraintransactions"> Train Bookings</MenuItem>
                <MenuItem value="gettraintatkaltransactions"> Train Tatkal Bookings</MenuItem>
                <MenuItem value="getcabtransactions"> Cab Bookings</MenuItem>
              </Select>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <DayPickerInput
                className="full-width"
                value={this.state.searchObj.from}
                onDayChange={day => this.handleChange(day, "from")}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <DayPickerInput
                className="full-width"
                value={this.state.searchObj.to}
                onDayChange={day => this.handleChange(day, "to")}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.props.doSearch(this.state.searchObj)}
              >
                Fetch Transactions
              </Button>
            </Grid>
          </Grid>
        )}
        { this.state.tabValue === 1 && (
          <Grid container spacing={16} >
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Select
                fullWidth
                value={this.state.travelTtype}
                onChange={this.handleTodayBookingChange}
                name="travelTtype"
              >
                <MenuItem value="gettodaystravelflights">Flight</MenuItem>
                <MenuItem value="gettodaystraveltrains">Train</MenuItem>
                <MenuItem value="gettodayscheckinhotels">Hotel</MenuItem>
                <MenuItem value="gettodaystravelbuses">Bus</MenuItem>
                <MenuItem value="unflownpendingbookings">Unflown</MenuItem>
              </Select>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

export default CTSearch;
