import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Drawer,
  TextField,
  NativeSelect,
  InputLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  InputAdornment,
  OutlinedInput,
  FormControl,
  MenuItem
} from "@material-ui/core";
import Async from "react-select/lib/Async";
import {
  getCabs,
  addCab,
  updateCab,
  getCities,
  getPlaces,
  getPlaceDetails
} from "../../services/inventory/CabsService";
import { Loading } from "../../components/common/Loading";
import { formatCurrency, debounce } from "../../components/common/Utils";
import CustomSnackBar from "../../components/common/CustomSnackBar";
import "./CabsPage.css";
import {
  getAllCustomersAdmin
} from "../../services/suppliers/SupplierService";
import { getSuppliers, getSuppliersById } from "../../services/queues/FlightQueuesService";

class CabsPage extends React.Component {
  isEdit = false;
  constructor(props) {
    super(props);
    this.state = {
      selectedCity: undefined,
      cabObj: undefined,
      citiesList: [],
      cabsList: undefined,
      filteredCabs: undefined,
      isLoading: false,
      isOpenDrawer: false,
      agentsList: [],
      agentsListFiltered: [{ 
        value: "all", 
        label: "Tripgain (all)" 
      }],
      suppliers: [],
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  filterAgentsList = (inputValue: string) => {
    return this.state.agentsListFiltered.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  getFilterAgentsList = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(this.filterAgentsList(inputValue));
    }, 1000);
  });

  handleSearchChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.cabsList.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredCabs: data
    });
  }, 500);

  initCabObject = () => {
    this.setState({
      cabObj: {
        cabid: "",
        carname: "",
        cartype: "sedan",
        maxnumpeople: 4,
        acprice: 0,
        nonacprice: 0,
        acnonac: "ac",
        perkmcost: 10,
        extrahourcost: 100,
        driverbattaperday: 0,
        tripdestination: "Bangalore",
        inserttime: "",
        termsandconditions: "",
        status: "active",
        servicetax: 0,
        commission: 0,
        tripcity: "all",
        transportation_type: "airporttransfer",
        numdays: 0,
        numnights: 0,
        packagename: "",
        totalcost: 800,
        currency: "INR",
        package_description: "NA",
        currency_type: "INR",
        policyinfo: "NA",
        supplier_currency: "INR",
        supplier_amount: 0,
        adult_price: 0,
        child_price: 0,
        supplierid: "",
        daysavail: "yyyyyyy",
        cancellationpolicy: "",
        cabimage: "",
        areascovered: "",
        areas_geolocation: "",
        operating_city: "",
        bookingopen_hours: "6",
        cabinventoryid: "",
        waitingcharges: 2,
        nightcharges: 250,
        operatinghours: "0000-2359",
        pricetype: "higheroftwo",
        minfare: 80,
        minkms: 8,
        allowed_kms: 25,
        allowed_hours: 1,
        owner: ""
      }
    });
  };

  async componentDidMount() {
    this.initCabObject();
    await this.getCabCities();
    await this.getCabsList();
    await this.getAgentsList();
    await this.getSuppliersList();
  }

  getAgentsListDropdown = (list) => {
    let agentsListFiltered = [{
          value: "all", 
          label: "Tripgain (all)"
        }];
     for(let i=0;i<list.length;i++){
        agentsListFiltered.push({
          value: list[i].profileid, 
          label: list[i].corporate_name+' ('+list[i].corporateid+')',
          xtraval: list[i].corporateid
        });
     }
    this.setState({
      agentsListFiltered: agentsListFiltered
    });
  }

  getAgentsList = async () => {
    this.showLoading(true);
    let res = await getAllCustomersAdmin();
    this.setState({
      agentsList: res
    });
    this.showLoading(false);
    this.getAgentsListDropdown(res);
  };

  getSuppliersList = async () => {
    this.showLoading(true);
    let res = await getSuppliers();
    this.setState({
      suppliers: res
    });
    this.showLoading(false);
  };

  getSuppliersListById = async (ownerid) => {
    this.showLoading(true);
    let res = await getSuppliersById(ownerid);
    this.setState({
      suppliers: res
    });
    this.showLoading(false);
  };

  getCabCities = async () => {
    this.showLoading(true);
    let res = await getCities();
    this.setState({
      citiesList: res
    });
    this.showLoading(false);
  };

  getCabsList = async () => {
    this.showLoading(true);
    let res = await getCabs();
    this.setState({
      cabsList: res,
      filteredCabs: res
    });
    this.showLoading(false);
  };

  showLoading = loading => {
    this.setState({
      isLoading: loading
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  handleCityChange = selectedOption => {
    this.setState({ selectedCity: selectedOption });
  };

  openCabDrawer = flag => {
    if (flag === false) {
      this.isEdit = false;
      this.initCabObject();
    }
    this.setState({
      isOpenDrawer: flag
    });
  };

  encodeImageFileAsURL = () => {
    let base64Str = "";
    var filesSelected = document.getElementById("cabImage").files;
    if (filesSelected.length > 0) {
      var fileToLoad = filesSelected[0];
      var fileReader = new FileReader();

      fileReader.onload = fileLoadedEvent => {
        base64Str = fileLoadedEvent.target.result;
        document.getElementById("cab_image_preview").src = base64Str;
        this.setState(prevState => ({
          cabObj: {
            ...prevState.cabObj,
            cabimage: base64Str
          }
        }));
      };
      fileReader.readAsDataURL(fileToLoad);
    } else {
      this.setState(prevState => ({
        cabObj: {
          ...prevState.cabObj,
          cabimage: ""
        }
      }));
    }
  };

  getAllPlaces(input, callback) {
    return getPlaces(input, callback);
  }

  handleSaveOrUpdate = async isUpdate => {
    this.showLoading(true);
    let res = undefined;
    if (isUpdate) {
      res = await updateCab(this.state.cabObj);
    } else {
      res = await addCab(this.state.cabObj);
    }
    if (res) {
      if (res.ResponseStatus.status === "OK") {
        this.showSnackBar(res.ResponseStatus.message, "success");
      } else {
        this.showSnackBar(res.ResponseStatus.message, "error");
      }
    } else {
      this.showSnackBar(
        "Failed to add / update a cab. Please try again.",
        "error"
      );
    }
    this.openCabDrawer(false);
    this.showLoading(false);
    await this.getCabsList();
  };

  handleInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      cabObj: {
        ...prevState.cabObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  handleCarOwnerInputChange = async obj => {
    if (obj !== null) {
      await this.getSuppliersListById(obj.xtraval);  
      this.setState(prevState => ({
        cabObj: {
          ...prevState.cabObj,
          owner: obj.value,
          supplierid: ''
        }
      }));
    }
  };

  showEdit = (cab, index) => {
    this.isEdit = true;
    this.openCabDrawer(true);
    this.setState({
      cabObj: cab
    });
  };

  handleSnakBarClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  handlePlaceSelect = async obj => {
    if (obj !== null) {
      let res = await getPlaceDetails(obj);
      if (res) {
        // this.fromLocation = obj;
        this.setState(prevState => ({
          cabObj: {
            ...prevState.cabObj,
            areascovered: obj.label,
            areas_geolocation: `${res.lat},${res.lng}`
          }
        }));
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16} className="mb-8">
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Typography variant="h6"> Cabs </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Button
              className="right"
              variant="contained"
              size="small"
              color="primary"
              onClick={() => this.openCabDrawer(true)}
            >
              Add Cab
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              fullWidth
              name="searchTerm"
              onChange={this.handleSearchChange}
              variant="outlined"
              label="Search"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Hidden smDown>
          <Paper className="padding-16">
            <Table className="auto-table">
              <TableHead>
                <TableRow>
                  <TableCell>Car Name</TableCell>
                  <TableCell>Car Type</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Trip City</TableCell>
                  <TableCell>Transportation Type</TableCell>
                  <TableCell>Total Cost</TableCell>
                  <TableCell>KM Cost</TableCell>
                  <TableCell>Extra Hour Cost</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filteredCabs &&
                  this.state.filteredCabs.map((cab, index) => (
                    <TableRow key={index}>
                      <TableCell>{cab.carname}</TableCell>
                      <TableCell>{cab.cartype}</TableCell>
                      <TableCell>{cab.owner}</TableCell>
                      <TableCell>{cab.tripcity}</TableCell>
                      <TableCell>{cab.transportation_type}</TableCell>
                      <TableCell>{formatCurrency(cab.totalcost)}</TableCell>
                      <TableCell>{formatCurrency(cab.perkmcost)}</TableCell>
                      <TableCell>{formatCurrency(cab.extrahourcost)}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => this.showEdit(cab, index)}
                          className="blue-btn"
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.filteredCabs &&
                  this.state.filteredCabs.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="8">
                        No cabs found. Click on <kbd>Add Cab</kbd> button on the
                        top right corner to add a cab.
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden mdUp>
          {this.state.filteredCabs &&
            this.state.filteredCabs.map((cab, index) => (
              <Paper className="padding-16 mb-8 relative" key={index}>
                <Grid container spacing={8}>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Car Name
                    </Typography>
                    <Typography>{cab.carname}</Typography>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Car Type
                    </Typography>
                    <Typography>{cab.cartype}</Typography>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Car Owner
                    </Typography>
                    <Typography>{cab.owner}</Typography>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Trip City
                    </Typography>
                    <Typography>{cab.tripcity}</Typography>
                  </Grid>
                  <Grid item sm={3} xs={6}>
                    <Typography variant="caption" className="caption">
                      Transportation Type
                    </Typography>
                    <Typography>{cab.transportation_type}</Typography>
                  </Grid>
                  <Grid item sm={3} xs={4}>
                    <Typography variant="caption" className="caption">
                      Total Cost
                    </Typography>
                    <Typography>{formatCurrency(cab.totalcost)}</Typography>
                  </Grid>
                  <Grid item sm={3} xs={4}>
                    <Typography variant="caption" className="caption">
                      Per Km Cost
                    </Typography>
                    <Typography>{formatCurrency(cab.perkmcost)}</Typography>
                  </Grid>
                  <Grid item sm={3} xs={4}>
                    <Typography variant="caption" className="caption">
                      Extra Hour Cost
                    </Typography>
                    <Typography>{formatCurrency(cab.extrahourcost)}</Typography>
                  </Grid>
                </Grid>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => this.showEdit(cab, index)}
                  className="blue-btn top-right"
                >
                  Edit
                </Button>
              </Paper>
            ))}
          {this.state.filteredCabs && this.state.filteredCabs.length === 0 && (
            <div>
              <Typography align="center">
                No cabs found. Click on <kbd>Add Cab</kbd> button on the top
                right corner to add a cab.
              </Typography>
            </div>
          )}
          <Typography />
        </Hidden>
        <Drawer
          className="cabs-drawer"
          open={this.state.isOpenDrawer}
          anchor="right"
        >
          {this.state.cabObj && (
            <Grid container spacing={8}>
              <Grid item lg={6} md={6} sm={6} xs={8}>
                <Typography variant="h6">Add a Cab</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={4}>
                <Button
                  className="right"
                  onClick={() => this.openCabDrawer(false)}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  Close
                </Button>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <hr className="full-width" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  fullWidth
                  name="carname"
                  value={this.state.cabObj.carname}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  label="Car Name"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="cartype"
                  >
                    Car Type
                  </InputLabel>
                  <NativeSelect
                    input={
                      <OutlinedInput
                        labelWidth={100}
                        id="cartype"
                        name="cartype"
                      />
                    }
                    fullWidth
                    variant="outlined"
                    name="cartype"
                    value={this.state.cabObj.cartype}
                    onChange={this.handleInputChange}
                  >
                    <option value="sedan">Sedan</option>
                    <option value="economy">Economy</option>
                    <option value="luxury">Luxury</option>
                    <option value="suv">SUV</option>
                    <option value="shared">Shared</option>
                    <option value="minivan">Mini Van</option>
                    <option value="van">Van</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Async 
                  className="areas-covered"
                  placeholder="Car Owner"
                  onChange={value => this.handleCarOwnerInputChange(value)}
                  loadOptions={this.getFilterAgentsList} 
                  isClearable
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  select
                  fullWidth
                  label="Supplier"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  name="supplierid"
                  value={this.state.cabObj.supplierid}
                  onChange={this.handleInputChange}
                >
                  {this.state.suppliers.map((sup, index) => (
                    <MenuItem key={index} value={sup.supplier_id}>
                      {`${sup.supplier_name} (${sup.agentid})`}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  inputProps={{
                    min: 0,
                    max: 8
                  }}
                  type="number"
                  fullWidth
                  name="maxnumpeople"
                  value={this.state.cabObj.maxnumpeople}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  label="Max No. Peolple"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  type="number"
                  fullWidth
                  name="perkmcost"
                  value={this.state.cabObj.perkmcost}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  label="Per KM Cost"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  type="number"
                  fullWidth
                  name="extrahourcost"
                  value={this.state.cabObj.extrahourcost}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  label="Extra Hour Cost"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="tripcity"
                  >
                    Trip City
                  </InputLabel>
                  <NativeSelect
                    input={
                      <OutlinedInput
                        labelWidth={100}
                        id="tripcity"
                        name="tripcity"
                      />
                    }
                    fullWidth
                    name="tripcity"
                    value={this.state.cabObj.tripcity}
                    onChange={this.handleInputChange}
                  >
                    <option value="all">All </option>
                    {this.state.citiesList.map((obj, idx) => (
                      <option value={obj.locationname} key={idx}>
                        {obj.locationname} ({obj.state})
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Async
                  className="areas-covered"
                  placeholder="Area Covered"
                  onChange={value => this.handlePlaceSelect(value)}
                  loadOptions={debounce(this.getAllPlaces, 500)}
                  isClearable
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="caption">Area Covered</Typography>
                <Typography>
                  {this.state.cabObj.areascovered ||
                    "Not Selected, search for the area and select"}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel
                    ref={ref => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="transportation_type"
                  >
                    Transportation Type{" "}
                  </InputLabel>
                  <NativeSelect
                    label="Transportation Type"
                    input={
                      <OutlinedInput
                        labelWidth={100}
                        name="transportation_type"
                        id="transportation_type"
                      />
                    }
                    fullWidth
                    name="transportation_type"
                    value={this.state.cabObj.transportation_type}
                    onChange={this.handleInputChange}
                  >
                    <option value="airporttransfer">Airport Transfer</option>
                    <option value="localrental"> Local Rental </option>
                    <option value="outstation"> Out Station </option>
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  fullWidth
                  name="totalcost"
                  type="number"
                  value={this.state.cabObj.totalcost}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  label="Total Cost"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    )
                  }}
                />
              </Grid>
              {this.state.cabObj.transportation_type === "localrental" && (
                <React.Fragment>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <TextField
                      fullWidth
                      name="packagename"
                      value={this.state.cabObj.packagename}
                      onChange={this.handleInputChange}
                      variant="outlined"
                      label="Package Name"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <TextField
                      fullWidth
                      type="number"
                      name="waitingcharges"
                      value={this.state.cabObj.waitingcharges}
                      onChange={this.handleInputChange}
                      variant="outlined"
                      label="Waiting Charges Per Min"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <TextField
                      fullWidth
                      type="number"
                      name="allowed_hours"
                      value={this.state.cabObj.allowed_hours}
                      onChange={this.handleInputChange}
                      variant="outlined"
                      label="Allowed Hours"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <TextField
                      type="number"
                      fullWidth
                      name="allowed_kms"
                      value={this.state.cabObj.allowed_kms}
                      onChange={this.handleInputChange}
                      variant="outlined"
                      label="Allowed KMs"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </React.Fragment>
              )}

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  type="file"
                  fullWidth
                  id="cabImage"
                  name="cabimage"
                  onChange={this.encodeImageFileAsURL}
                  variant="outlined"
                  label="Cab Image"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <img
                  src={this.state.cabObj.cabimage}
                  id="cab_image_preview"
                  alt=""
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows="4"
                  name="termsandconditions"
                  value={this.state.cabObj.termsandconditions}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  label="Terms and Conditions"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows="4"
                  name="cancellationpolicy"
                  value={this.state.cabObj.cancellationpolicy}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  label="Cancellation Policy"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {this.isEdit === true ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSaveOrUpdate(true)}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSaveOrUpdate(false)}
                  >
                    Save
                  </Button>
                )}
                <br />
                <br />
              </Grid>
            </Grid>
          )}
        </Drawer>
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleSnakBarClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default CabsPage;
