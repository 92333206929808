import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Hidden,
  CircularProgress,
  Avatar,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  formatCurrency,
  formatTimeString,
  formatToMonthDate,
  getConnectingTime,
  ConvertTravelTime
} from "../../common/Utils";
import { AirplaneTakeoff, Airplane, Phone, Hotel } from "mdi-material-ui";
import { getFlightInvoice } from "../../../services/queues/FlightQueuesService";
import EmailTicket from "../common/EmailTicket";
import { withLoadingContext } from "../../common/LoadingProvider";
import { withCustomSnackBarContext } from "../../common/CustomSnackBarProvider";
import CustomSnackBar from "../../common/CustomSnackBar";
import CancelTicket from "./CancelTicket";
import RescheduleTicket from "./RescheduleTicket";

const IMAGE_PATH = process.env.REACT_APP_FLIGHT_CARRIER_IMG;

class ETicket extends React.PureComponent {
  profile = {};
  txid = undefined;
  paxTicketsDict = {};
  specialServicesDict = {};
  paxPNRDict = {};
  constructor(props) {
    super(props);
    this.state = {
      hasTicket: undefined,
      eticket: undefined,
      cancelTicket: false,
      rescheduleTicket: false,
      emailTicket: false,
      openSnackBar: false,
      snackbarMsg: "",
      msgType: "success",
    };
    this.txid = props.txId || window.location.pathname.split("/").pop();
  }

  async componentDidMount() {
    this.props.loadingContext.show();
    await this.getFlightTicket();
    this.props.loadingContext.hide();
  }

  getFlightTicket = async () => {
    let data = await getFlightInvoice(this.txid);
    if (data !== undefined) {
      data.passengerRecords[0].PassengerRecord = Array.isArray(
        data.passengerRecords[0].PassengerRecord
      )
        ? data.passengerRecords[0].PassengerRecord
        : [data.passengerRecords[0].PassengerRecord];
      var ticketsArr = Array.isArray(data.flightTickets[0].FlightTicket)
        ? data.flightTickets[0].FlightTicket
        : [data.flightTickets[0].FlightTicket];
      this.paxTicketsDict = ticketsArr.reduce((acc, item) => {
        acc[`${item.trip_number}_${item.paxid}`] = item.ticket_number;
        return acc;
      }, {});
      this.paxPNRDict = ticketsArr.reduce((acc, item) => {
        acc[`${item.trip_number}_${item.paxid}`] = item.airline_pnr;
        return acc;
      }, {});
      const specialServicesArr =
        data.passengerssrrecords[0] === ""
          ? []
          : Array.isArray(data.passengerssrrecords[0].FlightPassengerSSR)
          ? data.passengerssrrecords[0].FlightPassengerSSR
          : [data.passengerssrrecords[0].FlightPassengerSSR];
      this.specialServicesDict = specialServicesArr.reduce((acc, service) => {
        if (service.ssrtype === "meal" || service.ssrtype === "baggage") {
          acc[`${service.jtype}_${service.ssrtype}_${service.paxid}`] =
            service.ssrname;
        } else {
          acc[`${service.jtype}_${service.ssrtype}_${service.paxid}`] =
            service.ssrvalue;
        }
        return acc;
      }, {});
      this.setState({
        hasTicket: true,
        eticket: data
      });
    } else {
      this.setState({
        hasTicket: false
      });
    }
  };

  getPassengers = (passengers, jtype) => {
    return (
      <React.Fragment>
        <br />
        <br />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Traveller Name</TableCell>
              <TableCell>Cabin Class</TableCell>
              <TableCell>Airline PNR</TableCell>
              <TableCell>Ticket Number</TableCell>
              <TableCell>Seat/Meal/Baggage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passengers.map((person, index) => (
              <TableRow key={index}>
                <TableCell>
                  {person.title}. {person.first_name} {person.last_name}
                </TableCell>
                <TableCell>
                  {this.state.eticket.flightRecord.cabinclass}
                </TableCell>
                <TableCell>
                  {this.paxPNRDict[`${person.trip_number}_${person.paxid}`]}
                </TableCell>
                <TableCell>
                  {this.paxTicketsDict[`${person.trip_number}_${person.paxid}`]}
                </TableCell>
                <TableCell>
                  <Typography>
                    Seat:
                    {this.specialServicesDict[`${jtype}_seat_${person.paxid}`]}
                  </Typography>
                  <Typography>
                    Meal:
                    {this.specialServicesDict[`${jtype}_meal_${person.paxid}`]}
                  </Typography>
                  <Typography>
                    Baggage:
                    {
                      this.specialServicesDict[
                        `${jtype}_baggage_${person.paxid}`
                      ]
                    }
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  };

  flightInfo = sector => {
    return (
      <Grid
        key={sector.flight_number}
        className="text-center ticket-flight-details"
        container
        spacing={8}
      >
        <Hidden mdUp>
          <Grid item xs={12}>
            <Typography variant="body2">
              <img
                className="flt-img"
                alt="Flight"
                src={`${IMAGE_PATH + sector.carrier.split("-")[0]}.jpg`}
              />
              &nbsp;
              <span>
                {sector.carrier.split("-")[1]} - ({sector.carrier.split("-")[0]}
                &nbsp;
                {sector.flight_number})
              </span>
            </Typography>
            <br />
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1" className="bold">
              {sector.origin_fullname} ({sector.origin})
            </Typography>
            <small> on {formatToMonthDate(sector.jdate)} , </small>
            <Typography variant="subtitle1" className="bold">
              {formatTimeString(sector.departure_time)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Airplane className="rotate-90" />
            <Typography variant="subtitle1" className="bold">
              {ConvertTravelTime(sector.sector_duration)}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle1" className="bold">
              {sector.destination_fullname} ({sector.destination})
            </Typography>
            <small> on {formatToMonthDate(sector.arrdate)} , </small>
            <Typography variant="subtitle1" className="bold">
              {formatTimeString(sector.arrival_time)}
            </Typography>
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item lg={2} md={2} sm={5} className="print-al no-print">
            <Avatar
              className="flt-img"
              alt=""
              src={`${IMAGE_PATH + sector.carrier.split("-")[0]}.jpg`}
            />
            <Typography variant="body2">
              {sector.carrier.split("-")[1]}
            </Typography>
            <Typography variant="body1">
              ({sector.carrier.split("-")[0]}
              &nbsp;
              {sector.flight_number})
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12} className="print-al">
            <Typography variant="h6">
              {sector.origin_fullname} ({sector.origin})
            </Typography>
            <small> on {formatToMonthDate(sector.jdate)} , </small>
            <Typography variant="h6">
              {formatTimeString(sector.departure_time)}{" "}
            </Typography>
          </Grid>
          <Grid item lg={2} md={4} sm={4} xs={12} className="print-al">
            <div className="only-print">
              <Typography variant="body2">
                {sector.carrier.split("-")[1]} ({sector.carrier.split("-")[0]}
                &nbsp;
                {sector.flight_number})
              </Typography>
              <img
                className="flt-img"
                alt=""
                src={`${IMAGE_PATH + sector.carrier.split("-")[0]}.jpg`}
              />
            </div>
            <div className="no-print">
              <Airplane className="rotate-90" />
            </div>
            <Typography variant="h6">
              {ConvertTravelTime(sector.sector_duration)}
            </Typography>
          </Grid>
          <Grid item lg={4} md={2} sm={4} xs={12} className="print-al">
            <Typography variant="h6">
              {sector.destination_fullname} ({sector.destination})
            </Typography>
            <small> on {formatToMonthDate(sector.arrdate)} , </small>
            <Typography variant="h6">
              {formatTimeString(sector.arrival_time)}
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
    );
  };

  showEmailDialog = () => {
    this.setState({
      emailTicket: true
    });
  };

  handleEmailClose = () => {
    this.setState({
      emailTicket: false
    });
  };

  showCancelDialog = () => {
    this.setState({
      cancelTicket: true,
    });
  };

  showRescheduleDialog = () => {
    this.setState({
      rescheduleTicket: true,
    });
  };

  hideReschedule = () => {
    this.setState({
      rescheduleTicket: false,
    });
  };

  hideCancel = () => {
    this.setState({
      cancelTicket: false,
    });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      openSnackBar: false,
    });
  };

  render() {
    const { eticket } = this.state;
    return (
      <div>
        {this.state.hasTicket && this.props.showPrintButton === true && (
          <div className="text-center print-btn">
            <br />
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => window.print()}
            >
              Print
            </Button>
            &nbsp;&nbsp;
            <Button size="small" variant="outlined" color="secondary" onClick={this.showCancelDialog}>
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button size="small" variant="outlined" color="secondary" onClick={this.showRescheduleDialog}>
              Reschedule
            </Button>
            &nbsp;&nbsp;
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={this.showEmailDialog}
            >
              Email
            </Button>
            &nbsp;&nbsp;
          </div>
        )}
        <br />
        {this.state.emailTicket === true && (
          <EmailTicket
            handleClose={this.handleEmailClose}
            txid={this.txid}
            opId="TG-FLIGHTCONFIRMATION"
            showEmail={true}
          />
        )}

        <Card>
          <CardContent>
            {this.state.hasTicket ? (
              this.state.eticket.flightRecord &&
              this.state.eticket.sectorRecords.map((sr, idx) => {
                var sectorList = sr.SectorRecord;
                var firstSector = {},
                  onwardSector = {},
                  returnSector = {};
                if (Array.isArray(sectorList)) {
                  onwardSector = sectorList.filter(x => x.flight_type === "Onward");
                  returnSector = sectorList.filter(x => x.flight_type === "Return");
                  firstSector = sectorList[0];
                } else {
                  firstSector = sectorList;
                  onwardSector = sectorList;
                }

                let prs = this.state.eticket.passengerRecords[0]
                  .PassengerRecord;
                var onwardPass = prs.filter(x => x.trip_number === 1);
                var returnPass = prs.filter(x => x.trip_number === 2);

                return (
                  <Grid container key={idx}>
                    <Grid item lg={6} md={6} sm={4} xs={4}>
                      <img
                        className="ticket-logo"
                        src="/images/logo.png"
                        alt="TRIPGAIN"
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={8} xs={8}>
                      <div className="right">
                        <Typography variant="subtitle1">
                          TripGain Booking Ref:
                        </Typography>
                        <Typography className="mt-5" variant="h6">
                          {firstSector.txid}
                        </Typography>
                      </div>
                    </Grid>
                    <hr className="full-width" />
                    {
                      <Grid container>
                        <Typography className="bold" variant="subtitle1">
                          <AirplaneTakeoff className="vertical-bottom" />{" "}
                          &nbsp;&nbsp;DEPARTING FLIGHT:
                        </Typography>
                        {Array.isArray(onwardSector)
                          ? onwardSector.map((sector, idx1) => {
                              return (
                                <Grid container key={idx1}>
                                  {this.flightInfo(sector)}
                                  {onwardSector.length > idx1 + 1 && (
                                    <Grid
                                      item
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="text-center change-over-msg"
                                    >
                                      <Typography variant="body1">
                                        Change planes at&nbsp;
                                        <strong>
                                          {sector.destination_fullname} (
                                          {sector.destination}
                                          ),
                                        </strong>{" "}
                                        Connecting Time:&nbsp;
                                        <strong>
                                          {getConnectingTime(
                                            sector.arrival_time,
                                            sector.arrdate,
                                            onwardSector[idx1 + 1]
                                              .departure_time,
                                            onwardSector[idx1 + 1].jdate
                                          )}
                                        </strong>
                                        | Connecting flight may depart from a
                                        different terminal
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              );
                            })
                          : this.flightInfo(sectorList)}
                        <br />
                        <br />
                        {this.getPassengers(onwardPass, "onward")}
                        {returnSector.length > 0 && (
                          <Grid container spacing={8}>
                            <Typography className="bold" variant="subtitle1">
                              <br /> <br />
                              <AirplaneTakeoff className="vertical-bottom" />{" "}
                              &nbsp;&nbsp;RETURNING FLIGHT:
                            </Typography>
                            {returnSector.map((sector, index) => {
                              return (
                                <Grid container key={index}>
                                  {this.flightInfo(sector)}
                                  {returnSector.length > index + 1 && (
                                    <Grid
                                      item
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="text-center change-over-msg"
                                    >
                                      <Typography variant="body1">
                                        Change planes at&nbsp;
                                        <strong>
                                          {sector.destination_fullname} (
                                          {sector.destination}
                                          ),&nbsp;
                                        </strong>
                                        Connecting Time:&nbsp;
                                        <strong>
                                          {getConnectingTime(
                                            sector.arrival_time,
                                            sector.arrdate,
                                            returnSector[index + 1]
                                              .departure_time,
                                            returnSector[index + 1].jdate
                                          )}
                                        </strong>
                                        | Connecting flight may depart from a
                                        different terminal
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              );
                            })}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              {returnPass.length > 0 &&
                                this.getPassengers(returnPass, "return")}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    }
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <br />
                      <Typography variant="subtitle1" className="bold">
                        FARE BREAKUP:
                      </Typography>
                      <br />
                      <Grid container>
                        <Grid item lg={4} md={4} sm={6} xs={8}>
                          <Typography variant="subtitle1">
                            Base fare:{" "}
                          </Typography>
                          <Typography variant="subtitle1">Tax:</Typography>
                          <Typography variant="subtitle1">Discount:</Typography>
                          <Typography variant="subtitle1">
                            Total Amount:{" "}
                          </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} sm={6} xs={4}>
                          <Typography variant="subtitle1">
                            {formatCurrency(
                              this.state.eticket.flightRecord.basefare
                            )}
                          </Typography>
                          <Typography variant="subtitle1">
                            {formatCurrency(
                              this.state.eticket.flightRecord.tax
                            )}
                          </Typography>
                          <Typography variant="subtitle1">
                            {formatCurrency(
                              this.state.eticket.flightRecord.total_amount -
                                (this.state.eticket.flightRecord.basefare +
                                  this.state.eticket.flightRecord.tax)
                            )}
                          </Typography>
                          <Typography variant="subtitle1" className="bold">
                            {formatCurrency(
                              this.state.eticket.flightRecord.total_amount
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <br />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="subtitle1" className="bold">
                        ABOUT THIS TRIP
                      </Typography>
                      <ul>
                        <li>
                          <Typography variant="body1">
                            Use your Trip ID for all communication with TripGain
                            about this booking.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            Check-in counters at all Airports close 45 minutes
                            before departure.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            Your carry-on baggage shouldn&#39;t weigh more than
                            7kgs.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            Carry photo identification, you will need it as
                            proof of identity while checking-in.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            If Cancellation is done through the customer support
                            executives assistance, we will levy Rs. 500 per
                            passenger per flight, however, if you do it online
                            using your TRIPGAIN account, we will only levy Rs.
                            250 per passenger per flight as TripGain Processing
                            charges. This is over and above the airline
                            cancellation charges.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="body1">
                            For hassle free refund processing, cancel/amend your
                            tickets with TripGain Customer Care instead of doing
                            so directly with Airline.
                          </Typography>
                        </li>
                      </ul>
                    </Grid>
                    <hr className="full-width" />
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      className="text-center"
                    >
                      <Phone />
                      <Typography variant="body2">TRIPGAIN support</Typography>
                      <Typography>(+91) 9380901964</Typography>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      className="text-center"
                    >
                      <Phone />
                      <Typography variant="body2">TRIPGAIN support</Typography>
                      <Typography>(+91) 9380901963</Typography>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      className="text-center"
                    >
                      <Hotel />
                      <Typography variant="body2">Need a hotel?</Typography>
                      <Typography>(+91) 9740832434</Typography>
                    </Grid>
                    <hr className="full-width" />
                  </Grid>
                );
              })
            ) : (
              <div>
                <Typography align="center" variant="h6">
                  {this.state.hasTicket === undefined ? (
                    <CircularProgress />
                  ) : (
                    `No Ticket found for the Transaction No: ${this.txid}`
                  )}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
        {this.state.cancelTicket && (
          <CancelTicket
            onwardRules={eticket.onwardfarerules}
            returnRules={eticket.returnfarerules}
            txid={this.txid}
            PassengerRecord={eticket.passengerRecords[0].PassengerRecord}
            showCancel={true}
            hideCancel={this.hideCancel}
          />
        )}
        {this.state.rescheduleTicket && (
          <RescheduleTicket
            onwardRules={eticket.onwardfarerules}
            returnRules={eticket.returnfarerules}
            traveller={{
              name: `${eticket.flightRecord.passengername} ${eticket.flightRecord.passengerlastname}`,
              mobile: eticket.flightRecord.passengercontact,
            }}
            flightRecord={eticket.flightRecord}
            txid={this.txid}
            PassengerRecord={eticket.passengerRecords[0].PassengerRecord}
            showResc={true}
            hideReschedule={this.hideReschedule}
            totalAmount={eticket.flightRecord.total_amount}
          />
        )}

        <CustomSnackBar message={this.state.snackbarMsg} variant={this.state.msgType} isOpen={this.state.openSnackBar} onClose={this.closeSnackbar} />
      </div>
    );
  }
}

export default withLoadingContext(withCustomSnackBarContext(ETicket));
