import { apiPost } from "../common/CommonService";

export const addRuleMasters = async obj => {
  try {
    let jsonObj = {
      CanxRescRule: {
        ...obj
      }
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "addcanxrescrow",
      canxrescdata: JSON.stringify(jsonObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const updateRuleMasters = async obj => {
  try {
    let jsonObj = {
      CanxRescRule: obj
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatecanxrescrow",
      canxrescdata: JSON.stringify(jsonObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getRuleMasters = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getallcanxrescrows"
    };
    let res = await apiPost(reqObj);
    if (res.list[0].CanxRescRule) {
      return Array.isArray(res.list[0].CanxRescRule)
        ? res.list[0].CanxRescRule
        : [res.list[0].CanxRescRule];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getRuleMastersDetails = async recordid => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getcanxrescrowbyid",
      recordid: recordid,
    };
    let res = await apiPost(reqObj);
    return res.CanxRescRule;
  } catch (err) {
    return undefined;
  }
};

export const deleteRuleMasters = async recordid => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "deletecanxrescrow",
      recordid: recordid,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};