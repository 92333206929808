import React, { Component } from "react";
import moment from "moment";
import { Typography, Paper, Grid, TextField, MenuItem } from "@material-ui/core";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

class HotelDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotel: props.hotelDetailsObj
    };
  }

  handleInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      hotel: {
        ...prevState.hotel,
        [eve.target.name]: eve.target.value
      }
    }),
      () => this.props.updateChange(this.state.hotel)
    );
  };

  handleDateChange = (day, name) => {
    this.setState(prevState => ({
      hotel: {
        ...prevState.hotel,
        [name]: day
      }
    }),
      () => this.props.updateChange(this.state.hotel)
    );
  };

  render() {
    return (
      <Paper className="padding-16 hotel-details-form">
        <Typography variant="subtitle1" className="bold">
          Hotel Details
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="hotelname"
              label="Hotel Name"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.hotelname}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="hotelrating"
              type="number"
              label="Hotel Rating"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.hotelrating}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <DayPickerInput
              placeholder="Check-In Date"
              className="full-width"
              value={this.state.hotel.checkindate}
              dayPickerProps={{ disabledDays: { before: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "checkindate")}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <DayPickerInput
              placeholder="Checkout Date"
              className="full-width"
              value={this.state.hotel.checkoutdate}
              dayPickerProps={{ disabledDays: { before: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "checkoutdate")}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              required
              fullWidth
              select
              className="select"
              variant="outlined"
              name="servicetype"
              label="Service Type"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.servicetype}
              onChange={this.handleInputChange}
            >
              <MenuItem value="domestic">Domestic</MenuItem>
              <MenuItem value="international">International</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={6}>
            <TextField
              required
              fullWidth
              select
              className="select"
              variant="outlined"
              name="numrooms"
              label="Num. Rooms"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.numrooms}
              onChange={this.handleInputChange}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((x, i) => (
                <MenuItem key={i} value={x}>
                  {x}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="roomtype"
              label="Room Type"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.roomtype}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="supplierreference"
              label="Supplier Reference"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.supplierreference}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="city"
              label="City"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.city}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="state"
              label="State"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.state}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="country"
              label="Country"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.country}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="hoteladdress"
              label="Hotel Address"
              multiline
              rows={2}
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.hoteladdress}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="narration"
              label="Narration"
              multiline
              rows={2}
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.hotel.narration}
              onChange={this.handleInputChange}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default HotelDetails;
