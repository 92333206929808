import React, { Component } from "react";
import {
  Button,
  Grid,
  TextField,
  Drawer,
  Divider,
  Typography,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";
import { raiseReceipt } from "../../services/receipt/ReceiptService";
import { getAllCustomerslite } from "../../services/common/CommonService";

class ReceiptForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpen: false,
      customersliteList: [],
      carrier: "",
      supplierid: "",
      searchObj: {
        from: moment().format("YYYY-MM-DD"),
        to: moment().add(1, "days").format("YYYY-MM-DD")
      },
      agentid: "",
      payment_mode: "",
      details: "",
      deposited_bank: "",
      deposited_branch: "",
      cheque_dd_no: "",
      cheque_dd_date: "",//moment().format("YYYY-MM-DD")
      deposited_amount: "",
      account_depositedto: "",
      admin_remarks: "",
    };
  }

  async componentDidMount() {
    const customersliteList = await getAllCustomerslite();
    this.setState({
      customersliteList,
    });
  }

  handleChange = (val, name) => {
    this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: val
      }
    }));
  };

  handleDateChange = (val, name) => {
    this.setState({        
      [name]: val
    });
  };

  handleInputChange = e => {
    this.setState({        
      [e.target.name]: e.target.value
    });
  };

  openCardDrawer = isShow => {
    this.setState({
      isDrawerOpen: isShow
    });
  };

  submitRaiseReceipt = async () => {
    this.props.showLoading(true);
    let reqObj = {
      jsondata: JSON.stringify({
        Receipt: {
          agentid: this.state.agentid,
          payment_mode: this.state.payment_mode,
          details: this.state.details,
          deposited_bank: "ICICI-142105000614",
          deposited_branch: this.state.deposited_branch,
          cheque_dd_no: this.state.cheque_dd_no,
          cheque_dd_date: this.state.cheque_dd_date,
          deposited_amount: this.state.deposited_amount,
          account_depositedto: "ICICI-142105000614",
          admin_remarks: this.state.admin_remarks,
        }
      }),
      payment_mode: this.state.payment_mode,
      account_depositedto: 'ICICI - 142105000614',
    }
    let res = await raiseReceipt(reqObj);
    if (res) {
      if (res.ResponseStatus.status === "OK") {
        this.props.showSnackBar(res.ResponseStatus.message, "success");
        this.openCardDrawer(false);
      } else {
        this.props.showSnackBar(res.ResponseStatus.message, "error");
      }
    } else {
      this.props.showSnackBar("Failed to raise receipt. Please try again.", "error");
    }
    this.props.showLoading(false);
  }

  render() {
    const { customersliteList } = this.state;
    return (
      <Grid container spacing={16}>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <DayPickerInput
            className="full-width"
            value={this.state.searchObj.from}
            onDayChange={day => this.handleChange(day, "from")}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <DayPickerInput
            className="full-width"
            value={this.state.searchObj.to}
            onDayChange={day => this.handleChange(day, "to")}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.doSearch(this.state.searchObj)}
          >
            Search
          </Button>
          <Button
            className="right"
            variant="contained"
            size="small"
            color="primary"
            onClick={() => this.openCardDrawer(true)}
          >
            Raise Receipt
          </Button>
          <span className="right">&nbsp;</span>
          <Button
            className="right"
            variant="contained"
            size="small"
            color="primary"
            onClick={() => this.props.pendingReceiptQueues()}
          >
            Get pending Receipt Queues
          </Button>
        </Grid>

        <Drawer
          className="custom-drawer"
          anchor="right"
          open={this.state.isDrawerOpen}
        >
          <Grid container spacing={16}>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography variant="h6">Raise Receipt</Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                variant="outlined"
                color="secondary"
                className="right"
                onClick={() => this.openCardDrawer(false)}
              >
                Close
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider />
              <br />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="agentid"
                label="Agent ID"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Agent ID"
                fullWidth
                required
                variant="outlined"
                margin="normal"
                value={this.state.agentid}
                onChange={this.handleInputChange}
                select
              >
                {customersliteList.map(c => (
                  <MenuItem value={c.corporateid} key={c.corporateid}>
                    <ListItemText primary={c.corporate_name} secondary={c.corporateid} />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="payment_mode"
                label="Payment Mode"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Payment Mode"
                fullWidth
                required
                select
                variant="outlined"
                margin="normal"
                value={this.state.payment_mode}
                onChange={this.handleInputChange}
              >
                <MenuItem value="Cash Deposit">Cash Deposit</MenuItem>
                <MenuItem value="Online Transfer">Online Transfer</MenuItem>
                <MenuItem value="Cheque">Cheque</MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="details"
                label="Details"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Details"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.details}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="deposited_bank"
                label="Deposited Bank"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Deposited Bank"
                fullWidth
                required
                select
                variant="outlined"
                margin="normal"
                value={this.state.deposited_bank}
                onChange={this.handleInputChange}
              >
                <MenuItem value="ICICI-142105000614">ICICI-142105000614</MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="deposited_branch"
                label="Deposited Branch"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Deposited Branch"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.deposited_branch}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="cheque_dd_no"
                label="Cheque dd no"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Cheque dd no"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.cheque_dd_no}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="deposited_amount"
                label="Deposited Amount"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Deposited Amount"
                fullWidth
                variant="outlined"
                margin="normal"
                type="number"
                value={this.state.deposited_amount}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="account_depositedto"
                label="Account Deposited To"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Account Deposited To"
                fullWidth
                required
                select
                variant="outlined"
                margin="normal"
                value={this.state.account_depositedto}
                onChange={this.handleInputChange}
              >
                <MenuItem value="ICICI-142105000614">ICICI-142105000614</MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <DayPickerInput
                className="full-width"
                required
                placeholder="Payment Date"
                value={this.state.cheque_dd_date}
                dayPickerProps={{ disabledDays: { before: new Date() } }}
                onDayChange={day => this.handleDateChange(day, "cheque_dd_date")}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="admin_remarks"
                label="Admin Remarks"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Account Deposited To"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.admin_remarks}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                onClick={this.submitRaiseReceipt}
                variant="contained"
                color="secondary"
                disabled={
                  !this.state.agentid || 
                  !this.state.payment_mode || 
                  !this.state.details || 
                  !this.state.deposited_bank || 
                  !this.state.deposited_branch || 
                  !this.state.cheque_dd_no || 
                  !this.state.cheque_dd_date || 
                  !this.state.deposited_amount || 
                  !this.state.account_depositedto || 
                  !this.state.admin_remarks
                }
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </Grid>
    );
  }
}

export default ReceiptForm;
