import React, { Component } from "react";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Button
} from "@material-ui/core";
import { getCorporateCompleteProfile } from "../../services/user/UserService";
import { Loading } from "../../components/common/Loading";

class UserCorporateProfilePage extends Component {
  txId = undefined;
  refTxId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      CompleteProfile: undefined
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    let paths = window.location.pathname.split("/");
    this.txId = paths[2];
    this.refTxId = paths[3];
    let res = await getCorporateCompleteProfile({pid: this.txId, cid: this.refTxId});
    if (res) {
      this.setState({
        isLoading: false,
        CompleteProfile: res.CompleteProfile
      });
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  handelChange = event => {
  };

  handleQueueUpdate = async () => {
    
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Typography variant="h6" className="mb-8">
          Profile Details
        </Typography>
        {this.state.CompleteProfile && (
          <Grid container spacing={16}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Profile Name"
                      name="profile_name"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.profile_name}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Corporate Name"
                      name="corporate_name"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.corporate_name}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <InputLabel>Corporate Branch</InputLabel>
                    <Select
                      onChange={this.handelChange}
                      value={this.state.CompleteProfile.profilemaster.corporate_branch}
                      name="corporate_branch"
                      displayEmpty
                      variant="outlined"
                      fullWidth
                      required
                    >
                      <MenuItem 
                        value={this.state.CompleteProfile.profilemaster.corporate_branch}
                        key={this.state.CompleteProfile.profilemaster.profileid}
                      >
                        {this.state.CompleteProfile.profilemaster.corporate_branch}
                      </MenuItem>
                      
                    </Select>
                  </Grid>
                </Grid>
              </Paper>
              <br/>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Typography variant="subtitle1" className="bold">
                    Primary Details
                  </Typography>
                  <hr className="full-width mb-16" />

                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <InputLabel>Title</InputLabel>
                    <Select
                      onChange={this.handelChange}
                      value={this.state.CompleteProfile.profilemaster.title}
                      name="title"
                      displayEmpty
                      variant="outlined"
                      fullWidth
                      required
                    >
                      <MenuItem 
                        value={this.state.CompleteProfile.profilemaster.title}
                        key={this.state.CompleteProfile.profilemaster.profileid}
                      >
                        {this.state.CompleteProfile.profilemaster.title}
                      </MenuItem>
                      
                    </Select>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="First Name"
                      name="first_name"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.first_name}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Middle Name"
                      name="middle_name"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.middle_name}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Last Name"
                      name="last_name"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.last_name}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <InputLabel>Corporate</InputLabel>
                    <Select
                      onChange={this.handelChange}
                      value={this.state.CompleteProfile.profilemaster.iscorporate_traveller.toString()}
                      name="iscorporate_traveller"
                      variant="outlined"
                      fullWidth
                      required
                    >
                      <MenuItem value="true" key="1">true</MenuItem>
                      <MenuItem value="false" key="2">false</MenuItem>
                      
                    </Select>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <InputLabel>Leisure</InputLabel>
                    <Select
                      onChange={this.handelChange}
                      value={this.state.CompleteProfile.profilemaster.isleisure_traveller.toString()}
                      name="isleisure_traveller"
                      variant="outlined"
                      displayEmpty
                      fullWidth
                      required
                    >
                      <MenuItem value="true" key="1">true</MenuItem>
                      <MenuItem value="false" key="2">false</MenuItem>
                      
                    </Select>
                  </Grid>
                </Grid>
              </Paper>
              <br/>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Typography variant="subtitle1" className="bold">
                    Billing Address
                  </Typography>
                  <hr className="full-width mb-16" />

                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <InputLabel>Country</InputLabel>
                    <Select
                      onChange={this.handelChange}
                      value={this.state.CompleteProfile.profilemaster.country}
                      name="country"
                      displayEmpty
                      variant="outlined"
                      fullWidth
                      required
                    >
                      <MenuItem 
                        value={this.state.CompleteProfile.profilemaster.country}
                      >
                        {this.state.CompleteProfile.profilemaster.country}
                      </MenuItem>
                      
                    </Select>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="State"
                      name="state"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.state}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="City"
                      name="city"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.city}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Street / Lane"
                      name="address1"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.address1}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Pincode"
                      name="pincode"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.pincode}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Landmark"
                      name="address2"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.address2}
                      onChange={this.handelChange}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <br/>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Typography variant="subtitle1" className="bold">
                    Communication Information
                  </Typography>
                  <hr className="full-width mb-16" />

                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <InputLabel>Country Code</InputLabel>
                    <Select
                      onChange={this.handelChange}
                      value={this.state.CompleteProfile.profilemaster.mobile_countrycode}
                      name="mobile_countrycode"
                      displayEmpty
                      variant="outlined"
                      fullWidth
                      required
                    >
                      <MenuItem 
                        value={this.state.CompleteProfile.profilemaster.mobile_countrycode}
                      >
                        {this.state.CompleteProfile.profilemaster.mobile_countrycode}
                      </MenuItem>
                      
                    </Select>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Mobile"
                      name="mobile"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.mobile}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Email"
                      name="email"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.email}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Fax"
                      name="fax"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.fax}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Landline"
                      name="phone"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.phone}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Website"
                      name="websiteurl"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.websiteurl}
                      onChange={this.handelChange}
                    />
                  </Grid>

                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Facebook ID"
                      name="facebook_details"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.facebook_details}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Twitter ID"
                      name="twitter_details"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.twitter_details}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Additional Email Address"
                      name="ticketingccemailaddress"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.ticketingccemailaddress}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Email Domain"
                      name="emaildomain"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.emaildomain}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="Amadeus profile ID"
                      name="amadeus_profileid"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.amadeus_profileid}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12}>
                    <TextField
                      variant="outlined"
                      label="CRM ID"
                      name="crm_id"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.CompleteProfile.profilemaster.crm_id}
                      onChange={this.handelChange}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <br />
              <br />
              <Grid item className="text-center" lg={12} md={12} sm={12} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleQueueUpdate}
                >
                  Update Profile
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {this.state.CompleteProfile === undefined && (
          <Paper className="padding-16">
            <div>
              <Typography align="center" variant="h6">
                No data found for the Transaction No: {this.txId}
              </Typography>
            </div>
          </Paper>
        )}
      </React.Fragment>
    );
  }
}

export default UserCorporateProfilePage;
