import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class FlightFareBreakUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getBreakUpUI = () => {
    return (
      <React.Fragment>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography variant="subtitle1">Pax. Type</Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            fullWidth
            required
            variant="outlined"
            name="basic"
            type="number"
            label="Basic"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            fullWidth
            required
            variant="outlined"
            name="basic"
            type="number"
            label="Tax"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            fullWidth
            required
            variant="outlined"
            name="basic"
            type="number"
            label="Other Tax"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography> X 3</Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <TextField
            fullWidth
            required
            variant="outlined"
            name="total"
            type="number"
            label="Sub Total"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return (
      <Paper className="padding-16 flight-fare-breakup">
        <Typography variant="subtitle1" className="bold">
          Booking Info
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          className="right total-update-btn"
        >
          Update
        </Button>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          {this.getBreakUpUI()}
        </Grid>
      </Paper>
    );
  }
}

export default FlightFareBreakUp;
