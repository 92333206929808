import React, { Component } from "react";
import moment from "moment";
import { Typography, Paper, Grid, TextField, MenuItem } from "@material-ui/core";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

class VisaDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visa: props.visaDetailsObj
    };
  }

  handleInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      visa: {
        ...prevState.visa,
        [eve.target.name]: eve.target.value
      }
    }),
      () => this.props.updateChange(this.state.visa)
    );
  };

  handleDateChange = (day, name) => {
    this.setState(prevState => ({
      visa: {
        ...prevState.visa,
        [name]: day
      }
    }),
      () => this.props.updateChange(this.state.visa)
    );
  };

  render() {
    return (
      <Paper className="padding-16 visa-details-form">
        <Typography variant="subtitle1" className="bold">
          Visa Details
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="appliedcountry"
              label="Applied Country"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.visa.appliedcountry}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              select
              className="select"
              variant="outlined"
              name="visatype"
              label="Type Of Visa"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.visa.visatype}
              onChange={this.handleInputChange}
            >
              <MenuItem value="SingleEntry">Single Entry</MenuItem>
              <MenuItem value="DoubleEntry">Double Entry</MenuItem>
              <MenuItem value="MultipleEntry">Multiple Entry</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <DayPickerInput
              placeholder="Valid From"
              className="full-width"
              value={this.state.visa.validfrom}
              // dayPickerProps={{ disabledDays: { before: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "validfrom")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <DayPickerInput
              placeholder="Valid To"
              className="full-width"
              value={this.state.visa.validto}
              // dayPickerProps={{ disabledDays: { before: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "validto")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <DayPickerInput
              placeholder="Application Date"
              className="full-width"
              value={this.state.visa.applicationdate}
              //dayPickerProps={{ disabledDays: { before: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "applicationdate")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              select
              className="select"
              variant="outlined"
              name="visastatus"
              label="Visa Status"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.visa.visastatus}
              onChange={this.handleInputChange}
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="processing">Processing</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Declined">Declined</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="visareference"
              label="Visa Reference"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.visa.visareference}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="adminremarks"
              label="Admin Remarks"
              multiline
              rows={2}
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.visa.adminremarks}
              onChange={this.handleInputChange}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default VisaDetails;
