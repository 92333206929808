import React, { Component } from "react";
import { Typography, TextField, Grid, Button, Drawer, Divider, MenuItem, ListItemText } from "@material-ui/core";
import UserMain from "../../components/user/UserMain";
import { getAllCorporates, addACorporateProfile } from "../../services/user/UserService";
import { Loading } from "../../components/common/Loading";
import "./UserPage.css";
import CustomSnackBar from "../../components/common/CustomSnackBar";
import { debounce } from "../../components/common/Utils";
import moment from "moment";
import TGSelect from "../../components/common/tg-select/TGSelect";
import { getGrades, getAllCostCenters, getAllDepartments, getAllProjects, getCountries, getEntitiesForCorporate, getStatesAndCities, getAllCustomerslite } from "../../services/common/CommonService";

class UserPage extends Component {
  stateWiseCities = [];
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      corporatesList: undefined,
      filteredList: undefined,
      pagenum: 0,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      },
      isAddTraveller: false,
      entitiesList: [],
      customersliteList: [],
      countriesList: [],
      departmentsList: [],
      projectsList: [],
      costCentersList: [],
      gradesList: [],
      selectedCT: {
        corporateid: "",
        title: "",
        first_name: "",
        last_name: "",
        employeeid: "",
        birthdate: "",
        email: "",
        mobile: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        costcenterid: "",
        projectid: "",
        corporate_designation: "",
        department: "",
        grade: "",
        entityid: "",
        tags: "",
      }
    };
  }

  handleResetTravellerObj = () => {
    this.setState({
      selectedCT: {
        corporateid: "",
        title: "",
        first_name: "",
        last_name: "",
        employeeid: "",
        birthdate: "",
        email: "",
        mobile: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        costcenterid: "",
        projectid: "",
        corporate_designation: "",
        department: "",
        grade: "",
        entityid: "",
        tags: "",
      }
    });
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  async componentDidMount() {
    await this.getCorporatesList(this.state.pagenum);
    const customersliteList = await getAllCustomerslite();
    
    const list = await getCountries();
    const countriesList = list.map(country => {
      return {
        label: `${country.countryname} (${country.countrycode3})`,
        value: country.countrycode3,
      };
    });
    this.setState({
      gradesList: [],
      projectsList: [],
      departmentsList: [],
      costCentersList: [],
      entitiesList: [],
      countriesList,
      customersliteList,
    });
  }

  getAllApis = async (corporateid) => {
    const gradesList = await getGrades(corporateid);
    const projectsList = await getAllProjects(corporateid);
    const departmentsList = await getAllDepartments(corporateid);
    const costCentersList = await getAllCostCenters(corporateid);
    const entitiesList = await getEntitiesForCorporate(corporateid);
    this.setState({
      gradesList,
      projectsList,
      departmentsList,
      costCentersList,
      entitiesList,
    });
  }

  getCorporatesList = async (pagenum) => {
    this.setState({
      isLoading: true
    });
    let res = await getAllCorporates(pagenum);
    this.setState({
      isLoading: false,
      corporatesList: res,
      filteredList: res,
      pagenum: pagenum
    });
  };

  showSnackBar = (msg, variant) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: variant
      }
    });
  };

  showLoading = isLoading => {
    this.setState({
      isLoading: isLoading
    });
  };

  handleChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.corporatesList.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredList: data
    });
  }, 500);

  handleAddTraveller = () => {
    this.setState({
      isAddTraveller: true
    });
  };

  handleCloseTraveller = () => {
    this.setState({
      isAddTraveller: false
    });
    this.handleResetTravellerObj();
  };

  handleTravellerChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      selectedCT: {
        ...prevState.selectedCT,
        [eve.target.name]: eve.target.value,
      },
    }));
    if(eve.target.name === "corporateid"){
      this.getAllApis(eve.target.value);
    }
  };

  handleCountryChange = async (name, selectedCountry) => {
    let countryCode = "";
    if (selectedCountry !== null) {
      countryCode = selectedCountry.value;
    }
    await this.getStatesFromCountry(countryCode);
    this.setState(prevState => ({
      selectedCT: {
        ...prevState.selectedCT,
        [name]: countryCode,
      },
    }));
  };

  getStatesFromCountry = async countryCode => {
    let statesList = [],
      states = [];
    if (countryCode !== "") {
      this.stateWiseCities = await getStatesAndCities(countryCode);
      this.stateWiseCities.forEach(x => {
        if (statesList.indexOf(x.state) === -1) {
          statesList.push(x.state);
        }
      });
      states = statesList.map(x => {
        return {
          label: x,
          value: x,
        };
      });
    }
    this.setState({
      states,
      cities: [],
    });
  };

  getStateWiseCities = state => {
    let cities = [];
    if (state !== "") {
      const list = this.stateWiseCities.filter(x => x.state === state);
      cities = list.map(x => {
        return {
          label: x.locationname,
          value: x.locationcode,
        };
      });
    }
    this.setState({
      cities,
    });
  };

  handleStateChange = (name, selectedState) => {
    let state = "";
    if (selectedState !== null) {
      state = selectedState.value;
    }
    this.getStateWiseCities(state);
    this.setState(prevState => ({
      selectedCT: {
        ...prevState.selectedCT,
        [name]: state,
      },
    }));
  };

  handleCityChange = (name, selectedCity) => {
    let val = "";
    if (selectedCity !== null) {
      val = selectedCity.value;
    }
    this.setState(prevState => ({
      selectedCT: {
        ...prevState.selectedCT,
        [name]: val,
      },
    }));
  };

  addTraveller = async () => {
    this.setState({
      isLoading: true
    });
    var res = await addACorporateProfile(this.state.selectedCT);
    let msg = "";
    let type = "success";
    if (res.ResponseStatus.status === "OK") {
      msg = res.ResponseStatus.message;
      await this.getCorporatesList(this.state.pagenum);
      this.handleCloseTraveller();
    } else {
      msg = res.ResponseStatus.message;
      type = "error";
    }
    this.setState({
      isLoading: false,
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type,
      },
    });
  };

  travllerReadyTosubmit = () => {
    const { ...obj } = this.state.selectedCT;
    return obj.title === "" ||
      obj.first_name === "" ||
      obj.last_name === "" ||
      obj.employeeid === "" ||
      obj.birthdate === "" ||
      obj.email === "" ||
      obj.mobile === "" ||
      obj.address1 === "" ||
      obj.address2 === "" ||
      //obj.city === "" ||
      //obj.state === "" ||
      //obj.country === "" ||
      obj.pincode === "" ||
      obj.costcenterid === "" ||
      obj.projectid === "" ||
      obj.corporate_designation === "" ||
      obj.department === "" ||
      obj.grade === "" ||
      obj.entityid === "" ||
      obj.tags === ""
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  render() {
    var minDate = moment()
      .subtract(150, "years")
      .format("YYYY-MM-DD");
    var maxDate = moment().format("YYYY-MM-DD");
    const { projectsList, costCentersList, departmentsList, entitiesList, customersliteList } = this.state;
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={8} md={7} sm={4} xs={4}>
            <Typography variant="h6" className="mb-8">
              All Corporate Users
            </Typography>
          </Grid>
          <Grid item lg={2} md={2} sm={4} xs={4}>
            <Button
              className="right"
              variant="contained"
              color="secondary"
              onClick={this.handleAddTraveller}
            >
              Add Traveller
            </Button>
          </Grid>
          <Grid item lg={2} md={3} sm={4} xs={4}>
            <TextField
              variant="outlined"
              className="search-field mb-8"
              name="searchTerm"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>

        {this.state.filteredList && (
          <UserMain
            showLoading={this.showLoading}
            showSnackBar={this.showSnackBar}
            corporatesList={this.state.filteredList}
            getCorporatesListFn= {this.getCorporatesList}
            pagenum= {this.state.pagenum}
          />
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}

        {this.state.isAddTraveller === true && (
          <Drawer anchor="right" open={this.state.isAddTraveller} className="add-supplier">
            <Grid container spacing={8}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Typography variant="h5">Add Traveller</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Button variant="outlined" className="right" color="secondary" onClick={() => this.handleCloseTraveller()}>
                  Close
                </Button>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={16}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      margin="normal"
                      required
                      value={this.state.selectedCT.title}
                      onChange={this.handleTravellerChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Title"
                      name="title">
                      <MenuItem value="Mr">Mr</MenuItem>
                      <MenuItem value="Ms">Ms</MenuItem>
                      <MenuItem value="Mrs">Mrs</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="first_name"
                      margin="normal"
                      autoComplete="given-name"
                      label="First Name"
                      placeholder="Enter your First Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.first_name}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      autoComplete="family-name"
                      fullWidth
                      required
                      variant="outlined"
                      name="last_name"
                      margin="normal"
                      label="Last Name"
                      placeholder="Enter your Last Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.last_name}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      autoComplete="tel-national"
                      fullWidth
                      required
                      variant="outlined"
                      name="mobile"
                      label="Mobile"
                      type="number"
                      margin="normal"
                      placeholder="Enter your mobile no"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.mobile}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                      label="Gender"
                      value={this.state.selectedCT.gender}
                      onChange={this.handleTravellerChange}
                      name="gender"
                      className="capitalize">
                      {["", "male", "female", "other"].map(option => (
                        <MenuItem className="capitalize" key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid> */}
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      autoComplete="birthdate"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="birthdate"
                      label="Date of Birth"
                      type="date"
                      variant="outlined"
                      error={this.state.showValidationDate ? true : false}
                      margin="normal"
                      fullWidth
                      placeholder="Enter Date of Birth"
                      inputProps={{
                        min: minDate,
                        max: maxDate,
                      }}
                      helperText={this.state.showValidationDate ? "Date of Birth shouldnt be greater than today" : ""}
                      value={this.state.selectedCT.birthdate}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="outlined"
                      required
                      name="employeeid"
                      margin="normal"
                      label="Employee id"
                      placeholder="Employee id"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.employeeid}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      autoComplete="corporate_designation"
                      fullWidth
                      required
                      name="corporate_designation"
                      margin="normal"
                      label="Designation"
                      variant="outlined"
                      placeholder="Enter designation"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.corporate_designation}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      fullWidth
                      required
                      select
                      name="corporateid"
                      margin="normal"
                      variant="outlined"
                      label="Select Corporate"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.corporateid}
                      onChange={this.handleTravellerChange}>
                      {customersliteList.map(c => (
                        <MenuItem value={c.corporateid} key={c.corporateid}>
                          <ListItemText primary={c.corporate_name} secondary={c.corporateid} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      select
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Select Grade"
                      margin="normal"
                      fullWidth
                      name="grade"
                      variant="outlined"
                      value={this.state.selectedCT.grade}
                      onChange={this.handleTravellerChange}
                      required
                      disabled={!this.state.selectedCT.corporateid}
                    >
                      {this.state.gradesList.map((x, idx) => {
                        return (
                          <MenuItem key={idx} value={x.gradeid}>
                            <ListItemText primary={`${x.grade_name} - ${x.grade_description}`} />
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      autoComplete="department"
                      fullWidth
                      required
                      select
                      name="department"
                      margin="normal"
                      variant="outlined"
                      label="Select Department"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.department}
                      onChange={this.handleTravellerChange}
                      disabled={!this.state.selectedCT.corporateid}
                    >
                      {departmentsList.map(d => (
                        <MenuItem value={d.departmentid} key={d.departmentid}>
                          <ListItemText primary={d.departmentname} secondary={d.department_description} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      fullWidth
                      required
                      select
                      name="projectid"
                      margin="normal"
                      variant="outlined"
                      label="Select Project"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.projectid}
                      onChange={this.handleTravellerChange}
                      disabled={!this.state.selectedCT.corporateid}
                    >
                      {projectsList.map(p => (
                        <MenuItem key={p.projectid} value={p.projectid}>
                          <ListItemText primary={p.projectname} secondary={p.project_description} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      fullWidth
                      required
                      select
                      name="costcenterid"
                      margin="normal"
                      variant="outlined"
                      label="Select Cost Center"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.costcenterid}
                      onChange={this.handleTravellerChange}
                      disabled={!this.state.selectedCT.corporateid}
                    >
                      {costCentersList.map(c => (
                        <MenuItem value={c.costcenterid} key={c.costcenterid}>
                          <ListItemText primary={c.costcentername} secondary={c.description} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      fullWidth
                      required
                      select
                      name="entityid"
                      margin="normal"
                      variant="outlined"
                      label="Select Entity"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.entityid}
                      onChange={this.handleTravellerChange}
                      disabled={!this.state.selectedCT.corporateid}
                    >
                      {entitiesList.map(entity => (
                        <MenuItem value={entity.entityid} key={entity.entityid}>
                          <ListItemText primary={entity.entity_name} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="outlined"
                      required
                      name="email"
                      margin="normal"
                      label="Email id"
                      placeholder="Email id"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.email}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="outlined"
                      required
                      name="tags"
                      margin="normal"
                      label="Tags"
                      placeholder="Tags"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.tags}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TGSelect
                      className="mt-16"
                      name="country"
                      label="Select Country"
                      options={this.state.countriesList}
                      defaultValue={this.state.selectedCT.country}
                      onChange={this.handleCountryChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TGSelect
                      className="mt-16"
                      name="state"
                      label="Select State"
                      options={this.state.states}
                      defaultValue={this.state.selectedCT.state}
                      onChange={this.handleStateChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TGSelect
                      className="mt-16"
                      name="city"
                      label="Select City"
                      options={this.state.cities}
                      defaultValue={this.state.selectedCT.city}
                      onChange={this.handleCityChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="outlined"
                      required
                      name="address1"
                      margin="normal"
                      label="Address 1"
                      placeholder="Address 1"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.address1}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      variant="outlined"
                      required
                      name="address2"
                      margin="normal"
                      label="Address 2"
                      placeholder="Address 2"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.address2}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      autoComplete="tel-national"
                      fullWidth
                      required
                      variant="outlined"
                      name="pincode"
                      label="Pin Code"
                      type="number"
                      margin="normal"
                      placeholder="Enter pincode"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={this.state.selectedCT.pincode}
                      onChange={this.handleTravellerChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button variant="contained" color="primary" size="large" disabled={this.travllerReadyTosubmit()} onClick={() => this.addTraveller()}>
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Drawer>
        )}
      </React.Fragment>
    );
  }
}

export default UserPage;

