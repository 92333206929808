import React, { Component } from "react";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "mdi-material-ui/Menu";
import ChevronDown from "mdi-material-ui/ChevronDown";
import { BrowserRouter } from "react-router-dom";
import SmartScanMain from "./smart-scan/SmartScanMain";

class ExpenseMain extends Component {
  constructor(props) {
    super(props);
    let num = 1;
    let tab = Number(window.location.hash.split("_").pop());
    if (tab) num = tab;
    this.state = {
      selectedItem: num,
      anchorEl: null
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleChange = (event, value) => {
    this.setState({ value });
    window.location.hash = value;
  };

  getMenu = () => {
    return (
      <div>
        <MenuItem
          className={`${this.state.selectedItem === 1 && "active-menu"}`}
          onClick={() => this.handleItemClick(1, "smart-expense_1")}
        >
          <ListItemIcon>
            <img
              className="smart-scan-img"
              src="/images/smart-scan.png"
              alt=""
            />
          </ListItemIcon>
          <ListItemText primary="Smart Expenses" />
        </MenuItem>
      </div>
    );
  };

  handleItemClick(itemId, txt) {
    this.setState({ selectedItem: itemId, anchorEl: null });
    window.location.hash = txt;
  }

  renderSwitch(comp) {
    switch (comp) {
      case 1:
        return <SmartScanMain />;
      default:
        return <SmartScanMain />;
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <div className="jram">
              <Hidden mdUp>
                <Button
                  className="secondary-bg"
                  fullWidth
                  aria-owns={this.state.anchorEl ? "admin-menu" : null}
                  aria-haspopup="true"
                  color="secondary"
                  onClick={this.handleClick}
                >
                  <MenuIcon color="secondary" /> &nbsp; Expense Menu
                  <ChevronDown color="secondary" />
                </Button>
                <Menu
                  id="admin-menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  {this.getMenu()}
                </Menu>
              </Hidden>
              <Hidden smDown>
                <MenuList role="menu">{this.getMenu()}</MenuList>
              </Hidden>
            </div>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <BrowserRouter>
              {this.renderSwitch(this.state.selectedItem)}
            </BrowserRouter>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default ExpenseMain;
