import { apiPost } from "../common/CommonService";

export const offlineInsuranceBooking = async (obj) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "offlineinsurancebooking",
      jsondata: obj.jsondata,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};