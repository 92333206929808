import React from "react";
import {
    Button,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Drawer,
    Grid,
    Typography,
    Divider
} from "@material-ui/core";

class TransactionRemarkLog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            transactionRemarkLog: props.transactionRemarkLog || [],
            isDrawerOpen: props.isReviewDrawerOpen,
            openCardDrawer: props.openReviewCardDrawer,
        };
    }

    render() {
        return (
            <React.Fragment>
                <Drawer
                    className="review-drawer"
                    anchor="right"
                    open={this.state.isDrawerOpen}
                >
                    <Grid container spacing={16}>
                        <Grid item lg={8} md={8} sm={8} xs={8}>
                            <Typography variant="h6">Transaction Remarks</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className="right"
                                onClick={() => this.state.openCardDrawer(false)}
                            >
                                Close
                            </Button>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Divider />
                            <br />
                        </Grid>

                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Txid</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Remark</TableCell>
                                    <TableCell>UpdatedBy</TableCell>
                                    <TableCell>UpdatedOn</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.transactionRemarkLog.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{item.txid}</TableCell>
                                        <TableCell>{item.details}</TableCell>
                                        <TableCell>{item.remark}</TableCell>
                                        <TableCell>{item.updatedby}</TableCell>
                                        <TableCell>{item.updatedon}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default TransactionRemarkLog;
