import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
} from "@material-ui/core";
import { withLoadingContext } from "../../common/LoadingProvider";
import { withCustomSnackBarContext } from "../../common/CustomSnackBarProvider";
import { retryTrainBookingLive } from "../../../services/queues/TrainQueuesService";

class RetryBooking extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      remarks: "",
    };
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = async () => {
    this.props.loadingContext.show();
    let obj = {
      txid: this.props.txid,
      remarks: this.state.remarks,
    };

    let res = await retryTrainBookingLive(obj);
    try {
      if (res) {
        if (res.ResponseStatus.status === "NOK") {
          this.props.snackBarContext.show(res.ResponseStatus.message, "error");
        } else {
          this.props.snackBarContext.show(res.ResponseStatus.message, "success");
        }
      } else {
        this.props.snackBarContext.show(
          "Failed to book. Please try again.",
          "error"
        );
      }
    }
    catch (e) {
      this.props.snackBarContext.show(
        "Failed to book. Please try again.",
        "error"
      );
    }
    this.props.handlePopoverClose();
    this.props.handleClose();
    this.props.loadingContext.hide();
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          open={true}
          aria-labelledby="rollback-transaction"
        >
          <DialogTitle>Retry Train Booking: {this.props.txid}</DialogTitle>
          <hr className="full-width" />
          <DialogContent>
            <TextField
              name="remarks"
              label="Remarks"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Remarks"
              fullWidth
              variant="outlined"
              margin="normal"
              value={this.state.remarks}
              onChange={this.handleInputChange}
            />
          </DialogContent>
          <DialogActions className="left">
            <Button onClick={this.props.handleClose} color="secondary">
              Close
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              disabled={!this.state.remarks}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withLoadingContext(withCustomSnackBarContext(RetryBooking));
