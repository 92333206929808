import moment from "moment";
import { apiPost, getUserAuth } from "../common/CommonService";

const API_PATH = process.env.REACT_APP_API_PATH;

export const viewAllReconcileMaster = async searchObj => {
  try {
    let obj = {
      opid: "TG-SUPERADMIN",
      action: "viewallreconcilemaster",
      fromdate: moment(searchObj.from).format("YYYY-MM-DD"),
      todate: moment(searchObj.to).format("YYYY-MM-DD")
    };

    let res = await apiPost(obj);
    if (res.hasOwnProperty("null") || res.list[0] === "") {
      return undefined;
    } else {
      return res.list[0];
    }
  } catch (err) {
    return undefined;
  }
};

export const reconcilationUpload = async obj => {
  let userAuth = getUserAuth();
  try {
    obj.authtoken = userAuth.authtoken;
    let url = `${API_PATH}reconcilationupload.jsp`;
    let response = await fetch(url, {
      method: "POST",
      body: obj,
    });
    return await response.json();
  } catch (error) {
    return undefined;
  }
};

export const uploadReconcileFile = async (data={}) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "uploadreconcilefile",
      ...data,
    };

    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};
