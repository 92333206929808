import React from "react";
import {
    Divider,
    MenuItem,
    Drawer,
    Button,
    TextField,
    Grid,
    Checkbox,
    ListItemSecondaryAction,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@material-ui/core";
import { formatToMonthDate, formatTimeString, ConvertTravelTime, formatCurrency } from "../../common/Utils";
import moment from "moment";
import { withCustomSnackBarContext } from "../../common/CustomSnackBarProvider";
import { withLoadingContext } from "../../common/LoadingProvider";
import { API_REQUEST_DATE_FORMAT } from "../../common/constants";
import { flightRescheduleRequest, getRescheduledSearchFlights } from "../../../services/queues/FlightQueuesService";
import { getUserAuth, getUserProfile } from "../../../services/common/CommonService";
import TGDateInput from "../../common/TGDateInput";

class RescheduleTicket extends React.PureComponent {
    profile = {};
    userAuth = {};
    hasReturn = false;
    constructor(props) {
        super(props);
        const returnPax = this.props.PassengerRecord.filter(x => x.trip_number === 2);
        this.hasReturn = returnPax.length > 0 ? true : false;
        this.state = {
            toSort: "",
            fromSort: "",
            selectedFlight: {},
            selectedFlights: [{}, {}],
            step: "detailsSelection",
            paxIds: [],
            flightsList: [],
            remarks: "",
            returnrescdate: this.props.flightRecord.rdate,
            onwardrescdate: this.props.flightRecord.jdate,
        };
        this.profile = getUserProfile();
        this.userAuth = getUserAuth();
    }

    closeDialog = () => {
        this.props.hideReschedule();
    };

    getRescObj = () => {
        var paxs = this.state.paxIds.join();
        var returndate = undefined;
        if (this.props.flightRecord.jtype === "RoundTrip") {
            returndate = moment(this.state.returnrescdate).format("YYYY-MM-DD");
        }
        return {
            profileid: this.profile.profileid,
            corporateid: this.profile.corporateid,
            opid: "TG-FLIGHTRESCREQUEST",
            reftxid: this.props.txid,
            remarks: this.state.remarks,
            canxpax: paxs,
            authtoken: this.userAuth.authtoken,
            contactname: this.props.traveller.name,
            contactphone: this.props.traveller.mobile,
            rescdate: moment(this.state.onwardrescdate).format("YYYY-MM-DD"),
            onwardrescdate: moment(this.state.onwardrescdate).format("YYYY-MM-DD"),
            returnrescdate: returndate,
            tcprofileid: this.profile.profileid,
            flightkeyonward: "",
            flightkeyreturn: "",
        };
    };

    rescheduleTicket = async () => {
        if (this.state.paxIds.length === 0) {
            this.props.snackBarContext.show("Please select traveller(s) to reschedule", "error");
        } else if (this.state.onwardrescdate === undefined || this.state.onwardrescdate === "") {
            this.props.snackBarContext.show("Please select journey date to reschedule", "error");
        } else {
            this.props.loadingContext.show();
            var returndate = undefined;
            if (this.props.flightRecord.jtype === "RoundTrip") {
                returndate = moment(this.state.returnrescdate).format("YYYY-MM-DD");
            }
            var paxs = this.state.paxIds.join();
            var obj = this.getRescObj();
            if (this.props.flightRecord.jtype === "RoundTrip") {
                if (Object.keys(this.state.selectedFlights[0]).length > 0) {
                    obj.flightkeyonward = this.state.selectedFlights[0].flightkey;
                }
                if (Object.keys(this.state.selectedFlights[1]).length > 0) {
                    obj.flightkeyreturn = this.state.selectedFlights[1].flightkey;
                }
            } else if (Object.keys(this.state.selectedFlight).length > 0) {
                obj.flightkeyonward = this.state.selectedFlight.flightkey;
            }

            let reqObj = {
                profileid: this.profile.profileid,
                corporateid: this.profile.corporateid,
                reftxid: this.props.txid,
                remarks: this.state.remarks,
                canxpax: paxs,
                contactname: this.props.traveller.name,
                contactphone: this.props.traveller.mobile,
                rescdate: moment(this.state.onwardrescdate).format("YYYY-MM-DD"),
                onwardrescdate: moment(this.state.onwardrescdate).format("YYYY-MM-DD"),
                returnrescdate: returndate,
                tcprofileid: this.profile.profileid,
                jsonrequest: JSON.stringify(obj),
            };

            const res = await flightRescheduleRequest(reqObj);
            try {
                if (res.ApiStatus.Status === "NOK") {
                    this.props.snackBarContext.show(res.ApiStatus.Message, "error");
                } else {
                    this.props.snackBarContext.show("Ticket reschedule request has been successfully registered.", "success");
                    this.props.hideReschedule();
                }
            }
            catch (e) { }
            this.props.loadingContext.hide();
        }
    };

    handleToggle = id => {
        var arr = [...this.state.paxIds];
        var idx = arr.indexOf(id);
        if (idx > -1) {
            arr.splice(idx, 1);
        } else {
            arr.push(id);
        }
        this.setState({
            paxIds: arr,
        });
    };

    handleRemarksChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handelDateChange = (name, day) => {
        this.setState({
            [name]: day,
        });
    };

    handleContinue = async () => {
        if (this.state.paxIds.length === 0) {
            this.props.snackBarContext.show("Please select traveller(s) to reschedule", "error");
        } else if (this.state.onwardrescdate === undefined || this.state.onwardrescdate === "") {
            this.props.snackBarContext.show("Please select journey date to reschedule", "error");
        } else if (this.props.flightRecord.jtype !== "RoundTrip" && this.state.onwardrescdate === this.props.flightRecord.jdate) {
            this.props.snackBarContext.show("Please chagne onward date", "error");
        } else if (
            this.props.flightRecord.jtype === "RoundTrip" &&
            this.state.onwardrescdate === this.props.flightRecord.jdate &&
            this.state.returnrescdate === this.props.flightRecord.rdate
        ) {
            this.props.snackBarContext.show("Please change onward date or return date", "error");
        } else {
            this.props.loadingContext.show();
            let returnDate = "";
            if (this.props.flightRecord.jtype === "RoundTrip") {
                returnDate = moment(this.state.returnrescdate).format(API_REQUEST_DATE_FORMAT);
            }
            const res = await getRescheduledSearchFlights(this.props.txid, moment(this.state.onwardrescdate).format(API_REQUEST_DATE_FORMAT), returnDate);
            if (res) {
                try {
                    if (Array.isArray(res.flightjourneys[0].FlightJourney)) {
                        if (res.flightjourneys[0].FlightJourney[0].recommendations[0] === "" && res.flightjourneys[0].FlightJourney[1].recommendations[0] === "") {
                            this.rescheduleTicket();
                        } else {
                            this.setState({
                                step: "flightsSelection",
                                flightsList: res.flightjourneys[0].FlightJourney,
                            });
                        }
                        this.props.loadingContext.hide();
                    } else {
                        if (res.flightjourneys[0].FlightJourney.recommendations[0] === "") {
                            this.props.loadingContext.hide();
                            this.rescheduleTicket();
                        } else {
                            const list = res.flightjourneys[0].FlightJourney.recommendations[0].FlightRecommendation;
                            this.setState({
                                flightsList: Array.isArray(list) ? list : [list],
                                step: "flightsSelection",
                            });
                            this.props.loadingContext.hide();
                        }
                    }
                }
                catch (e) { 
                    this.props.loadingContext.hide();
                    this.rescheduleTicket();
                }
            } else {
                this.props.loadingContext.hide();
                this.rescheduleTicket();
            }
        }
    };

    handleRoundTripFlightSelection = (flt, journeyType) => {
        let selectedFlights = [...this.state.selectedFlights];
        if (journeyType === "from") {
            selectedFlights[0] = flt.flights[0].FlightObject;
        } else {
            selectedFlights[1] = flt.flights[0].FlightObject;
        }
        this.setState({
            selectedFlights,
        });
    };

    handleFlightSelect = flt => {
        this.setState({
            selectedFlight: flt.flights[0].FlightObject,
        });
    };

    handleFlightSelectionContinue = () => {
        this.rescheduleTicket();
    };

    handleBack = () => {
        this.setState({
            step: "detailsSelection",
        });
    };

    sortResult = (sortProp, sortType, journeyType) => {
        let results = [];
        if (journeyType === "oneway") {
            results = [...this.state.flightsList];
        } else {
            if (journeyType === "onward") {
                if (this.state.flightsList[0].recommendations[0] !== "") {
                    results = Array.isArray(this.state.flightsList[0].recommendations[0].FlightRecommendation)
                        ? this.state.flightsList[0].recommendations[0].FlightRecommendation
                        : [this.state.flightsList[0].recommendations[0].FlightRecommendation];
                }
            } else {
                if (this.state.flightsList[1].recommendations[0] !== "") {
                    results = Array.isArray(this.state.flightsList[1].recommendations[0].FlightRecommendation)
                        ? this.state.flightsList[1].recommendations[0].FlightRecommendation
                        : [this.state.flightsList[1].recommendations[0].FlightRecommendation];
                }
            }
        }
        if (sortType === "asc") {
            results.sort((a, b) => Number(a.flights[0].FlightObject[sortProp]) - Number(b.flights[0].FlightObject[sortProp]));
        } else {
            results.sort((a, b) => Number(b.flights[0].FlightObject[sortProp]) - Number(a.flights[0].FlightObject[sortProp]));
        }
        let flightsList = [];
        if (journeyType === "oneway") {
            flightsList = results;
        } else {
            flightsList = [...this.state.flightsList];
            if (journeyType === "onward") {
                if (flightsList[0].recommendations[0] !== "") {
                    flightsList[0].recommendations[0].FlightRecommendation = results;
                }
            } else {
                if (flightsList[1].recommendations[0] !== "") {
                    flightsList[1].recommendations[0].FlightRecommendation = results;
                }
            }
        }
        this.setState({
            flightsList,
        });
    };

    handleSortChange = (event, journeyType) => {
        const { name, value } = event.target;
        this.sortResult(value.split("-")[0], value.split("-")[1], journeyType);
        this.setState({
            [name]: value,
        });
    };

    getDomesticRoundTripFlights = type => {
        let flights = [];
        if (type === "from") {
            if (this.state.flightsList[0].recommendations[0] !== "") {
                flights = Array.isArray(this.state.flightsList[0].recommendations[0].FlightRecommendation)
                    ? this.state.flightsList[0].recommendations[0].FlightRecommendation
                    : [this.state.flightsList[0].recommendations[0].FlightRecommendation];
            }
        } else {
            if (this.state.flightsList[1].recommendations[0] !== "") {
                flights = Array.isArray(this.state.flightsList[1].recommendations[0].FlightRecommendation)
                    ? this.state.flightsList[1].recommendations[0].FlightRecommendation
                    : [this.state.flightsList[1].recommendations[0].FlightRecommendation];
            }
        }
        return flights.map((fltJrny, index) => {
            let flightLeg = fltJrny.flights[0].FlightObject.flightlegs[0].FlightLeg;
            let flightLegs = Array.isArray(flightLeg) ? flightLeg : [flightLeg];
            return (
                <React.Fragment key={index}>
                    <Grid item lg={10} md={10} sm={10} xs={12} style={{ backgroundColor: "#f9f9f9", marginBottom: "16px" }}>
                        <Grid container spacing={8}>
                            {flightLegs.map((flight, index1) => (
                                <React.Fragment key={index1}>
                                    <Grid item lg={4} md={4} sm={12} xs={12} className="text-center">
                                        <Typography variant="subtitle2" className="bold">
                                            {formatTimeString(flight.deptime)}
                                        </Typography>
                                        <Typography variant="subtitle1" className="bold">
                                            {flight.origin_name}
                                        </Typography>
                                        <small>on {formatToMonthDate(flight.depdate)}</small>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12} className="text-center">
                                        <Typography variant="body1" className="capitalize">
                                            {flight.cabinclass} / {ConvertTravelTime(flight.journeyduration)}
                                        </Typography>
                                        <hr style={{ margin: 0 }} />
                                        <Typography variant="body1" className="capitalize">
                                            {flight.carriername} - ({flight.carrier}&nbsp;{flight.flightnumber})
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12} className="text-center">
                                        <Typography variant="subtitle2" className="bold">
                                            {formatTimeString(flight.arrtime)}
                                        </Typography>
                                        <Typography variant="subtitle1" className="bold">
                                            {flight.destination_name}
                                        </Typography>
                                        <small> on {formatToMonthDate(flight.arrdate)}</small>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={12} className="text-center" style={{ backgroundColor: "#f9f9f9", marginBottom: "8px" }}>
                        <Typography variant="h6" className="price">
                            {formatCurrency(fltJrny.flights[0].FlightObject.cheapestprice)}
                        </Typography>
                        {type === "from" && fltJrny.flights[0].FlightObject.flightkey === this.state.selectedFlights[0].flightkey ? (
                            <Button variant="contained" color="primary">
                                Selected
                            </Button>
                        ) : type === "to" && fltJrny.flights[0].FlightObject.flightkey === this.state.selectedFlights[1].flightkey ? (
                            <Button variant="contained" color="primary">
                                Selected
                            </Button>
                        ) : (
                            <Button onClick={() => this.handleRoundTripFlightSelection(fltJrny, type)} variant="outlined" color="primary">
                                Select
                            </Button>
                        )}
                    </Grid>
                </React.Fragment>
            );
        });
    };

    getDomesticOnewayFlights = () => {
        return this.state.flightsList.map((flt, index) => {
            let flightLeg = flt.flights[0].FlightObject.flightlegs[0].FlightLeg;
            let flightLegs = Array.isArray(flightLeg) ? flightLeg : [flightLeg];
            return (
                <React.Fragment key={index}>
                    <Grid item lg={10} md={10} sm={10} xs={12} style={{ backgroundColor: "#f9f9f9", marginBottom: "16px" }}>
                        <Grid container spacing={8}>
                            {flightLegs.map((flight, index1) => (
                                <React.Fragment key={index1}>
                                    <Grid item lg={4} md={4} sm={12} xs={12} className="text-center">
                                        <Typography variant="subtitle1" className="bold">
                                            {flight.origin_name} ({flight.origin})
                                        </Typography>
                                        <small>
                                            on {formatToMonthDate(flight.depdate)} ,
                                            {formatTimeString(flight.deptime)}
                                        </small>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12} className="text-center">
                                        <Typography variant="body1" className="capitalize">
                                            {flight.cabinclass} / {ConvertTravelTime(flight.journeyduration)}
                                        </Typography>
                                        <hr style={{ margin: 0 }} />
                                        <Typography variant="body1" className="capitalize">
                                            {flight.carriername} - ({flight.carrier}&nbsp;{flight.flightnumber})
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={12} xs={12} className="text-center">
                                        <Typography variant="subtitle1" className="bold">{`${flight.destination_name} (${flight.destination})`}</Typography>
                                        <small>
                                            on {formatToMonthDate(flight.arrdate)} , {formatTimeString(flight.arrtime)}
                                        </small>
                                    </Grid>
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={12} className="text-center" style={{ backgroundColor: "#f9f9f9", marginBottom: "8px" }}>
                        <Typography variant="h6" className="price">
                            {formatCurrency(flt.flights[0].FlightObject.cheapestprice)}
                        </Typography>
                        {flt.flights[0].FlightObject.flightkey === this.state.selectedFlight.flightkey ? (
                            <Button variant="contained" color="primary">
                                Selected
                            </Button>
                        ) : (
                            <Button onClick={() => this.handleFlightSelect(flt)} variant="outlined" color="primary">
                                Select
                            </Button>
                        )}
                    </Grid>
                </React.Fragment>
            );
        });
    };

    render() {
        return (
            <React.Fragment>
                <Drawer anchor="right" className="ticket-resc-drawer" open={this.props.showResc} aria-labelledby="resc-ticket">
                    <Grid container spacing={16}>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                            <Typography variant="h5">Reschedule Ticket</Typography>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={8}>
                            <Typography variant="caption">Preivous Price</Typography>
                            <Typography variant="h5" className="price">
                                {formatCurrency(this.props.totalAmount)}
                            </Typography>
                        </Grid>
                        <Grid item lg={4} ms={4} sm={4} xs={4}>
                            <Button onClick={this.closeDialog} variant="outlined" color="secondary" className="right">
                                Close
                            </Button>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Divider />
                        </Grid>
                        {this.state.step === "detailsSelection" && (
                            <React.Fragment>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <TGDateInput
                                        defaultValue={this.state.onwardrescdate}
                                        disableFrom={new Date()}
                                        label="Select Onward Date"
                                        name="onwardrescdate"
                                        onDateChange={this.handelDateChange}
                                    />
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {this.props.flightRecord.jtype === "RoundTrip" && (
                                        <TGDateInput
                                            defaultValue={this.state.returnrescdate}
                                            disableFrom={new Date()}
                                            label="Select Return Date"
                                            name="returnrescdate"
                                            onDateChange={this.handelDateChange}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant="subtitle1"> Select traveller(s) to reschedule ticket</Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <List>
                                        <ListItem className="p-l-0">Onward Journey</ListItem>
                                        {this.props.PassengerRecord.map(
                                            (pr, id) =>
                                                pr.trip_number === 1 && (
                                                    <ListItem key={id} dense button>
                                                        <ListItemText primary={`${pr.title}. ${pr.first_name} ${pr.last_name}`} />
                                                        <ListItemSecondaryAction>
                                                            <Checkbox onChange={() => this.handleToggle(pr.paxid)} checked={this.state.paxIds.indexOf(pr.paxid) !== -1} />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                        )}
                                        <Typography variant="subtitle1" className="mt-8">
                                            {this.props.onwardRules}
                                        </Typography>
                                    </List>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    {this.hasReturn && (
                                        <List>
                                            <ListItem className="p-l-0">Return Journey</ListItem>
                                            {this.props.PassengerRecord.map(
                                                (pr, id) =>
                                                    pr.trip_number === 2 && (
                                                        <ListItem key={id} dense button>
                                                            <ListItemText primary={`${pr.title}. ${pr.first_name} ${pr.last_name}`} />
                                                            <ListItemSecondaryAction>
                                                                <Checkbox onChange={() => this.handleToggle(pr.paxid)} checked={this.state.paxIds.indexOf(pr.paxid) !== -1} />
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    )
                                            )}
                                            <Typography variant="subtitle1" className="mt-8">
                                                {this.props.returnRules}
                                            </Typography>
                                        </List>
                                    )}
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        name="remarks"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        label="Remarks"
                                        fullWidth
                                        margin="normal"
                                        value={this.state.remarks}
                                        onChange={this.handleRemarksChange}
                                    />
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Button onClick={this.handleContinue} variant="contained" color="primary">
                                        Continue
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        )}
                        {this.state.step === "flightsSelection" && (
                            <React.Fragment>
                                {this.hasReturn ? (
                                    <React.Fragment>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Grid container spacing={8}>
                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                    <Typography variant="h6" className="mb-16">
                                                        Onward Journey
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                    <TextField
                                                        name="fromSort"
                                                        select
                                                        value={this.state.fromSort}
                                                        variant="outlined"
                                                        className="mb-16 right"
                                                        label="Sort By"
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={event => this.handleSortChange(event, "onward")}>
                                                        <MenuItem value="cheapestprice-asc">Price: Ascending</MenuItem>
                                                        <MenuItem value="cheapestprice-desc">Price: Descending</MenuItem>
                                                        <MenuItem value="deptime-asc">Depart: Ascending</MenuItem>
                                                        <MenuItem value="deptime-desc">Depart: Descending</MenuItem>
                                                        <MenuItem value="arrtime-asc">Arrival: Ascending</MenuItem>
                                                        <MenuItem value="arrtime-desc">Arrival: Descending</MenuItem>
                                                    </TextField>
                                                </Grid>
                                                {this.getDomesticRoundTripFlights("from")}
                                            </Grid>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Grid container spacing={8} className="mb-16">
                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                    <Typography variant="h6" className="mb-16">
                                                        Return Journey
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                                    <TextField
                                                        name="toSort"
                                                        select
                                                        value={this.state.toSort}
                                                        variant="outlined"
                                                        className="mb-16 right"
                                                        label="Sort By"
                                                        InputLabelProps={{ shrink: true }}
                                                        onChange={event => this.handleSortChange(event, "return")}>
                                                        <MenuItem value="cheapestprice-asc">Price: Ascending</MenuItem>
                                                        <MenuItem value="cheapestprice-desc">Price: Descending</MenuItem>
                                                        <MenuItem value="deptime-asc">Depart: Ascending</MenuItem>
                                                        <MenuItem value="deptime-desc">Depart: Descending</MenuItem>
                                                        <MenuItem value="arrtime-asc">Arrival: Ascending</MenuItem>
                                                        <MenuItem value="arrtime-desc">Arrival: Descending</MenuItem>
                                                    </TextField>
                                                </Grid>

                                                {this.getDomesticRoundTripFlights("to")}
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Grid container spacing={8}>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <Typography variant="h6" className="mb-16">
                                                    Search Results
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <TextField
                                                    name="toSort"
                                                    select
                                                    value={this.state.toSort}
                                                    variant="outlined"
                                                    className="mb-16 right"
                                                    label="Sort By"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={event => this.handleSortChange(event, "oneway")}>
                                                    <MenuItem value="cheapestprice-asc">Price: Ascending</MenuItem>
                                                    <MenuItem value="cheapestprice-desc">Price: Descending</MenuItem>
                                                    <MenuItem value="deptime-asc">Depart: Ascending</MenuItem>
                                                    <MenuItem value="deptime-desc">Depart: Descending</MenuItem>
                                                    <MenuItem value="arrtime-asc">Arrival: Ascending</MenuItem>
                                                    <MenuItem value="arrtime-desc">Arrival: Descending</MenuItem>
                                                </TextField>
                                            </Grid>
                                            {this.getDomesticOnewayFlights()}
                                        </Grid>
                                    </React.Fragment>
                                )}
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Button onClick={this.handleBack} variant="outlined" color="secondary">
                                        Back
                                    </Button>
                                    <Button className="m-l-r-8" onClick={this.rescheduleTicket} variant="contained" color="primary">
                                        Submit
                                    </Button>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default withCustomSnackBarContext(withLoadingContext(RescheduleTicket));
