import React from "react";
import { Dialog, DialogContent, CircularProgress } from "@material-ui/core";

export const Loading = () => {
  return (
    <Dialog disableBackdropClick={true} open={true} aria-labelledby="loading">
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};
