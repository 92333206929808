import React, { Component } from "react";
import { formatDate, parseDate } from "react-day-picker/moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import "./TGDateInput.css";

class TGDateInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateValue: props.defaultValue !== undefined ? props.defaultValue : "",
    };
  }
  handleChange = (name, day) => {
    this.setState(
      {
        dateValue: day,
      },
      () => this.props.onDateChange(name, day)
    );
  };
  render() {
    return (
      <div className={`relative tg-date-input ${this.props.className}`}>
        <label className="tg-date-label">
          {this.props.label} {this.props.required && "*"}
        </label>
        <DayPickerInput
          value={this.state.dateValue}
          placeholder=""
          formatDate={formatDate}
          parseDate={parseDate}
          fromMonth={new Date()}
          format={"Do-MMM-YYYY"}
          inputProps={{
            readOnly: true,
          }}
          dayPickerProps={{
            disabledDays: {
              before: this.props.disableFrom !== undefined && this.props.disableFrom,
              after: this.props.disableAfter !== undefined && this.props.disableAfter,
            },
          }}
          onDayChange={day => this.handleChange(this.props.name, day)}
        />
      </div>
    );
  }
}

export default TGDateInput;
