import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Checkbox,
  ListItemSecondaryAction,
  List,
  ListItem,
  ListItemText,
  Typography,
  TextField,
} from "@material-ui/core";
import { withCustomSnackBarContext } from "../../common/CustomSnackBarProvider";
import { withLoadingContext } from "../../common/LoadingProvider";
import { getUserProfile } from "../../../services/common/CommonService";
import { cancelFlightTicket } from "../../../services/queues/FlightQueuesService";

class CancelTicket extends React.PureComponent {
  profile = {};
  hasReturn = false;
  constructor(props) {
    super(props);
    const returnPax = this.props.PassengerRecord.filter(x => x.trip_number === 2);
    this.hasReturn = returnPax.length > 0 ? true : false;
    this.state = {
      paxIds: [],
      remarks: "",
    };
    this.profile = getUserProfile();
  }

  closeDialog = () => {
    this.props.hideCancel();
  };

  cancelTicket = async () => {
    if (this.state.paxIds.length === 0) {
      this.props.snackBarContext.show("Please select traveller(s) to reschedule", "error");
      return;
    }
    this.props.loadingContext.show();
    var paxs = this.state.paxIds.join();
    let reqObj = {
      profileid: this.profile.profileid,
      corporateid: this.profile.corporateid,
      tcprofileid: this.profile.profileid,
      reference_txid: this.props.txid,
      remarks: this.state.remarks,
      canxpax: paxs,
    };

    const res = await cancelFlightTicket(reqObj);
    try {
      if (res.data.ApiStatus.Status === "NOK") {
        this.props.snackBarContext.show(res.data.ApiStatus.Message, "error");
      } else {
        this.props.snackBarContext.show("Ticket cancellation request has been successfully registered.", "success");
        this.props.hideCancel();
      }
    }
    catch (e) { }
    this.props.loadingContext.hide();
  };

  handleToggle = id => {
    var arr = [...this.state.paxIds];
    var idx = arr.indexOf(id);
    if (idx > -1) {
      arr.splice(idx, 1);
    } else {
      arr.push(id);
    }
    this.setState({
      paxIds: arr,
    });
  };

  handleRemarksChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Dialog className="ticket-cancel-dialog" open={this.props.showCancel} aria-labelledby="cancel-ticket">
          <DialogTitle id="cancel-ticket">Cancel Ticket</DialogTitle>
          <hr className="full-width" />
          <DialogContent>
            <Typography variant="subtitle1"> Select traveller(s) to cancel ticket</Typography>
            <List>
              <ListItem className="p-l-0">Onward Journey</ListItem>
              {this.props.PassengerRecord.map(
                (pr, id) =>
                  pr.trip_number === 1 && (
                    <ListItem key={id} dense button>
                      <ListItemText>
                        <Typography>{`${pr.title}. ${pr.first_name} ${pr.last_name}`}</Typography>
                        {pr.status === "canxprocessing" && (
                          <Typography variant="caption">ticket has already been cancelled &amp; under process for refund from airline</Typography>
                        )}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <Checkbox
                          disabled={pr.status !== "confirmed"}
                          onChange={() => this.handleToggle(pr.paxid)}
                          checked={this.state.paxIds.indexOf(pr.paxid) !== -1}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )
              )}
              <Typography variant="subtitle1" className="mt-8">
                {this.props.onwardRules}
              </Typography>
            </List>
            {this.hasReturn && (
              <List>
                <ListItem className="p-l-0">Return Journey</ListItem>
                {this.props.PassengerRecord.map(
                  (pr, id) =>
                    pr.trip_number === 2 && (
                      <ListItem key={id} dense button>
                        <ListItemText>
                          <Typography>{`${pr.title}. ${pr.first_name} ${pr.last_name}`}</Typography>
                          {pr.status === "canxprocessing" && (
                            <Typography variant="caption">Ticket has already been cancelled &amp; under process for refund from airline</Typography>
                          )}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <Checkbox
                            disabled={pr.status !== "confirmed"}
                            onChange={() => this.handleToggle(pr.paxid)}
                            checked={this.state.paxIds.indexOf(pr.paxid) !== -1}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                )}
                <Typography variant="subtitle1" className="mt-8">
                  {this.props.returnRules}
                </Typography>
              </List>
            )}
            <TextField
              variant="outlined"
              name="remarks"
              label="Remarks"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal"
              value={this.state.remarks}
              onChange={this.handleRemarksChange}
            />
          </DialogContent>

          <DialogActions className="left">
            <Button onClick={this.closeDialog} color="secondary">
              Close
            </Button>
            <Button onClick={this.cancelTicket} variant="contained" color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withLoadingContext(withCustomSnackBarContext(CancelTicket));
