import React, { Component } from "react";
import { Loading } from "./Loading";

const LoadingContext = React.createContext();
export class LoadingProvider extends Component {
  state = {
    isLoading: false,
    show: () => {
      this.setState({ isLoading: true });
    },
    hide: () => {
      this.setState({
        isLoading: false
      });
    }
  };
  render() {
    return (
      <LoadingContext.Provider
        value={{
          ...this.state
        }}
      >
        {this.state.isLoading && <Loading />}
        <>{this.props.children}</>
      </LoadingContext.Provider>
    );
  }
}
// create the consumer as higher order component
// eslint-disable-next-line react/display-name
export const withLoadingContext = ChildComponent => props => (
  <LoadingContext.Consumer>
    {context => <ChildComponent {...props} loadingContext={context} />}
  </LoadingContext.Consumer>
);
