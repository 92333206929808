import React, { Component } from "react";
import AgentContactInfo from "../common/AgentContactInfo";
import CustomerDetails from "../common/CustomerDetails";
import FareBreakUp from "../common/FareBreakUp";
import HotelDetails from "./HotelDetails";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CorporateDetails from "../common/CorporateDetails";

class HotelBillingMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      agentObj: {
        customerid: "",
        title: "Mr",
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        requestedby: "",
        supplierid: ""
      },
      hotelDetailsObj: {
        hotelname: "",
        hotelrating: "",
        checkindate: "",
        checkoutdate: "",
        numrooms: 1,
        roomtype: "",
        hoteladdress: "",
        upplierreference: "",
        servicetype: "domestic",
        city: "",
        state: "",
        country: "",
        narration: ""
      },
      corporateDetailsObj: {
        costcenterid: "",
        departmentid: "",
        projectid: "",
      },
      customerDetailsObj: {
        numadults: 1,
        numchildren: 0,
        numinfants: 0,
        adultsArr: [
          {
            title: "Mr",
            firstname: "",
            lastname: "",
            paxtype: ""
          }
        ],
        childrenArr: [],
        infantArr: []
      },
      fareObj: {
        basefare: 0,
        tax: 0,
        servicefee: 0,
        totalinvoice: 0,
      }
    };
  }

  updateCorporateDetailsObject = obj => {
    this.setState({
      corporateDetailsObj: obj
    });
  };

  updateHotelDetailsObject = obj => {
    this.setState({
      hotelDetailsObj: obj
    });
  };

  updateCustomerDetailsObject = obj => {
    this.setState({
      customerDetailsObj: obj
    });
  };

  updateStateObject = obj => {
    this.setState({
      agentObj: obj,
      customerId: obj.customerid
    });
  };

  updateFareObject = obj => {
    this.setState({
      fareObj: obj
    });
  };

  handleGenerateBill = async () => {
    let passengers = [];
    let agentObjError = 0, customerDetailsObjError = 0, hotelDetailsObjError = 0, fareObjError = 0;
    const alertMsg = "Please enter value for ";
    const { agentObj, hotelDetailsObj, customerDetailsObj, fareObj } = this.state;

    Object.keys(agentObj).some(key => {
      if (agentObj[key] === "") {
        window.alert(alertMsg + key);
        agentObjError = 1;
        return true;
      }
      return false;
    });

    if(agentObjError === 1){
      return false;
    }

    Object.keys(customerDetailsObj).some(key => {
      const keyVal = customerDetailsObj[key];
      if (["adultsArr", "childrenArr", "infantArr"].includes(key) && Array.isArray(keyVal) && keyVal.length > 0) {
        for(let i=0; i<keyVal.length; i++){
          passengers.push({
            "FlightPassenger": keyVal[i]
          });
        }
      }
      
      if (customerDetailsObj[key] === "") {
        window.alert(alertMsg + key);
        customerDetailsObjError = 1;
        return true;
      }
      return false;
    });

    if(customerDetailsObjError === 1){
      return false;
    }

    Object.keys(hotelDetailsObj).some(key => {
      if (hotelDetailsObj[key] === "" || hotelDetailsObj[key] === "select") {
        window.alert(alertMsg + key);
        hotelDetailsObjError = 1;
        return true;
      }
      return false;
    });

    if(hotelDetailsObjError === 1){
      return false;
    }

    Object.keys(fareObj).some(key => {
      if (fareObj[key] === "") {
        window.alert(alertMsg + key);
        fareObjError = 1;
        return true;
      }
      return false;
    });

    if(fareObjError === 1){
      return false;
    }
  };
  
  render() {
    return (
      <React.Fragment>
        <Typography variant="h5" className="mb-8">
          Hotel Offline Billing
        </Typography>
        <AgentContactInfo
          agentObj={this.state.agentObj}
          updateChange={this.updateStateObject}
        />
        <br />
        <HotelDetails 
          hotelDetailsObj={this.state.hotelDetailsObj}
          updateChange={this.updateHotelDetailsObject}
        />
        <br />
        <CorporateDetails 
          detailsObj={this.state.corporateDetailsObj}
          customerId={this.state.customerId}
          updateChange={this.updateCorporateDetailsObject}
        />
        <br />
        <CustomerDetails 
          customerDetailsObj={this.state.customerDetailsObj}
          updateChange={this.updateCustomerDetailsObject}
        />
        <br />
        <FareBreakUp
          fareObj={this.state.fareObj}
          updateFare={this.updateFareObject}
        />
        <br />
        <div className="text-center">
          <Button
            onClick={this.handleGenerateBill}
            variant="contained"
            color="primary"
          >
            Generate Bill
          </Button>
        </div>
        <br />
        <br />
      </React.Fragment>
    );
  }
}

export default HotelBillingMain;
