import React, { Component } from "react";
import moment from "moment";
import { Typography, Paper, Grid, TextField, MenuItem } from "@material-ui/core";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { TRIP_TYPE } from "../../common/constants";

class CabDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cab: props.cabDetailsObj,
    };
  }
  handleInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      cab: {
        ...prevState.cab,
        [eve.target.name]: eve.target.value
      }
    }),
    () => this.props.updateChange(this.state.cab)
    );
  };

  handleDateChange = (day, name) => {
    this.setState(prevState => ({
      cab: {
        ...prevState.cab,
        [name]: day
      }
    }),
      () => this.props.updateChange(this.state.cab)
    );
  };

  render() {
    return (
      <Paper className="padding-16 cab-details-form">
        <Typography variant="subtitle1" className="bold">
          Cab Details
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="cabid"
              label="Cab Id"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.cab.cabid}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="carname"
              label="Car Name"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.cab.carname}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              select
              variant="outlined"
              name="cartype"
              label="Car Type"
              className="select"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.cab.cartype}
              onChange={this.handleInputChange}
            >
              <MenuItem value="ac">AC</MenuItem>
              <MenuItem value="nonac">Non AC</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              select
              variant="outlined"
              name="triptype"
              label="Trip Type"
              className="select"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.cab.triptype}
              onChange={this.handleInputChange}
            >
              <MenuItem value="select" disabled>--Select--</MenuItem>
              {Object.keys(TRIP_TYPE).map((key, i) => (
                <MenuItem value={TRIP_TYPE[key].value} key={i}>{TRIP_TYPE[key].label}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <DayPickerInput
              placeholder="Pickup Date"
              className="full-width"
              name="pickupdate"
              value={this.state.cab.pickupdate}
              // dayPickerProps={{ disabledDays: { before: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "pickupdate")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              type="time"
              variant="outlined"
              name="pickuptime"
              label="Pickup Time"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.cab.pickuptime}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <DayPickerInput
              placeholder="Drop Date"
              className="full-width"
              name="dropdate"
              value={this.state.cab.dropdate}
              // dayPickerProps={{ disabledDays: { before: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "dropdate")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              type="time"
              variant="outlined"
              name="droptime"
              label="Drop Time"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.cab.droptime}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="pickup"
              multiline
              rows={2}
              label="Pickup Address"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.cab.pickup}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="destination"
              label="Destination Address"
              multiline
              rows={2}
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.cab.destination}
              onChange={this.handleInputChange}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default CabDetails;
