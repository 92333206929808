import React from "react";
import {
  Button,
  Typography,
  TextField,
  Grid,
  MenuItem,
  Select,
  FormHelperText,
  Drawer
} from "@material-ui/core";
import { createSupplier } from "../../services/suppliers/SupplierService";

class RegisterSupplier extends React.Component {
  userId = "";
  constructor(props) {
    super(props);
    this.state = {
      register: {
        salutation: "Mr",
        first_name: "",
        last_name: "",
        company: "",
        contact_email: "",
        mobile_number: "",
        password: ""
      },
      isError: false
    };
  }

  handleChange = event => {
    let eve = { ...event };
    if (event.target.name === "contact_email") {
      this.userId = eve.target.value;
      this.setState(prevState => ({
        register: {
          ...prevState.register,
          [eve.target.name]: eve.target.value
        }
      }));
    } else {
      this.setState(prevState => ({
        register: {
          ...prevState.register,
          [eve.target.name]: eve.target.value
        }
      }));
    }
  };

  handleRegistration = async event => {
    event.preventDefault();
    let isValid = true;
    Object.keys(this.state.register).forEach(property => {
      if (this.state.register[property] === "") {
        isValid = false;
      }
    });
    if (isValid) {
      this.props.showLoading(true);
      let res = await createSupplier(this.state.register, this.userId);
      if (res) {
        if (res.ApiStatus.Status === "NOK") {
          this.props.showSnackBar(res.ApiStatus.Message, "error");
        } else {
          this.props.showSnackBar(res.ApiStatus.Message, "success");
        }
      } else {
        this.props.showSnackBar(
          "Failed to create user account. Please try again.",
          "error"
        );
      }
      this.props.showLoading(false);
    } else {
      this.setState({
        isError: true
      });
    }
  };

  render() {
    return (
      <Drawer open={true} anchor="right" className="register-drawer">
        <Grid container spacing={16}>
          <Grid item lg={6} md={6} sm={6} xs={8}>
            <Typography variant="h6"> Register User </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={4}>
            <Button
              onClick={this.props.closeDrawer}
              className="right"
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Grid>
        </Grid>
        <form onSubmit={this.handleRegistration}>
          <Grid container spacing={8}>
            {Object.keys(this.state.register).map((property, index) => (
              <Grid key={index} item lg={12} md={12} sm={12} xs={12}>
                {property === "salutation" ? (
                  <Select
                    placeholder={property}
                    name={property}
                    required
                    fullWidth
                    value={this.state.register[property]}
                    onChange={this.handleChange}
                  >
                    <MenuItem value="Mr">Mr</MenuItem>
                    <MenuItem value="Mrs">Mrs</MenuItem>
                    <MenuItem value="Ms">Ms</MenuItem>
                  </Select>
                ) : (
                  <React.Fragment>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      placeholder={property}
                      name={property}
                      value={this.state.register[property]}
                      onChange={this.handleChange}
                    />
                    {this.state.isError &&
                      this.state.supplierObj[property] === "" && (
                        <FormHelperText className="error-text">
                          This field is required.
                        </FormHelperText>
                      )}
                  </React.Fragment>
                )}
              </Grid>
            ))}
          </Grid>
          <br />
          <br />
          <Button
            className="full-width"
            variant="contained"
            color="secondary"
            type="submit"
          >
            Register
          </Button>
        </form>
      </Drawer>
    );
  }
}

export default RegisterSupplier;
