import React, { Component } from "react";
import { Loading } from "../../components/common/Loading";
import {
  Paper,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import moment from "moment";
import {
  getTripTransactions,
  getTripDetailsLite,
} from "../../services/trips/TripsService";
import "./TripTicketPage.css";
import FlightETicket from "../../components/queues/flight/ETicket";
import HotleETicket from "../queues/hotel/HotelTicketPage";
import CabETicket from "../../components/queues/cab/ETicket";
import TrainETicket from "../../components/queues/train/ETicket";
import BusETicket from "../../components/queues/bus/ETicket";

class TripTicketPage extends Component {
  tripId = "";
  constructor(props) {
    super(props);
    this.tripId = window.location.href.split("/").pop();
    this.state = {
      isLoading: false,
      tripItems: {},
      selectedTripId: undefined,
      tripDetails: undefined,
      selectedServiceType: undefined,
      startDate: "",
      endDate: "",
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    const res = await getTripTransactions(this.tripId);
    const tripDetails = await getTripDetailsLite(this.tripId);
    const startDate = tripDetails.jdate;
    const endDate = tripDetails.rdate;
    if (res) {
      const tripItems = res.reduce((acc, cur) => {
        if (!acc[cur.servicetype]) {
          acc[cur.servicetype] = [];
        }
        acc[cur.servicetype].push(cur);
        return acc;
      }, {});

      this.setState({
        tripDetails,
        tripItems,
        startDate,
        endDate,
      });
    } else {
      this.setState({
        tripDetails,
        startDate,
        endDate,
      });
    }
    this.setState({
      isLoading: false
    });
  }

  showTransactionDetails = async (serviceType, tripId) => {
    this.setState({
      selectedTripId: tripId,
      selectedServiceType: serviceType,
    });
  };

  render() {
    const { tripDetails, tripItems, selectedServiceType, selectedTripId } = this.state;
    const serviceTypes = Object.keys(tripItems);
    return (
      <div className="main-container">
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={12} md={12} sm={12} xs={12} className="no-print">
            {tripDetails && (
              <Paper className="padding-16">
                <Grid container spacing={8}>
                  <Grid item lg={5} md={5} sm={5} xs={12}>
                    <Typography variant="caption">Trip Name</Typography>
                    <Typography variant="h5">
                      {tripDetails.tripname} {tripDetails.isownbooking && <span className="own-booking">(Own booking)</span>}
                    </Typography>
                  </Grid>
                  <Grid item lg={7} md={7} sm={7} xs={12}>
                    <Typography variant="caption">Booking Status</Typography>
                    <Typography variant="h5">{tripDetails.status}</Typography>
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} xs={12}>
                    <Typography variant="caption">Trip ID</Typography>
                    <Typography variant="h5">{tripDetails.tripid}</Typography>
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} xs={12}>
                    <Typography variant="caption">Journey Date</Typography>
                    <Typography variant="h6">{moment(tripDetails.jdate).format('DD-MM-YYYY')}</Typography>
                  </Grid>
                  <Grid item lg={3} md={3} sm={3} xs={12}>
                    <Typography variant="caption">Return Date</Typography>
                    <Typography variant="h6">{moment(tripDetails.rdate).format('DD-MM-YYYY')}</Typography>
                  </Grid>
                  {tripDetails.ismodified === true && (
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                      <Typography variant="caption">Original Trip</Typography>
                      <Button target="_blank" color="secondary" className="no-padding fs-16" href={`/pre-trip-flow/view/${tripDetails.original_tripid}`}>
                        {tripDetails.original_tripid}
                      </Button>
                    </Grid>
                  )}
                  {tripDetails.amended_tripids !== undefined &&
                    tripDetails.amended_tripids !== "" && (
                      <Grid item lg={3} md={3} sm={3} xs={12}>
                        <Typography variant="caption">Original Trip</Typography>
                        {tripDetails.amended_tripids.split(",").map((tripId, index) => (
                          <Button key={index} target="_blank" color="secondary" className="no-padding fs-16" href={`/pre-trip-flow/view/${tripId}`}>
                            {tripDetails.original_tripid}
                          </Button>
                        ))}
                      </Grid>
                    )}
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="caption">Origin</Typography>
                    <Typography variant="h6">{tripDetails.origin}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="caption">Destination</Typography>
                    <Typography variant="h6">{tripDetails.destination}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>
          {tripDetails &&
            tripDetails.isownbooking && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Paper className="padding-16">
                  <Typography variant="h6" className="text-center">
                    This is Own booking trip. No transactions found for this trip.
                  </Typography>
                </Paper>
              </Grid>
            )}
          {tripDetails &&
            (tripDetails.isownbooking === false || tripDetails.isownbooking === "") && (
              <React.Fragment>
                <Grid item lg={3} md={3} sm={3} xs={12} className="no-print">
                  <br/>
                  <Paper>
                    {serviceTypes.map((serviceType, index) => (
                      <div key={index}>
                        <Typography variant="h6" className="service-title">
                          {serviceType}
                        </Typography>
                        {tripItems[serviceType].map(trip => (
                          <div
                            className={`${selectedTripId === trip.tripid ? "active-trip" : ""}`}
                            key={trip.tripid}
                            onClick={() => this.showTransactionDetails(serviceType, trip.tripid)}>
                            <Typography variant="subtitle1" className="trip-name">
                              {trip.origin} - {trip.destination}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    ))}
                  </Paper>
                </Grid>
                <Grid item lg={9} md={9} sm={9} xs={12}>
                  {selectedServiceType === undefined && serviceTypes.length > 0 && (
                    <React.Fragment>
                      <br/>
                      <Paper className="padding-16">
                        <Typography variant="h6" className="text-center">
                          Please select service type on the left side to view the transaction details
                        </Typography>
                      </Paper>
                    </React.Fragment>
                  )}
                  {selectedServiceType === "flight" && selectedTripId !== undefined && <FlightETicket txId={this.state.selectedTripId} showPrintButton={true} />}
                  {selectedServiceType === "hotel" && selectedTripId !== undefined && <HotleETicket txId={this.state.selectedTripId} showPrintButton={true} />}
                  {selectedServiceType === "bus" && selectedTripId !== undefined && <BusETicket txId={this.state.selectedTripId} showPrintButton={true} />}
                  {selectedServiceType === "train" && selectedTripId !== undefined && <TrainETicket txId={this.state.selectedTripId} showPrintButton={true} />}
                  {selectedServiceType === "cab" && selectedTripId !== undefined && <CabETicket txId={this.state.selectedTripId} showPrintButton={true} />}
                </Grid>
              </React.Fragment>
            )}
        </Grid>
      </div>
    );
  }
}

export default TripTicketPage;
