import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  Typography,
  TextField,
  Hidden
} from "@material-ui/core";
import {
  getSuppliers,
  getSupplierCredentials,
  getSupplierDetails,
  getAllCustomers
} from "../../services/suppliers/SupplierService";
import { Loading } from "../common/Loading";
import { debounce } from "../common/Utils";
import CustomSnackBar from "../common/CustomSnackBar";
import AddOrUpdateSupplier from "./AddOrUpdateSupplier";
import AddOrUpdateCredentials from "./AddOrUpdateCredentials";
import RegisterSupplier from "./RegisterSupplier";

class ListSuppliers extends Component {
  supId = undefined;
  supName = undefined;
  sortType = "asc";
  selectedSup = undefined;
  isEdit = false;
  constructor(props) {
    super(props);
    this.state = {
      suppliers: undefined,
      filteredSuppliers: undefined,
      credsList: undefined,
      isLoading: false,
      showCreds: false,
      isAddSupplier: false,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      },
      agentsList: [],
      isAddUser: false,
      isError: false,
      supplierObj: undefined
    };
  }

  initializeSupplierObj = () => {
    this.setState({
      supplierObj: {
        supplier_name: "",
        supplier_contact1: "",
        supplier_contact2: "",
        supplier_mobile: "",
        supplier_address1: "",
        supplier_address2: "",
        supplier_city: "",
        supplier_state: "",
        supplier_country: "",
        supplier_pincode: "",
        supplier_status: "active",
        supplier_url: "",
        supplier_contact_person: "",
        supplier_contact_name: "",
        supplier_contact_designation: "",
        supplier_contact_email: "",
        supplier_contact_phone: "",
        supplier_contact_mobile: "",
        supplier_pannumber: "",
        supplier_servicetax_no: "",
        supplier_productlist: "",
        belongstocustomer: "false",
        agentid: ""
      }
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  showLoading = loading => {
    this.setState({
      isLoading: loading
    });
  };

  async componentDidMount() {
    this.initializeSupplierObj();
    await this.getSuppliersList();
    await this.getAgentsList();
  }

  getAgentsList = async () => {
    this.showLoading(true);
    let res = await getAllCustomers();
    this.setState({
      agentsList: res
    });
    this.showLoading(false);
  };

  getSuppliersList = async () => {
    this.showLoading(true);
    let res = await getSuppliers();
    this.setState({
      suppliers: res,
      filteredSuppliers: res
    });
    this.showLoading(false);
  };

  handleSearchChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.suppliers.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredSuppliers: data
    });
  }, 500);

  sortBy = (property, type) => {
    let data = [...this.state.filteredSuppliers];
    if (type === this.sortType) {
      data.sort((a, b) => (a[property] > b[property] ? -1 : 1));
      this.sortType = "desc";
    } else {
      data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
      this.sortType = "asc";
    }
    this.setState({
      filteredSuppliers: data
    });
  };

  getCredentialsList = async () => {
    let res = await getSupplierCredentials(this.supId);
    res = Array.isArray(res) ? res : [res];
    return res;
  };

  handleEdit = async supId => {
    this.showLoading(true);
    let res = await getSupplierDetails(supId);
    this.selectedSup = res;
    this.isEdit = true;
    this.setState({
      supplierObj: res,
      isLoading: false,
      isAddSupplier: true
    });
  };

  viewCredentials = async (supId, supName) => {
    this.showLoading(true);
    this.supId = supId;
    this.supName = supName;
    let res = await this.getCredentialsList();
    this.setState({
      credsList: res,
      isLoading: false,
      showCreds: true
    });
  };

  handleCreateUser = () => {
    this.setState({
      isAddUser: true
    });
  };

  handleAddSupplier = () => {
    this.setState({
      isAddSupplier: true
    });
  };

  closeDrawer = () => {
    this.isEdit = false;
    this.initializeSupplierObj();
    this.setState({
      showCreds: false,
      isAddUser: false,
      isAddSupplier: false
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Typography variant="h6" className="mb-8">
              Suppliers
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Button
              className="right"
              variant="contained"
              color="secondary"
              onClick={this.handleAddSupplier}
            >
              Add Supplier
            </Button>
            <Button
              className="right"
              variant="contained"
              color="primary"
              onClick={this.handleCreateUser}
            >
              Create User
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              className="cred-key mb-8"
              name="searchTerm"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleSearchChange}
            />
          </Grid>
        </Grid>
        <Table className="table-auto">
          <TableHead>
            <TableRow>
              <TableCell>
                Supplier ID <Hidden smUp>/ Supplier Name</Hidden>
              </TableCell>
              <Hidden xsDown>
                <TableCell>
                  <Button onClick={() => this.sortBy("supplier_name", "asc")}>
                    Supplier Name
                  </Button>
                </TableCell>
              </Hidden>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.filteredSuppliers &&
              this.state.filteredSuppliers.map((sup, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{sup.supplier_id}</Typography>
                    <Hidden smUp>
                      <Typography>{sup.supplier_name}</Typography>
                    </Hidden>
                  </TableCell>
                  <Hidden xsDown>
                    <TableCell>
                      <Typography>{sup.supplier_name}</Typography>
                    </TableCell>
                  </Hidden>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => this.handleEdit(sup.supplier_id)}
                      className="blue-btn"
                    >
                      Edit
                    </Button>{" "}
                    &nbsp;&nbsp;
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        this.viewCredentials(sup.supplier_id, sup.supplier_name)
                      }
                      className="blue-btn"
                    >
                      Credentials
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {this.state.filteredSuppliers &&
              this.state.filteredSuppliers.length === 0 && (
                <TableRow>
                  <TableCell colSpan="3">No suppliers found.</TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>

        {this.state.showCreds && (
          <AddOrUpdateCredentials
            showLoading={this.showLoading}
            showSnackBar={this.showSnackBar}
            credsList={this.state.credsList}
            closeDrawer={this.closeDrawer}
            supId={this.supId}
            supName={this.supName}
          />
        )}

        {this.state.isAddSupplier && (
          <AddOrUpdateSupplier
            closeDrawer={this.closeDrawer}
            showLoading={this.showLoading}
            showSnackBar={this.showSnackBar}
            supplierObj={this.state.supplierObj}
            agentsList={this.state.agentsList}
            getSuppliersList={this.getSuppliersList}
            isEdit={this.isEdit}
          />
        )}

        {this.state.isAddUser && (
          <RegisterSupplier
            closeDrawer={this.closeDrawer}
            showLoading={this.showLoading}
            showSnackBar={this.showSnackBar}
          />
        )}

        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleSnackBarClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ListSuppliers;
