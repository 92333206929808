import React, { Component } from "react";
import { Typography, Grid, Paper, TextField, Button } from "@material-ui/core";
import {
  getTrainTicket,
  updateTrainFare,
} from "../../../services/queues/TrainQueuesService.js";
import { Loading } from "../../../components/common/Loading";
import CustomSnackBar from "../../../components/common/CustomSnackBar";

class TrainFareUpdatePage extends Component {
  train = undefined;
  txId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      trainInvoice: undefined,
      snackBar: {
        isOpen: false,
        message: "",
        variant: "",
      },
      travellers: undefined,
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.txId = window.location.pathname.split("/").pop();
    let data = "";
    let res = await getTrainTicket(this.txId);
    if (res) {
      data = res;
      this.train = res.TrainInvoice;
      data.TrainInvoice.trainrecord = Array.isArray(
        data.TrainInvoice.trainrecord
      )
        ? data.TrainInvoice.trainrecord
        : [data.TrainInvoice.trainrecord];
      this.setState({
        isLoading: false,
        trainInvoice: data,
        travellers: data.TrainInvoice.trainrecord,
        adminremarks: "",
      });
      this.calculateTotalAmount();
    } else {
      this.setState({
        isLoading: false,
        trainInvoice: undefined,
      });
    }
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  handelChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (event, index) => {
    let eve = { ...event };
    let data = [...this.state.travellers];
    data[index][eve.target.name] = eve.target.value;
    this.setState({
      travellers: data,
    });
    this.calculateTotalAmount();
  };

  calculateTotalAmount = () => {
    let data = [...this.state.travellers];
    let totalAmount = Number(data[0].totalfare) + Number(data[0].agentServiceCharge) + Number(data[0].irctcConvFee) + Number(data[0].irctcPgCharges) + Number(data[0].irctcInsuranceAmount);
    data[0]["totalinvoice"] = Number(totalAmount);
    data[0]["totalfarequote"] = Number(totalAmount);
    this.setState({
      travellers: data,
    });
  };

  handleQueueUpdate = async () => {
    this.setState({
      isLoading: true,
    });
    const traveller = {
      ...this.state.travellers,
    };
    let fares = {
      totalbasefare: traveller[0].totalfare,
      totaltax: traveller[0].totaltax,
      commission: traveller[0].commission,
      tds: traveller[0].tds,
      servicetax: traveller[0].servicetax,
      handlingcharges: traveller[0].handlingcharges,
      totalinvoice: traveller[0].totalinvoice,
      totalfarequote: traveller[0].totalfarequote,
      agentServiceCharge: traveller[0].agentServiceCharge,
      irctcConvFee: traveller[0].irctcConvFee,
      irctcPgCharges: traveller[0].irctcPgCharges,
      irctcInsuranceAmount: traveller[0].irctcInsuranceAmount,
    };

    let res = await updateTrainFare(this.txId, fares, this.state.adminremarks);
    let msg = "";
    let type = "success";
    if (res.ResponseStatus.status === "OK") {
      msg = res.ResponseStatus.message;
    } else {
      msg =  res.ResponseStatus.message;
      type = "error";
    }
    this.setState({
      isLoading: false,
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type,
      },
    });
  };
  
  getInputFields = () => {
    return (
      <React.Fragment>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={8}>
            {this.state.travellers.map(
              (traveller, index) => (
                  <React.Fragment key={index}>
                    <Grid item lg={2} md={2} sm={2} xs={12}>
                      <Typography>{traveller.paxname}</Typography>
                      <Typography>{traveller.paxmobile}</Typography>
                      <Typography>{traveller.jdate}</Typography>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Total Base Fare"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        name="totalfare"
                        type="number"
                        value={traveller.totalfare}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Agent Service Charge"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        name="agentServiceCharge"
                        value={traveller.agentServiceCharge}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="irctcConvFee"
                        type="number"
                        name="irctcConvFee"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.irctcConvFee}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="irctcPgCharges"
                        type="number"
                        name="irctcPgCharges"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.irctcPgCharges}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="irctcInsAmount"
                        type="number"
                        name="irctcInsuranceAmount"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.irctcInsuranceAmount}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Total Invoice"
                        type="number"
                        name="totalinvoice"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.totalinvoice}
                        disabled
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Total Farequote"
                        name="totalfarequote"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.totalfarequote}
                        disabled
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                  </React.Fragment>
                )
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={4} xs={8}>
            <Typography variant="h6" className="mb-8">
              Update Train Fare
            </Typography>
          </Grid>
          <Grid item lg={9} md={9} sm={8} xs={4}>
            {this.state.trainInvoice && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  const url = `/train-ticket/${this.txId}`;
                  window.open(url, "_blank");
                }}
              >
                View Ticket
              </Button>
            )}
          </Grid>
        </Grid>

        {this.state.trainInvoice && (
          <Grid container spacing={16}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Typography variant="h6"> Fare Details </Typography>
                <br />
                <Grid container spacing={8}>
                  {this.getInputFields()}
                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      name="adminremarks"
                      value={this.state.adminremarks}
                      variant="outlined"
                      label="Admin Remarks"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handelChange}
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleQueueUpdate}
                >
                  Update Fare
                </Button>
              </Paper>
            </Grid>
          </Grid>
        )}
        {this.state.trainInvoice === undefined && (
          <Paper className="padding-16">
            <div>
              <Typography align="center" variant="h6">
                No data found for the Transaction No: {this.txId}
              </Typography>
            </div>
          </Paper>
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default TrainFareUpdatePage;
