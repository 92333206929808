import {
  apiPost,
  getUserAuth,
  getUserProfile,
} from "../common/CommonService";

export const getCabBookingQueues = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getcabbookingqueues"
    };
    let res = await apiPost(reqObj);
    if (res.list[0].CabInvoice) {
      return Array.isArray(res.list[0].CabInvoice) ? res.list[0].CabInvoice : [res.list[0].CabInvoice];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getCabTicket = async txId => {
  try {
    let reqObj = {
      opid: "TG-GETETICKET",
      txid: txId
    };
    const res = await apiPost(reqObj);
    return res;
  } catch (error) {
    return undefined;
  }
};

export const updateBusOrTrainQueueBooking = async (obj, acitonType) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: acitonType,
      jsondata: JSON.stringify(obj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (error) {
    return {
      ResponseStatus: {
        status: "NOK",
        message: "Failed to update queue. Please try again."
      }
    };
  }
};

export const updateCabFare = async (txnId, fares = {}, remarks) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatecabfare",
      jsondata: JSON.stringify({
        "com.nexpense.vobs.cabs.CabBookingFareUpdate": {
          txid: txnId,
          adminremarks: remarks,
          ...fares,
        }
      }),
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const rollbackCabTicket = async (txId, remarks) => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "rollbackticket",
      txtype: "cab",
      txid: txId,
      agentid: userAuth.corporateid,
      remarks: remarks,
    };
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    }
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getCabCancellationQueues = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getcabcancellationqueues",
      agentid: getUserProfile().corporateid
    };
    let res = await apiPost(reqObj);
    if (res.list[0].CabCancellations) {
      return res.list[0].CabCancellations;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const performCabRefund = async obj => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "performcabrefund",
      txid: obj.txid,
      adminid: userAuth.corporateid,
      agentid: obj.agentid,
      reference_txid: obj.refTxId,
      totalrefundamount: obj.refundAmount,
      refundamount_operator: obj.canxChargeSup,
      operator_canx_charge: obj.canxChargeSup,
      canxcharge_ota: obj.canxChargeOTA,
      remarks: obj.adminRemarks,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const rejectCabCancellation = async (txId, adminRemarks) => {
  try {
    let userAuth = getUserAuth();
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "rejectcabcancellation",
      agentid: userAuth.corporateid,
      txid: txId,
      remarks: adminRemarks,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};