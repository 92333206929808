import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Hidden,
  Paper,
  Grid,
  TextField,
  Popover,
  Drawer,
  Divider,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { debounce } from "../common/Utils";
import { approveReceipt, getReceiptByTxid } from "../../services/receipt/ReceiptService";

class SearchTable extends React.Component {
  allReceiptList = [];
  selectedRow = undefined;
  constructor(props) {
    super(props);
    this.allReceiptList = props.receiptList;
    this.state = {
      sortType: "asc",
      receiptList: props.receiptList,
      searchText: "",
      openPopover: false,
      anchorEl: null,
      isDrawerOpen: false,
      agentid: "",
      adminid: "",
      txid: "",
      isbillwise: "false",
      transaction_type: "receipts",
      approvedAmount: "",
      adminremarks: "",
      otherParams: "ICICI-142105000614",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.receiptList !== prevProps.receiptList) {
      this.allReceiptList = this.props.receiptList;
      this.setState({
        receiptList: this.props.receiptList,
      });
    }
  }

  openCardDrawer = isShow => {
    if(!isShow){
      this.handlePopoverClose();
    }
    this.setState({
      isDrawerOpen: isShow
    });
  };

  handleInputChange = e => {
    this.setState({        
      [e.target.name]: e.target.value
    });
  };

  handlePopoverOpen = (event, record) => {
    this.selectedRow = record;
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget
    });
  };

  handlePopoverClose = () => {
    this.setState({
      openPopover: false
    });
  };

  handleSearchChange = (event) => {
    const { value } = event.target;
    this.setState({
      searchText: value,
    });
    if (value === "") {
      this.setState({
        receiptList: this.allReceiptList,
      });
    } else {
      this.setSearchResults(value);
    }
  };

  setSearchResults = debounce((val) => {
    let data = this.allReceiptList.filter((o) =>
      Object.keys(o).some((k) =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      receiptList: data,
    });
  }, 500);

  sortBy = (property, type, sortType) => {
    let data = [...this.state.receiptList];
    if (type === "string") {
      if (this.state.sortType === "asc") {
        data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
        sortType = "desc";
      } else {
        data.sort((a, b) => (b[property] < a[property] ? -1 : 1));
        sortType = "asc";
      }
    } else {
      if (this.state.sortType === sortType) {
        data.sort((a, b) => new Date(b[property]) - new Date(a[property]));
        sortType = "desc";
      } else {
        data.sort((a, b) => new Date(a[property]) - new Date(b[property]));
        sortType = "asc";
      }
    }
    this.setState({
      receiptList: data,
      sortType: sortType
    });
  };

  submitApproveReceipt = async () => {
    this.props.showLoading(true);
    let reqObj = {
      agentid: this.state.agentid,
      adminid: this.state.adminid,
      txid: this.state.txid,
      isbillwise: this.state.isbillwise,
      transaction_type: this.state.transaction_type,
      approvedAmount: this.state.approvedAmount,
      adminremarks: this.state.adminremarks,
      otherParams: this.state.otherParams,
    };
    let res = await approveReceipt(reqObj);
    if (res) {
      if (res.ResponseStatus.status === "OK") {
        this.props.showSnackBar(res.ResponseStatus.message, "success");
        this.openCardDrawer(false);
      } else {
        this.props.showSnackBar(res.ResponseStatus.message, "error");
      }
    } else {
      this.props.showSnackBar("Failed to approve receipt. Please try again.", "error");
    }
    this.props.showLoading(false);
  }

  callReceiptByTxid = async (txid) => {
    this.props.showLoading(true);
    let res = await getReceiptByTxid(txid);
    if (res) {
      this.setState({
        agentid: res.Receipt.agentid,
        adminid: res.Receipt.adminid,
        txid: res.Receipt.txid,
        isbillwise: res.Receipt.isbillwise,
        transaction_type: res.Receipt.transaction_type,
        approvedAmount: res.Receipt.approved_amount,
        adminremarks: res.Receipt.admin_remarks,
        otherParams: res.Receipt.otherParams,
      });
    }
    this.props.showLoading(false);
  }

  handleAction = type => {
    switch (type) {
      case "approveRequest":
        this.callReceiptByTxid(this.selectedRow.txid);
        this.openCardDrawer(true);
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Hidden xsDown>
          <Paper className="padding-16">
            <Grid container spacing={16}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                &nbsp;
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <TextField
                  name="searchText"
                  variant="outlined"
                  label="Search"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={this.state.searchText}
                  onChange={this.handleSearchChange}
                />
              </Grid>
            </Grid>
            <Table className="queue-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("txid", "string", "asc")}
                    >
                      txid
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("agent_company", "string", "asc")}
                    >
                      agent company
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("approval_date", "date", "asc")}
                    >
                      approval date
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("approved_amount", "string", "asc")}
                    >
                      approved amount
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("cheque_dd_no", "string", "asc")}
                    >
                      cheque dd no
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("deposited_bank", "string", "asc")}
                    >
                      deposited bank
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("deposited_amount", "string", "asc")}
                    >
                      deposited amount
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("payment_mode", "date", "asc")}
                    >
                      payment mode
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("status", "string", "asc")}
                    >
                      status
                    </Button>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.receiptList &&
                  this.state.receiptList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.txid}</TableCell>
                      <TableCell>{item.agent_company}</TableCell>
                      <TableCell>{item.approval_date}</TableCell>
                      <TableCell>{item.approved_amount}</TableCell>
                      <TableCell>{item.cheque_dd_no}</TableCell>
                      <TableCell>{item.deposited_bank}</TableCell>
                      <TableCell>{item.deposited_amount}</TableCell>
                      <TableCell>{item.payment_mode}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          className="blue-btn"
                          onClick={event =>
                            this.handlePopoverOpen(event, item)
                          }
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.receiptList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="10">No records found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden smUp>
          {this.state.receiptList &&
            this.state.receiptList.map((item, index) => (
              <Paper className="padding-16 mb-8" key={index}>
                <Grid container spacing={16}>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Txid
                    </Typography>
                    <Typography>{item.txid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Agent Company
                    </Typography>
                    <Typography>{item.agent_company}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Approval Date
                    </Typography>
                    <Typography>{item.approval_date}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Approved Amount
                    </Typography>
                    <Typography>{item.approved_amount}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Cheque dd no
                    </Typography>
                    <Typography>{item.cheque_dd_no}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Deposited Bank
                    </Typography>
                    <Typography>{item.deposited_bank}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Deposited Amount
                    </Typography>
                    <Typography>{item.deposited_amount}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Payment Mode
                    </Typography>
                    <Typography>{item.payment_mode}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Status
                    </Typography>
                    <Typography>{item.status}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      className="blue-btn"
                      onClick={event => this.handlePopoverOpen(event, item)}
                    >
                      Select
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          {this.state.receiptList && (
            <div>
              <Typography align="center">
                No records found. Please change your search criteria and try
                again.
              </Typography>
            </div>
          )}
        </Hidden>
        <Popover
          id="hotell-queue-popper"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Button onClick={() => this.handleAction("approveRequest")}>Approve Request</Button>
        </Popover>

        <Drawer
          className="custom-drawer"
          anchor="right"
          open={this.state.isDrawerOpen}
        >
          <Grid container spacing={16}>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography variant="h6">Approve Receipt</Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                variant="outlined"
                color="secondary"
                className="right"
                onClick={() => this.openCardDrawer(false)}
              >
                Close
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider />
              <br />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="agentid"
                label="Agent ID"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Agent ID"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.agentid}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="adminid"
                label="Admin ID"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Admin ID"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.adminid}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="txid"
                label="Txid"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Txid"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.txid}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="isbillwise"
                label="Is billwise"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Is billwise"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.isbillwise}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="transaction_type"
                label="Transaction Type"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Transaction Type"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.transaction_type}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="approvedAmount"
                label="Approved Amount"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Approved Amount"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.approvedAmount}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                name="adminremarks"
                label="Admin Remarks"
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="Admin Remarks"
                fullWidth
                variant="outlined"
                margin="normal"
                value={this.state.adminremarks}
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                onClick={this.submitApproveReceipt}
                variant="contained"
                color="secondary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default withRouter(SearchTable);
