import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  Hidden,
  Button,
  Popover,
} from "@material-ui/core";
import FirstPageIcon from "mdi-material-ui/PageFirst";
import KeyboardArrowLeft from "mdi-material-ui/ChevronLeft";
import KeyboardArrowRight from "mdi-material-ui/ChevronRight";
import LastPageIcon from "mdi-material-ui/PageLast";
import { withRouter } from "react-router-dom";

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});

class UserMain extends React.Component {
  selectedRecord = undefined;
  constructor(props) {
    super(props);
    this.state = {
      page: props.pagenum,
      rowsPerPage: 500,
      openPopover: false,
      anchorEl: null,
      corporatesList: props.corporatesList,
      sortType: "asc",
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
    this.props.getCorporatesListFn(page);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  handleEdit = async (cid) => {
    this.props.showLoading(true);
    this.props.showLoading(false);
  };

  componentDidUpdate(prevProps) {
    if (this.props.corporatesList !== prevProps.corporatesList) {
      this.setState({
        corporatesList: this.props.corporatesList,
      });
    }
  }

  handlePopoverClose = () => {
    this.setState({
      openPopover: false,
      anchorEl: null,
    });
  };

  handleRedirect = (url, id) => {
    this.setState(
      {
        openPopover: false,
        anchorEl: null,
      },
      () => {
        this.props.history.push({ pathname: `${url}/${id}` });
      }
    );
  };

  handleSelect = (event, record) => {
    this.selectedRecord = record;
    this.setState({
      anchorEl: event.currentTarget,
      openPopover: true,
    });
  };

  sortBy = (property, type, sortType) => {
    let data = [...this.state.corporatesList];
    if (type === "string") {
      if (this.state.sortType === "asc") {
        data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
        sortType = "desc";
      } else {
        data.sort((a, b) => (b[property] < a[property] ? -1 : 1));
        sortType = "asc";
      }
    } else {
      if (this.state.sortType === sortType) {
        data.sort((a, b) => new Date(b[property]) - new Date(a[property]));
        sortType = "desc";
      } else {
        data.sort((a, b) => new Date(a[property]) - new Date(b[property]));
        sortType = "asc";
      }
    }
    this.setState({
      corporatesList: data,
      sortType: sortType,
    });
  };

  render() {
    const { corporatesList, rowsPerPage, page } = this.state;

    return (
      <React.Fragment>
        <Hidden xsDown>
          <Paper className="padding-16">
            <Table className="queue-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("profileid", "string", "asc")}
                    >
                      ProfileId
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("creationdate", "date", "asc")}
                    >
                      CreationDate
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() =>
                        this.sortBy("corporateid", "string", "asc")
                      }
                    >
                      CorporateId
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() =>
                        this.sortBy("corporate_name", "string", "asc")
                      }
                    >
                      CorporateName
                    </Button>
                  </TableCell>
                  <TableCell>UserName</TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() =>
                        this.sortBy("lastupdatedon", "date", "asc")
                      }
                    >
                      LastUpdatedOn
                    </Button>
                  </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("city", "string", "asc")}
                    >
                      City
                    </Button>
                  </TableCell>
                  <TableCell>UserCategory</TableCell>
                  <TableCell>EmployeeId</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.corporatesList &&
                  this.state.corporatesList.map(
                    (corporateUserRecord, index) => (
                      <TableRow key={index}>
                        <TableCell>{corporateUserRecord.profileid}</TableCell>
                        <TableCell>
                          {corporateUserRecord.creationdate}
                        </TableCell>
                        <TableCell>{corporateUserRecord.corporateid}</TableCell>
                        <TableCell>
                          {corporateUserRecord.corporate_name}
                        </TableCell>
                        <TableCell>
                          {`${corporateUserRecord.title}
                        ${corporateUserRecord.first_name}
                        ${corporateUserRecord.middle_name}
                        ${corporateUserRecord.last_name}`}
                        </TableCell>
                        <TableCell>
                          {corporateUserRecord.lastupdatedon}
                        </TableCell>
                        <TableCell>{corporateUserRecord.email}</TableCell>
                        <TableCell>{corporateUserRecord.mobile}</TableCell>
                        <TableCell>{corporateUserRecord.city}</TableCell>
                        <TableCell>
                          {corporateUserRecord.user_category}
                        </TableCell>
                        <TableCell>{corporateUserRecord.employeeid}</TableCell>
                        <TableCell>
                          <Button
                            size="small"
                            className="blue-btn"
                            onClick={(event) =>
                              this.handleSelect(event, corporateUserRecord)
                            }
                          >
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                {this.state.corporatesList &&
                  this.state.corporatesList.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="14">No results found.</TableCell>
                    </TableRow>
                  )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[100]}
                    colSpan={12}
                    count={corporatesList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActionsWrapped}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Paper>
        </Hidden>

        <Popover
          id="flight-queue-popper"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Button
            onClick={() =>
              this.handleRedirect(
                "/user-profile",
                this.selectedRecord.profileid +
                  "/" +
                  this.selectedRecord.corporateid
              )
            }
          >
            View Corporate Details
          </Button>
          <Button
            onClick={() =>
              this.handleRedirect(
                "/user-traveller-list",
                this.selectedRecord.corporateid
              )
            }
          >
            View Travellers
          </Button>
          <Button
            onClick={() =>
              this.handleRedirect(
                "/user-settings",
                this.selectedRecord.corporateid
              )
            }
          >
            User Settings
          </Button>
          <Button
            onClick={() =>
              this.handleRedirect(
                "/user-setcreditlimit",
                this.selectedRecord.corporateid
              )
            }
          >
            Set Credit Limit
          </Button>
        </Popover>
      </React.Fragment>
    );
  }
}

UserMain.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(UserMain));
