import { apiPost } from "../common/CommonService";

export const getAirlines = async () => {
  try {
    let reqObj = {
      opid: "TG-GENERIC",
      act: "getairlines",
    };
    let res = await apiPost(reqObj);
    if (res.list[0].AirlineObject) {
      return Array.isArray(res.list[0].AirlineObject)
        ? res.list[0].AirlineObject
        : [res.list[0].AirlineObject];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const addPromoCode = async obj => {
  try {
    let jsonObj = {
      PromoMaster: {
        ...obj
      }
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "addpromocode",
      jsondata: JSON.stringify(jsonObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const editPromoCode = async obj => {
  try {
    let jsonObj = {
      PromoMaster: obj
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatepromocode",
      jsondata: JSON.stringify(jsonObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getPromoCodes = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getpromomasters"
    };
    let res = await apiPost(reqObj);
    if (res.list[0].PromoMaster) {
      return Array.isArray(res.list[0].PromoMaster)
        ? res.list[0].PromoMaster
        : [res.list[0].PromoMaster];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getPromoCodeDetails = async promocode => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getpromodetails",
      promocode: promocode,
    };
    let res = await apiPost(reqObj);
    return res.PromoMaster;
  } catch (err) {
    return undefined;
  }
};

export const deletePromoCode = async promocode => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "deletepromocode",
      promocode: promocode,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};
