import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import ListSuppliers from "../../components/suppliers/ListSuppliers";
import "./SuppliersPage.css";

class SuppliersPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Paper className="padding-16">
          <ListSuppliers />
        </Paper>
      </React.Fragment>
    );
  }
}

export default SuppliersPage;
