import React, { Component } from "react";
import { Grid, TextField, MenuItem, Button } from "@material-ui/core";
import { withLoadingContext } from "../common/LoadingProvider";
import { withCustomSnackBarContext } from "../common/CustomSnackBarProvider";
import { searchCorporatetravellersLite } from "../../services/transactions/CardTransactionsService";

class SearchInCorporateTravellers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSearchResults: false,
      search: {
        searchType: "",
        searchValue: "",
      },
    };
  }

  handleSearchChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      search: {
        ...prevState.search,
        [name]: value,
      },
    }));
  };

  handleTravellerSearch = async () => {
    if (this.state.search.searchType === "") {
      this.props.snackBarContext.show("Please select search type", "error");
      return false;
    } else if (this.state.search.searchValue === "") {
      this.props.snackBarContext.show("Please select search value", "error");
      return false;
    } else {
      this.props.loadingContext.show();
      let searchObj = {
        corporateid: this.props.customerId,
        searchBy: this.state.search.searchType,
        searchTxt: this.state.search.searchValue
      };
      const corporateTravellersList = await searchCorporatetravellersLite(searchObj);
      this.setState(
        {
          hasSearchResults: true,
        },
        () => this.props.setSearchResults(corporateTravellersList)
      );
      this.props.loadingContext.hide();
    }
  };

  handleClearTravellerSearch = () => {
    this.setState(
      {
        hasSearchResults: false,
        search: {
          searchBy: "",
          searchValue: "",
        },
      },
      () => this.props.clearSearchResults()
    );
  };

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={16} className="mt-8 mb-16">
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              select
              fullWidth
              variant="outlined"
              label="Search By"
              name="searchType"
              InputLabelProps={{ shrink: true }}
              value={this.state.search.searchType}
              onChange={this.handleSearchChange}>
              <MenuItem value="mobile"> Mobile </MenuItem>
              <MenuItem value="email"> Email </MenuItem>
              <MenuItem value="first_name"> First Name </MenuItem>
              <MenuItem value="last_name"> Last Name </MenuItem>
              <MenuItem value="employeeid"> Employee ID </MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Search Value"
              name="searchValue"
              InputLabelProps={{ shrink: true }}
              value={this.state.search.searchValue}
              onChange={this.handleSearchChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Button variant="outlined" color="secondary" size="small" onClick={this.handleTravellerSearch}>
              Search
            </Button>&nbsp;&nbsp;
            {this.state.hasSearchResults && (
              <Button variant="outlined" color="secondary" size="small" onClick={this.handleClearTravellerSearch}>
                Clear Search
              </Button>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withLoadingContext(withCustomSnackBarContext(SearchInCorporateTravellers));
