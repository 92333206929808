import React, { Component } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button,
  Paper,
  MenuItem,
  TextField
} from "@material-ui/core";
import './MapBooking.css';
import Autocomplete from 'react-autocomplete';
import { searchCorporatetravellersLite, mapOfflineBooking } from "../../services/transactions/CardTransactionsService.js";
import { getFlightInvoice } from "../../services/queues/FlightQueuesService";
import { withLoadingContext } from "../common/LoadingProvider";
import { withCustomSnackBarContext } from "../common/CustomSnackBarProvider";

class MapBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pid: "",
      searchText: "",
      txid:"",
      travellersList: [],
      FlightInvoice: undefined,
      passengerRecords : undefined,
      inpolicy: "select",
    };
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.getItemValue = this.getItemValue.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.getCorporateTravellers = this.getCorporateTravellers.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() {
    await this.getFlightTicket();
  }

  getFlightTicket = async () => {
    let data = await getFlightInvoice(this.props.txid);
    if (data !== undefined) {
      data.passengerRecords[0].PassengerRecord = Array.isArray(
        data.passengerRecords[0].PassengerRecord
      )
        ? data.passengerRecords[0].PassengerRecord
        : [data.passengerRecords[0].PassengerRecord];

      const uniqueRecordsByProfileid =  [...new Map(data.passengerRecords[0].PassengerRecord.map(item =>
                                        [item['profileid'], item])).values()];
      this.setState({
        FlightInvoice: data,
        passengerRecords: uniqueRecordsByProfileid
      });
    }
  }

  getCorporateTravellers = async (searchText) => {
    let searchObj = {};
      searchObj.corporateid = this.props.agentid;
      searchObj.searchTxt = searchText;
      
    let res = await searchCorporatetravellersLite(searchObj);
    if (res) {
      if(res===null||res===undefined||res==="")
      {
        res=[];
      }
      this.setState({
        travellersList: res
      });
    }
  };

  mapOfflineBooking = async () => {
    if(this.state.pid !== "" && this.state.searchText !==""){
      let searchObj = {};
        searchObj.txtype = this.props.txtype;
        searchObj.customerprofileid = this.state.pid;
        searchObj.txid=this.props.txid;
        searchObj.inpolicy= this.state.inpolicy;
        
      let res = await mapOfflineBooking(searchObj);
      if (res) {
        this.props.snackBarContext.show(
          `Mapped successfully: ${this.state.searchText}`,
          "success"
        );
      }else{
        this.props.snackBarContext.show("Error. Please try Later", "error");
      }
    }else{
      this.props.snackBarContext.show("Please select traveller from dropdown", "error");
    }
  }
    
  onChange(e){
    this.setState({
      pid: "",
      searchText: e.target.value
    });
    this.getCorporateTravellers(e.target.value);
  }

  onSelect(val){
    this.setState({
      pid: val.split('-')[1].trim(),
      searchText: val
    });
  }

  handleInputChange(e){
    this.setState({
      inpolicy: e.target.value,
    });
  }

  renderItem(item, isHighlighted){
    return (
      <div key={item.pid} className="autoDropdownlist" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        {`${item.ln} ${item.fn} - ${item.pid}`}
      </div>   
    ); 
  }

  getItemValue(item){
    return `${item.ln} ${item.fn} - ${item.pid}`;
  }

  render() {
    return (
      <Drawer open={true} anchor="right" className="add-supplier">
        <Grid container spacing={16}>
          <Grid item lg={6} md={6} sm={6} xs={8}>
            <Typography variant="h6"> Map Offline Booking </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={4}>
            <Button
              onClick={this.props.closeDrawer}
              className="right"
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <hr className="full-width" />
          </Grid>
          { this.state.FlightInvoice !== undefined && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16" elevation={1}>
                <Typography variant="subtitle1" className="bold">
                  Passenger(s)
                </Typography>
                <hr className="full-width" />
                {this.state.passengerRecords.map((eachRecord, index) => (
                  <Grid item lg={12} md={12} sm={12} xs={12} key={index}>
                    {eachRecord.title} {eachRecord.first_name} {eachRecord.last_name} ({eachRecord.profileid})
                  </Grid>
                ))}
              </Paper>
            </Grid>
          )}
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              name="inpolicy"
              label="In Policy"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="In Policy"
              select
              fullWidth
              variant="outlined"
              value={this.state.inpolicy}
              onChange={this.handleInputChange}
            >
              <MenuItem value="select" disabled>--Select--</MenuItem>
              <MenuItem value="true">True</MenuItem>
              <MenuItem value="false">False</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={9} md={9} sm={9} xs={9}>
            <React.Fragment>
            <div className="AutocompleteContainer">
              <Autocomplete
                getItemValue={this.getItemValue}
                items={this.state.travellersList}
                renderItem={this.renderItem}
                value={this.state.searchText}
                onChange={this.onChange}
                onSelect={this.onSelect}
                inputProps={{ 
                  className: 'acinput', 
                  placeholder: 'Search Travellers' 
                }}
              />
            </div>
            </React.Fragment>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.mapOfflineBooking}
                fullWidth
                disabled={this.state.inpolicy === "select" || this.state.pid === ""}
              >
                Map
              </Button>
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default withLoadingContext(withCustomSnackBarContext(MapBooking));
