import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import moment from "moment";

class SmartScanList extends Component {
  handleImageClick = imageUrl => {
    window.open(imageUrl, "_blank");
  };

  render() {
    return (
      <Grid container spacing={16}>
        {this.props.list.map((item, index) => (
          <Grid item lg={4} md={4} sm={4} xs={12} key={index}>
            <Paper className="scan-item">
              <div className="mb-16">
                <Typography variant="h6">{item.receipt_name}</Typography>
                <Typography variant="subtitle1">
                  {moment(item.addedon).format("YYYY-MM-DD @HH:mm")}
                </Typography>
                <Typography variant="subtitle1" className="capitalize">
                  {item.status}
                </Typography>
              </div>
              <Button
                className="edit-button"
                color="secondary"
                variant="outlined"
                size="small"
                onClick={() => this.props.editExpense(true, item)}
              >
                Edit
              </Button>
              <div className="cursor" title="Click to view it in new tab" onClick={() => this.handleImageClick(item.receipt_url)}>
                <img src={item.receipt_url} alt={item.receipt_name} />
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default SmartScanList;
