import React, { Component } from "react";
import { MenuItem, Select, Button, Grid } from "@material-ui/core";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";
import { getUserAuth } from "../../services/common/CommonService";

class ReportsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchObj: {
                type: "pnrwisereport",
                from: moment().format("YYYY-MM-DD"),
                to: moment().add(1, "days").format("YYYY-MM-DD"),
            }
        };
    }

    handleChange = (val, name) => {
        this.setState(prevState => ({
            searchObj: {
                ...prevState.searchObj,
                [name]: val
            }
        }));
    };

    handleFetchReports = (event) => {
        let usauth = getUserAuth().authtoken;
        let type= this.state.searchObj.type;
        let fromDate= moment(this.state.searchObj.from).format("YYYY-MM-DD");
        let toDate= moment(this.state.searchObj.to).format("YYYY-MM-DD");
        let link = `${process.env.REACT_APP_API_PATH}servicedispatch.jsp?opid=TG-ADMINREPORT&actioncode=${type}&authtoken=${usauth}&fromdate=${fromDate}&todate=${toDate}&corporateid=all&resformat=excel`;
        const element = document.createElement("a");
        element.href = link;
        element.target = "_blank";
        element.click();
    };

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={16}>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <Select
                            fullWidth
                            value={this.state.searchObj.type}
                            onChange={event => this.handleChange(event.target.value, "type")}
                            name="type"
                        >
                            <MenuItem value="pnrwisereport">Flight PNR Report</MenuItem>
                            <MenuItem value="hotelsalereport">Hotel Sale Report</MenuItem>
                            <MenuItem value="trainsalereport">Train Sale Report</MenuItem>
                            <MenuItem value="bussalereport">Bus Sale Report</MenuItem>
                            <MenuItem value="cabsalereport">Cab Sale Report</MenuItem>
                            <MenuItem value="flightrefundreport">Flight Refund Report</MenuItem>
                            <MenuItem value="hotelrefundreport">Hotel Refund Report</MenuItem>
                            <MenuItem value="trainrefundreport">Train Refund Report</MenuItem>
                            <MenuItem value="busrefundreport">Bus Refund Report</MenuItem>
                            <MenuItem value="cabrefundreport">Cab Refund Report</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <DayPickerInput
                            className="full-width"
                            value={this.state.searchObj.from}
                            onDayChange={day => this.handleChange(day, "from")}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <DayPickerInput
                            className="full-width"
                            value={this.state.searchObj.to}
                            onDayChange={day => this.handleChange(day, "to")}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleFetchReports}
                            disabled={!this.state.searchObj.from || !this.state.searchObj.to}
                        >
                            Fetch Reports
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default ReportsPage;
