import React, { Component } from "react";
import VisaBillingMain from "../../components/offline-billing/visa-billing/VisaBillingMain";
import "./common.css";

class VisaBillingPage extends Component {
  render() {
    return (
      <React.Fragment>
        <VisaBillingMain />
      </React.Fragment>
    );
  }
}

export default VisaBillingPage;
