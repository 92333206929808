import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Train from "mdi-material-ui/Train";
import { withRouter } from "react-router-dom";
import { formatCurrency } from "../../common/Utils";
import { getCabTicket } from "../../../services/queues/CabQueuesService";
import EmailTicket from "../common/EmailTicket";

class ETicket extends Component {
  transactionId = "";
  constructor(props) {
    super(props);
    this.transactionId = props.txId || window.location.pathname.split("/").pop();
    this.state = {
      travellersList: [],
      selectedCab: undefined,
      hasTicket: undefined,
      openCancel: false
    };
  }
  componentDidMount() {
    this.getTicket();
  }

  getTicket = async () => {
    const data = await getCabTicket(this.transactionId);
    if (data) {
      const travellersList = Array.isArray(
        data.CabInvoice
      )
        ? data.CabInvoice
        : [data.CabInvoice];
      const selectedCab = data.CabInvoice;
      this.setState({
        travellersList,
        selectedCab,
        hasTicket: true
      });
    } else {
      this.setState({
        hasTicket: false
      });
    }
  };

  showEmailDialog = () => {
    this.setState({
      emailTicket: true
    });
  };

  handleEmailClose = () => {
    this.setState({
      emailTicket: false
    });
  };

  render() {
    const { hasTicket, travellersList, selectedCab } = this.state;
    return (
      <div>
        {hasTicket !== undefined && hasTicket === false && (
          <Paper className="padding-16">
            <div className="no-ticket-found">
              <Train className="train-icon mb-8" />
              <Typography variant="h6" className="mb-8">
                Sorry! No ticket found
              </Typography>
              <Typography variant="subtitle1" className="text-center">
                No ticket found with the transaction id{" "}
                {`'${this.transactionId}'`}
              </Typography>
            </div>
          </Paper>
        )}
        {hasTicket !== undefined && hasTicket === true && (
          <React.Fragment>
            <div className="btn-bar text-center print-btn mb-16">
              <br />
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => window.print()}
              >
                Print
              </Button>
              &nbsp;&nbsp;
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={this.showEmailDialog}
              >
                Email
              </Button>
              &nbsp;&nbsp;
              <br />
              {this.state.emailTicket === true && (
                <EmailTicket
                  handleClose={this.handleEmailClose}
                  txid={this.transactionId}
                  opId="TG-CABCONFIRMATION"
                  showEmail={true}
                />
              )}
            </div>
            <Paper className="padding-16">
              <div>
                <img
                  className="ticket-logo"
                  src="/images/logo_bg.png"
                  alt="TRIPGAIN"
                />
                <div className="right">
                  <Typography variant="subtitle1">
                    TripGain Booking Ref:
                  </Typography>
                  <Typography className="mt-5" variant="h6">
                    {this.transactionId}
                  </Typography>
                </div>
                <div className="right text-center" style={{paddingRight : '40px'}}>
                  <Typography variant="subtitle1">
                    Booking Status:
                  </Typography>
                  <Typography className="mt-5" variant="h6">
                    {selectedCab.bookingstatus}
                  </Typography>
                </div>
              </div>
              <hr className="full-width"/>
              <div className="mb-16 mt-8">
                <div>
                  <div>
                    <Typography variant="h5" className="mb-16">
                      {selectedCab.selected_carname} ({selectedCab.tripdesc})
                    </Typography>
                  </div>
                  <div className="mb-16">
                    <div className="text-center train-info">
                      <div>
                        <Typography
                          variant="subtitle1"
                          className="station-name"
                        >
                          {selectedCab.pickuppoint}
                        </Typography>
                        <Typography variant="h6">
                          {selectedCab.pickuptime}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="caption">Travelling on</Typography>
                        <Typography variant="h6">
                          {selectedCab.pickpdate}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          variant="subtitle1"
                          className="station-name"
                        >
                          {selectedCab.tripdestination}
                        </Typography>
                        <Typography variant="h6">
                          &nbsp;
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="caption">Payment Status</Typography>
                        <Typography variant="h6">
                          {selectedCab.paymentstatus}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="caption">Tx. Date</Typography>
                        <Typography variant="h6">
                          {selectedCab.txdate}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <Typography variant="h6" className="mt-8">
                  Passenger Details
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Mobile</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {travellersList.map((t, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="subtitle1">{t.paxname}</Typography>
                        </TableCell>
                        <TableCell>{t.paxemail}</TableCell>
                        <TableCell className="capitalize">{t.paxmobile}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              <div className="mt-8">
                <br />
                <Typography variant="h6">Fare Details</Typography>
              </div>
              <div className="widht-100">
                <div className="fare-div">
                  <Typography variant="caption">Basic Fare</Typography>
                  <Typography className="fs-18">
                    {formatCurrency(selectedCab.basefare)}
                  </Typography>
                </div>
                <div className="fare-div">
                  <Typography variant="caption">
                    Reservation &amp; Levy Fee
                  </Typography>
                  <Typography className="fs-18">
                    {formatCurrency(selectedCab.handlingcharges)}
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption" className="mt-8">
                    Total Net
                  </Typography>
                  <Typography variant="h6" className="price bold">
                    {formatCurrency(selectedCab.totalfarequote)}
                  </Typography>
                </div>
              </div>
              <div className="mt-8 mb-16 text-center">
                <br />
                <Typography variant="h6" color="secondary" className="mt-16">
                  Please note that, this is not your Eticket copy, this is
                  TripGain booking voucher.
                </Typography>
              </div>
            </Paper>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(ETicket);
