import React, { Component } from "react";
import Receipt from "../../components/receipt/Receipt";

class ReceiptPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Receipt />
      </React.Fragment>
    );
  }
}

export default ReceiptPage;
