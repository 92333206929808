import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

class ConfirmDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  handleClose = resultType => {
    this.props.onClose(resultType);
  };

  render() {
    const { title, children, cancelText, OKText } = this.props;
    return (
      <div>
        <Dialog
          className="confirm-dialog"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{title || "Are you sure ?"}</DialogTitle>
          <DialogContent className="xs-max-dialog-height">{children}</DialogContent>
          <DialogActions className="left">
            <Button onClick={() => this.handleClose(false)} color="secondary">
              {cancelText || "Cancel"}
            </Button>
            <Button variant="contained" onClick={() => this.handleClose(true)} color="primary" autoFocus>
              {OKText || "OK"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ConfirmDialog;
