import { apiPost, getUserAuth } from "../common/CommonService";

const API_PATH = process.env.REACT_APP_API_PATH;
const OPID = "TG-VISAMASTERS";

export const getAllVisaTypeList = async () => {
    try {
        let reqObj = {
            opid: OPID,
            actioncode: "getallvisatypemaster",
        };
        let res = await apiPost(reqObj);
        if (res.list[0] !== "") {
            let data = Array.isArray(res.list[0].VisaTypeMaster)
                ? res.list[0].VisaTypeMaster
                : [res.list[0].VisaTypeMaster];
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
};

export const getVisaTypeById = async (recordid) => {
    try {
        let reqObj = {
            opid: OPID,
            actioncode: "getvisatypemasterbyid",
            recordid
        };
        let res = await apiPost(reqObj);
        if (res.list[0] !== "") {
            let data = Array.isArray(res.list[0].VisaTypeMaster)
                ? res.list[0].VisaTypeMaster
                : [res.list[0].VisaTypeMaster];
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
};

export const addVisaType = async obj => {
    try {
        let jsonObj = {
            VisaTypeMaster: obj
        };
        let reqObj = {
            opid: OPID,
            actioncode: "addvisatypemaster",
            jsondata: JSON.stringify(jsonObj)
        };
        let res = await apiPost(reqObj);
        return res;
    } catch (err) {
        return undefined;
    }
};

export const updateVisaType = async obj => {
    try {
        let jsonObj = {
            VisaTypeMaster: obj
        };
        let reqObj = {
            opid: OPID,
            actioncode: "updatevisatypemaster",
            jsondata: JSON.stringify(jsonObj)
        };
        let res = await apiPost(reqObj);
        return res;
    } catch (err) {
        return undefined;
    }
};

export const getAllVisaEntryTypeList = async () => {
    try {
        let reqObj = {
            opid: OPID,
            actioncode: "getallvisaentrytypemaster",
        };
        let res = await apiPost(reqObj);
        if (res.list[0] !== "") {
            let data = Array.isArray(res.list[0].VisaEntryTypeMaster)
                ? res.list[0].VisaEntryTypeMaster
                : [res.list[0].VisaEntryTypeMaster];
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
};

export const getVisaEntryTypeById = async (recordid) => {
    try {
        let reqObj = {
            opid: OPID,
            actioncode: "getvisaentrytypemasterbyid",
            recordid
        };
        let res = await apiPost(reqObj);
        if (res.list[0] !== "") {
            let data = Array.isArray(res.list[0].VisaEntryTypeMaster)
                ? res.list[0].VisaEntryTypeMaster
                : [res.list[0].VisaEntryTypeMaster];
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
};

export const addVisaEntryType = async obj => {
    try {
        let jsonObj = {
            VisaEntryTypeMaster: obj
        };
        let reqObj = {
            opid: OPID,
            actioncode: "addvisaentrytypemaster",
            jsondata: JSON.stringify(jsonObj)
        };
        let res = await apiPost(reqObj);
        return res;
    } catch (err) {
        return undefined;
    }
};

export const updateVisaEntryType = async obj => {
    try {
        let jsonObj = {
            VisaEntryTypeMaster: obj
        };
        let reqObj = {
            opid: OPID,
            actioncode: "updatevisaentrytypemaster",
            jsondata: JSON.stringify(jsonObj)
        };
        let res = await apiPost(reqObj);
        return res;
    } catch (err) {
        return undefined;
    }
};

export const getAllVisaDocumentTypeList = async () => {
    try {
        let reqObj = {
            opid: OPID,
            actioncode: "getallvisadocumenttype",
        };
        let res = await apiPost(reqObj);
        if (res.list[0] !== "") {
            let data = Array.isArray(res.list[0].VisaDocumentTypeMaster)
                ? res.list[0].VisaDocumentTypeMaster
                : [res.list[0].VisaDocumentTypeMaster];
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
};

export const getAllVisaInfoList = async () => {
    try {
        let reqObj = {
            opid: OPID,
            actioncode: "getallvisainfomaster",
        };
        let res = await apiPost(reqObj);
        if (res.list[0] !== "") {
            let data = Array.isArray(res.list[0].VisaInfoMaster)
                ? res.list[0].VisaInfoMaster
                : [res.list[0].VisaInfoMaster];
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
};

export const getVisaInfoById = async (recordid) => {
    try {
        let reqObj = {
            opid: OPID,
            actioncode: "getvisainfomasterbyid",
            recordid
        };
        let res = await apiPost(reqObj);
        if (res !== "") {
            let data = Array.isArray(res.VisaInfoMaster)
                ? res.VisaInfoMaster
                : [res.VisaInfoMaster];
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
};

export const addVisaInfo = async obj => {
    try {
        let jsonObj = {
            VisaInfoMaster: obj
        };
        let reqObj = {
            opid: OPID,
            actioncode: "addvisainfomaster",
            jsondata: JSON.stringify(jsonObj)
        };
        let res = await apiPost(reqObj);
        return res;
    } catch (err) {
        return undefined;
    }
};

export const updateVisaInfo = async obj => {
    try {
        let jsonObj = {
            VisaInfoMaster: obj
        };
        let reqObj = {
            opid: OPID,
            actioncode: "updatevisainfomaster",
            jsondata: JSON.stringify(jsonObj)
        };
        let res = await apiPost(reqObj);
        return res;
    } catch (err) {
        return undefined;
    }
};

export const visaDocumentUpload = async obj => {
    let userAuth = getUserAuth();
    try {
        obj.authtoken = userAuth.authtoken;
        let url = `${API_PATH}visadocumentupload.jsp`;
        let response = await fetch(url, {
            method: "POST",
            body: obj,
        });
        return await response.json();
    } catch (error) {
        return undefined;
    }
};
