import React, { Component } from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Paper,
  Grid,
  TextField,
  Typography,
  Hidden,
  Button,
  Drawer,
} from "@material-ui/core";
import { getTransactionLog, getApiBookingLog } from "../../../services/queues/HotelQueuesService";
import { Loading } from "../../../components/common/Loading";
import { debounce } from "../../../components/common/Utils";

class TransactionLogPage extends Component {
  txId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      logs: undefined,
      filteredLogs: undefined,
      isLoading: false,
      isOpenDrawer: false,
      apiLogs: "",
    };
    this.txId = window.location.pathname.split("/").pop();
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    let res = await getTransactionLog(this.txId);
    this.setState({
      logs: res,
      filteredLogs: res,
      isLoading: false
    });
  }

  handleChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.logs.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredLogs: data
    });
  }, 500);

  callApiBookingLog = async () => {
    this.setState({
      isLoading: true,
    });
    let res = await getApiBookingLog(this.txId);
    if (res) {
      this.setState({
        apiLogs: JSON.stringify(res),
      });
    } else {
      this.setState({
        apiLogs: "No logs found in the system.",
      });
    }
    this.setState({
      isOpenDrawer: true,
      isLoading: false,
    });
  };

  closeDrawer = () => {
    this.setState({
      isOpenDrawer: false,
      apiLogs: "",
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Drawer className="creds-drawer" open={this.state.isOpenDrawer} anchor="right">
          <Grid container spacing={8}>
            <Grid item lg={6} md={6} sm={6} xs={8}>
              <Typography variant="h6">Booking Logs</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={4}>
              <Button
                className="right"
                onClick={this.closeDrawer}
                size="small"
                variant="contained"
                color="secondary"
              >
                Close
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <hr className="full-width" />
              {this.state.apiLogs}
            </Grid>
          </Grid>
        </Drawer>
        <Grid container spacing={16}>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Typography variant="h6" className="mb-8">
              Transaction Logs
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Button
              size="small"
              variant="outlined"
              color="primary" 
              onClick={this.callApiBookingLog}
            >
              View API Log
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              className="search-field mb-8"
              name="searchTerm"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Paper className="padding-16">
            <Table className="table-auto">
              <TableHead>
                <TableCell>Log Time</TableCell>
                <TableCell>Tx ID</TableCell>
                <TableCell>Tx Type</TableCell>
                <TableCell>Ref TxID</TableCell>
                <TableCell>Agent ID</TableCell>
                <TableCell>Owner ID</TableCell>
                <TableCell>Details</TableCell>
              </TableHead>
              <TableBody>
                {this.state.filteredLogs &&
                  this.state.filteredLogs.map((log, index) => (
                    <TableRow key={index}>
                      <TableCell>{log.logtime}</TableCell>
                      <TableCell>{log.txid}</TableCell>
                      <TableCell>{log.txtype}</TableCell>
                      <TableCell>{log.reftxid}</TableCell>
                      <TableCell>{log.agentid}</TableCell>
                      <TableCell>{log.ownerid}</TableCell>
                      <TableCell>{log.details}</TableCell>
                    </TableRow>
                  ))}
                {this.state.filteredLogs &&
                  this.state.filteredLogs.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="7">
                        No logs found in the system.
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden smUp>
          {this.state.filteredLogs &&
            this.state.filteredLogs.map((log, index) => (
              <Paper className="padding-16 mb-8" key={index}>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Tx.ID
                    </Typography>
                    <Typography>{log.txid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Ref Tx.ID / Tx.Type
                    </Typography>
                    <Typography>
                      {log.reftxid} / {log.txtype}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Log Time
                    </Typography>
                    <Typography>{log.logtime}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Agent ID / Owner ID
                    </Typography>
                    <Typography>
                      {log.agentid || "-"} / {log.ownerid || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" className="caption">
                      Details
                    </Typography>
                    <Typography>{log.details}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          <Typography />
        </Hidden>
      </React.Fragment>
    );
  }
}

export default TransactionLogPage;
