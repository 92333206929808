import React, { Component } from "react";
//import "react-select/dist/react-select.css";
import Select from "react-select";
import "./TGSelect.css";

class TGSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectValue: this.props.defaultValue ? this.props.defaultValue : "",
    };
  }

  handleTGSelectChange = val => {
    this.setState(
      {
        selectValue: val,
      },
      () => this.props.onChange(this.props.name, val, this.props.dropDownKey || 0)
    );
  };

  render() {
    return (
      <div className={`relative tg-select-container ${this.props.className ? this.props.className : ""}`}>
        <label className="tg-select-label">
          {this.props.label}
          {this.props.required && "*"}
        </label>
        <Select
          placeholder=""
          className="tg-select"
          value={this.state.selectValue}
          onChange={val => this.handleTGSelectChange(val)}
          options={this.props.options}
        />
      </div>
    );
  }
}

export default TGSelect;
