import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import Login from "../../components/auth/Login";
import "./AuthPage.css";

class AuthPage extends Component {

  showParentMenu = (isShow) => {
    this.props.showMenu(isShow);
  }
  render() {
    return (
      <React.Fragment>
        <Paper className="padding-16 auth-paper">
          <Login showMenu={this.showParentMenu}/>
        </Paper>
      </React.Fragment>
    );
  }
}

export default AuthPage;
