import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { File, Menu as MenuIcon, ChevronDown } from "mdi-material-ui";
import {
  Hidden,
  Grid,
  Button,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import CustomSnackBar from "../../common/CustomSnackBar";
import { Loading } from "../../common/Loading";
import VisaType from "./VisaType";
import VisaEntryType from "./VisaEntryType";
import VisaInfo from "./VisaInfo";
import { getCountries } from "../../../services/common/CommonService";
import { getAllVisaTypeList } from "../../../services/inventory/VisaService";

class VisaPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 1,
      anchorEl: null,
      isLoading: false,
      countriesList: [],
      visaTypeList: [],
      visaTypes: [],
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  async componentDidMount() {
    this.handleItemClick(1, "visaType");
    await this.allVisaType();
    const list = await getCountries();
    const countriesList = list.map(country => {
      return {
        label: `${country.countryname} (${country.countrycode3})`,
        value: country.countrycode3,
      };
    });
    this.setState({
      countriesList,
    });
  }

  showLoading = loading => {
    this.setState({
      isLoading: loading
    });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleItemClick(itemId, txt) {
    this.setState({ selectedItem: itemId, anchorEl: null });
    window.location.hash = txt;
  }

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  allVisaType = async () => {
    this.showLoading(true);
    let res = await getAllVisaTypeList();
    if(res){
      let visaTypes = [];
      res.forEach((item) => {
        if(item.visa_type && !visaTypes.includes(item.visa_type)) visaTypes.push(item.visa_type);
      });
      this.setState({
        visaTypeList: res,
        visaTypes: visaTypes,
      })
    }
    this.showLoading(false);
  }

  renderSwitch(tab) {
    switch (tab) {
      case 1:
        return <VisaType
          visaTypeList={this.state.visaTypeList}
          visaTypes={this.state.visaTypes}
          allVisaType={this.allVisaType}
          countriesList={this.state.countriesList}
          showLoading={this.showLoading}
          showSnackBar={this.showSnackBar}
        />;
      case 2:
        return <VisaEntryType
          visaTypes={this.state.visaTypes}
          countriesList={this.state.countriesList}
          showLoading={this.showLoading}
          showSnackBar={this.showSnackBar}
        />;
      case 3:
        return <VisaInfo
          visaTypes={this.state.visaTypes}
          countriesList={this.state.countriesList}
          showLoading={this.showLoading}
          showSnackBar={this.showSnackBar}
        />;
      default:
        return <div></div>;
    }
  }

  getMenu = () => {
    return (
      <div>
        <MenuItem className={`${this.state.selectedItem === 1 && "active-menu"}`} onClick={() => this.handleItemClick(1, "visaType")}>
          <ListItemIcon>
            <File />
          </ListItemIcon>
          <ListItemText primary="Visa Type" />
        </MenuItem>
        <MenuItem className={`${this.state.selectedItem === 2 && "active-menu"}`} onClick={() => this.handleItemClick(2, "visaEntry")}>
          <ListItemIcon>
            <File />
          </ListItemIcon>
          <ListItemText primary="Visa Entry Type" />
        </MenuItem>
        <MenuItem className={`${this.state.selectedItem === 3 && "active-menu"}`} onClick={() => this.handleItemClick(3, "visaInfo")}>
          <ListItemIcon>
            <File />
          </ListItemIcon>
          <ListItemText primary="Visa Info" />
        </MenuItem>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <div className="jram">
              <Hidden mdUp>
                <Button
                  className="secondary-bg"
                  fullWidth
                  aria-owns={this.state.anchorEl ? "menu" : null}
                  aria-haspopup="true"
                  color="secondary"
                  onClick={this.handleClick}
                >
                  <MenuIcon color="secondary" /> &nbsp; Menu
                  <ChevronDown color="secondary" />
                </Button>
                <Menu
                  id="menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                >
                  {this.getMenu()}
                </Menu>
              </Hidden>
              <Hidden smDown>
                <MenuList role="menu">{this.getMenu()}</MenuList>
              </Hidden>
            </div>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <BrowserRouter>
              {this.renderSwitch(this.state.selectedItem)}
            </BrowserRouter>
          </Grid>
        </Grid>
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default VisaPage;
