import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import Async from "react-select/lib/Async";
import { debounce } from "../../common/Utils";

class FlightCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Paper className="padding-16 flight-card-form">
        <Typography variant="subtitle1" className="bold">
          {this.props.title}
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <TextField
              required
              fullWidth
              select
              className="select"
              name="carrier"
              label="Carrier"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem>G8 - Go Air</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <TextField
              required
              fullWidth
              name="carrier"
              label="Booking Class"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <TextField
              required
              fullWidth
              name="flightno"
              label="Flight No"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Async
              className="customer-id"
              placeholder="Dep. Airport"
              loadOptions={debounce(this.getAllCustomers, 500)}
              isClearable
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Async
              className="customer-id"
              placeholder="Arr. Airport"
              loadOptions={debounce(this.getAllCustomers, 500)}
              isClearable
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <DayPickerInput
              placeholder="Dep Date"
              className="full-width"
              dayPickerProps={{ disabledDays: { before: new Date() } }}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <DayPickerInput
              placeholder="Arr Date"
              className="full-width"
              dayPickerProps={{ disabledDays: { before: new Date() } }}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <TextField
              required
              fullWidth
              type="time"
              name="carrier"
              label="Dep.Time"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <TextField
              required
              fullWidth
              type="time"
              name="carrier"
              label="Arr.Time"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item lg={1} md={1} sm={1} xs={2}>
            <TextField
              required
              fullWidth
              type="number"
              name="carrier"
              label="Duration"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <TextField
              required
              fullWidth
              select
              className="select"
              name="carrier"
              label="Refundable"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem>Refundable</MenuItem>
              <MenuItem>Non-Refundable</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <TextField
              required
              fullWidth
              name="carrier"
              label="Dept.Terminal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <TextField
              required
              fullWidth
              name="carrier"
              label="Arr Terminal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <TextField
              required
              fullWidth
              name="carrier"
              label="Equipment"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={2}>
            <TextField
              required
              fullWidth
              name="carrier"
              label="Baggage"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default FlightCard;
