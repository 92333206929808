import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

class FlightDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Paper className="padding-16">
        <Typography variant="subtitle1" className="bold">
          Booking Info
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              className="select"
              name="bookingtype"
              variant="outlined"
              fullWidth
              select
              required
              label="Booking Type"
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem>Domestic</MenuItem>
              <MenuItem>International</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              className="select"
              name="carriertype"
              variant="outlined"
              fullWidth
              select
              required
              label="Carrier Type"
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem>GDS</MenuItem>
              <MenuItem>LCC</MenuItem>
              <MenuItem>GDS/LCC</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              className="select"
              name="triptype"
              variant="outlined"
              fullWidth
              select
              required
              label="Trip Type"
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem>One Way</MenuItem>
              <MenuItem>Round Trip</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              className="select"
              name="numsectorsonward"
              variant="outlined"
              fullWidth
              select
              required
              label="Num Sectors Onward"
              InputLabelProps={{ shrink: true }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((v,k) => (
                <MenuItem key={k}>{v}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              className="select"
              name="numsectorsreturn"
              variant="outlined"
              fullWidth
              select
              required
              label="Num Sectors Return"
              InputLabelProps={{ shrink: true }}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((v,k) => (
                <MenuItem key={k}>{v}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              className="select"
              name="cabinclass"
              variant="outlined"
              fullWidth
              select
              required
              label="Cabin Class"
              InputLabelProps={{ shrink: true }}
            >
              <MenuItem value="C">Business</MenuItem>
              <MenuItem value="Y">Economy</MenuItem>
              <MenuItem value="W">Economy Premium</MenuItem>
              <MenuItem value="M">Economy Standard</MenuItem>
              <MenuItem value="F">First Class</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default FlightDetails;
