import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Popover,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import {
  getFlightCancellationQueues,
  rejectFlightCancellation,
  updateFlightCancellationStatus
} from "../../../services/queues/FlightQueuesService";
import { blockTicket } from "../../../services/common/CommonService";
import { Loading } from "../../../components/common/Loading";
import CustomSnackBar from "../../../components/common/CustomSnackBar";
import { debounce } from "../../../components/common/Utils";

class FlightCancellationQueuesPage extends Component {
  selectedRow = undefined;
  constructor(props) {
    super(props);
    this.state = {
      showCancel: false,
      isLoading: false,
      openPopover: false,
      anchorEl: null,
      cancellationQueues: undefined,
      filteredQueues: undefined,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      },
      filterBy: "pending",
      searchTerm: "",
      cancelObj: {
        adminRemarks: "",
        status: "",
        txId: "",
        customerId: ""
      },
      showReject: false,
      rejectRemarks: "",
      sortType: "asc",
    };
  }

  async componentDidMount() {
    this.getCancellationsByStatus();
  }

  getCancellationsByStatus = async () => {
    this.setState({
      isLoading: true
    });
    let res = await getFlightCancellationQueues(this.state.filterBy);
    if (res.length > 0) {
      let da = Array.isArray(res[0].FlightCancellation)
        ? res[0].FlightCancellation
        : [res[0].FlightCancellation];
      this.setState({
        cancellationQueues: da,
        filteredQueues: da,
        isLoading: false
      });
    } else {
      this.setState({
        isLoading: false,
        filteredQueues: [],
        cancellationQueues: []
      });
    }
  };

  handleFilterChange = event => {
    this.setState(
      {
        filterBy: event.target.value
      },
      () => this.getCancellationsByStatus()
    );
  };

  handleSelect = (event, row) => {
    this.selectedRow = row;
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        message: msg,
        variant: type,
        isOpen: true
      }
    });
  };

  handleBlock = async () => {
    let isConfirm = window.confirm(
      "Are you sure, Do you want to block the queue?"
    );
    if (isConfirm) {
      this.setState({
        isLoading: true
      });
      let reqObj = {
        txtype: "flight",
        txid: this.selectedRow.txid,
      };
      let res = await blockTicket(reqObj);
      if (res) {
        if (res.ApiStatus.Status === "OK") {
          this.showSnackBar(res.ApiStatus.Message, "success");
        } else {
          this.showSnackBar(res.ApiStatus.Message, "error");
        }
      } else {
        this.showSnackBar(
          "Failed to block the transaction. Please try again.",
          "error"
        );
      }
      this.setState({
        isLoading: false,
        anchorEl: null,
        openPopover: false
      });
    }
  };

  showRejectDialog = () => {
    this.setState({
      showReject: true,
      anchorEl: null,
      openPopover: false
    });
  };

  closeRejectDialog = () => {
    this.setState({
      showReject: false
    });
  };

  handelRejectChange = event => {
    this.setState({
      rejectRemarks: event.target.value
    });
  };

  handleReject = async () => {
    this.setState({
      isLoading: true
    });
    let res = await rejectFlightCancellation(
      this.selectedRow.txid,
      this.state.rejectRemarks
    );
    if (res) {
      if (res.ApiStatus.Status === "OK") {
        this.showSnackBar(res.ApiStatus.Message, "success");
      } else {
        this.showSnackBar(res.ApiStatus.Message, "error");
      }
    } else {
      this.showSnackBar(
        "Failed to block the transaction. Please try again.",
        "error"
      );
    }
    this.setState({
      isLoading: false,
      showReject: false,
      anchorEl: null,
      openPopover: false
    });
  };

  handelCanxChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      cancelObj: {
        ...prevState.cancelObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  showCancelDialog = () => {
    this.setState({
      cancelObj: {
        txId: this.selectedRow.txid,
        customerId: this.selectedRow.agentid,
        status: this.selectedRow.status,
        adminRemarks: ""
      },
      showCancel: true,
      anchorEl: null,
      openPopover: false
    });
  };

  closeCancelDialog = () => {
    this.setState({
      showCancel: false
    });
  };

  handleCancel = async () => {
    this.setState({
      isLoading: true
    });
    let res = await updateFlightCancellationStatus(
      this.state.cancelObj.txId,
      this.state.cancelObj.adminRemarks,
      this.state.cancelObj.status
    );
    if (res) {
      if (res.ApiStatus.Status === "OK") {
        this.showSnackBar(res.ApiStatus.Message, "success");
      } else {
        this.showSnackBar(res.ApiStatus.Message, "error");
      }
    } else {
      this.showSnackBar(
        "Failed to block the transaction. Please try again.",
        "error"
      );
    }
    this.setState({
      showCancel: false,
      isLoading: false,
      anchorEl: null,
      openPopover: false
    });
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null,
      openPopover: false
    });
  };

  handleSearchChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.cancellationQueues.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredQueues: data
    });
  }, 500);

  handleSnackBarClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  handleAction = type => {
    switch (type) {
      case "ticket":
        window.open(`/flight-ticket/${this.selectedRow.reference_txid}`, "_blank");
        break;
      case "refund":
        this.props.history.push(`/flight-transaction-refund/${this.selectedRow.txid}/${
              this.selectedRow.reference_txid
            }`
        );
        break;
      default:
        break;
    }
  };

  sortBy = (property, type, sortType) => {
    let data = [...this.state.filteredQueues];
    if (type === "string") {
      if (this.state.sortType === "asc") {
        data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
        sortType = "desc";
      } else {
        data.sort((a, b) => (b[property] < a[property] ? -1 : 1));
        sortType = "asc";
      }
    } else {
      if (this.state.sortType === sortType) {
        data.sort((a, b) => new Date(b[property]) - new Date(a[property]));
        sortType = "desc";
      } else {
        data.sort((a, b) => new Date(a[property]) - new Date(b[property]));
        sortType = "asc";
      }
    }
    this.setState({
      filteredQueues: data,
      sortType: sortType
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Typography variant="h6"> Flight Cancellation Queues</Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <Typography variant="caption"> Show with status</Typography>
            <Select
              fullWidth
              name="filterBy"
              value={this.state.filterBy}
              onChange={this.handleFilterChange}
              className="mb-8"
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="inprocess">Inprocess</MenuItem>
              <MenuItem value="blocked">Blocked</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="refunded">Refunded</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <TextField
              className="mb-8"
              fullWidth
              variant="outlined"
              name="searchTerm"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.searchTerm}
              onChange={this.handleSearchChange}
            />
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Paper className="padding-16">
            <Table className="queue-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("refunddate", "date", "asc")}
                    >
                      RequestDate
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("txid", "string", "asc")}
                    >
                      Tx.ID
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("reference_txid", "string", "asc")}
                    >
                      ReferenceTxid
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("totalinvoice", "string", "asc")}
                    >
                      InvAmount
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("owjdate", "date", "asc")}
                    >
                      OnwardJDate
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("twjdate", "date", "asc")}
                    >
                      ReturnJDate
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("paxnames", "string", "asc")}
                    >
                      PaxNames
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("requestedby", "string", "asc")}
                    >
                      RaisedBy
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("agent_company", "string", "asc")}
                    >
                      Customer
                    </Button>
                  </TableCell>
                  <TableCell>
                  <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("airline_pnr", "string", "asc")}
                    >
                      Airline pnr
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("gds_pnr", "string", "asc")}
                    >
                      Gds pnr
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("ticket_number", "string", "asc")}
                    >
                      Ticket Number
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("status", "string", "asc")}
                    >
                      Status
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("isvoid", "string", "asc")}
                    >
                      IsVoid
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("isrecall", "string", "asc")}
                    >
                      IsRecall
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("additional", "string", "asc")}
                    >
                      blockedby
                    </Button>
                  </TableCell>
                  <TableCell>Select</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filteredQueues &&
                  this.state.filteredQueues.map((c, index) => (
                    <TableRow key={index}>
                      <TableCell>{c.refunddate}</TableCell>
                      <TableCell>{c.txid}</TableCell>
                      <TableCell>{c.reference_txid}</TableCell>
                      <TableCell>{c.totalinvoice}</TableCell>
                      <TableCell>{c.owjdate}</TableCell>
                      <TableCell>{c.twjdate}</TableCell>
                      <TableCell>{c.paxnames}</TableCell>
                      <TableCell>{c.requestedby}</TableCell>
                      <TableCell>
                        <Typography>{c.agent_company}</Typography>
                        <Typography>{c.agentid}</Typography>
                      </TableCell>
                      <TableCell>{c.airline_pnr}</TableCell>
                      <TableCell>{c.gds_pnr}</TableCell>
                      <TableCell>{c.ticket_number}</TableCell>
                      <TableCell>{c.status}</TableCell>
                      <TableCell>{c.isvoid}</TableCell>
                      <TableCell>{c.isrecall}</TableCell>
                      <TableCell>{c.additional}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          className="blue-btn"
                          onClick={event => this.handleSelect(event, c)}
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.filteredQueues &&
                  this.state.filteredQueues.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="17"> No records found.</TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden smUp>
          {this.state.filteredQueues &&
            this.state.filteredQueues.map((c, index) => (
              <Paper className="padding-16 mb-8" key={index}>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Tx.ID
                    </Typography>
                    <Typography>{c.txid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Reference Tx.ID
                    </Typography>
                    <Typography>{c.reference_txid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Request Date
                    </Typography>
                    <Typography>{c.refunddate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Customer
                    </Typography>
                    <Typography>
                      {c.agent_company} ({c.agentid})
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Pax Name
                    </Typography>
                    <Typography>{c.paxnames}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Amount
                    </Typography>
                    <Typography>{c.totalinvoice}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Onward JDate
                    </Typography>
                    <Typography>{c.owjdate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Return JDate
                    </Typography>
                    <Typography variant="caption">{c.twjdate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Airline / GDS PNR
                    </Typography>
                    <Typography>
                      {c.airline_pnr || "-"} / {c.gds_pnr || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      IsVoid / IsRecall
                    </Typography>
                    <Typography>
                      {c.isvoid || "-"} / {c.isrecall || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Blocked By
                    </Typography>
                    <Typography>{c.additional || "-"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      onClick={event => this.handleSelect(event, c)}
                      className="blue-btn"
                    >
                      Select
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          {this.state.filteredQueues &&
            this.state.filteredQueues.length === 0 && (
              <div>
                <br />
                <Typography align="center">
                  No records found with status{" "}
                  <strong>{this.state.filterBy}</strong>.
                </Typography>
              </div>
            )}
          <Typography />
        </Hidden>

        <Popover
          id="hotell-queue-popper"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Button onClick={() => this.handleAction("ticket")}>Ticket</Button>
          <Button onClick={this.handleBlock}>Block</Button>
          <Button onClick={() => this.handleAction("refund")}>Refund</Button>
          <Button onClick={this.showCancelDialog}>Inprocess / Cancel</Button>
          <Button onClick={this.showRejectDialog}>Reject</Button>
        </Popover>
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleSnackBarClose}
          />
        )}

        <Dialog open={this.state.showCancel} className="xs-100">
          <DialogTitle>Update Status</DialogTitle>
          <DialogContent>
            <Grid container spacing={16}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography>Transaction ID</Typography>
                <TextField
                  fullWidth
                  name="txId"
                  value={this.state.cancelObj.txId}
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography>Customer ID</Typography>
                <TextField
                  fullWidth
                  name="customerId"
                  value={this.state.cancelObj.customerId}
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography>Update Status to</Typography>
                <Select
                  fullWidth
                  name="status"
                  variant="outlined"
                  onChange={this.handelCanxChange}
                  value={this.state.cancelObj.status}
                >
                  <MenuItem value="pending">In Process</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </Select>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography>Admin Remarks</Typography>
                <TextField
                  fullWidth
                  name="adminRemarks"
                  variant="outlined"
                  onChange={this.handelCanxChange}
                  value={this.state.cancelObj.adminRemarks}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleCancel}
            >
              Update Status
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.closeCancelDialog}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.showReject && (
          <Dialog open={this.state.showReject} className="xs-100">
            <DialogTitle>Reject Flight Cancellation Request</DialogTitle>
            <DialogContent>
              <Grid container spacing={16}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="caption" className="caption">
                    Transaction ID
                  </Typography>
                  <Typography>{this.selectedRow.txid}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="caption" className="caption">
                    Tx. Date
                  </Typography>
                  <Typography>{this.selectedRow.refunddate}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="caption" className="caption">
                    Reference ID
                  </Typography>
                  <Typography>{this.selectedRow.reference_txid}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="caption" className="caption">
                    Refund Amount
                  </Typography>
                  <Typography>{this.selectedRow.refund_amount}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="caption" className="caption">
                    Admin Remarks
                  </Typography>
                  <Typography>{this.selectedRow.admin_remarks}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="caption" className="caption">
                    Airline Cax Charge
                  </Typography>
                  <Typography>{this.selectedRow.canx_charge}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="caption" className="caption">
                    Status
                  </Typography>
                  <Typography>{this.selectedRow.status}</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="caption" className="caption">
                    Agent ID
                  </Typography>
                  <Typography>{this.selectedRow.agentid}</Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography>Remarks</Typography>
                  <TextField
                    fullWidth
                    name="rejectRemarks"
                    variant="outlined"
                    onChange={this.handelRejectChange}
                    value={this.state.rejectRemarks}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleReject}
              >
                Reject Cancellation
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={this.closeRejectDialog}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(FlightCancellationQueuesPage);
