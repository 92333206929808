import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField
} from "@material-ui/core";
import { getUserProfile } from "../../../services/common/CommonService";
import { sendEmail } from "../../../services/queues/FlightQueuesService";
import { withLoadingContext } from "../../common/LoadingProvider";
import { withCustomSnackBarContext } from "../../common/CustomSnackBarProvider";

class EmailTicket extends React.PureComponent {
  profile = {};
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
    this.profile = getUserProfile();
  }

  handleEmailChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  sendEmail = async () => {
    if (this.state.email.trim() === "") {
      this.props.snackBarContext.show("Please enter valid email", "error");
    } else {
      this.props.loadingContext.show();
      let emailObj = {
        profileid: this.profile.profileid,
        opid: this.props.opId,
        txid: this.props.txid,
        actioncode: "SENDCONFIRMATIONEMAIL",
        fromname: "TripGain",
        toemail: this.state.email
      };

      let res = await sendEmail(emailObj);
      if (res) {
        this.props.snackBarContext.show(
          `Ticket has been sent to email: ${this.state.email}`,
          "success"
        );
      } else {
        this.props.snackBarContext.show(
          `Failed to send the ticket to : ${this.state.email}`,
          "error"
        );
      }
      this.props.loadingContext.hide();
    }
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          className="ticket-email-dialog"
          open={true}
          aria-labelledby="resc-ticket"
        >
          <DialogTitle id="resc-ticket">Email Ticket</DialogTitle>
          <hr className="full-width" />
          <DialogContent>
            <TextField
              name="email"
              label="Email"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Please enter email address"
              fullWidth
              variant="outlined"
              margin="normal"
              value={this.state.email}
              onChange={this.handleEmailChange}
            />
          </DialogContent>
          <DialogActions className="left">
            <Button onClick={this.props.handleClose} color="secondary">
              Close
            </Button>
            <Button
              onClick={this.sendEmail}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withLoadingContext(withCustomSnackBarContext(EmailTicket));
