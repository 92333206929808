import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Typography, MenuItem } from "@material-ui/core";

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: props.customerDetailsObj,
    };
  }

  handleInputChange = event => {
    let obj = {
      title: "Mr",
      firstname: "",
      lastname: ""
    };
    let evtName = event.target.name;
    let evtValue = Number(event.target.value);
    switch (evtName) {
      case "numadults":
        let arr = [...this.state.customer.adultsArr];
        if (arr.length > evtValue) {
          while (arr.length > evtValue) {
            arr.pop();
          }
        } else {
          while (arr.length < evtValue) {
            arr.push(obj);
          }
        }
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            numadults: evtValue,
            adultsArr: arr
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      case "numchildren":
        let arr1 = [...this.state.customer.childrenArr];
        if (arr1.length > evtValue) {
          while (arr1.length > evtValue) {
            arr1.pop();
          }
        } else {
          while (arr1.length < evtValue) {
            arr1.push(obj);
          }
        }
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            numchildren: evtValue,
            childrenArr: arr1
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      case "numinfants":
        let arr2 = [...this.state.customer.infantArr];
        if (arr2.length > evtValue) {
          while (arr2.length > evtValue) {
            arr2.pop();
          }
        } else {
          while (arr2.length < evtValue) {
            arr2.push(obj);
          }
        }
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            numinfants: evtValue,
            infantArr: arr2
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      default:
        break;
    }
  };

  handleFormChange = (event, index, type) => {
    switch (type) {
      case "adult":
        let data = [...this.state.customer.adultsArr];
        data[index][event.target.name] = event.target.value;
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            adultsArr: data
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      case "child":
        let data1 = [...this.state.customer.childrenArr];
        data1[index][event.target.name] = event.target.value;
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            childrenArr: data1
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      case "infant":
        let data2 = [...this.state.customer.infantArr];
        data2[index][event.target.name] = event.target.value;
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            infantArr: data2
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      default:
        break;
    }
  };

  createCustomerUI = (arr, type) => {
    return arr.map((adult, index) => (
      <React.Fragment key={index}>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Typography className="capitalize">
            {type} {index + 1}
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            select
            fullWidth
            required
            name="title"
            variant="outlined"
            label="Title"
            className="select"
            InputLabelProps={{ shrink: true }}
            value={adult.title}
            onChange={event => this.handleFormChange(event, index, type)}
          >
            <MenuItem value="Mr">Mr.</MenuItem>
            <MenuItem value="Mrs">Mrs.</MenuItem>
            <MenuItem value="Mstr">Mstr.</MenuItem>
            <MenuItem value="Ms">Ms.</MenuItem>
            <MenuItem value="Dr">Dr.</MenuItem>
            <MenuItem value="Fr">Fr.</MenuItem>
            <MenuItem value="Er">Er.</MenuItem>
            <MenuItem value="Capt">Capt.</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <TextField
            fullWidth
            required
            name="firstname"
            variant="outlined"
            label="First Name"
            InputLabelProps={{ shrink: true }}
            value={adult.firstname}
            onChange={event => this.handleFormChange(event, index, type)}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6}>
          <TextField
            fullWidth
            required
            name="lastname"
            variant="outlined"
            label="Last Name"
            InputLabelProps={{ shrink: true }}
            value={adult.lastname}
            onChange={event => this.handleFormChange(event, index, type)}
          />
        </Grid>
      </React.Fragment>
    ));
  };

  render() {
    return (
      <Paper className="padding-16 customer-details-form">
        <Typography variant="subtitle1" className="bold mb-8">
          Select Customer Details
        </Typography>
        <hr className="full-width" />
        <Grid container spacing={16}>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              fullWidth
              name="numadults"
              label="Adults"
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.customer.numadults}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              fullWidth
              name="numchildren"
              label="Children"
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.customer.numchildren}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              fullWidth
              name="numinfants"
              label="Infants"
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.customer.numinfants}
              onChange={this.handleInputChange}
            />
          </Grid>
        </Grid>
        <br />
        <Typography variant="subtitle2" className="bold mb-8">
          Passenger Info
        </Typography>
        <hr className="full-width" />
        <Grid container spacing={16}>
          {this.createCustomerUI(this.state.customer.adultsArr, "adult")}
          {this.createCustomerUI(this.state.customer.childrenArr, "child")}
          {this.createCustomerUI(this.state.customer.infantArr, "infant")}
        </Grid>
      </Paper>
    );
  }
}

export default CustomerDetails;
