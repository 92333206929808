import React, { Component } from "react";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Button
} from "@material-ui/core";
import {
  getFlightInvoice,
  getSuppliers,
  updateFlightBooking
} from "../../../services/queues/FlightQueuesService.js";
import { Loading } from "../../../components/common/Loading";
import { formatTimeString } from "../../../components/common/Utils.js";
import CustomSnackBar from "../../../components/common/CustomSnackBar";

class FlightQueueUpdatePage extends Component {
  flight = undefined;
  profile = undefined;
  txId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      flightInvoice: undefined,
      suppliers: undefined,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      },
      updateObj: {
        onwardSupplier: "",
        returnSupplier: ""
      },
      isRoundTrip: false,
      onwardSectors: undefined,
      returnSectors: undefined,
      travellers: undefined,
      flightTickets: undefined
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    let sups = await getSuppliers();
    this.txId = window.location.pathname.split("/").pop();
    let data = "",
      os = [],
      rs = [];
    let res = await getFlightInvoice(this.txId);
    if (res) {
      data = res;
      this.flight = res.flightRecord;
      data.flightTickets[0].FlightTicket = Array.isArray(
        data.flightTickets[0].FlightTicket
      )
        ? data.flightTickets[0].FlightTicket
        : [data.flightTickets[0].FlightTicket];
      data.sectorRecords[0].SectorRecord = Array.isArray(
        data.sectorRecords[0].SectorRecord
      )
        ? data.sectorRecords[0].SectorRecord
        : [data.sectorRecords[0].SectorRecord];
      data.passengerRecords[0].PassengerRecord = Array.isArray(
        data.passengerRecords[0].PassengerRecord
      )
        ? data.passengerRecords[0].PassengerRecord
        : [data.passengerRecords[0].PassengerRecord];
      os = data.sectorRecords[0].SectorRecord.filter(x => x.tripnumber === 1);
      rs = data.sectorRecords[0].SectorRecord.filter(x => x.tripnumber === 2);
      // this.travellers = res.passengerRecords[0].PassengerRecord;
      this.profile = data.promas;
      this.gstdetails=data.gstdetails;
      let bp = this.flight.booking_provider.split(",");
      let osn = bp[0];
      let rsn = bp[1] === undefined ? osn : bp[1];
      this.setState({
        isLoading: false,
        suppliers: sups,
        flightInvoice: data,
        isRoundTrip: data.flightRecord.jtype === "OneWay" ? false : true,
        onwardSectors: os,
        returnSectors: rs,
        travellers: data.passengerRecords[0].PassengerRecord,
        flightTickets: data.flightTickets[0].FlightTicket,
        gstdetails:data.gstdetails,
        updateObj: {
          gds_pnr: this.flight.gds_pnr,
          adminremarks: this.flight.adminremarks,
          onwardSupplier: osn,
          returnSupplier: rsn
        }
      });
    } else {
      this.setState({
        isLoading: false,
        flightInvoice: undefined
      });
    }
  }

  handelChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      updateObj: {
        ...prevState.updateObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  handleInputChange = (event, sn, tn, index) => {
    let eve = { ...event };
    let data = [...this.state.flightTickets];
    data[index][eve.target.name] = eve.target.value;
    this.setState({
      flightTickets: data
    });
  };

  handleQueueUpdate = async () => {
    //flighttickets=917,1,1,FT1345,ARTYUU,-:918,1,2,FT1345,ARTYUU,-
    this.setState({
      isLoading: true
    });
    let flightTickets = "";
    this.state.flightTickets.forEach((t, i) => {
      flightTickets += `${t.paxid},${t.sector_number},${t.trip_number},${
        t.ticket_number
      },${t.airline_pnr},${t.ffnnumber}:`;
    });
    flightTickets = flightTickets.substr(0, flightTickets.length - 1);
    let supIds = this.state.isRoundTrip
      ? `${this.state.updateObj.onwardSupplier},${
          this.state.updateObj.returnSupplier
        }`
      : this.state.updateObj.onwardSupplier;
    let reqObj = {
      txid: this.txId,
      gdspnr: this.state.updateObj.gds_pnr,
      remarks: this.state.updateObj.adminremarks,
      flighttickets: flightTickets,
      updatetype: "reupdate",
      supplierid: supIds
    };
    let res = await updateFlightBooking(reqObj);
    let msg = "";
    let type = "success";
    if (res.ResponseStatus.status === "OK") {
      msg = res.ResponseStatus.message;
    } else {
      msg = " Failed to update the status. Please try again.";
      type = "error";
    }
    this.setState({
      isLoading: false,
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  getSectorsUI = (sectors, tripNumber) => {
    return sectors.map((sector, idx) => (
      <React.Fragment key={idx}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="subtitle1">
            <strong> Sector: {sector.sector_number + 1}</strong>
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Typography variant="caption">Origin &amp; Destination</Typography>
          <Typography>
            {sector.origin} -> {sector.destination}
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography variant="caption">Carrier</Typography>
          <Typography>
            {sector.carrier}
            {sector.flight_number}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={6}>
          <Typography variant="caption">Departure</Typography>
          <Typography>
            {sector.jdate} @ {formatTimeString(sector.departure_time)}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={6}>
          <Typography variant="caption">Arrival</Typography>
          <Typography>
            {sector.arrdate} @ {formatTimeString(sector.arrival_time)}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={8}>
            {this.state.flightTickets.map(
              (fTicket, index) =>
                fTicket.trip_number === tripNumber &&
                sector.sector_number + 1 === fTicket.sector_number && (
                  <React.Fragment key={index}>
                    <Grid item lg={3} md={3} sm={3} xs={6}>
                      <Typography>{`${
                        this.state.travellers.find(
                          x => x.paxid === fTicket.paxid
                        ).first_name
                      } ${
                        this.state.travellers.find(
                          x => x.paxid === fTicket.paxid
                        ).last_name
                      }`}</Typography>
                      <Typography>
                        {
                          this.state.travellers.find(
                            x => x.paxid === fTicket.paxid
                          ).type
                        }
                      </Typography>
                      <Typography>
                        {
                          this.state.travellers.find(
                            x => x.paxid === fTicket.paxid
                          ).dob
                        }
                      </Typography>
                      {(this.state.travellers.find(
                            x => x.paxid === fTicket.paxid
                          ).passport.length>2) && (
                            <Grid>
                      <Typography>
                        Passport Number:
                        {
                          this.state.travellers.find(
                            x => x.paxid === fTicket.paxid
                          ).passport
                        }
                      </Typography>
                      <Typography>
                        Passport Expiry Date:
                        {
                          this.state.travellers.find(
                            x => x.paxid === fTicket.paxid
                          ).passport_dateofexpiry
                        }
                      </Typography>
                      <Typography>
                        Passport Issue Date:
                        {
                          this.state.travellers.find(
                            x => x.paxid === fTicket.paxid
                          ).passport_dateofissue
                        }
                      </Typography>
                      <Typography>
                        Passport Issue Place:
                        {
                          this.state.travellers.find(
                            x => x.paxid === fTicket.paxid
                          ).passport_placeofissue
                        }
                      </Typography></Grid>)}
                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={6}>
                      <TextField
                        label="Flight Ticket Number"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        name="ticket_number"
                        value={fTicket.ticket_number}
                        onChange={event =>
                          this.handleInputChange(
                            event,
                            sector.sector_number,
                            tripNumber,
                            index
                          )
                        }
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={6}>
                      <TextField
                        label="Airline PNR"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        name="airline_pnr"
                        value={fTicket.airline_pnr}
                        onChange={event =>
                          this.handleInputChange(
                            event,
                            sector.sector_number,
                            tripNumber,
                            index
                          )
                        }
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={6}>
                      <TextField
                        label="Frequent Flyer Number"
                        name="ffnnumber"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        value={fTicket.ffnnumber}
                        onChange={event =>
                          this.handleInputChange(
                            event,
                            sector.sector_number,
                            tripNumber,
                            index
                          )
                        }
                      />
                    </Grid>
                  </React.Fragment>
                )
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    ));
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Typography variant="h6" className="mb-8">
          Update Flight Queue
        </Typography>
        {this.state.flightInvoice && (
          <Grid container spacing={16}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={8}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6">Ticket Details</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Transaction Id</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.txid}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Journey Type</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.jtype}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Booking Date</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.bookingdate}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Agent Id</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.agentid}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Pax. Name</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{`${this.flight.passengername} ${
                      this.flight.passengerlastname
                    }`}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Pax. Contact</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.passengercontact}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Pax. Email</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.passengeremail}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={8}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6">Customer Details</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Customer Name</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{`${this.profile.first_name} ${
                      this.profile.last_name
                    }`}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Company</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.profile.corporate_name}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Contact</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.profile.phone}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Email ID</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.profile.email}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
             <Grid item lg={6} md={6} sm={6} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={8}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6">GST Details</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>GST Number</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Typography>{this.gstdetails.gstnumber}</Typography>

                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Company</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.gstdetails.gstcompany}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Phone</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.gstdetails.gstphone}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Email ID</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.gstdetails.gstemail}</Typography>
                  </Grid>
                   <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>GST Address</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.gstdetails.gstaddress}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Typography variant="h6"> Onward Journey </Typography>
                <br />
                <Grid container spacing={8}>
                  {this.getSectorsUI(this.state.onwardSectors, 1)}
                </Grid>
              </Paper>
            </Grid>
            {this.state.isRoundTrip === true && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Paper className="padding-16">
                  <Typography variant="h6"> Return Journey </Typography>
                  <br />
                  <Grid container spacing={8}>
                    {this.getSectorsUI(this.state.returnSectors, 2)}
                  </Grid>
                </Paper>
              </Grid>
            )}

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <TextField
                      variant="outlined"
                      label="GDS PNR"
                      name="gds_pnr"
                      fullWidth
                      required
                      value={this.state.updateObj.gds_pnr}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={this.handelChange}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <InputLabel>Onward Supplier</InputLabel>
                    <Select
                      onChange={this.handelChange}
                      name="onwardSupplier"
                      value={this.state.updateObj.onwardSupplier}
                      displayEmpty
                      variant="outlined"
                      fullWidth
                      required
                    >
                      <MenuItem value="">Select Supplier</MenuItem>
                      {this.state.suppliers.map((sup, idx) => (
                        <MenuItem key={idx} value={sup.supplier_id}>
                          {sup.supplier_name} ({sup.supplier_id})
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    {this.state.isRoundTrip === true && (
                      <React.Fragment>
                        <InputLabel>Select Supplier</InputLabel>
                        <Select
                          name="returnSupplier"
                          onChange={this.handelChange}
                          value={this.state.updateObj.returnSupplier}
                          displayEmpty
                          variant="outlined"
                          fullWidth
                          required
                        >
                          <MenuItem value="">Return Supplier</MenuItem>
                          {this.state.suppliers.map((sup, idx) => (
                            <MenuItem key={idx} value={sup.supplier_id}>
                              {sup.supplier_name} ({sup.supplier_id})
                            </MenuItem>
                          ))}
                        </Select>
                      </React.Fragment>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      name="adminremarks"
                      value={this.state.updateObj.adminremarks}
                      variant="outlined"
                      label="Admin Remarks"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={this.handelChange}
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleQueueUpdate}
                >
                  Update Queue
                </Button>
              </Paper>
            </Grid>
          </Grid>
        )}
        {this.state.flightInvoice === undefined && (
          <Paper className="padding-16">
            <div>
              <Typography align="center" variant="h6">
                No data found for the Transaction No: {this.txId}
              </Typography>
            </div>
          </Paper>
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default FlightQueueUpdatePage;
