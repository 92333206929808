import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Popover,
  Hidden,
  Paper,
  Grid,
  TextField,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { debounce } from "../common/Utils";
import { getUserAuth } from "../../services/common/CommonService";

class FlightReconcileTable extends React.Component {
  selectedRow = undefined;
  allReconcileList = [];
  constructor(props) {
    super(props);
    this.allReconcileList = props.reconcileList;
    this.state = {
      anchorEl: null,
      openPopover: false,
      reconcileList: props.reconcileList,
      totalAmount: 0,
      filterByCompany: "all",
      searchText: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.reconcileList !== prevProps.reconcileList) {
      this.allReconcileList = this.props.reconcileList;
      this.setState({
        reconcileList: this.props.reconcileList,
      });
    }
  }

  handlePopoverOpen = (event, record) => {
    this.selectedRow = record;
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget,
    });
  };

  handlePopoverClose = () => {
    this.setState({
      openPopover: false,
    });
  };

  handleAction = (type) => {
    switch (type) {
      case "processReconcile":
        this.handleProcessReconcile(this.selectedRow);
        break;
      default:
        break;
    }
  };

  handleSearchChange = (event) => {
    const { value } = event.target;
    this.setState({
      searchText: value,
    });
    if (value === "") {
      this.setState({
        reconcileList: this.allReconcileList,
      });
    } else {
      this.setSearchResults(value);
    }
  };

  setSearchResults = debounce((val) => {
    let data = this.allReconcileList.filter((o) =>
      Object.keys(o).some((k) =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      reconcileList: data,
    });
  }, 500);

  handleProcessReconcile = async (data) => {
    this.props.showLoading(true);
    let usauth = getUserAuth().authtoken;
    let link = `${process.env.REACT_APP_API_PATH}servicedispatch.jsp?opid=TG-SUPERADMIN&action=processreconcile&authtoken=${usauth}&carrier=${data.carrier}&supplierid=${data.supplier_id}&reconcileid=${data.reconcilationid}&resformat=excel`;
    const element = document.createElement("a");
    element.href = link;
    element.target = "_blank";
    element.click();
    this.props.showLoading(false);
  };

  render() {
    return (
      <React.Fragment>
        <Hidden xsDown>
          <Paper className="padding-16">
            <Grid container spacing={16}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                &nbsp;
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <TextField
                  name="searchText"
                  variant="outlined"
                  label="Search"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={this.state.searchText}
                  onChange={this.handleSearchChange}
                />
              </Grid>
            </Grid>
            <Table className="queue-table">
              <TableHead>
                <TableRow>
                  <TableCell>reconcilationid</TableCell>
                  <TableCell>suppliername</TableCell>
                  <TableCell>supplierId</TableCell>
                  <TableCell>carrier</TableCell>
                  <TableCell>pathtoexcel</TableCell>
                  <TableCell>addedon</TableCell>
                  <TableCell>addedby</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.reconcileList &&
                  this.state.reconcileList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.reconcilationid}</TableCell>
                      <TableCell>{item.suppliername}</TableCell>
                      <TableCell>{item.supplier_id}</TableCell>
                      <TableCell>{item.carrier}</TableCell>
                      <TableCell>{item.pathtoexcel}</TableCell>
                      <TableCell>{item.addedon}</TableCell>
                      <TableCell>{item.addedby}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          className="blue-btn"
                          onClick={(event) =>
                            this.handlePopoverOpen(event, item)
                          }
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.reconcileList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="10">No records found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden smUp>
          {this.state.reconcileList &&
            this.state.reconcileList.map((item, index) => (
              <Paper className="padding-16 mb-8" key={index}>
                <Grid container spacing={16}>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      reconcilationid
                    </Typography>
                    <Typography>{item.reconcilationid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      suppliername
                    </Typography>
                    <Typography>{item.suppliername}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      supplierId
                    </Typography>
                    <Typography>
                      {item.supplier_id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      carrier
                    </Typography>
                    <Typography>{item.carrier}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      pathtoexcel
                    </Typography>
                    <Typography>{item.pathtoexcel}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      addedon
                    </Typography>
                    <Typography>{item.addedon}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      addedby
                    </Typography>
                    <Typography>
                      {item.addedby}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      className="blue-btn"
                      onClick={(event) =>
                        this.handlePopoverOpen(event, item)
                      }
                    >
                      Select
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          {this.state.reconcileList && (
            <div>
              <Typography align="center">
                No records found. Please change your search criteria and try
                again.
              </Typography>
            </div>
          )}
        </Hidden>
        <Popover
          id="hotell-queue-popper"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Button onClick={() => this.handleAction("processReconcile")}>Reconcile</Button>
        </Popover>
      </React.Fragment>
    );
  }
}

export default withRouter(FlightReconcileTable);
