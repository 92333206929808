import { apiPost } from "../common/CommonService";

export const getAllCards = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getallcardinventory"
    };
    let res = await apiPost(reqObj);
    if (res.list[0] === "") {
      return [];
    } else {
      return res.list[0].CardInventory;
    }
  } catch (error) {
    return [];
  }
};

export const getAllCorporateTravellers = async corporateId => {
  try {
    let reqObj = {
      opid: "TG-GETCORPORATETRAVELLERS",
      corporateid: corporateId
    };

    let res = await apiPost(reqObj);
    if (res.list[0] && res.list[0].ProfileMaster)
      return Array.isArray(res.list[0].ProfileMaster)
        ? res.list[0].ProfileMaster
        : [res.list[0].ProfileMaster];
    return [];
  } catch (error) {
    return [];
  }
};

export const getAllCorporates = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getallcorporates"
    };
    let res = await apiPost(reqObj);
    if (res.list[0] === "") {
      return [];
    } else {
      return res.list[0].ProfileMaster;
    }
  } catch (err) {
    return [];
  }
};

export const addCardsToCorporate = async objArr => {
  try {
    let cabObj = {
      CardInventoryList: { cardinventory: [{ CardInventory: objArr }] }
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "addcardinventory",
      cardinventorylist: JSON.stringify(cabObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getAllPendingCorporateBalanceRequests = async objArr => {
  try {
    let reqObj = {
      opid: "TG-PREPAIDCARDAPI",
      action: "getallpendingcorporatebalancerequests"
    };
    let res = await apiPost(reqObj);
    if (res.list[0] === "") {
      return [];
    } else {
      return res.list[0].CorporatePrepaidReceipts;
    }
  } catch (err) {
    return [];
  }
};

export const approveCardBalanceRequest = async obj => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "approvecardbalance",
      requestid: obj.requestid,
      amount: obj.amount,
      corporateid: obj.corporateid,
      remarks: obj.remarks
    };
    let res = await apiPost(reqObj);
    if (res.ResponseStatus.status === "OK") {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const getBalanceForCard = async cardId => {
  try {
    let reqObj = {
      opid: "TG-PREPAIDCARDAPI",
      action: "getcardbalanceforcard",
      cardid: cardId
    };
    let res = await apiPost(reqObj);
    return res.CardBalance.available_balance;
  } catch (err) {
    return 0;
  }
};

export const getCardDetailsByCardId = async cardId => {
  try {
    let res = await apiPost({
      opid: "TG-PREPAIDCARDAPI",
      action: "getcardrow",
      cardid: cardId
    });
    if (res.hasOwnProperty("null")) {
      return undefined;
    } else {
      return res.CardInventory;
    }
  } catch (error) {
    return undefined;
  }
};

export const registerCard = async obj => {
  try {
    let reqObj = { CardCustomer: obj };
    let res = await apiPost({
      opid: "TG-SUPERADMIN",
      action: "registercustomer",
      jsondata: JSON.stringify(reqObj),
      corporateid: obj.corporateid,
      profileid: obj.profileid
    });
    if (res.data.ResponseStatus.status === "NOK") {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};
