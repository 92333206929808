import React from "react";
import {
  Grid,
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  Paper,
  TextField,
  Hidden,
  Menu,
  MenuItem,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ChevronDown from "mdi-material-ui/ChevronDown";
import { BrowserRouter } from "react-router-dom";
import MenuIcon from "mdi-material-ui/Menu";
import File from "mdi-material-ui/File";
import { withRouter } from "react-router-dom";
import { uploadLpoAttachment } from "../../../services/common/CommonService";
import { 
  getSupplierPurchase, 
  getTransactionFareRules, 
  updateSupplierPurchase, 
  updateTransactionFareRules, 
  updateWebcheckinStatus,
  getHotelReconfirmationDetailsByTxid,
} from "../../../services/queues/QualityCheckService";
import { getFlightInvoice } from "../../../services/queues/FlightQueuesService";
import { Loading } from "../../common/Loading";
import CustomSnackBar from "../../common/CustomSnackBar";
import HotelReconfirmationDetail from "./HotelReconfirmationDetail";

class QualityCheck extends React.PureComponent {
  txId = undefined;
  serviceType = undefined;
  paxTicketsDict = {};
  specialServicesDict = {};
  paxPNRDict = {};
  constructor(props) {
    super(props);
    let paths = window.location.pathname.split("/");
    this.serviceType = paths[2];
    this.txId = paths[3];
    this.state = {
      isLoading: false,
      selectedItem: 1,
      anchorEl: null,
      purchase_amount: 0,
      purchase_amount_return: 0,
      expectedplb: 0,
      remarks: "",
      addedby: "",
      updatedby: "",
      updatedon: "",
      adminremarks: "",
      flight_reconfirmation_status: "",
      hasPurchaseFare: false,
      hasFareRules: false,
      purchaseDetails: "",
      fareRulesDetails: "",
      eTicket: {},
      isRoundTrip: false,
      message: "",
      msgType: "green",
      travellers: undefined,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      },
      fareRulesObj: {
        txid:"",
        carrier1:"",
        carrier2:"",
        owrules:"",
        twrules:"",
        inserttime:"",
        txtype:"",
        ow_cancellation_charges:0,
        rt_cancellation_charges:0,
        ow_reschedule_charges:0,
        rt_reschedule_charges:0,
      },
      reconfirmationList: [],
    };
  }

  async componentDidMount() {
    await this.callFlightInvoice();
    await this.callSupplierPurchase();
    await this.callTransactionFareRules();
    if(this.serviceType === 'hotel'){
      await this.callHotelReconfirmationDetailsByTxid();
    }
  }

  callHotelReconfirmationDetailsByTxid = async () => {
    this.setState({
      isLoading: true,
    });
    let res = await getHotelReconfirmationDetailsByTxid(this.txId);
    this.setState({
      reconfirmationList: res,
      isLoading: false,
    });
  };

  callSupplierPurchase = async () => {
    this.setState({
      isLoading: true,
    });
    let res = await getSupplierPurchase(this.txId);
    if (res) {
      this.setState({
        purchaseDetails: res,
        hasPurchaseFare: true,
        isLoading: false,
        purchase_amount: res.SupplierPurchase.purchase_amount,
        purchase_amount_return: res.SupplierPurchase.purchase_amount_return,
        expectedplb: res.SupplierPurchase.expectedplb,
        remarks: res.SupplierPurchase.remarks,
        updatedby: res.SupplierPurchase.updatedby,
        addedby: res.SupplierPurchase.addedby,
        updatedon: res.SupplierPurchase.updatedon,
      });
    } else {
      this.setState({
        purchaseDetails: "",
        hasPurchaseFare: false,
        isLoading: false,
      });
    }
  };

  callTransactionFareRules = async () => {
    this.setState({
      isLoading: true,
    });
    let res = await getTransactionFareRules(this.txId);
    if (res) {
      this.setState(prevState => ({
        fareRulesDetails: res,
        hasFareRules: true,
        isLoading: false,
        fareRulesObj: {
          ...prevState.fareRulesObj,
          txid: res.TransactionFareRules.txid,
          carrier1: res.TransactionFareRules.carrier1,
          carrier2: res.TransactionFareRules.carrier2,
          owrules: res.TransactionFareRules.owrules,
          twrules: res.TransactionFareRules.twrules,
          inserttime: "",
          txtype: res.TransactionFareRules.txtype,
          ow_cancellation_charges: res.TransactionFareRules.ow_cancellation_charges,
          rt_cancellation_charges: res.TransactionFareRules.rt_cancellation_charges,
          ow_reschedule_charges: res.TransactionFareRules.ow_reschedule_charges,
          rt_reschedule_charges: res.TransactionFareRules.rt_reschedule_charges,
        },
      }));
    } else {
      this.setState({
        fareRulesDetails: "",
        hasFareRules: false,
        isLoading: false,
      });
    }
  };

  callFlightInvoice = async () => {
    this.setState({
      isLoading: true
    });
    let data = "";
    let res = await getFlightInvoice(this.txId);
    if (res) {
      data = res;
      data.flightTickets[0].FlightTicket = Array.isArray(
        data.flightTickets[0].FlightTicket
      )
        ? data.flightTickets[0].FlightTicket
        : [data.flightTickets[0].FlightTicket];
      data.sectorRecords[0].SectorRecord = Array.isArray(
        data.sectorRecords[0].SectorRecord
      )
        ? data.sectorRecords[0].SectorRecord
        : [data.sectorRecords[0].SectorRecord];
      data.passengerRecords[0].PassengerRecord = Array.isArray(
        data.passengerRecords[0].PassengerRecord
      )
        ? data.passengerRecords[0].PassengerRecord
        : [data.passengerRecords[0].PassengerRecord];

      let ticketsArr = Array.isArray(data.flightTickets[0].FlightTicket)
        ? data.flightTickets[0].FlightTicket
        : [data.flightTickets[0].FlightTicket];
      this.paxTicketsDict = ticketsArr.reduce((acc, item) => {
        acc[`${item.trip_number}_${item.paxid}`] = item.ticket_number;
        return acc;
      }, {});
      this.paxPNRDict = ticketsArr.reduce((acc, item) => {
        acc[`${item.trip_number}_${item.paxid}`] = item.airline_pnr;
        return acc;
      }, {});
      const specialServicesArr =
        data.passengerssrrecords[0] === ""
          ? []
          : Array.isArray(data.passengerssrrecords[0].FlightPassengerSSR)
          ? data.passengerssrrecords[0].FlightPassengerSSR
          : [data.passengerssrrecords[0].FlightPassengerSSR];
      this.specialServicesDict = specialServicesArr.reduce((acc, service) => {
        if (service.ssrtype === "meal" || service.ssrtype === "baggage") {
          acc[`${service.jtype}_${service.ssrtype}_${service.paxid}`] =
            service.ssrname;
        } else {
          acc[`${service.jtype}_${service.ssrtype}_${service.paxid}`] =
            service.ssrvalue;
        }
        return acc;
      }, {});

      this.setState({
        isLoading: false,
        hasTicket: true,
        eTicket: data,
        isRoundTrip: data.flightRecord.jtype === "OneWay" ? false : true,
        travellers: data.passengerRecords[0].PassengerRecord,
        flight_reconfirmation_status: data.flightRecord.flight_reconfirmation_status,
      });
    } else {
      this.setState({
        isLoading: false,
        hasTicket: false,
        eTicket: undefined
      });
    }
  }

  updatePurchaseFare = async event => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    let obj = {
      purchase_amount: this.state.purchase_amount,
      purchase_amount_return: this.state.purchase_amount_return,
      expectedplb: this.state.expectedplb,
      remarks: this.state.remarks,
      txid: this.txId,
    }
    let res = await updateSupplierPurchase(obj);
    let msg = "Purchase fare are added/updated successfully";
    let mt = "success";
    if (res.ResponseStatus.status === "NOK") {
      msg = "Failed to add/update purchase fare";
      mt = "error";
    }
    this.setState({
      isLoading: false,
      snackBar: {
        isOpen: true,
        message: msg,
        variant: mt,
      },
    });
  };

  handleWebcheckinUpdate = async () => {
    this.setState({
      isLoading: true,
    });
    let PassengerRecordList = [...this.state.travellers];

    let res = await updateWebcheckinStatus(this.txId, PassengerRecordList, this.state.adminremarks, this.state.flight_reconfirmation_status );
    let msg = "";
    let mt = "success";
    if (res.ResponseStatus.status === "OK") {
      msg = res.ResponseStatus.message;
    } else {
      msg =  res.ResponseStatus.message;
      mt = "error";
    }
    this.setState({
      isLoading: false,
      snackBar: {
        isOpen: true,
        message: msg,
        variant: mt,
      },
    });
  };

  updateFareRules = async event => {
    event.preventDefault();
    this.setState({
      isLoading: true,
    });
    let obj = {
      ...this.state.fareRulesObj,
      txid: this.txId,
    };
    let res = await updateTransactionFareRules(obj);
    let msg = "Fare rules are added/updated successfully";
    let mt = "success";
    if (res.ResponseStatus.status === "NOK") {
      msg = "Failed to add/update fare rules";
      mt = "error";
    }
    this.setState({
      isLoading: false,
      snackBar: {
        isOpen: true,
        message: msg,
        variant: mt,
      },
    });
  };

  handleItemClick(itemId) {
    this.setState({ selectedItem: itemId, anchorEl: null });
  }

  renderSwitch(comp) {
    switch (comp) {
      case 1:
        return this.getPurchaseFareFields();
      case 2:
        return this.serviceType === "flight" ? this.getWebcheckinStatusFields() : "";
      case 3:
        return this.getTransactionFareRulesFields();
      case 4:
        return (
              <HotelReconfirmationDetail 
                reconfirmationList={this.state.reconfirmationList}
                txId={this.txId}
                showLoading={this.showLoading}
                showSnackBar={this.showSnackBar}
                callHotelReconfirmationDetailsByTxid={this.callHotelReconfirmationDetailsByTxid}
              />
        );
      default:
        return <div>Invalid</div>;
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  showLoading = isLoading => {
    this.setState({
      isLoading: isLoading
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      snackBar: {
        isOpen: false,
        message: "",
        variant: "",
      }
    });
  };

  showSnackBar = async (msg, variant) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: variant
      }
    });
  };

  handleChange = event => {
    let evt = { ...event };
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      fareRulesObj: {
        ...prevState.fareRulesObj,
        [name]: value,
      },
    }));
  };

  handleWebCheckinInputChange = (event, index) => {
    let eve = { ...event };
    let data = [...this.state.travellers];
    data[index][eve.target.name] = eve.target.value;
    this.setState({
      travellers: data,
    });
  };

  getMenu = () => {
    return (
      <div>
        <MenuItem className={`${this.state.selectedItem === 1 && "active-menu"}`} onClick={() => this.handleItemClick(1)}>
          <ListItemIcon>
            <File />
          </ListItemIcon>
          <ListItemText primary="Update Purchase Fare" />
        </MenuItem>
        { this.serviceType === "flight" && (
          <MenuItem className={`${this.state.selectedItem === 2 && "active-menu"}`} onClick={() => this.handleItemClick(2)}>
            <ListItemIcon>
              <File />
            </ListItemIcon>
            <ListItemText primary="Update Webcheckin Status" />
          </MenuItem>
        )}
        <MenuItem className={`${this.state.selectedItem === 3 && "active-menu"}`} onClick={() => this.handleItemClick(3)}>
          <ListItemIcon>
            <File />
          </ListItemIcon>
          <ListItemText primary="Update Fare Rules" />
        </MenuItem>
        { this.serviceType === "hotel" && (
          <MenuItem className={`${this.state.selectedItem === 4 && "active-menu"}`} onClick={() => this.handleItemClick(4)}>
            <ListItemIcon>
              <File />
            </ListItemIcon>
            <ListItemText primary="Hotel Reconfirmation Detail" />
          </MenuItem>
        )}
      </div>
    );
  };

  getPurchaseFareFields = () => {
    return (
      <Paper className="padding-16">
        <form onSubmit={this.updatePurchaseFare}>
          <Typography variant="h6"> Purchase Fare </Typography>
          <br />
          <br />
          <Grid container spacing={16}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Purchase Amount"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                variant="outlined"
                onChange={this.handleChange}
                name="purchase_amount"
                type="number"
                value={this.state.purchase_amount}
              />
            </Grid>
            { this.state.isRoundTrip && (
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  label="Purchase Amount Return"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  required
                  variant="outlined"
                  onChange={this.handleChange}
                  name="purchase_amount_return"
                  type="number"
                  value={this.state.purchase_amount_return}
                />
              </Grid>
            )}
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Expected plb"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                variant="outlined"
                onChange={this.handleChange}
                name="expectedplb"
                type="number"
                value={this.state.expectedplb}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Remarks"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                multiline
                variant="outlined"
                onChange={this.handleChange}
                name="remarks"
                value={this.state.remarks}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Added By"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                variant="outlined"
                onChange={this.handleChange}
                name="addedby"
                value={this.state.addedby}
                disabled
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Updated By"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                variant="outlined"
                onChange={this.handleChange}
                name="updatedby"
                value={this.state.updatedby}
                disabled
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextField
                label="Updated On"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                required
                variant="outlined"
                onChange={this.handleChange}
                name="updatedon"
                value={this.state.updatedon}
                disabled
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button type="submit" variant="contained" color="primary" disabled={!this.state.remarks}>
                {this.state.hasPurchaseFare ? "Update" : "Add"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }

  getWebcheckinStatusFields = () => {
    return (
      <Paper className="padding-16">
          {this.state.hasTicket && (
          this.state.eTicket.flightRecord &&
          this.state.eTicket.sectorRecords.map((sr, idx) => {
            let sectorList = sr.SectorRecord;
            let returnSector = {};
            if (Array.isArray(sectorList)) {
              returnSector = sectorList.filter(x => x.flight_type === "Return");
            }
            let prs = this.state.eTicket.passengerRecords[0].PassengerRecord;
            let onwardPass = prs.filter(x => x.trip_number === 1);
            let returnPass = prs.filter(x => x.trip_number === 2);

            return (
              <Grid container spacing={16} key={idx}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="h6">Departing Flight:</Typography>
                  <hr className="full-width" />
                  {this.getPassengers(onwardPass, "onward")}
                </Grid>
                {returnSector.length > 0 && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6">Returning Flight:</Typography>
                    <hr className="full-width" />
                    {returnPass.length > 0 && this.getPassengers(returnPass, "return")}
                  </Grid>
                )}
                <br/>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    label="Admin Remarks"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    required
                    multiline
                    variant="outlined"
                    onChange={this.handleChange}
                    name="adminremarks"
                    value={this.state.adminremarks}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    fullWidth
                    select
                    required
                    label="Flight Confirmation Status"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="flight_reconfirmation_status"
                    value={this.state.flight_reconfirmation_status}
                    onChange={this.handleChange}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Issue">Issue</MenuItem>
                    <MenuItem value="InProcess">InProcess</MenuItem>
                  </TextField>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={ ()=> this.handleWebcheckinUpdate() }
                    disabled= {!this.state.adminremarks}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            );
          })
        )}
      </Paper>
    );
  }

  getTransactionFareRulesFields = () => {
    return (
      <Paper className="padding-16">
        <form onSubmit={this.updateFareRules}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Paper className="padding-16">
              <Typography variant="h6"> Onward Journey </Typography>
              <br />
              <Grid container spacing={8}>
                { this.serviceType === "flight" && (
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <TextField
                    fullWidth
                    required
                    label="Carrier"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="carrier1"
                    type="text"
                    value={this.state.fareRulesObj.carrier1}
                    onChange={(event) =>
                      this.handleInputChange(event)
                    }
                  />
                </Grid>
                )}
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <TextField
                    fullWidth
                    required
                    label="Cancellation Charges"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="ow_cancellation_charges"
                    type="number"
                    value={this.state.fareRulesObj.ow_cancellation_charges}
                    onChange={(event) =>
                      this.handleInputChange(event)
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <TextField
                    fullWidth
                    required
                    label="Reschedule Charges"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="ow_reschedule_charges"
                    type="number"
                    value={this.state.fareRulesObj.ow_reschedule_charges}
                    onChange={(event) =>
                      this.handleInputChange(event)
                    }
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    required
                    multiline
                    label="Rules"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="owrules"
                    value={this.state.fareRulesObj.owrules}
                    onChange={(event) =>
                      this.handleInputChange(event)
                    }
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {this.state.isRoundTrip === true && (
            <Grid item lg={12} md={12} sm={12} xs={12}><br/>
              <Paper className="padding-16">
                <Typography variant="h6"> Return Journey </Typography>
                <br />
                <Grid container spacing={8}>
                  <Grid item lg={4} md={4} sm={4} xs={6}>
                    <TextField
                      fullWidth
                      required
                      label="Carrier"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      name="carrier2"
                      type="text"
                      value={this.state.fareRulesObj.carrier2}
                      onChange={(event) =>
                        this.handleInputChange(event)
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={6}>
                    <TextField
                      fullWidth
                      required
                      label="Cancellation Charges"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      name="rt_cancellation_charges"
                      type="number"
                      value={this.state.fareRulesObj.rt_cancellation_charges}
                      onChange={(event) =>
                        this.handleInputChange(event)
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={6}>
                    <TextField
                      fullWidth
                      required
                      label="Reschedule Charges"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      name="rt_reschedule_charges"
                      type="number"
                      value={this.state.fareRulesObj.rt_reschedule_charges}
                      onChange={(event) =>
                        this.handleInputChange(event)
                      }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      fullWidth
                      required
                      multiline
                      label="Rules"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      name="twrules"
                      value={this.state.fareRulesObj.twrules}
                      onChange={(event) =>
                        this.handleInputChange(event)
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
          <br/>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button type="submit" variant="contained" color="primary">
              {this.state.hasFareRules ? "Update" : "Add"}
            </Button>
          </Grid>
        </form>
      </Paper>
    );
  }

  getPassengers = (passengers, jtype) => {
    return (
      <React.Fragment>
        <Paper className="padding-16">
        <Table className="queue-table">
          <TableHead>
            <TableRow>
              <TableCell>Traveller Name</TableCell>
              <TableCell>Cabin Class</TableCell>
              <TableCell>Airline PNR</TableCell>
              <TableCell>Ticket Number</TableCell>
              <TableCell>Seat/Meal/Baggage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passengers.map((person, index) => (
              <TableRow key={index}>
                <TableCell>
                  {person.title}. {person.first_name} {person.last_name}
                </TableCell>
                <TableCell>
                  {this.state.eTicket.flightRecord.cabinclass}
                </TableCell>
                <TableCell>
                  {this.paxPNRDict[`${person.trip_number}_${person.paxid}`]}
                </TableCell>
                <TableCell>
                  {this.paxTicketsDict[`${person.trip_number}_${person.paxid}`]}
                </TableCell>
                <TableCell>
                  <Typography>
                    Seat:
                    {this.specialServicesDict[`${jtype}_seat_${person.paxid}`]}
                  </Typography>
                  <Typography>
                    Meal:
                    {this.specialServicesDict[`${jtype}_meal_${person.paxid}`]}
                  </Typography>
                  <Typography>
                    Baggage:
                    {
                      this.specialServicesDict[
                        `${jtype}_baggage_${person.paxid}`
                      ]
                    }
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <br/>
        <Grid container spacing={16}>
          {this.state.travellers.map(
            (traveller, index) => (
              <React.Fragment key={index}>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Typography>{traveller.title}. {traveller.first_name} {traveller.last_name}</Typography>
                    {traveller.boardingpass_url && (
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          const url = traveller.boardingpass_url;
                          window.open(url, "_blank");
                        }}
                      >
                        View Boarding Pass
                      </Button>
                    )}
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={6}>
                  <TextField
                    fullWidth
                    required
                    label="Webcheckin Status"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="webcheckin_status"
                    type="text"
                    value={traveller.webcheckin_status}
                    onChange={(event) =>
                      this.handleWebCheckinInputChange(event, index)
                    }
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={6}>
                  <TextField
                    fullWidth
                    required
                    label="Seat Number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="seatnumber"
                    type="text"
                    value={traveller.seatnumber}
                    onChange={(event) =>
                      this.handleWebCheckinInputChange(event, index)
                    }
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={6}>
                  <TextField
                    fullWidth
                    select
                    required
                    label="Seat Type"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    name="seattypes"
                    value={traveller.seattypes}
                    onChange={(event) =>
                      this.handleWebCheckinInputChange(event, index)
                    }
                  >
                    <MenuItem value="window">Window</MenuItem>
                    <MenuItem value="aisle">Aisle</MenuItem>
                  </TextField>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <TextField
                    type="file"
                    fullWidth
                    id="boardingpass_url"
                    name="boardingpass_url"
                    variant="outlined"
                    label="Upload Boarding Pass"
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => this.handleUploadReceipt(event, index)}
                  />
                </Grid>
              </React.Fragment>
              )
            )}
          </Grid>
        </Paper>
        <br/>
      </React.Fragment>
    );
  };

  handleUploadReceipt = async (event, index) => {
    var eve = { ...event };
    let uploadFile = eve.target.files[0];
    this.setState({
      isLoading: true
    });
    if (uploadFile !== undefined) {
      let formData = new FormData();
      formData.append("authtoken", "");
      formData.append("corporateid", "");
      formData.append("expenseid", "");
      formData.append("fullpath", uploadFile, uploadFile.name);
      formData.append("name", "lpoemail");
      formData.append("profileid", "");
      formData.append("receiptname", "");
      formData.append("reportid", "");
      const uploadResponse = await uploadLpoAttachment(formData);
      const lpoReceiprtUrl = uploadResponse.ApiStatus.Status === "OK" ? uploadResponse.ApiStatus.Result : undefined;
      let data = [...this.state.travellers];
      data[index][eve.target.name] = lpoReceiprtUrl;
      
      if (this.lpoReceiprtUrl === undefined) {
        this.setState({
          travellers: data,
          snackBar: {
            isOpen: true,
            message: "Failed to upload the attachment. Please try again.",
            variant: "error"
          }
        });
      } else {
        this.setState({
          travellers: data,
          snackBar: {
            isOpen: true,
            message: "Attachment has been uploaded successfully.",
            variant: "success"
          }
        });
      }
    } else {
      this.setState({
        snackBar: {
          isOpen: true,
          message: "Please browse attachment",
          variant: "error"
        }
      });
    }
    this.setState({
      isLoading: false
    });
  };

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={16}>
          {this.state.isLoading && <Loading />}
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <div className="jram">
              <Hidden mdUp>
                <Button
                  className="secondary-bg"
                  fullWidth
                  aria-owns={this.state.anchorEl ? "quality-check-menu" : null}
                  aria-haspopup="true"
                  color="secondary"
                  onClick={this.handleClick}>
                  <MenuIcon color="secondary" /> &nbsp; Quality Check Menu
                  <ChevronDown color="secondary" />
                </Button>
                <Menu id="quality-check-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleClose}>
                  {this.getMenu()}
                </Menu>
              </Hidden>
              <Hidden smDown>
                <div role="menu">{this.getMenu()}</div>
              </Hidden>
            </div>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
              <React.Fragment>
                <Paper className="padding-16">
                  <Grid container>
                    <Grid item lg={6} md={6} sm={4} xs={4}>
                      <div>
                        <Typography variant="subtitle1">
                          TripGain Booking Ref:
                        </Typography>
                        <Typography className="mt-5" variant="h6">
                          {this.txId}
                        </Typography>
                      </div> 
                    </Grid>
                    <Grid item lg={6} md={6} sm={8} xs={8}>
                      <div className="right">
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            const url = `/${this.serviceType}-ticket/${this.txId}`;
                            window.open(url, "_blank");
                          }}
                        >
                          View Ticket
                        </Button>
                      </div>
                    </Grid>
                    <hr className="full-width" />
                  </Grid>
                </Paper>
                <br/>
              </React.Fragment>
            <BrowserRouter>{this.renderSwitch(this.state.selectedItem)}</BrowserRouter>
          </Grid>
          {this.state.snackBar.isOpen && (
            <CustomSnackBar isOpen={this.state.snackBar.isOpen} message={this.state.snackBar.message} variant={this.state.snackBar.variant} onClose={this.handleSnackbarClose} />
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(QualityCheck);
