import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Loading } from "../../../components/common/Loading";
import { Grid, Typography, Paper, TextField, Button } from "@material-ui/core";
import {
  getHotelInvoice,
  performHotelRefund
} from "../../../services/queues/HotelQueuesService";
import CustomSnackBar from "../../../components/common/CustomSnackBar";

class HotelTransactionRefundPage extends Component {
  txId = undefined;
  refTxId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      invoice: undefined,
      adminRemarks: "",
      canxChargeSup: 0,
      refundAmount: 0,
      canxChargeOTA: 0,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
    let paths = window.location.pathname.split("/");
    this.txId = paths[2];
    this.refTxId = paths[3];
  }
  async componentDidMount() {
    if (
      this.txId === "" ||
      this.txId === undefined ||
      this.refTxId === "" ||
      this.refTxId === undefined
    ) {
      this.setState({
        invoice: undefined
      });
    } else {
      let res = await getHotelInvoice(this.refTxId);
      if (res === undefined) {
        this.setState({
          invoice: undefined
        });
      } else {
        this.setState({
          refundAmount: res.invoice_amount,
          invoice: res
        });
      }
    }
  }

  handleChange = event => {
    let refund = this.state.invoice.invoice_amount;
    if (event.target.name === "canxChargeSup") {
      refund = refund - this.state.canxChargeOTA - Number(event.target.value);
      this.setState({
        [event.target.name]: event.target.value,
        refundAmount: refund
      });
    } else if (event.target.name === "canxChargeOTA") {
      refund = refund - this.state.canxChargeSup - Number(event.target.value);
      this.setState({
        [event.target.name]: event.target.value,
        refundAmount: refund
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type,
      }
    });
  };

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  handleCancellation = async () => {
    this.setState({
      isLoading: true
    });
    let obj = {
      txid: this.txId,
      agentid: this.state.invoice.agentid,
      refTxId: this.refTxId,
      refundAmount: this.state.refundAmount,
      canxChargeSup: this.state.canxChargeSup,
      canxChargeOTA: this.state.canxChargeOTA,
      adminRemarks: this.state.adminRemarks
    };
    let res = await performHotelRefund(obj);
    if (res) {
      if (res.ResponseStatus.status === "NOK") {
        this.showSnackBar(res.ResponseStatus.message, "error");
      } else {
        this.showSnackBar(res.ResponseStatus.message, "success");
      }
    } else {
      this.showSnackBar(
        "Failed to save the refund transaction. Please try again",
        "error"
      );
    }
    this.setState({
      isLoading: false
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Typography variant="h6" className="mb-8">
          Hotel Transaction Refund
        </Typography>
        <Paper className="padding-16">
          {this.state.invoice && (
            <React.Fragment>
              <Grid container spacing={16}>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <Typography variant="caption" className="caption">
                    Tx.ID
                  </Typography>
                  <Typography>{this.txId}</Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <Typography variant="caption" className="caption">
                    Ref.Tx.ID
                  </Typography>
                  <Typography>
                    <Link to={`/hotel-ticket/${this.refTxId}`} target="_blank">
                      {this.refTxId}
                    </Link>
                  </Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <Typography variant="caption" className="caption">
                    Pax. Name
                  </Typography>
                  <Typography>{`${this.state.invoice.leadpassenger_firstname} ${
                    this.state.invoice.leadpassenger_lastname
                  }`}</Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <Typography variant="caption" className="caption">
                    Hotel Name
                  </Typography>
                  <Typography>{this.state.invoice.hotel_name}</Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <Typography variant="caption" className="caption">
                    Amount
                  </Typography>
                  <Typography>{this.state.invoice.invoice_amount}</Typography>
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={16}>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <TextField
                    inputProps={{
                      min: 0,
                      max: this.state.invoice.invoice_amount
                    }}
                    name="canxChargeOTA"
                    label="Canx Charge OTA"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={this.state.canxChargeOTA}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <TextField
                    inputProps={{
                      min: 0,
                      max: this.state.invoice.invoice_amount
                    }}
                    name="canxChargeSup"
                    label="Canx Charge Supplier"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={this.state.canxChargeSup}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <TextField
                    readOnly
                    disabled
                    name="refundAmount"
                    label="Canx Refund Amount"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={this.state.refundAmount}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <TextField
                fullWidth
                name="adminRemarks"
                variant="outlined"
                label="Admin Remakrs"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.adminRemarks}
                onChange={this.handleChange}
              />
              <br />
              <br />
              <Button
                onClick={this.handleCancellation}
                variant="contained"
                color="primary"
              >
                Process Cancellation
              </Button>
            </React.Fragment>
          )}
          {this.state.invoice === undefined && (
            <div>
              <Typography align="center">
                No hotel transaction found for this transaction {this.txId}
              </Typography>
            </div>
          )}
        </Paper>
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HotelTransactionRefundPage;
