import React, { Component } from "react";
import { Typography, Grid, Paper, TextField, Button } from "@material-ui/core";
import {
  getHotelInvoice,
  updateHotelFare,
} from "../../../services/queues/HotelQueuesService.js";
import { Loading } from "../../../components/common/Loading";
import CustomSnackBar from "../../../components/common/CustomSnackBar";

class HotelFareUpdatePage extends Component {
  hotel = undefined;
  txId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hotelInvoice: undefined,
      snackBar: {
        isOpen: false,
        message: "",
        variant: "",
      },
      travellers: undefined,
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.txId = window.location.pathname.split("/").pop();
    let data = "";
    let res = await getHotelInvoice(this.txId);
    if (res) {
      data = res;
      this.hotel = res;
      data.hotelrooms[0].HotelRoomDetail = Array.isArray(
        data.hotelrooms[0].HotelRoomDetail
      )
        ? data.hotelrooms[0].HotelRoomDetail
        : [data.hotelrooms[0].HotelRoomDetail];
      this.setState({
        isLoading: false,
        hotelInvoice: data,
        travellers: data.hotelrooms[0].HotelRoomDetail,
        adminremarks: "",
      });
    } else {
      this.setState({
        isLoading: false,
        hotelInvoice: undefined,
      });
    }
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  handelChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (event, index) => {
    let eve = { ...event };
    let data = [...this.state.travellers];
    data[index][eve.target.name] = eve.target.value;
    this.setState({
      travellers: data,
    });
  };

  handleQueueUpdate = async () => {
    this.setState({
      isLoading: true,
    });
    let fares = [];
    this.state.travellers.forEach((traveller) => {
      fares.push({
        ...traveller,
        tax: traveller.tax,
        servicetax: traveller.servicetax,
        tds: traveller.tds,
        commission: traveller.commission,
        handlingcharges: traveller.handlingcharges,
        farequotetotal: traveller.farequotetotal,
        invoicetotal: traveller.invoicetotal,
      });
    });

    let res = await updateHotelFare(this.txId, fares, this.state.adminremarks);
    let msg = "";
    let type = "success";
    if (res.ResponseStatus.status === "OK") {
      msg = res.ResponseStatus.message;
    } else {
      msg = res.ResponseStatus.message;
      type = "error";
    }
    this.setState({
      isLoading: false,
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type,
      },
    });
  };

  getInputFields = () => {
    return (
      <React.Fragment>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={8}>
            {this.state.travellers.map(
              (traveller, index) => (
                  <React.Fragment key={index}>
                    <Grid item lg={3} md={3} sm={3} xs={6}>
                      <Typography>{traveller.roomname}</Typography>
                    </Grid>

                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Base Fare"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        name="basefare"
                        type="number"
                        value={traveller.basefare}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Tax"
                        name="tax"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.tax}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Commission"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        name="commission"
                        value={traveller.commission}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="TDS"
                        type="number"
                        name="tds"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.tds}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Service Tax"
                        type="number"
                        name="servicetax"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.servicetax}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Handling Charges"
                        type="number"
                        name="handlingcharges"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.handlingcharges}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Total invoice"
                        type="number"
                        name="invoicetotal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.invoicetotal}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Total Farequote"
                        name="farequotetotal"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.farequotetotal}
                        onChange={(event) =>
                          this.handleInputChange(event, index)
                        }
                      />
                    </Grid>
                  </React.Fragment>
                )
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={4} xs={8}>
            <Typography variant="h6" className="mb-8">
              Update Hotel Fare
            </Typography>
          </Grid>
          <Grid item lg={9} md={9} sm={8} xs={4}>
            {this.state.hotelInvoice && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  const url = `/hotel-ticket/${this.txId}`;
                  window.open(url, "_blank");
                }}
              >
                View Ticket
              </Button>
            )}
          </Grid>
        </Grid>
        
        {this.state.hotelInvoice && (
          <Grid container spacing={16}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Typography variant="h6"> Fare Details </Typography>
                <br />
                <Grid container spacing={8}>
                  {this.getInputFields()}
                </Grid>
              </Paper>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      name="adminremarks"
                      value={this.state.adminremarks}
                      variant="outlined"
                      label="Admin Remarks"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handelChange}
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleQueueUpdate}
                >
                  Update Fare
                </Button>
              </Paper>
            </Grid>
          </Grid>
        )}
        {this.state.hotelInvoice === undefined && (
          <Paper className="padding-16">
            <div>
              <Typography align="center" variant="h6">
                No data found for the Transaction No: {this.txId}
              </Typography>
            </div>
          </Paper>
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HotelFareUpdatePage;