import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {
  getBusTicket,
  updateBusOrTrainQueueBooking
} from "../../../services/queues/BusQueuesService";
import { Loading } from "../../../components/common/Loading";
import CustomSnackBar from "../../../components/common/CustomSnackBar";

class UpdateBusBookingQueuePage extends Component {
  transactionId = undefined;
  updateObj = {
    BusBookingUpdate: {
      txid: "",
      operator_pnr: "",
      supplier_ticketnumber: "",
      supplier_contactnumber: "",
      supplier_id: "",
      adminremarks: "",
      buspax: [
        {
          BusPassenger: []
        }
      ]
    }
  };
  
  constructor(props) {
    super(props);
    this.transactionId = window.location.pathname.split("/").pop();
    this.updateObj.BusBookingUpdate.txid = this.transactionId;
    this.state = {
      busInvoice: undefined,
      passengers: [],
      isLoading: false,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      },
      remarks: "",
      operator_pnr: "",
      supplier_ticketnumber: "",
      supplier_contactnumber: "",
      supplier_id: "",
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    const data = await getBusTicket(this.transactionId);
    const busInvoice = data.BusBookingDetail;
    const passengers = Array.isArray(busInvoice.buspas[0].BusPassenger)
      ? busInvoice.buspas[0].BusPassenger
      : [busInvoice.buspas[0].BusPassenger];
    this.setState({
      passengers,
      busInvoice,
      isLoading: false,
      remarks: busInvoice.busrecord.adminremarks,
      operator_pnr: busInvoice.busrecord.operator_pnr,
      supplier_ticketnumber: busInvoice.busrecord.supplier_ticketnumber,
      supplier_contactnumber: busInvoice.busrecord.supplier_contactnumber,
      supplier_id: busInvoice.busrecord.supplier_id,
    });
  }

  handleChange = (event, index) => {
    const { name, value } = event.target;
    let passengers = [...this.state.passengers];
    passengers[index][name] = value;
    this.setState({
      passengers
    });
  };

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  handleUpdateQueue = async () => {
    this.updateObj.BusBookingUpdate.buspax[0].BusPassenger = this.state.passengers;
    this.updateObj.BusBookingUpdate.operator_pnr = this.state.operator_pnr;
    this.updateObj.BusBookingUpdate.supplier_ticketnumber = this.state.supplier_ticketnumber;
    this.updateObj.BusBookingUpdate.supplier_contactnumber = this.state.supplier_contactnumber;
    this.updateObj.BusBookingUpdate.supplier_id = this.state.supplier_id;
    this.updateObj.BusBookingUpdate.adminremarks = this.state.remarks;
    const response = await updateBusOrTrainQueueBooking(
      this.updateObj,
      "updatebusbooking"
    );
    if (response.ResponseStatus.statuscode === 200) {
      this.setState({
        snackBar: {
          isOpen: true,
          message: response.ResponseStatus.message,
          variant: "success"
        }
      });
    } else {
      this.setState({
        snackBar: {
          isOpen: true,
          message: response.ResponseStatus.message,
          variant: "error"
        }
      });
    }
  };

  render() {
    const { busInvoice } = this.state;
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        {this.state.busInvoice ? (
          <React.Fragment>
            <Grid container spacing={16} className="mb-16">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="caption">Boardingpoint Name</Typography>
                <Typography>{busInvoice.busrecord.boardingpoint_name}</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="caption">Droppingpoint Name</Typography>
                <Typography>{busInvoice.busrecord.droppingpoint_name}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="caption">Supplier Id</Typography>
                <Typography>{busInvoice.busrecord.supplier_id}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="caption">Transaction Id</Typography>
                <Typography>{busInvoice.busrecord.txid}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="caption">Journey Date</Typography>
                <Typography>{busInvoice.busrecord.dateofjourney}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="caption">Bus Type</Typography>
                <Typography>{busInvoice.busrecord.bustype}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="caption">Operator PNR</Typography>
                <Typography>{busInvoice.busrecord.operator_pnr}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="caption">Supplier Ticketnumber</Typography>
                <Typography>{busInvoice.busrecord.supplier_ticketnumber}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="caption">Travels Name</Typography>
                <Typography>{busInvoice.busrecord.travelsname}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="caption">Seats Selected</Typography>
                <Typography>{busInvoice.busrecord.seats_selected}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="caption">Ticket Status</Typography>
                <Typography>{busInvoice.busrecord.ticketstatus}</Typography>
              </Grid>
            </Grid>
            {this.state.passengers.map((p, i) => (
              <Grid container spacing={16} className="mb-16 mt-8" key={i}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography>
                    {p.title} {p.firstname} {p.lastname}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="txid"
                    label="Transaction Id"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={p.txid}
                    onChange={event => this.handleChange(event, i)}
                    disabled
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="paxid"
                    label="Pax Id"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={p.paxid}
                    onChange={event => this.handleChange(event, i)}
                    disabled
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="seatnumber"
                    label="Seat Number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={p.seatnumber}
                    onChange={event => this.handleChange(event, i)}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    fullWidth
                    name="status"
                    label="Status"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => this.handleChange(event, i)}
                    value={p.status}
                    select
                  >
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="confirm">Confirmed</MenuItem>
                    <MenuItem value="cancel">Cancelled</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            ))}
            {this.state.passengers && (
            <Paper className="padding-16">
              <Grid container spacing={16} className="mb-16 mt-8">
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="operator_pnr"
                    label="Operator PNR"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={this.state.operator_pnr}
                    onChange={event => this.handleInputChange(event)}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="supplier_ticketnumber"
                    label="Supplier Ticketnumber"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={this.state.supplier_ticketnumber}
                    onChange={event => this.handleInputChange(event)}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="supplier_id"
                    label="Supplier Id"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={this.state.supplier_id}
                    onChange={event => this.handleInputChange(event)}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="supplier_contactnumber"
                    label="Supplier Contact No."
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    type="number"
                    value={this.state.supplier_contactnumber}
                    onChange={event => this.handleInputChange(event)}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="adminremarks"
                    label="Admin Remarks"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={this.state.remarks}
                    onChange={event => this.handleInputChange(event)}
                    required
                  />
                </Grid>
              </Grid>
            </Paper>
            )}
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleUpdateQueue}
            >
              Update Queue
            </Button>
          </React.Fragment>
        ) : (
          <div>
            <Typography align="center" variant="h6">
              No booking found for the Transaction No: {this.transactionId}
            </Typography>
          </div>
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default UpdateBusBookingQueuePage;
