import React from "react";
import { Switch, Route, Link, withRouter } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Grid,
  Hidden,
  IconButton,
  SwipeableDrawer,
  MenuList,
} from "@material-ui/core";
import HomePage from "../../pages/home/HomePage";
import CardTransactionsPage from "../../pages/transactions/CardTransactionsPage";
import BusBookingQueuesPage from "../../pages/queues/bus/BusBookingQueuesPage";
import FlightBookingQueuesPage from "../../pages/queues/flight/FlightBookingQueuesPage";
import HotelBookingQueuesPage from "../../pages/queues/hotel/HotelBookingQueuesPage";
import FlightQueueUpdatePage from "../../pages/queues/flight/FlightQueueUpdatePage";
import FlightBookingTicketPage from "../../pages/queues/flight/FlightBookingTicketPage";
import FlightCancellationQueuesPage from "../../pages/queues/flight/FlightCancellationQueuesPage";
import HotelCancellationQueuesPage from "../../pages/queues/hotel/HotelCancellationQueuesPage";
import TrainCancellationQueuesPage from "../../pages/queues/train/TrainCancellationQueuesPage";
import BusCancellationQueuesPage from "../../pages/queues/bus/BusCancellationQueuesPage";
import CabCancellationQueuesPage from "../../pages/queues/cab/CabCancellationQueuesPage";
import TransactionLogPage from "../../pages/queues/common/TransactionLogPage";
import QualityCheckPage from "../../pages/queues/common/QualityCheckPage";
import HotelTransactionRefundPage from "../../pages/queues/hotel/HotelTransactionRefundPage";
import TrainTransactionRefundPage from "../../pages/queues/train/TrainTransactionRefundPage";
import BusTransactionRefundPage from "../../pages/queues/bus/BusTransactionRefundPage";
import CabTransactionRefundPage from "../../pages/queues/cab/CabTransactionRefundPage";
import HotelTicketPage from "../../pages/queues/hotel/HotelTicketPage";
import HotelBookingUpdatePage from "../../pages/queues/hotel/HotelBookingUpdatePage";
import SuppliersPage from "../../pages/suppliers/SuppliersPage";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import AuthPage from "../../pages/auth/AuthPage";
import FlightTransactionRefundPage from "../../pages/queues/flight/FlightTransactionRefundPage";
import CabsPage from "../../pages/inventory/CabsPage";
import CardsPage from "../../pages/inventory/cards/CardsPage";
import VisaPage from "../../pages/inventory/VisaPage";
import {
  getUserProfile,
  getUserAuth,
} from "../../services/common/CommonService";
import { clearLocalStorage } from "../common/Utils";
import MenuIcon from "mdi-material-ui/Menu";
import { DotsVertical } from "mdi-material-ui";
import CabBillingPage from "../../pages/offline-billing/CabBillingPage";
import VisaBillingPage from "../../pages/offline-billing/VisaBillingPage";
import HotelBillingPage from "../../pages/offline-billing/HotelBillingPage";
import FlightBillingPage from "../../pages/offline-billing/FlightBillingPage";
import TrainBillingPage from "../../pages/offline-billing/TrainBillingPage";
import InsuranceBillingPage from "../../pages/offline-billing/InsuranceBillingPage";
import ExpensesPage from "../../pages/expenses/ExpensesPage";
import UserPage from "../../pages/user/UserPage";
import UserTravellerListPage from "../../pages/user/UserTravellerListPage";
import UserCorporateProfilePage from "../../pages/user/UserCorporateProfilePage";
import UserSettingsPage from "../../pages/user/UserSettingsPage";
import UserSetCreditLimitPage from "../../pages/user/UserSetCreditLimitPage";
import CabBookingQueuesPage from "../../pages/queues/cab/CabBookingQueuesPage";
import TrainBookingQueuesPage from "../../pages/queues/train/TrainBookingQueuesPage";
import TrainBookingTicketPage from "../../pages/queues/train/TrainBookingTicketPage";
import UpdateTrainBookingQueuePage from "../../pages/queues/train/UpdateTrainBookingQueuePage";
import UpdateBusBookingQueuePage from "../../pages/queues/bus/UpdateBusBookingQueuePage";
import BusBookingTicketPage from "../../pages/queues/bus/BusBookingTicketPage";
import CabBookingTicketPage from "../../pages/queues/cab/CabBookingTicketPage";
import FlightFareUpdatePage from "../../pages/queues/flight/FlightFareUpdatePage";
import BusFareUpdatePage from "../../pages/queues/bus/BusFareUpdatePage";
import CabFareUpdatePage from "../../pages/queues/cab/CabFareUpdatePage";
import HotelFareUpdatePage from "../../pages/queues/hotel/HotelFareUpdatePage";
import TrainFareUpdatePage from "../../pages/queues/train/TrainFareUpdatePage";
import PromoCodePage from "../../pages/promocode/PromoCodePage";
import RuleMastersPage from "../../pages/rulemasters/RuleMastersPage";
import ReconciliationPage from "../../pages/reconciliation/ReconciliationPage";
import ReportsPage from "../../pages/reports/ReportsPage";
import SearchPage from "../../pages/search/SearchPage";
import ReceiptPage from "../../pages/receipt/ReceiptPage";
import TripTicketPage from "../../pages/trips/TripTicketPage";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuth: false,
      anchorEl: null,
      anchorElt: null,
      inventoryAnchor: null,
      offlineEl: null,
      mastersEl: null,
      openDrawer: false,
      profileMenuEL: null,
    };
  }

  showMenu = (isShow) => {
    if (isShow) {
      this.setState({ isAuth: isShow });
    } else {
      let user = getUserAuth();
      if (user !== null) {
        this.setState({ isAuth: true });
      } else {
        this.setState({ isAuth: false });
      }
    }
  };

  componentDidMount() {
    this.showMenu();
  }

  checkAuthAndRedirect = () => {
    let profile = getUserProfile();
    if (profile !== null) {
      return <HomePage />;
    }
    return <AuthPage showMenu={this.showMenu} />;
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClickT = (event) => {
    this.setState({
      anchorElt: event.currentTarget,
    });
  };

  handleInventoryClick = (event) => {
    this.setState({
      inventoryAnchor: event.currentTarget,
    });
  };

  handleOfflineClick = (event) => {
    this.setState({
      offlineEl: event.currentTarget,
    });
  };

  handleMasterClick = (event) => {
    this.setState({
      mastersEl: event.currentTarget,
    });
  };

  handleClose = (flag, url) => {
    this.setState(
      {
        anchorEl: null,
        anchorElt: null,
        inventoryAnchor: null,
        offlineEl: null,
        mastersEl: null,
      },
      () => {
        if (flag) {
          this.props.history.push({ pathname: url });
        }
      }
    );
  };

  handleLogout = () => {
    clearLocalStorage();
    this.setState(
      {
        isAuth: false,
        profileMenuEL: null,
      },
      () => {
        this.props.history.push({
          pathname: "/",
        });
      }
    );
  };

  handleMenu = (event) => {
    this.setState({ profileMenuEL: event.currentTarget });
  };
  toggleDrawer = () => {
    this.setState({ openDrawer: !this.state.openDrawer });
  };
  handleMenuClose = () => {
    this.setState({ profileMenuEL: null });
  };

  render() {
    return (
      <React.Fragment>
        <AppBar position="static">
          <Toolbar>
            <Grid container>
              {this.state.isAuth && (
                <Hidden lgUp>
                  <Grid item md={1} sm={1} xs={2}>
                    <IconButton
                      onClick={this.toggleDrawer}
                      color="inherit"
                      aria-label="Menu"
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                </Hidden>
              )}
              <Grid item lg={1} md={2} sm={3} xs={4}>
                <Typography variant="h6" color="inherit" noWrap>
                  <Link to="/">
                    <img
                      src="/images/logo.png"
                      alt="TripGain Admin"
                      className="logo"
                    />
                    <Typography className="white" variant="h6">
                      ADMIN
                    </Typography>
                  </Link>
                </Typography>
              </Grid>
              <Hidden mdDown>
                {this.state.isAuth && (
                  <Grid item lg={9} md={8} sm={6} className="menu-buttons">
                    <Button
                      className="white"
                      aria-owns={this.state.anchorEl ? "queues-menu" : null}
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      Queues
                    </Button>
                    <Menu
                      id="queues-menu"
                      anchorEl={this.state.anchorEl}
                      open={Boolean(this.state.anchorEl)}
                      onClose={() => this.handleClose(true, "")}
                    >
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/flight-booking-queues")
                        }
                      >
                        Flight Booking Queues
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/flight-cancellation-queues")
                        }
                      >
                        Flight Cancellation Queues
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/hotel-booking-queues")
                        }
                      >
                        Hotel Booking Queues
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/hotel-cancellation-queues")
                        }
                      >
                        Hotel Cancellation Queues
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/bus-booking-queues")
                        }
                      >
                        Bus Booking Queues
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/bus-cancellation-queues")
                        }
                      >
                        Bus Cancellation Queues
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/train-booking-queues")
                        }
                      >
                        Train Booking Queues
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/train-cancellation-queues")
                        }
                      >
                        Train Cancellation Queues
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/cab-booking-queues")
                        }
                      >
                        Cab Booking Queues
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/cab-cancellation-queues")
                        }
                      >
                        Cab Cancellation Queues
                      </MenuItem>
                    </Menu>
                    &nbsp;&nbsp;
                    <Button
                      className="white"
                      aria-owns={
                        this.state.anchorElt ? "transactions-menu" : null
                      }
                      aria-haspopup="true"
                      onClick={this.handleClickT}
                    >
                      Transactions
                    </Button>
                    <Menu
                      id="transactions-menu"
                      anchorEl={this.state.anchorElt}
                      open={Boolean(this.state.anchorElt)}
                      onClose={() => this.handleClose(true, "")}
                    >
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/card-transactions")
                        }
                      >
                        Card Transactions
                      </MenuItem>
                    </Menu>
                    <Button
                      className="white"
                      onClick={() => this.handleClose(true, "/suppliers")}
                    >
                      Suppliers
                    </Button>
                    <Button
                      className="white"
                      aria-owns={
                        this.state.inventoryAnchor ? "inventory-menu" : null
                      }
                      aria-haspopup="true"
                      onClick={this.handleInventoryClick}
                    >
                      Inventory
                    </Button>
                    <Menu
                      id="inventory-menu"
                      anchorEl={this.state.inventoryAnchor}
                      open={Boolean(this.state.inventoryAnchor)}
                      onClose={() => this.handleClose(true, "")}
                    >
                      <MenuItem onClick={() => this.handleClose(true, "/cabs")}>
                        Cabs
                      </MenuItem>
                      <MenuItem onClick={() => this.handleClose(true, "/visa")}>
                        Visa
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleClose(true, "/forex")}
                      >
                        Forex
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleClose(true, "/cards")}
                      >
                        Cards
                      </MenuItem>
                    </Menu>
                    <Button
                      className="white"
                      aria-owns={
                        this.state.offlineEl ? "offline-billing-menu" : null
                      }
                      aria-haspopup="true"
                      onClick={this.handleOfflineClick}
                    >
                      Offline Billing
                    </Button>
                    <Menu
                      id="offline-billing-menu"
                      anchorEl={this.state.offlineEl}
                      open={Boolean(this.state.offlineEl)}
                      onClose={() => this.handleClose(true, "")}
                    >
                      <MenuItem
                        onClick={() => this.handleClose(true, "/cab-billing")}
                      >
                        Cab Billing
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleClose(true, "/visa-billing")}
                      >
                        Visa Billing
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleClose(true, "/hotel-billing")}
                      >
                        Hotel Billing
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          this.handleClose(true, "/flight-billing")
                        }
                      >
                        Flight Billing
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleClose(true, "/train-billing")}
                      >
                        Train Billing
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleClose(true, "/insurance-billing")}
                      >
                        Insurance Billing
                      </MenuItem>
                    </Menu>
                    <Button
                      className="white"
                      onClick={() => this.handleClose(true, "/expenses")}
                    >
                      Expenses
                    </Button>
                    <Button
                      className="white"
                      onClick={() => this.handleClose(true, "/user")}
                    >
                      User
                    </Button>
                    <Button
                      className="white"
                      aria-owns={
                        this.state.mastersEl ? "masters-menu" : null
                      }
                      aria-haspopup="true"
                      onClick={this.handleMasterClick}
                    >
                      Master
                    </Button>
                    <Menu
                      id="masters-menu"
                      anchorEl={this.state.mastersEl}
                      open={Boolean(this.state.mastersEl)}
                      onClose={() => this.handleClose(true, "")}
                    >
                      <MenuItem onClick={() => this.handleClose(true, "/promocode")} >Promo Code</MenuItem>
                      <MenuItem onClick={() => this.handleClose(true, "/rule-masters")} >Rule Masters</MenuItem>
                    </Menu>
                    <Button
                      className="white"
                      onClick={() => this.handleClose(true, "/reconciliation")}
                    >
                      Reconciliation
                    </Button>
                    <Button
                      className="white"
                      onClick={() => this.handleClose(true, "/search")}
                    >
                      Search
                    </Button>
                    <Button
                      className="white"
                      onClick={() => this.handleClose(true, "/receipt")}
                    >
                      Receipts
                    </Button>
                    <Button
                      className="white"
                      onClick={() => this.handleClose(true, "/reports")}
                    >
                      Reports
                    </Button>
                  </Grid>
                )}
              </Hidden>
              <Grid item lg={2} md={9} sm={8} xs={6}>
                {this.state.isAuth && (
                  <React.Fragment>
                    <Button
                      className="right"
                      size="small"
                      onClick={this.handleMenu}
                      color="inherit"
                      aria-haspopup="true"
                    >
                      <Hidden xsDown>
                        {getUserProfile() === null
                          ? ""
                          : `${getUserProfile().title} ${
                              getUserProfile().first_name
                            } ${getUserProfile().last_name}`}
                      </Hidden>
                      <DotsVertical />
                    </Button>
                    <Menu
                      anchorEl={this.state.profileMenuEL}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(this.state.profileMenuEL)}
                      onClose={this.handleMenuClose}
                    >
                      <MenuItem color="inherit" onClick={this.handleLogout}>
                        Logout
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <main className="main-entry">
          <Switch>
            <Route exact path="/" render={() => this.checkAuthAndRedirect()} />
            <AuthenticatedRoute exact path="/home" component={HomePage} />
            <AuthenticatedRoute
              path="/flight-booking-queues"
              component={FlightBookingQueuesPage}
            />
            <AuthenticatedRoute
              path="/flight-cancellation-queues"
              component={FlightCancellationQueuesPage}
            />
            <AuthenticatedRoute
              path="/update-flight-queue/:id"
              component={FlightQueueUpdatePage}
            />
            <AuthenticatedRoute
              path="/flight-ticket/:id"
              component={FlightBookingTicketPage}
            />
            <AuthenticatedRoute
              path="/flight-transaction-refund/:id/:refId"
              component={FlightTransactionRefundPage}
            />
            <AuthenticatedRoute
              path="/hotel-booking-queues"
              component={HotelBookingQueuesPage}
            />
            <AuthenticatedRoute
              path="/hotel-cancellation-queues"
              component={HotelCancellationQueuesPage}
            />
            <AuthenticatedRoute
              path="/bus-booking-queues"
              component={BusBookingQueuesPage}
            />
            <AuthenticatedRoute
              path="/bus-cancellation-queues"
              component={BusCancellationQueuesPage}
            />
            <AuthenticatedRoute
              path="/bus-ticket/:id"
              component={BusBookingTicketPage}
            />
            <AuthenticatedRoute
              path="/train-booking-queues"
              component={TrainBookingQueuesPage}
            />
            <AuthenticatedRoute
              path="/train-cancellation-queues"
              component={TrainCancellationQueuesPage}
            />
            <AuthenticatedRoute
              path="/train-ticket/:id"
              component={TrainBookingTicketPage}
            />
            <AuthenticatedRoute
              path="/update-train-queue/:id"
              component={UpdateTrainBookingQueuePage}
            />
            <AuthenticatedRoute
              path="/cab-booking-queues"
              component={CabBookingQueuesPage}
            />
            <AuthenticatedRoute
              path="/cab-cancellation-queues"
              component={CabCancellationQueuesPage}
            />
            <AuthenticatedRoute
              path="/cab-ticket/:id"
              component={CabBookingTicketPage}
            />
            <AuthenticatedRoute
              path="/transaction-log/:id"
              component={TransactionLogPage}
            />
            <AuthenticatedRoute
              path="/quality-check/:type/:id"
              component={QualityCheckPage}
            />
            <AuthenticatedRoute
              path="/cab-transaction-refund/:id/:refId"
              component={CabTransactionRefundPage}
            />
            <AuthenticatedRoute
              path="/bus-transaction-refund/:id/:refId"
              component={BusTransactionRefundPage}
            />
            <AuthenticatedRoute
              path="/update-bus-queue/:id"
              component={UpdateBusBookingQueuePage}
            />
            <AuthenticatedRoute
              path="/train-transaction-refund/:id/:refId"
              component={TrainTransactionRefundPage}
            />
            <AuthenticatedRoute
              path="/hotel-transaction-refund/:id/:refId"
              component={HotelTransactionRefundPage}
            />
            <AuthenticatedRoute
              path="/update-hotel-queue/:id"
              component={HotelBookingUpdatePage}
            />
            <AuthenticatedRoute
              path="/hotel-ticket/:id"
              component={HotelTicketPage}
            />
            <AuthenticatedRoute
              path="/card-transactions"
              component={CardTransactionsPage}
            />
            <AuthenticatedRoute path="/suppliers" component={SuppliersPage} />
            <AuthenticatedRoute path="/cabs" component={CabsPage} />
            <AuthenticatedRoute path="/cards" component={CardsPage} />
            <AuthenticatedRoute path="/visa" component={VisaPage} />
            <AuthenticatedRoute
              path="/cab-billing"
              component={CabBillingPage}
            />
            <AuthenticatedRoute
              path="/visa-billing"
              component={VisaBillingPage}
            />
            <AuthenticatedRoute
              path="/hotel-billing"
              component={HotelBillingPage}
            />
            <AuthenticatedRoute
              path="/flight-billing"
              component={FlightBillingPage}
            />
            <AuthenticatedRoute
              path="/train-billing"
              component={TrainBillingPage}
            />
            <AuthenticatedRoute
              path="/insurance-billing"
              component={InsuranceBillingPage}
            />
            <AuthenticatedRoute path="/expenses" component={ExpensesPage} />
            <AuthenticatedRoute path="/user" component={UserPage} />
            <AuthenticatedRoute path="/promocode" component={PromoCodePage} />
            <AuthenticatedRoute path="/rule-masters" component={RuleMastersPage} />
            <AuthenticatedRoute path="/reconciliation" component={ReconciliationPage} />
            <AuthenticatedRoute path="/reports" component={ReportsPage} />
            <AuthenticatedRoute path="/search" component={SearchPage} />
            <AuthenticatedRoute path="/receipt" component={ReceiptPage} />
            <AuthenticatedRoute
              path="/user-traveller-list/:id"
              component={UserTravellerListPage}
            />
            <AuthenticatedRoute
              path="/user-profile/:id/:refId"
              component={UserCorporateProfilePage}
            />
            <AuthenticatedRoute
              path="/user-settings/:id"
              component={UserSettingsPage}
            />
            <AuthenticatedRoute
              path="/user-setcreditlimit/:id"
              component={UserSetCreditLimitPage}
            />
            <AuthenticatedRoute
              path="/update-flight-fare/:id"
              component={FlightFareUpdatePage}
            />
            <AuthenticatedRoute
              path="/update-bus-fare/:id"
              component={BusFareUpdatePage}
            />
            <AuthenticatedRoute
              path="/update-cab-fare/:id"
              component={CabFareUpdatePage}
            />
            <AuthenticatedRoute
              path="/update-hotel-fare/:id"
              component={HotelFareUpdatePage}
            />
            <AuthenticatedRoute
              path="/update-train-fare/:id"
              component={TrainFareUpdatePage}
            />
            <AuthenticatedRoute exact path="/trip-ticket/:id" component={TripTicketPage} />
          </Switch>
        </main>
        {this.state.isAuth && (
          <SwipeableDrawer
            onOpen={this.toggleDrawer}
            disableBackdropTransition
            className="sidebar"
            anchor="left"
            open={this.state.openDrawer}
            onClose={this.toggleDrawer}
          >
            <div tabIndex={0} onClick={this.toggleDrawer}>
              <MenuList>
                <MenuItem component={Link} to="/flight-booking-queues">
                  Flight Booking Queues
                </MenuItem>
                <MenuItem component={Link} to="/flight-cancellation-queues">
                  Flight Cancellation Queues
                </MenuItem>
                <MenuItem component={Link} to="/hotel-booking-queues">
                  Hotel Booking Queues
                </MenuItem>
                <MenuItem component={Link} to="/hotel-cancellation-queues">
                  Hotel Cancellation Queues
                </MenuItem>
                <MenuItem component={Link} to="/bus-booking-queues">
                  Bus Booking Queues
                </MenuItem>
                <MenuItem component={Link} to="/train-booking-queues">
                  Train Booking Queues
                </MenuItem>
                <MenuItem component={Link} to="/cab-booking-queues">
                  Cab Booking Queues
                </MenuItem>
                <MenuItem component={Link} to="/card-transactions">
                  Card Transactions
                </MenuItem>
                <MenuItem component={Link} to="/suppliers">
                  Suppliers
                </MenuItem>
                <MenuItem component={Link} to="/cabs">
                  Cabs
                </MenuItem>
                <MenuItem component={Link} to="/forex">
                  Forex
                </MenuItem>
                <MenuItem component={Link} to="/visa">
                  Visa
                </MenuItem>
                <MenuItem component={Link} to="/cards">
                  Cards
                </MenuItem>
                <MenuItem component={Link} to="/expenses">
                  Expenses
                </MenuItem>
              </MenuList>
            </div>
          </SwipeableDrawer>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
