import React, { Component } from "react";
import { Typography, Paper, Grid, TextField, ListItemText, MenuItem } from "@material-ui/core";
import { getAllCostCenters, getAllDepartments, getAllProjects } from "../../../services/common/CommonService";

class CorporateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obj: props.detailsObj,
      customerId: props.customerId,
      departmentsList: [],
      projectsList: [],
      costCentersList: [],
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.customerId && this.props.customerId !== prevProps.customerId) {
      await this.getAllApis(this.props.customerId);
    }
  }

  getAllApis = async (corporateid) => {
    const projectsList = await getAllProjects(corporateid);
    const departmentsList = await getAllDepartments(corporateid);
    const costCentersList = await getAllCostCenters(corporateid);
    this.setState({
      projectsList,
      departmentsList,
      costCentersList,
    });
  }

  handleInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      obj: {
        ...prevState.obj,
        [eve.target.name]: eve.target.value
      }
    }),
      () => this.props.updateChange(this.state.obj)
    );
  };

  render() {
    const { projectsList, costCentersList, departmentsList, obj } = this.state;
    return (
      <Paper className="padding-16 visa-details-form">
        <Typography variant="subtitle1" className="bold">
          Corporate Other Detail
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              select
              variant="outlined"
              name="costcenterid"
              label="Cost Center"
              InputLabelProps={{
                shrink: true
              }}
              value={obj.costcenterid}
              onChange={this.handleInputChange}
              disabled={!this.props.customerId}
            >
              {costCentersList.map(c => (
                <MenuItem value={c.costcenterid} key={c.costcenterid}>
                  <ListItemText primary={c.costcentername} secondary={c.description} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              autoComplete="department"
              required
              fullWidth
              select
              variant="outlined"
              name="departmentid"
              label="Department Code"
              InputLabelProps={{
                shrink: true
              }}
              value={obj.departmentid}
              onChange={this.handleInputChange}
              disabled={!this.props.customerId}
            >
              {departmentsList.map(d => (
                <MenuItem value={d.departmentid} key={d.departmentid}>
                  <ListItemText primary={d.departmentname} secondary={d.department_description} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              select
              variant="outlined"
              name="projectid"
              label="Project Code"
              InputLabelProps={{
                shrink: true
              }}
              value={obj.projectid}
              onChange={this.handleInputChange}
              disabled={!this.props.customerId}
            >
              {projectsList.map(p => (
                <MenuItem key={p.projectid} value={p.projectid}>
                  <ListItemText primary={p.projectname} secondary={p.project_description} />
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default CorporateDetails;
