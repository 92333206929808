import React, { Component } from "react";
import {
    Button,
    Typography,
    Grid,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";

class RejectDialog extends Component {
    constructor(props) {
        super(props);
        this.selectedRow = props.selectedRow;
    }

    transactionsType = {
        TRAIN: 'Train',
        BUS: 'Bus',
        CAB: 'Cab',
        HOTEL: 'Hotel',
    }

    txnsSettings = {
        [this.transactionsType.TRAIN]: [
            { key: 'Transaction ID', value: 'txid'},
            { key: 'Reference ID', value: 'reftxid'},
            { key: 'Blocked By', value: 'blockedbyid'},
            { key: 'Cancel Date', value: 'canceldate'},
            { key: 'PNR', value: 'pnr'},
            { key: 'Agent ID', value: 'agentid'},
            { key: 'Pax Names', value: 'paxnames'},
        ],
        [this.transactionsType.HOTEL]: [
            { key: 'Transaction ID', value: 'txid'},
            { key: 'Reference ID', value: 'reference_txid'},
            { key: 'Blocked By', value: 'blockedbyid'},
            { key: 'Txn Date', value: 'tx_date'},
            { key: 'Remarks', value: 'remarks'},
            { key: 'Agent ID', value: 'agentid'},
            { key: 'Pax Names', value: 'paxnames'},
        ],
        [this.transactionsType.CAB]: [
            { key: 'Transaction ID', value: 'txid'},
            { key: 'Reference ID', value: 'reference_txid'},
            { key: 'Blocked By', value: 'blockedbyid'},
            { key: 'Canx. Raised Date', value: 'refunddate_nex'},
            { key: 'Status', value: 'status'},
            { key: 'Agent ID', value: 'agentid'},
            { key: 'Pax Name', value: 'paxname'},
        ],
        [this.transactionsType.BUS]: [
            { key: 'Transaction ID', value: 'txid'},
            { key: 'Reference ID', value: 'reference_txid'},
            { key: 'Blocked By', value: 'blockedbyid'},
            { key: 'Txn Date', value: 'tx_date'},
            { key: 'Remarks', value: 'remarks'},
            { key: 'Agent ID', value: 'agentid'},
            { key: 'Pax Names', value: 'paxnames'},
        ],
    };
    
    render() {
        const { handleReject, closeRejectDialog, handelRejectChange, rejectRemarks } = this.props;
        console.log(JSON.stringify(this.selectedRow));
        
        return (
            <React.Fragment>
                <Dialog open={this.props.showReject} className="xs-100">
                    <DialogTitle>Reject {this.props.txnType} Cancellation Request</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={16}>
                            {this.txnsSettings[this.props.txnType] && this.txnsSettings[this.props.txnType].map((item, index) => (
                                <Grid item lg={6} md={6} sm={6} xs={12} key={index}>
                                    <Typography variant="caption" className="caption">
                                        {item.key}
                                    </Typography>
                                    <Typography>{this.selectedRow[item.value]}</Typography>
                                </Grid>
                            ))}
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography>Remarks</Typography>
                                <TextField
                                    fullWidth
                                    name="rejectRemarks"
                                    variant="outlined"
                                    onChange={handelRejectChange}
                                    value={rejectRemarks}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReject}
                            disabled={!rejectRemarks}
                        >
                            Reject Cancellation
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={closeRejectDialog}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default RejectDialog;