import React, { Component } from "react";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Button
} from "@material-ui/core";
import { getUserAccount, setCreditLimit } from "../../services/user/UserService";
import { Loading } from "../../components/common/Loading";
import CustomSnackBar from "../../components/common/CustomSnackBar";

class UserSetCreditLimitPage extends Component {
  txId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userData: undefined,
      creditData: {
        cid: "",
        credit_limit: "",
        amt_balance: "",
        amt_due: ""
      },
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    this.txId = window.location.pathname.split("/").pop();

    this.setState(prevState => ({
      creditData: {
        ...prevState.creditData,
        cid: this.txId
      }
    }));

    let res = await getUserAccount(this.txId);
    if (res) {
      this.setState({
        isLoading: false,
        userData: res.UserAccount
      });
      this.setState(prevState => ({
        creditData: {
          ...prevState.creditData,
          credit_limit: res.UserAccount.credit_limit,
          amt_balance: res.UserAccount.balance,
          amt_due: res.UserAccount.due
        }
      }));
    } else {
      this.setState({
        isLoading: false
      });
    }
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  showSnackBar = (msg, variant) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: variant
      }
    });
  };

  handelChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      creditData: {
        ...prevState.creditData,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  handleCreditLimit = async event => {
    event.preventDefault();
    let isValid = true;
    Object.keys(this.state.creditData).forEach(property => {
      if (this.state.creditData[property] === "") {
        isValid = false;
      }
    });
    if (isValid) {
      this.setState({
        isLoading: true
      });

      let res = await setCreditLimit(this.state.creditData);
      
      if (res) {
        if (res.ApiStatus.Status === "NOK") {
          this.showSnackBar(res.ApiStatus.Message, "error");
        } else {
          this.showSnackBar(res.ApiStatus.Message, "success");
        }
      } else {
        this.showSnackBar(
          "Failed to set credit limit. Please try again.",
          "error"
        );
      }
      this.setState({
        isLoading: false
      });
    } 
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Typography variant="h6" className="mb-8">
          Agent Fund
        </Typography>

        <Grid container spacing={16}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Paper className="padding-16">
              <form onSubmit={this.handleCreditLimit}>
              <Grid container spacing={16}>
                <Typography variant="subtitle1" className="bold">
                  Set Credit Limit
                </Typography>
                <hr className="full-width mb-16" />
                
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Available Balance"
                    name="amt_balance"
                    disabled
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.creditData.amt_balance}
                    onChange={this.handelChange}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Due Amount"
                    name="amt_due"
                    disabled
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.creditData.amt_due}
                    onChange={this.handelChange}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Credit Limit"
                    name="credit_limit"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={this.state.creditData.credit_limit}
                    onChange={this.handelChange}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Button
                    className="left"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Set Credit Limit
                  </Button>
                </Grid>
              </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>  
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default UserSetCreditLimitPage;
