import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import ListPromoCodes from "../../components/promocode/ListPromoCodes";
import "./PromoCodePage.css";

class PromoCodePage extends Component {
  render() {
    return (
      <React.Fragment>
        <Paper className="padding-16">
          <ListPromoCodes />
        </Paper>
      </React.Fragment>
    );
  }
}

export default PromoCodePage;
