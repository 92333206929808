import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Loading } from "../../../components/common/Loading";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";
import CustomSnackBar from "../../../components/common/CustomSnackBar";
import {
  getFlightInvoice,
  performFlightRefund
} from "../../../services/queues/FlightQueuesService";

class FlightTransactionRefundPage extends Component {
  txId = undefined;
  refTxId = undefined;
  passengers = [];
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      invoice: undefined,
      adminRemarks: "",
      canxChargeSup: 0,
      refundAmount: 0,
      canxChargeOTA: 0,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
    let paths = window.location.pathname.split("/");
    this.txId = paths[3];
    this.refTxId = paths[2];
  }
  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    if (
      this.txId === "" ||
      this.txId === undefined ||
      this.refTxId === "" ||
      this.refTxId === undefined
    ) {
      this.setState({
        invoice: undefined
      });
    } else {
      let res = await getFlightInvoice(this.txId);
      if (res === undefined) {
        this.setState({
          invoice: undefined
        });
      } else {
        this.passengers = Array.isArray(res.passengerRecords[0].PassengerRecord)
          ? res.passengerRecords[0].PassengerRecord
          : [res.passengerRecords[0].PassengerRecord];

        this.passengers.forEach(ele => {
          ele.canxChargeOTA = 0;
          ele.canxChargeSup = 0;
          ele.refundAmount =
            Number(ele.base_fare) +
            Number(ele.tax) +
            Number(ele.servicetax) +
            Number(ele.commission) +
            Number(ele.tds);
          ele.originalRefundAmount =
            Number(ele.base_fare) +
            Number(ele.tax) +
            Number(ele.servicetax) +
            Number(ele.commission) +
            Number(ele.tds);
        });

        this.setState({
          refundAmount: res.flightRecord.total_base_tax,
          invoice: res.flightRecord
        });
      }
    }
    this.setState({
      isLoading: false
    });
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  handleCancellation = async () => {
    this.setState({
      isLoading: true
    });
    let paxStr = this.passengers
      .map(x =>
        [x.paxid, x.canxChargeOTA, x.canxChargeSup, x.refundAmount].join(",")
      )
      .join(":");
    const canxChargeSup = this.passengers.reduce(
      (acc, cur) => acc + cur.canxChargeSup,
      0
    );
    const canxChargeOTA = this.passengers.reduce(
      (acc, cur) => acc + cur.canxChargeOTA,
      0
    );
    let obj = {
      txId: this.refTxId,
      refTxId: this.txId,
      refundAmount: this.state.refundAmount,
      canxChargeSup: canxChargeSup,
      canxChargeOTA: canxChargeOTA,
      adminRemarks: this.state.adminRemarks,
      paxStr: paxStr,
      agentId: this.state.invoice.agentid
    };
    let res = await performFlightRefund(obj);
    if (res) {
      if (res.ApiStatus.Status === "OK") {
        this.showSnackBar(res.ApiStatus.Message, "success");
      } else {
        this.showSnackBar(res.ApiStatus.Message, "error");
      }
    } else {
      this.showSnackBar(
        "Failed to save the refund transaction. Please try again",
        "error"
      );
    }
    this.setState({
      isLoading: false
    });
  };

  handleChangePax = (event, index) => {
    const { name, value } = event.target;
    this.passengers[index][name] = Number(value);
    const pax = this.passengers[index];
    this.passengers[index]["refundAmount"] =
      pax.originalRefundAmount -
      Number(pax.canxChargeSup) -
      Number(pax.canxChargeOTA);
    const refundAmount = this.passengers.reduce(
      (acc, cur) => acc + cur.refundAmount,
      0
    );
    this.setState({
      refundAmount
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Typography variant="h6" className="mb-8">
          Flight Transaction Refund
        </Typography>
        <Paper className="padding-16">
          {this.state.invoice && (
            <React.Fragment>
              <Grid container spacing={16}>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <Typography variant="caption" className="caption">
                    Tx.ID
                  </Typography>
                  <Typography>{this.state.invoice.txid}</Typography>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <Typography variant="caption" className="caption">
                    Ref.Tx.ID
                  </Typography>
                  <Typography>
                    <Link to={`/flight-ticket/${this.refTxId}`} target="_blank">
                      {this.refTxId}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Table className="flight-canx-pax-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>JType</TableCell>
                    <TableCell>Sector</TableCell>
                    <TableCell>BF</TableCell>
                    <TableCell>Tax</TableCell>
                    <TableCell>Stax</TableCell>
                    <TableCell>Tds</TableCell>
                    <TableCell>Commission</TableCell>
                    <TableCell>CanxChargeOTA</TableCell>
                    <TableCell>CanxChargeAirline</TableCell>
                    <TableCell>Refund Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.passengers.map((p, i) => (
                    <TableRow key={i}>
                      <TableCell>{p.paxid}</TableCell>
                      <TableCell>
                        {p.title} {p.first_name} {p.last_name}
                      </TableCell>
                      <TableCell>
                        {p.trip_number === 1 ? "Onward" : "Return"}
                      </TableCell>
                      <TableCell>{p.paxid}</TableCell>
                      <TableCell>{p.base_fare}</TableCell>
                      <TableCell>{p.tax}</TableCell>
                      <TableCell>{p.servicetax}</TableCell>
                      <TableCell>{p.tds}</TableCell>
                      <TableCell>{p.commission}</TableCell>
                      <TableCell>
                        <TextField
                          inputProps={{
                            min: 0,
                            max: this.state.invoice.total_base_tax
                          }}
                          name="canxChargeOTA"
                          label="Canx Charge OTA"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          fullWidth
                          type="number"
                          value={p.canxChargeOTA}
                          onChange={event => this.handleChangePax(event, i)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          inputProps={{
                            min: 0,
                            max: this.state.invoice.total_base_tax
                          }}
                          name="canxChargeSup"
                          label="Canx Charge Airline"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          fullWidth
                          type="number"
                          value={p.canxChargeSup}
                          onChange={event => this.handleChangePax(event, i)}
                        />
                      </TableCell>
                      <TableCell>{p.refundAmount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {/* <Grid item lg={4} md={4} sm={4} xs={6}>
                  <Typography variant="caption" className="caption">
                    Pax. Name
                  </Typography>
                  <Typography>{`${this.state.invoice.passengername} ${
                    this.state.invoice.passengerlastname
                  }`}</Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={6}>
                  <Typography variant="caption" className="caption">
                    Sector
                  </Typography>
                  <Typography>
                    {this.state.invoice.origin} ->{" "}
                    {this.state.invoice.destination}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Typography variant="caption" className="caption">
                    Base Fare
                  </Typography>
                  <Typography>{this.state.invoice.basefare}</Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Typography variant="caption" className="caption">
                    Tax
                  </Typography>
                  <Typography>{this.state.invoice.tax}</Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Typography variant="caption" className="caption">
                    Amount
                  </Typography>
                  <Typography>{this.state.invoice.total_base_tax}</Typography>
                </Grid>
              </Grid> */}
              <br />
              <br />
              <Grid container spacing={16}>
                <Grid item lg={5} md={5} sm={5} xs={6}>
                  {/* <TextField
                    inputProps={{
                      min: 0,
                      max: this.state.invoice.total_base_tax
                    }}
                    name="canxChargeOTA"
                    label="Canx Charge OTA"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={this.state.canxChargeOTA}
                    onChange={this.handleChange}
                  /> */}
                </Grid>
                <Grid item lg={5} md={5} sm={5} xs={6}>
                  {/* <TextField
                    inputProps={{
                      min: 0,
                      max: this.state.invoice.total_base_tax
                    }}
                    name="canxChargeSup"
                    label="Canx Charge Airline"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={this.state.canxChargeSup}
                    onChange={this.handleChange}
                  /> */}
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={6}>
                  <TextField
                    readOnly
                    name="refundAmount"
                    label="Canx Refund Amount"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={this.state.refundAmount}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <TextField
                fullWidth
                name="adminRemarks"
                variant="outlined"
                label="Admin Remakrs"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.adminRemarks}
                onChange={this.handleChange}
              />
              <br />
              <br />
              <Button
                onClick={this.handleCancellation}
                variant="contained"
                color="primary"
              >
                Process Cancellation
              </Button>
            </React.Fragment>
          )}
          {this.state.invoice === undefined && (
            <div>
              <Typography align="center">
                No flight transaction found for this transaction {this.txId}
              </Typography>
            </div>
          )}
        </Paper>
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleSnackBarClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default FlightTransactionRefundPage;
