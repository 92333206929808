import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUserAuth } from "../../services/common/CommonService";

function isAuthenticated() {
  var userAuth = getUserAuth();
  if (userAuth) {
    if (userAuth.authtoken) {
      return true;
    }
    return false;
  }
}

export const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
