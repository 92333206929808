export const DATE_FORMAT = "Do MMM, YYYY";
export const API_REQUEST_DATE_FORMAT = "YYYY-MM-DD";
export const TRIP_TYPE = {
    outstation: {
        value: "outstation",
        label: "Outstation",
    },
    localtransfer: {
        value: "localtransfer",
        label: "Local Transfer",
    },
    airportdrop: {
        value: "airportdrop",
        label: "Airport Drop",
    },
    airportpickup: {
        value: "airportpickup",
        label: "Airport Pickup",
    },
    airporttransfer: {
        value: "airporttransfer",
        label: "Airport Transfer",
    },
    localpackage: {
        value: "localpackage",
        label: "Local Package",
    },
    localrental: {
        value: "localrental",
        label: "Local Rental",
    },
}