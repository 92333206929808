import { apiPost, getUserAuth } from "../common/CommonService";

export const getTripTransactions = async tripId => {
  try {
    let reqObj = {
      opid: "TG-TRIPTXNS",
      tripid: tripId,
    };

    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null") || res.list[0] === "") {
      return undefined;
    } else {
      return Array.isArray(res.list[0].GenericTrip) ? res.list[0].GenericTrip : [res.list[0].GenericTrip];
    }
  } catch (err) {
    return undefined;
  }
};

export const getTripDetailsLite = async tripId => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-EXPENSE",
      action: "gettripdetailslite",
      corporateid: userAuth.corporateid,
      profileid: userAuth.profileid,
      tripid: tripId,
    };
    let res = await apiPost(reqObj);
    return res.CorporateTrip;
  } catch (err) {
    return undefined;
  }
};
