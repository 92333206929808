import React, { Component } from "react";
import InsuranceBillingMain from "../../components/offline-billing/insurance-billing/InsuranceBillingMain";
import "./common.css";

class InsuranceBillingPage extends Component {
  render() {
    return (
      <React.Fragment>
        <InsuranceBillingMain />
      </React.Fragment>
    );
  }
}

export default InsuranceBillingPage;
