import { apiPost, apiPostOtherRoute } from "../common/CommonService";

export const getCabs = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getcabinventory"
    };
    let res = await apiPost(reqObj);
    if (res.list[0] !== "") {
      let data = Array.isArray(res.list[0].CabInventory)
        ? res.list[0].CabInventory
        : [res.list[0].CabInventory];
      return data;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const addCab = async obj => {
  try {
    let cabObj = {
      CabInventory: obj
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "addcabinventory",
      jsondata: JSON.stringify(cabObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const updateCab = async obj => {
  try {
    let cabObj = {
      CabInventory: obj
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatecabinventory",
      jsondata: JSON.stringify(cabObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getCities = async () => {
  try {
    let reqObj = {
      opid: "TG-GENERIC",
      act: "getcities",
      countrycode: "IND"
    };
    let res = await apiPost(reqObj);
    if (res.list[0].TgPlaceObject) {
      return res.list[0].TgPlaceObject;
    } else {
      return undefined;
    }
  } catch (err) {
    return undefined;
  }
};

export const getPlaces = async (searchText, callback) => {
  try {
    if (searchText === "") {
      callback([]);
    } else {
      let res = await apiPostOtherRoute("googleplaces.jsp", {
        searchkey: searchText,
        actionname: "autotextsearch",
        placeid: "",
        latitude: "",
        longitude: "",
        query: "",
        isArray: false,
        timeout: 30000
      });
      if (res.status === "OK") {
        var places = res.predictions.map(x => {
          return {
            value: x.place_id,
            label: x.description
          };
        });
        callback(places);
      } else {
        callback([]);
      }
    }
  } catch (error) {
    callback([]);
  }
};

export const getPlaceDetails = async obj => {
  try {
    let res = await apiPostOtherRoute("googleplaces.jsp", {
      searchkey: obj.label,
      actionname: "placedetails",
      placeid: obj.value,
      latitude: "",
      longitude: "",
      query: "",
      isArray: false,
      timeout: 30000
    });

    if (res.result) {
      return res.result.geometry.location;
    } else {
      return undefined;
    }
  } catch (err) {
    return undefined;
  }
};
