import React, { Component } from "react";
import { Typography, Button } from "@material-ui/core";
import AgentContactInfo from "../common/AgentContactInfo";
import CabCustomers from "../common/CustomerDetails";
import FareBreakUp from "../common/FareBreakUp";
import CabDetails from "./CabDetails";
import { offlineCabBooking } from "../../../services/offline-billing/CabOfflineService";
import CorporateDetails from "../common/CorporateDetails";

class CabBillingMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      agentObj: {
        customerid: "",
        title: "Mr",
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        requestedby: "",
        supplierid: ""
      },
      cabDetailsObj: {
        destination: "",
        pickup: "",
        cartype: "ac",
        carname: "",
        pickupdate: "",
        pickuptime: "",
        cabid: "",
        dropdate: "",
        droptime: "",
        triptype: "select"
      },
      corporateDetailsObj: {
        costcenterid: "",
        departmentid: "",
        projectid: "",
      },
      customerDetailsObj: {
        numadults: 1,
        numchildren: 0,
        numinfants: 0,
        adultsArr: [
          {
            title: "Mr",
            firstname: "",
            lastname: ""
          }
        ],
        childrenArr: [],
        infantArr: []
      },
      fareObj: {
        basefare: 0,
        tax: 0,
        servicefee: 0,
        totalinvoice: 0,
      }
    };
  }

  updateCorporateDetailsObject = obj => {
    this.setState({
      corporateDetailsObj: obj
    });
  };

  updateCabDetailsObject = obj => {
    this.setState({
      cabDetailsObj: obj
    });
  };

  updateCustomerDetailsObject = obj => {
    this.setState({
      customerDetailsObj: obj
    });
  };

  updateStateObject = obj => {
    this.setState({
      agentObj: obj,
      customerId: obj.customerid
    });
  };

  updateFareObject = obj => {
    this.setState({
      fareObj: obj
    });
  };

  handleGenerateBill = async () => {
    let passengers = [];
    let agentObjError = 0, customerDetailsObjError = 0, cabDetailsObjError = 0, fareObjError = 0;
    const alertMsg = "Please enter value for ";
    const { agentObj, cabDetailsObj, customerDetailsObj, corporateDetailsObj, fareObj } = this.state;

    Object.keys(agentObj).some(key => {
      if (agentObj[key] === "") {
        window.alert(alertMsg + key);
        agentObjError = 1;
        return true;
      }
      return false;
    });

    if(agentObjError === 1){
      return false;
    }

    Object.keys(customerDetailsObj).some(key => {
      const keyVal = customerDetailsObj[key];
      if (["adultsArr", "childrenArr", "infantArr"].includes(key) && Array.isArray(keyVal) && keyVal.length > 0) {
        for(let i=0; i<keyVal.length; i++){
          passengers.push({
            "FlightPassenger": keyVal[i]
          });
        }
      }
      
      if (customerDetailsObj[key] === "") {
        window.alert(alertMsg + key);
        customerDetailsObjError = 1;
        return true;
      }
      return false;
    });

    if(customerDetailsObjError === 1){
      return false;
    }

    Object.keys(cabDetailsObj).some(key => {
      if (cabDetailsObj[key] === "" || cabDetailsObj[key] === "select") {
        window.alert(alertMsg + key);
        cabDetailsObjError = 1;
        return true;
      }
      return false;
    });

    if(cabDetailsObjError === 1){
      return false;
    }

    Object.keys(fareObj).some(key => {
      if (fareObj[key] === "") {
        window.alert(alertMsg + key);
        fareObjError = 1;
        return true;
      }
      return false;
    });

    if(fareObjError === 1){
      return false;
    }

    const jsondata ={
      "TgCarBookingRequest": {
        "passengers": passengers,
        "corporatedetails": {
          "corporateid": agentObj.customerid,
          "profileid": "530",
          "tcbookingrequestid": "",
          "tcbookingid": "",
          "tcprofileid": "",
          "policyid": "",
          "inpolicy": "true",
          "policy_reasonid": "",
          "policy_reason_remarks": "",
          "costcenterid": corporateDetailsObj.costcenterid,
          "departmentid": corporateDetailsObj.departmentid,
          "customerid": "",
          "billabletype": "",
          "projectid": corporateDetailsObj.projectid,
          "autoexpense": "",
          "bookingfor": "official",
          "travelreason": "",
          "approvalid": "",
          "txid": "",
          "employeeid": "",
          "bookedbyid": ""
        },
        "carrecommendation": {
          "sno": 1,
          "startLocation": {
            "locationname": cabDetailsObj.pickup,
            "locationtype": "",
            "locationcode": "",
            "googleplaceid": "",
            "location_lat": "",
            "location_lon": "",
            "state": ""
          },
          "endLocation": {
            "locationname": cabDetailsObj.destination,
            "locationtype": "",
            "locationcode": "",
            "googleplaceid": "",
            "location_lat": "",
            "location_lon": "",
            "state": ""
          },
          "startdate": cabDetailsObj.pickupdate,
          "starttime": cabDetailsObj.pickuptime,
          "enddate": cabDetailsObj.dropdate,
          "endtime": cabDetailsObj.droptime,
          "duration": "",
          "supplierid": agentObj.supplierid,
          "nightcharges": 0,
          "tgfulfilled": true,
          "inpolicy": true,
          "approvalrequired": false,
          "policyid": "",
          "policyaction": "allowedwithreason",
          "drivercharges": 0,
          "kms": 0,
          "totalfare": fareObj.basefare,
          "totaldiscount": 0,
          "servicefee": fareObj.servicefee,
          "servicetax": fareObj.tax,
          "totalnet": fareObj.totalinvoice,
          "supplier": "",
          "tag": "",
          "cartype": cabDetailsObj.cartype,
          "carname": cabDetailsObj.carname,
          "triptype": cabDetailsObj.triptype,
          "tripdesc": cabDetailsObj.triptype,
          "owner": "",
          "inventoryid": "1"
        },
        "bookingtype": "b2e",
        "numadults": customerDetailsObj.numadults,
        "numchildren": customerDetailsObj.numchildren,
        "numinfants": customerDetailsObj.numinfants,
        "triptype": cabDetailsObj.triptype,
        "tripdesc": cabDetailsObj.triptype,
        "tripid": "",
        "paymentmode": "cashcard",
        "corporateid": agentObj.customerid,
        "promovalue": "",
        "promocode": "",
        "promosuccess": "false",
        "contact_email": agentObj.email,
        "contact_mobile": agentObj.mobile,
      }
    };

    let obj = { jsondata: JSON.stringify(jsondata) };
    let res = await offlineCabBooking(obj);
    let msg = `Failed to generate bill`;
    try {
      if (res.FlightBookingResponse.bookingstatus === 200) {
        msg = res.FlightBookingResponse.message;
      } else {
        msg = res.FlightBookingResponse.message;
      }
      alert(msg);
    } catch (error) {
      alert(msg);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h5" className="mb-8">
          Cab Offline Billing
        </Typography>
        <AgentContactInfo
          agentObj={this.state.agentObj}
          updateChange={this.updateStateObject}
        />
        <br />
        <CabDetails
          cabDetailsObj={this.state.cabDetailsObj}
          updateChange={this.updateCabDetailsObject}
        />
        <br />
        <CorporateDetails 
          detailsObj={this.state.corporateDetailsObj}
          customerId={this.state.customerId}
          updateChange={this.updateCorporateDetailsObject}
        />
        <br />
        <CabCustomers
          customerDetailsObj={this.state.customerDetailsObj}
          updateChange={this.updateCustomerDetailsObject}
        />
        <br />
        <FareBreakUp
          fareObj={this.state.fareObj}
          updateFare={this.updateFareObject}
        />
        <br />
        <div className="text-center">
          <Button
            onClick={this.handleGenerateBill}
            variant="contained"
            color="primary"
          >
            Generate Bill
          </Button>
        </div>
        <br />
        <br />
      </React.Fragment>
    );
  }
}

export default CabBillingMain;
