import React, { Component } from "react";
import { Paper, TextField, Grid, Typography, MenuItem, Hidden, Button } from "@material-ui/core";
import Travellers from "../../common/Travellers";
import { getCorporatesTravellerList } from "../../../services/user/UserService";

class CustomerDetails extends Component {
  tvlType = "";
  personalTravellersList = [];
  corporateTravellersList = [];
  constructor(props) {
    super(props);
    this.state = {
      customer: props.customerDetailsObj,
      isTvlOpen: false,
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.customerId && this.props.customerId !== prevProps.customerId) {
      await this.getMyTravellers(this.props.customerId);
    }
  }

  getMyTravellers = async (customerid) => {
    let reqData = {
      cid: customerid,
      pagenum: 0
    };
    this.corporateTravellersList = await getCorporatesTravellerList(reqData);
  };

  handleInputChange = event => {
    let obj = {
      title: "Mr",
      firstname: "",
      lastname: "",
      age: "",
      gender: "",
      paxtype: "CHD",
    };
    let adultObj = {
      ...obj,
      paxtype: "ADT",
      seatpref: "",
      seatno: "",
      coach: "",
      bedrollrequired: ""
    };
    let evtName = event.target.name;
    let evtValue = Number(event.target.value);
    switch (evtName) {
      case "numadults":
        evtValue = (evtValue > 6) ? 6 : ((evtValue < 1) ? 1 : evtValue);
        let arr = [...this.state.customer.adultsArr];
        if (arr.length > evtValue) {
          while (arr.length > evtValue) {
            arr.pop();
          }
        } else {
          while (arr.length < evtValue) {
            arr.push(adultObj);
          }
        }
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            numadults: evtValue,
            adultsArr: arr
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      case "numchildren":
        evtValue = (evtValue > 2) ? 2 : ((evtValue < 0) ? 0 : evtValue);
        let arr1 = [...this.state.customer.childrenArr];
        if (arr1.length > evtValue) {
          while (arr1.length > evtValue) {
            arr1.pop();
          }
        } else {
          while (arr1.length < evtValue) {
            arr1.push(obj);
          }
        }
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            numchildren: evtValue,
            childrenArr: arr1
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      default:
        break;
    }
  };

  handleFormChange = (event, index, type) => {
    switch (type) {
      case "adult":
        let data = [...this.state.customer.adultsArr];
        data[index][event.target.name] = event.target.value;
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            adultsArr: data
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      case "child":
        let eventValue = event.target.value;
        if(event.target.name === "age" && !isNaN(eventValue)){
          eventValue = (Number(eventValue) > 5) ? 5 : (Number(eventValue) <= 0) ? 1 : eventValue;
        }
        let data1 = [...this.state.customer.childrenArr];
        data1[index][event.target.name] = eventValue;
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            childrenArr: data1
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      default:
        break;
    }
  };

  createCustomerUI = (arr, type) => {
    return arr.map((item, index) => (
      <React.Fragment key={index}>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <Typography className="capitalize">
            {type} {index + 1}
            &nbsp;&nbsp;
            <Button
              title="Select traveller details"
              className="right"
              color="primary"
              size="small"
              onClick={() => this.pickTraveller(type, index)}
              disabled={!this.props.customerId}
            >
              Add Traveller
            </Button>
          </Typography>
        </Grid>
        <Grid item lg={1} md={1} sm={2} xs={6}>
          <TextField
            select
            fullWidth
            required
            name="title"
            variant="outlined"
            label="Title"
            className="select"
            InputLabelProps={{ shrink: true }}
            value={item.title}
            onChange={event => this.handleFormChange(event, index, type)}
          >
            <MenuItem value="Mr">Mr.</MenuItem>
            <MenuItem value="Mrs">Mrs.</MenuItem>
            <MenuItem value="Mstr">Mstr.</MenuItem>
            <MenuItem value="Ms">Ms.</MenuItem>
            <MenuItem value="Dr">Dr.</MenuItem>
            <MenuItem value="Fr">Fr.</MenuItem>
            <MenuItem value="Er">Er.</MenuItem>
            <MenuItem value="Capt">Capt.</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={12}>
          <TextField
            fullWidth
            required
            name="firstname"
            variant="outlined"
            label="First Name"
            InputLabelProps={{ shrink: true }}
            value={item.firstname}
            onChange={event => this.handleFormChange(event, index, type)}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={2} xs={6}>
          <TextField
            fullWidth
            required
            name="lastname"
            variant="outlined"
            label="Last Name"
            InputLabelProps={{ shrink: true }}
            value={item.lastname}
            onChange={event => this.handleFormChange(event, index, type)}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={6} xs={12}>
          <TextField
            fullWidth
            required
            name="age"
            variant="outlined"
            label="Age"
            InputLabelProps={{ shrink: true }}
            type="number"
            value={item.age}
            onChange={event => this.handleFormChange(event, index, type)}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={6} xs={12}>
          <TextField
            fullWidth
            select
            required
            name="gender"
            variant="outlined"
            label="Sex"
            className="select"
            InputLabelProps={{ shrink: true }}
            value={item.gender}
            onChange={event => this.handleFormChange(event, index, type)}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
          </TextField>
        </Grid>
        { type !== 'adult' && (<Hidden xsDown><Grid item lg={4} md={4} sm={6} xs={12}></Grid></Hidden>)}
        { type === 'adult' && (
          <React.Fragment>
            <Grid item lg={1} md={1} sm={6} xs={12}>
              <TextField
                fullWidth
                select
                required
                name="seatpref"
                variant="outlined"
                label="Berth Pref"
                className="select"
                InputLabelProps={{ shrink: true }}
                value={item.seatpref}
                onChange={event => this.handleFormChange(event, index, type)}
              >
                <MenuItem value="LB">LB - Lower</MenuItem>
                <MenuItem value="MB">MB - Middle</MenuItem>
                <MenuItem value="UB">UB - Upper</MenuItem>
                <MenuItem value="SL">SL - Side Lower</MenuItem>
                <MenuItem value="SU">SU - Side Uper</MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={1} md={1} sm={6} xs={12}>
              <TextField
                fullWidth
                required
                name="seatno"
                variant="outlined"
                label="Seat No"
                InputLabelProps={{ shrink: true }}
                value={item.seatno}
                onChange={event => this.handleFormChange(event, index, type)}
              />
            </Grid>
            <Grid item lg={1} md={1} sm={6} xs={12}>
              <TextField
                fullWidth
                required
                name="coach"
                variant="outlined"
                label="Coach"
                InputLabelProps={{ shrink: true }}
                value={item.coach}
                onChange={event => this.handleFormChange(event, index, type)}
              />
            </Grid>
            <Grid item lg={1} md={1} sm={6} xs={12}>
              <TextField
                fullWidth
                select
                required
                name="bedrollrequired"
                variant="outlined"
                label="Senior Citizen"
                className="select"
                InputLabelProps={{ shrink: true }}
                value={item.bedrollrequired}
                onChange={event => this.handleFormChange(event, index, type)}
              >
                <MenuItem value="no">No</MenuItem>
                <MenuItem value="yes">Yes</MenuItem>
              </TextField>
            </Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    ));
  };

  pickTraveller = (type, index) => {
    this.tvlType = type;
    this.tvlIndex = index;
    this.setState({
      isTvlOpen: true,
    });
  };

  closeTvl = () => {
    this.setState({
      isTvlOpen: false,
    });
  };

  handleTvlSelect = (tvl) => {
    const isAdult = this.tvlType === "adult";
    let data = isAdult ? [...this.state.customer.adultsArr] : [...this.state.customer.childrenArr];
    data[this.tvlIndex].title = tvl.title || tvl.ti;
    data[this.tvlIndex].firstname = tvl.firstname || tvl.fn;
    data[this.tvlIndex].lastname = tvl.lastname || tvl.ln;
    data[this.tvlIndex].gender = tvl.gen;
    this.setState(prevState => ({
      customer: {
        ...prevState.customer,
        ...(isAdult ? { adultsArr: data } : { childrenArr: data })
      },
      isTvlOpen: false,
    }),
      () => this.props.updateChange(this.state.customer)
    );
  };

  render() {
    return (
      <Paper className="padding-16 customer-details-form">
        <Typography variant="subtitle1" className="bold mb-8">
          Select Customer Details
        </Typography>
        <hr className="full-width" />
        <Grid container spacing={16}>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              fullWidth
              name="numadults"
              label="Adults"
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ inputMode: 'numeric', pattern: '[1-6]' }}
              value={this.state.customer.numadults}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              fullWidth
              name="numchildren"
              label="Children"
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-2]' }}
              value={this.state.customer.numchildren}
              onChange={this.handleInputChange}
            />
          </Grid>
        </Grid>
        <br />
        <Typography variant="subtitle2" className="bold mb-8">
          Passenger Info
        </Typography>
        <hr className="full-width" />
        <Grid container spacing={16}>
          {this.createCustomerUI(this.state.customer.adultsArr, "adult")}
          {this.createCustomerUI(this.state.customer.childrenArr, "child")}
        </Grid>
        {this.state.isTvlOpen && (
          <Travellers
            closeTvl={this.closeTvl}
            perList={this.personalTravellersList}
            corpList={this.corporateTravellersList}
            handleTvlSelect={this.handleTvlSelect}
            serviceConfig={this.serviceConfig}
            customerId={this.props.customerId}
          />
        )}
      </Paper>
    );
  }
}

export default CustomerDetails;
