import { apiPost } from "../common/CommonService";

export const getSupplierPurchase = async (txid) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getsupplierpurchase",
      txid: txid,
    };
    let res = await apiPost(reqObj);
    return !res.hasOwnProperty("null") ? res : undefined;
  } catch (err) {
    return undefined;
  }
};

export const getTransactionFareRules = async (txid) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "gettransactionfarerules",
      txid: txid,
    };
    let res = await apiPost(reqObj);
    return !res.hasOwnProperty("null") ? res : undefined;
  } catch (err) {
    return undefined;
  }
};

export const updateSupplierPurchase = async (obj) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatesupplierpurchase",
      ...obj,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const updateWebcheckinStatus = async (txid, passengerList, adminRemarks, flightReconfirmationStatus) => {
  try {
    let jsonObj = {
      PassengerRecordList: {
        txid: txid,
        remark: adminRemarks,
        flight_reconfirmation_status: flightReconfirmationStatus,
        passengers: [{
          PassengerRecord: passengerList
        }],
      }
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatewebcheckinstatus",
      jsondata: JSON.stringify(jsonObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const updateTransactionFareRules = async (obj) => {
  try {
    let jsonObj = {
      TransactionFareRules: {
        ...obj
      }
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatetransactionfarerules",
      jsondata: JSON.stringify(jsonObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getHotelReconfirmationDetailsByTxid = async (txid) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "gethotelreconfirmationdetailsbytxid",
      txid: txid,
    };
    let res = await apiPost(reqObj);
    if (res.list[0] !== "" && res.list[0].HotelReconfirmationDetail) {
      return Array.isArray(res.list[0].HotelReconfirmationDetail) ? res.list[0].HotelReconfirmationDetail : [res.list[0].HotelReconfirmationDetail];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const addHotelReconfirmationDetail = async (obj) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "addhotelreconfirmationdetail",
      ...obj,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const updateHotelReconfirmationDetail = async (obj) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatehotelreconfirmationdetail",
      ...obj,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};
