import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "react-select";
import CustomSnackBar from "../../common/CustomSnackBar";
import {
  getAllCorporateTravellers,
  addCardsToCorporate
} from "../../../services/inventory/CardsService";

class AddCard extends React.Component {
  cardObj = {};
  corporateList = [];
  constructor(props) {
    super(props);
    this.cardObj = props.card;
    this.corporateList = this.props.corporateList.map(x => {
      return {
        label: `${x.corporate_name} (${x.email})`,
        value: x.corporateid
      };
    });
    this.state = {
      cardsCount: 0,
      cardsList: [],
      corporateid: "",
      ctList: [],
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  handleChange = (event, index) => {
    let eve = { ...event };
    let allList = [...this.state.cardsList];
    let data = { ...allList[index] };
    data[eve.target.name] = eve.target.value;
    allList[index] = data;
    this.setState({
      cardsList: allList
    });
  };

  handleReset = () => {
    this.setState({
      card: {
        cardid: "",
        proxynumber: "",
        alias: "",
        provider: "",
        corporateid: "",
        addedon: "",
        addedby: "SUPER",
        status: "active",
        assignedto: "",
        masked_card: "",
        expirydate: ""
      }
    });
  };

  handleCountChange = event => {
    this.setState({
      cardsCount: event.target.value
    });
  };

  handleCorporateChange = async selectedOption => {
    this.cardObj.corporateid = selectedOption.value;
    this.setState(
      {
        corporateid: selectedOption.value
      },
      async () => await this.getCorporateTravellers()
    );
  };

  getCorporateTravellers = async () => {
    let res = await getAllCorporateTravellers(this.state.corporateid);
    let data = res.map(x => {
      return {
        name: `${x.title} ${x.first_name} ${x.last_name}`,
        email: x.email,
        profileid: x.profileid
      };
    });
    this.setState({
      ctList: data
    });
  };

  handleGenerate = () => {
    if (this.state.corporateid === "") {
      window.alert("Please select a corporate to generate cards");
    } else if (this.state.cardsCount <= 0) {
      window.alert("Cards count should be greater than zero");
    } else {
      let cards = [];
      for (var i = 0; i < this.state.cardsCount; i++) {
        cards.push(this.cardObj);
      }
      this.setState({
        cardsList: cards
      });
    }
  };

  showSnackBar(message, variant) {
    this.setState({
      snackBar: {
        isOpen: true,
        message: message,
        variant: variant
      }
    });
  }

  handleAddCard = async () => {
    let res = await addCardsToCorporate(this.state.cardsList);
    if (res.ResponseStatus.status === "OK") {
      this.showSnackBar(
        "Cards have been added to cards inventory..",
        "success"
      );
    } else {
      this.showSnackBar(
        "Failed to add cards to cards inventory. Please try again.",
        "error"
      );
    }
  };

  handleSnackBarClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={16}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography variant="h6"> Add Card </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Button
              variant="outlined"
              color="secondary"
              className="right"
              onClick={() => this.props.openCardDrawer(false)}
            >
              Close
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider />
            <br />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <Select
              className="mt-10"
              onChange={this.handleCorporateChange}
              defaultValue={this.state.corporateid}
              options={this.corporateList}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              fullWidth
              className="custom-input"
              name="cardsCount"
              type="number"
              label="Enter No. of Cards"
              variant="outlined"
              value={this.state.cardsCount}
              onChange={this.handleCountChange}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
            <Button
              className="generate-btn"
              variant="outlined"
              color="secondary"
              onClick={this.handleGenerate}
            >
              Generate Cards
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {this.state.cardsList.length > 0 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Card No.</TableCell>
                    <TableCell>Expiry Date</TableCell>
                    <TableCell>Proxy No.</TableCell>
                    <TableCell>Alias</TableCell>
                    <TableCell>Provider</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Assigned To</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.cardsList.map((card, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          className="custom-input"
                          fullWidth
                          name="masked_card"
                          variant="outlined"
                          placeholder="1234XXXXXXXX1234"
                          value={card.masked_card}
                          onChange={event => this.handleChange(event, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className="custom-input"
                          fullWidth
                          name="expirydate"
                          variant="outlined"
                          placeholder="YYYY-MM"
                          value={card.expirydate}
                          onChange={event => this.handleChange(event, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className="custom-input"
                          fullWidth
                          name="proxynumber"
                          variant="outlined"
                          placeholder="1234567890"
                          value={card.proxynumber}
                          onChange={event => this.handleChange(event, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className="custom-input"
                          fullWidth
                          name="alias"
                          variant="outlined"
                          placeholder="GCY12345"
                          value={card.alias}
                          onChange={event => this.handleChange(event, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className="custom-input"
                          fullWidth
                          name="provider"
                          variant="outlined"
                          placeholder="yespay"
                          value={card.provider}
                          onChange={event => this.handleChange(event, index)}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          select
                          className="custom-input"
                          fullWidth
                          name="status"
                          variant="outlined"
                          value={card.status}
                          onChange={event => this.handleChange(event, index)}
                        >
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="inactive"> InActive </MenuItem>
                        </TextField>
                      </TableCell>

                      <TableCell>
                        <TextField
                          select
                          className="custom-input"
                          fullWidth
                          name="assignedto"
                          variant="outlined"
                          value={card.assignedto}
                          onChange={event => this.handleChange(event, index)}
                        >
                          {this.state.ctList.map((c, i) => (
                            <MenuItem key={i} value={c.profileid + ""}>
                              <ListItemText
                                primary={c.name}
                                secondary={c.email}
                              />
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          {this.state.cardsList.length > 0 && (
            <Grid item lg={2} md={2} sm={2} xs={12}>
              <div>
                <br />
                <br />
                <Button onClick={this.handleReset} variant="outlined">
                  Reset
                </Button>
                &nbsp;&nbsp;
                <Button
                  onClick={this.handleAddCard}
                  variant="contained"
                  color="secondary"
                >
                  Add Card
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleSnackBarClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default AddCard;
