import React, { Component } from "react";
import ETicket from "../../../components/queues/flight/ETicket";
import "./FlightBookingTicketPage.css";

class FlightBookingTicketPage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <ETicket showPrintButton={true}/>
      </React.Fragment>
    );
  }
}

export default FlightBookingTicketPage;
