import React from "react";
import {
  Paper,
  Grid,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Hidden,
  Button,
  Popover,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { rollbackTicket } from "../../../services/queues/FlightQueuesService";
import { blockTicket } from "../../../services/common/CommonService";
import RollbackTransaction from "../common/RollbackTransaction";

class FlightQueueTable extends React.Component {
  selectedRecord = undefined;
  constructor(props) {
    super(props);
    this.state = {
      openPopover: false,
      anchorEl: null,
      isShowRollback: false,
      bookingQueues: props.bookingQueues,
      sortType: "asc"
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.bookingQueues !== prevProps.bookingQueues) {
      this.setState({
        bookingQueues: this.props.bookingQueues
      });
    }
  }

  handlePopoverClose = () => {
    this.setState({
      openPopover: false,
      anchorEl: null
    });
  };

  handleRedirect = (url, id) => {
    this.setState(
      {
        openPopover: false,
        anchorEl: null
      },
      () => {
        this.props.history.push({ pathname: `${url}/${id}` });
      }
    );
  };

  handleBlock = async (txId) => {
    let isConfirm = window.confirm(
      "Are you sure, Do you want to block the ticket?"
    );
    if (isConfirm === true) {
      this.props.showLoading(true);
      let reqObj = {
        txtype: "flight",
        txid: txId,
      };
      let res = await blockTicket(reqObj);
      if (res) {
        if (res.ApiStatus.Status === "OK") {
          this.props.showSnackBar(res.ApiStatus.Message, "success");
        } else {
          this.props.showSnackBar(res.ApiStatus.Message, "error");
        }
      }
      this.props.showLoading(false);
      this.handlePopoverClose();
    }
  };

  handleRollback = async data => {
    if (data) {
      this.props.showLoading(true);
      let res = await rollbackTicket(this.selectedRecord.txid, data);
      if (res) {
        if (res.ResponseStatus.status === "OK") {
          this.props.showSnackBar(res.ResponseStatus.message, "success");
        } else {
          this.props.showSnackBar(res.ResponseStatus.message, "error");
        }
      }
      this.closeRollbackDialog();
      this.props.showLoading(false);
      this.handlePopoverClose();
    }
  };

  handleSelect = (event, record) => {
    this.selectedRecord = record;
    this.setState({
      anchorEl: event.currentTarget,
      openPopover: true
    });
  };

  openRollbackDialog = () => {
    this.setState({
      isShowRollback: true
    });
  };

  closeRollbackDialog = () => {
    this.setState({
      isShowRollback: false
    });
  };

  sortBy = (property, type, sortType) => {
    let data = [...this.state.bookingQueues];
    if (type === "string") {
      if (this.state.sortType === "asc") {
        data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
        sortType = "desc";
      } else {
        data.sort((a, b) => (b[property] < a[property] ? -1 : 1));
        sortType = "asc";
      }
    } else {
      if (this.state.sortType === sortType) {
        data.sort((a, b) => new Date(b[property]) - new Date(a[property]));
        sortType = "desc";
      } else {
        data.sort((a, b) => new Date(a[property]) - new Date(b[property]));
        sortType = "asc";
      }
    }
    this.setState({
      bookingQueues: data,
      sortType: sortType
    });
  };

  getTags = (tags) => {
    if (tags) {
      return (<Typography variant="span" className="red-text-container">
          (<Typography variant="span" className="red-text">
            {tags}
          </Typography>)
        </Typography>);
    } else {
      return '';
    }
  }

  render() {
    return (
      <React.Fragment>
        <Hidden xsDown>
          <Paper className="padding-16">
            <Table className="queue-table">
              <TableHead>
                <TableRow>
                  <TableCell>TxID</TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("bookingdate", "date", "asc")}
                    >
                      BookingDate
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("tripid", "string", "asc")}
                    >
                      Tripid
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("agentid", "string", "asc")}
                    >
                      Booked By
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() =>
                        this.sortBy("passengername", "string", "asc")
                      }
                    >
                      Passenger Name
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("carrier", "date", "asc")}
                    >
                      Carrier/Supplier
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("jdate", "date", "asc")}
                    >
                      JDate/Sector
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("gds_pnr", "string", "asc")}
                    >
                      GDSPNR
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("airline_pnr", "string", "asc")}
                    >
                      APNR
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("booking_status", "string", "asc")}
                    >
                      Booking Status
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("payment_status", "string", "asc")}
                    >
                      Payment Status
                    </Button>
                  </TableCell>
                  <TableCell>BlockedBy</TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("issupplierpurchaseupdated", "string", "asc")}
                    >
                      QC Status
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("flight_reconfirmation_status", "string", "asc")}
                    >
                      Flight Reconfirmation Status
                    </Button>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.bookingQueues &&
                  this.state.bookingQueues.map((flightRecord, index) => (
                    <TableRow key={index}>
                      <TableCell>{flightRecord.txid}</TableCell>
                      <TableCell>{flightRecord.bookingdate}</TableCell>
                      <TableCell>
                        <Button
                          component="button"
                          variant="body2"
                          onClick={() => window.open(`/trip-ticket/${flightRecord.tripid}`, "_blank")}
                        >
                          {flightRecord.tripid}
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Typography>{flightRecord.agent_company}</Typography>
                        <Typography>({flightRecord.agentid})</Typography>
                      </TableCell>
                      <TableCell>
                        {`${flightRecord.passengername}
                        ${flightRecord.passengerlastname} `}{ this.getTags(flightRecord.tags)}
                      </TableCell>
                      <TableCell>
                        <Typography>{flightRecord.carrier}</Typography>
                        <Typography>{flightRecord.booking_provider}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{flightRecord.jdate}</Typography>
                        <Typography>
                          {flightRecord.origin} -> {flightRecord.destination}
                        </Typography>
                        <Typography variant="caption">
                          {flightRecord.jtype}
                        </Typography>
                      </TableCell>
                      <TableCell>{flightRecord.gds_pnr}</TableCell>
                      <TableCell>{flightRecord.airline_pnr}</TableCell>
                      <TableCell>{flightRecord.booking_status}</TableCell>
                      <TableCell>{flightRecord.payment_status}</TableCell>
                      <TableCell>
                        <Typography style={{ width: 80 }}>
                          {flightRecord.booking_status === "blocked"
                            ? flightRecord.additional
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell>{flightRecord.issupplierpurchaseupdated}</TableCell>
                      <TableCell>{flightRecord.flight_reconfirmation_status}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          className="blue-btn"
                          onClick={event =>
                            this.handleSelect(event, flightRecord)
                          }
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.bookingQueues &&
                  this.state.bookingQueues.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="14">No results found.</TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden smUp>
          {this.state.bookingQueues &&
            this.state.bookingQueues.map((flightRecord, index) => (
              <Paper className="mb-8 padding-16" key={index}>
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      TxID
                    </Typography>
                    <Typography>{flightRecord.txid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Booking Date
                    </Typography>
                    <Typography>{flightRecord.bookingdate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Tripid
                    </Typography>
                    <Typography>
                      <Button
                        component="button"
                        variant="body2"
                        onClick={() => window.open(`/trip-ticket/${flightRecord.tripid}`, "_blank")}
                      >
                        {flightRecord.tripid}
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Sector
                    </Typography>
                    <Typography>
                      {flightRecord.origin} -> {flightRecord.destination}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Carrier
                    </Typography>
                    <Typography>{flightRecord.carrier}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Journey Date
                    </Typography>
                    <Typography>{flightRecord.jdate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Passenger Name
                    </Typography>
                    <Typography>{`${flightRecord.passengername}
                        ${flightRecord.passengerlastname} `}{ this.getTags(flightRecord.tags)}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" className="caption">
                      Booking Status
                    </Typography>
                    <Typography>{flightRecord.booking_status}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" className="caption">
                      Payment
                    </Typography>
                    <Typography>{flightRecord.payment_status}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      QC Status
                    </Typography>
                    <Typography>{flightRecord.issupplierpurchaseupdated}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Flight Reconfirmation Status
                    </Typography>
                    <Typography>{flightRecord.flight_reconfirmation_status}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      size="small"
                      onClick={event => this.handleSelect(event, flightRecord)}
                      className="blue-btn"
                    >
                      Select
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          <Typography />
        </Hidden>
        <Popover
          id="flight-queue-popper"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Button
            onClick={() =>
              window.open(`/flight-ticket/${this.selectedRecord.txid}`, "_blank")
            }
          >
            Ticket
          </Button>
          <Button onClick={() => this.handleBlock(this.selectedRecord.txid)}>
            Block
          </Button>
          <Button
            onClick={() =>
              this.handleRedirect(
                "/update-flight-queue",
                this.selectedRecord.txid
              )
            }
          >
            Update
          </Button>
          <Button onClick={this.openRollbackDialog}>
            Rollback
          </Button>
        </Popover>
        {this.state.isShowRollback && (
          <RollbackTransaction
            handleClose={this.closeRollbackDialog}
            handleSubmit={this.handleRollback}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(FlightQueueTable);
