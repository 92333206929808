import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  DialogContentText,
} from "@material-ui/core";
import { withLoadingContext } from "../../common/LoadingProvider";
import { withCustomSnackBarContext } from "../../common/CustomSnackBarProvider";

class RollbackTransaction extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      remarks: ""
    };
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleRollbackSubmit = () => this.props.handleSubmit(this.state.remarks);

  render() {
    return (
      <React.Fragment>
        <Dialog
          open={true}
          aria-labelledby="rollback-transaction"
        >
          <DialogTitle>Rollback Transaction</DialogTitle>
          <hr className="full-width" />
          <DialogContent>
            <DialogContentText>
              Are you sure, Do you want to cancel this hotel transaction?
            </DialogContentText>
            <TextField
              name="remarks"
              label="Remarks"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Remarks"
              fullWidth
              variant="outlined"
              margin="normal"
              value={this.state.remarks}
              onChange={this.handleInputChange}
            />
          </DialogContent>
          <DialogActions className="left">
            <Button onClick={this.props.handleClose} color="secondary">
              Close
            </Button>
            <Button
              onClick={this.handleRollbackSubmit}
              variant="contained"
              color="primary"
              disabled={!this.state.remarks}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withLoadingContext(withCustomSnackBarContext(RollbackTransaction));
