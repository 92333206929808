import React, { Component } from "react";
import CustomSnackBar from "./CustomSnackBar";

const SnackBarContext = React.createContext();

export class CustomSnackBarProvider extends Component {
  state = {
    isOpen: false,
    message: "",
    variant: "",
    show: (message, variant) => {
      this.setState({
        message: message,
        variant: variant,
        isOpen: true
      });
    },
    hide: () => {
      this.setState({
        isOpen: false
      });
    }
  };
  render() {
    return (
      <SnackBarContext.Provider
        value={{
          ...this.state
        }}
      >
        {this.state.isOpen && (
          <CustomSnackBar
            isOpen={this.state.isOpen}
            message={this.state.message}
            variant={this.state.variant}
            onClose={() => this.state.hide()}
          />
        )}
        <>{this.props.children}</>
      </SnackBarContext.Provider>
    );
  }
}

// create the consumer as higher order component
// eslint-disable-next-line react/display-name
export const withCustomSnackBarContext = ChildComponent => props => (
  <SnackBarContext.Consumer>
    {context => <ChildComponent {...props} snackBarContext={context} />}
  </SnackBarContext.Consumer>
);
