import React, { Component } from "react";
import { Typography, Button } from "@material-ui/core";
import AgentContactInfo from "../common/AgentContactInfo";
import CorporateDetails from "../common/CorporateDetails";
import CustomerDetails from "./CustomerDetails";
import FareBreakUp from "../common/FareBreakUp";
import InsuranceDetails from "./InsuranceDetails";
import { offlineInsuranceBooking } from "../../../services/offline-billing/InsuranceOfflineService";

class InsuranceBillingMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      agentObj: {
        customerid: "",
        title: "Mr",
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        requestedby: "",
        supplierid: ""
      },
      insuranceDetailsObj: {
        insurance_name: "",
        policyissuedate: "",
        startdate: "",
        enddate: "",
        insurance_details: "",
        productdescription: "",
        jtype: "",
        adminremarks: ""
      },
      corporateDetailsObj: {
        costcenterid: "",
        departmentid: "",
        projectid: "",
      },
      customerDetailsObj: {
        numadults: 1,
        numchildren: 0,
        adultsArr: [
          {
            title: "Mr",
            firstname: "",
            lastname: "",
            gender: "",
            policynumber: "",
            paxtype: "Adult",
          }
        ],
        childrenArr: [],
      },
      fareObj: {
        basefare: 0,
        tax: 0,
        servicefee: 0,
        totalinvoice: 0,
      }
    };
  }

  updateCorporateDetailsObject = obj => {
    this.setState({
      corporateDetailsObj: obj
    });
  };

  updateInsuranceDetailsObject = obj => {
    this.setState({
      insuranceDetailsObj: obj
    });
  };

  updateCustomerDetailsObject = obj => {
    this.setState({
      customerDetailsObj: obj
    });
  };

  updateStateObject = obj => {
    this.setState({
      agentObj: obj,
      customerId: obj.customerid
    });
  };

  updateFareObject = obj => {
    this.setState({
      fareObj: obj
    });
  };

  handleGenerateBill = async () => {
    let passengers = [];
    let agentObjError = 0, customerDetailsObjError = 0, insuranceDetailsObjError = 0, fareObjError = 0;
    const alertMsg = "Please enter value for ";
    const { agentObj, insuranceDetailsObj, customerDetailsObj, corporateDetailsObj, fareObj } = this.state;

    Object.keys(agentObj).some(key => {
      if (agentObj[key] === "") {
        window.alert(alertMsg + key);
        agentObjError = 1;
        return true;
      }
      return false;
    });

    if(agentObjError === 1){
      return false;
    }

    Object.keys(customerDetailsObj).some(key => {
      const keyVal = customerDetailsObj[key];
      if (["adultsArr", "childrenArr"].includes(key) && Array.isArray(keyVal) && keyVal.length > 0) {
        for(let i=0; i<keyVal.length; i++){
          passengers.push({
            "TravelInsurancePassenger": { ...keyVal[i], profileid: "" }
          });
        }
      }
      
      if (customerDetailsObj[key] === "") {
        window.alert(alertMsg + key);
        customerDetailsObjError = 1;
        return true;
      }
      return false;
    });

    if(customerDetailsObjError === 1){
      return false;
    }

    Object.keys(insuranceDetailsObj).some(key => {
      if (insuranceDetailsObj[key] === "" || insuranceDetailsObj[key] === "select") {
        window.alert(alertMsg + key);
        insuranceDetailsObjError = 1;
        return true;
      }
      return false;
    });

    if(insuranceDetailsObjError === 1){
      return false;
    }

    Object.keys(fareObj).some(key => {
      if (fareObj[key] === "") {
        window.alert(alertMsg + key);
        fareObjError = 1;
        return true;
      }
      return false;
    });

    if(fareObjError === 1){
      return false;
    }

    const jsondata = {
      "TgInsuranceBookingRequest": {
        "passengers": passengers,
        "corporatedetails": {
          "corporateid": agentObj.customerid,
          "profileid": "",
          "tcbookingrequestid": "",
          "tcbookingid": "",
          "tcprofileid": "",
          "policyid": "",
          "inpolicy": "",
          "policy_reasonid": "",
          "policy_reason_remarks": "",
          "costcenterid": corporateDetailsObj.costcenterid,
          "departmentid": corporateDetailsObj.departmentid,
          "customerid": "",
          "billabletype": "",
          "projectid": corporateDetailsObj.projectid,
          "autoexpense": "",
          "bookingfor": "official",
          "travelreason": "",
          "approvalid": "",
          "txid": "",
          "triprequestid": "",
          "employeeid": "",
          "bookedbyid": ""
        },
        "insurancerecommendation": {
          "sno": 1,
          "insurance_name": insuranceDetailsObj.insurance_name,
          "insurance_details": insuranceDetailsObj.insurance_details,
          "startdate": insuranceDetailsObj.startdate,
          "enddate": insuranceDetailsObj.enddate,
          "policyissuedate": insuranceDetailsObj.policyissuedate,
          "productdescription": insuranceDetailsObj.productdescription,
          "processstatus": "pending",
          "adminremarks": insuranceDetailsObj.adminremarks,
          "supplierid": agentObj.supplierid,
          "requestedby": "",
          "tgfulfilled": true,
          "inpolicy": true,
          "approvalrequired": false,
          "policyid": "",
          "policyaction": "allowedwithreason",
          "totalfare": fareObj.basefare,
          "totaldiscount": 0,
          "servicefee": fareObj.servicefee,
          "servicetax": fareObj.tax,
          "totalnet": fareObj.totalinvoice,
          "inventoryid": "",
        },
        "bookingtype": "b2e",
        "jtype": insuranceDetailsObj.jtype,
        "numadults": customerDetailsObj.numadults,
        "numchildren": customerDetailsObj.numchildren,
        "numinfants": 0,
        "address": agentObj.address,
        "tripid": "",
        "promocode": "",
        "promovalue": "",
        "promosuccess": "false",
        "paymentmode": "cashcard",
        "pgname": "",
        "corporateid": agentObj.customerid,
        "contact_email": agentObj.email,
        "contact_mobile": agentObj.mobile,
        "tripitemid": "",
        "policyurl": "",
        "supplierinvoiceurl": ""
      }
    }

    let obj = { jsondata: JSON.stringify(jsondata) };
    let res = await offlineInsuranceBooking(obj);
    let msg = `Failed to generate bill`;
    try {
      if (res.FlightBookingResponse.bookingstatus === 200) {
        msg = res.FlightBookingResponse.message;
        window.location.reload();
      } else {
        msg = res.ApiStatus.Message;
      }
      alert(msg);
    } catch (error) {
      alert(msg);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h5" className="mb-8">
          Insurance Offline Billing
        </Typography>
        <AgentContactInfo
          agentObj={this.state.agentObj}
          updateChange={this.updateStateObject}
        />
        <br />
        <InsuranceDetails 
          detailObj={this.state.insuranceDetailsObj}
          updateChange={this.updateInsuranceDetailsObject}
        />
        <br />
        <CorporateDetails 
          detailsObj={this.state.corporateDetailsObj}
          customerId={this.state.customerId}
          updateChange={this.updateCorporateDetailsObject}
        />
        <br />
        <CustomerDetails 
          customerDetailsObj={this.state.customerDetailsObj}
          updateChange={this.updateCustomerDetailsObject}
        />
        <br />
        <FareBreakUp
          fareObj={this.state.fareObj}
          updateFare={this.updateFareObject}
        />
        <br />
        <div className="text-center">
          <Button
            onClick={this.handleGenerateBill}
            variant="contained"
            color="primary"
          >
            Generate Bill
          </Button>
        </div>
        <br />
        <br />
      </React.Fragment>
    );
  }
}

export default InsuranceBillingMain;
