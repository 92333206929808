import moment from "moment";
import { apiPost, getUserProfile } from "../common/CommonService";

export const getAllCorporates = async (pagenum) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getallcorporates",
      pagenum: pagenum
    };
    let res = await apiPost(reqObj);
    if (res.list[0].ProfileMaster) {
      return Array.isArray(res.list[0].ProfileMaster) ? res.list[0].ProfileMaster: [res.list[0].ProfileMaster];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getCorporatesTravellerList = async (reqdata) => {
  try {
    let reqObj = {
      opid: "TG-GETCORPORATETRAVELLERSLITE",
      corporateid: reqdata.cid,
      pagenum: reqdata.pagenum
    };
    let res = await apiPost(reqObj);
    if (res.ProfileCollection.profiles[0].ProfileLite) {
      return Array.isArray(res.ProfileCollection.profiles[0].ProfileLite) ? res.ProfileCollection.profiles[0].ProfileLite : [res.ProfileCollection.profiles[0].ProfileLite];
    } else {
      return [];
    }
  }
  catch (err) {
    return [];
  }
};

export const getCorporateCompleteProfile = async (ids) => {
  try {
    let reqObj = {
      opid: "TG-GETCOMPLETEPROFILE",
      corporateid: ids.cid,
      profileid: ids.pid
    };
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    } else {
      return res;
    }
  } 
  catch (err) {
    return undefined;
  }
};

export const getUserSettings = async (cid) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getusersettings",
      corporateid: cid
    };
    let res = await apiPost(reqObj);
    if (res.list[0].UserAdditionalSetting) {
      return Array.isArray(res.list[0].UserAdditionalSetting) ? res.list[0].UserAdditionalSetting: [res.list[0].UserAdditionalSetting];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const addUserSettings = async (reqdata) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "addusersetting",
      corporateid: reqdata.cid,
      settingkey: reqdata.user_key,
      settingvalue: reqdata.user_value
    };
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    } else {
      return res;
    }
  } 
  catch (err) {
    return undefined;
  }
};

export const getUserAccount = async (cid) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getuseraccount",
      corporateid: cid
    };
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    } else {
      return res;
    }
  } 
  catch (err) {
    return undefined;
  }
};

export const setCreditLimit = async (reqdata) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "setcreditlimit",
      corporateid: reqdata.cid,
      credit_limit: reqdata.credit_limit
    };
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    } else {
      return res;
    }
  } 
  catch (err) {
    return undefined;
  }
};

export const addACorporateProfile = async obj => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "addtraveller",
      jsondata: JSON.stringify({
        "ProfileMaster": {
          ...obj,
          corporateid: getUserProfile().corporateid,
          birthdate: obj.birthdate ? moment(obj.birthdate).format("YYYY-MM-DD") : "",
        },
      }),
    };
    
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    } else {
      return res;
    }
  } catch (error) {
    return undefined;
  }
};
