import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import ListRuleMasters from "../../components/rulemasters/ListRuleMasters";
import "./RuleMastersPage.css";

class RuleMastersPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Paper className="padding-16">
          <ListRuleMasters />
        </Paper>
      </React.Fragment>
    );
  }
}

export default RuleMastersPage;
