import React, { Component } from "react";
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Paper,
} from "@material-ui/core";

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchObj: {
        searchvalue: "",
        searchkey: "pnr",
        finyear: "all",
      }
    };
  }

  handleInputChange = (event) => {
    let eve = { ...event };
    this.setState(prevState => ({
      searchObj: {
        ...prevState.searchObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  render() {
    return (
      <Paper className="padding-16">
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              name="searchkey"
              label="Search By"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Search By"
              select
              fullWidth
              variant="outlined"
              value={this.state.searchObj.searchkey}
              onChange={this.handleInputChange}
            >
              <MenuItem value="pnr">PNR</MenuItem>
              <MenuItem value="txid">Transaction</MenuItem>
              <MenuItem value="tripid">Trip Id</MenuItem>
              <MenuItem value="approvalid">Approval Id</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              name="finyear"
              label="Financial Year"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Financial Year"
              select
              fullWidth
              variant="outlined"
              value={this.state.searchObj.finyear}
              onChange={this.handleInputChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="2018-19">2018-19</MenuItem>
              <MenuItem value="2019-20">2019-20</MenuItem>
              <MenuItem value="2020-21">2020-21</MenuItem>
              <MenuItem value="2021-22">2021-22</MenuItem>
              <MenuItem value="2022-23">2022-23</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              name="searchvalue"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Search"
              fullWidth
              variant="outlined"
              value={this.state.searchObj.searchvalue}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.props.doSearch(this.state.searchObj)}
              disabled={!this.state.searchObj.searchvalue}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default SearchForm;
