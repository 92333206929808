import moment from "moment";
import { apiPost } from "../common/CommonService";

export const getReceiptTransactions = async (obj={}) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getreceipttransactions",
      fromdate: moment(obj.from).format("YYYY-MM-DD"),
      todate: moment(obj.to).format("YYYY-MM-DD")
    };

    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null") || res.list[0] === "") {
      return undefined;
    } else {
      return res.list[0];
    }
  } catch (err) {
    return undefined;
  }
};

export const raiseReceipt = async (obj={}) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "raisereceipt",
      ...obj,
    };

    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null") || res.list[0] === "") {
      return undefined;
    } else {
      return res.list[0];
    }
  } catch (err) {
    return undefined;
  }
};

export const getReceiptByTxid = async txid => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getreceiptbytxid",
      txid: txid,
    };
  
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getPendingReceiptQueues = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getpendingreceiptqueues",
    };
    
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null") || res.list[0] === "") {
      return undefined;
    } else {
      return res.list[0];
    }
  } catch (err) {
    return undefined;
  }
};

export const approveReceipt = async (obj={}) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "approvereceipt",
      ...obj,
    };

    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};
