import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { getUserProfile, uploadLpoAttachment } from "../../../services/common/CommonService";
import {
  getTrainTicket,
  updateBusOrTrainQueueBooking
} from "../../../services/queues/TrainQueuesService";
import { Loading } from "../../../components/common/Loading";
import CustomSnackBar from "../../../components/common/CustomSnackBar";

class UpdateTrainBookingQueuePage extends Component {
  userProfile = undefined;
  transactionId = undefined;
  lpoReceiprtUrl = undefined;
  uploadFile = undefined;
  updateObj = {
    TrainBookingUpdate: {
      txid: "",
      pnr: "",
      ticketnumber: "",
      supplier_id: "",
      adminremarks: "",
      original_eticket_url: "",
      trainpax: [
        {
          TrainPassenger: []
        }
      ]
    }
  };
  constructor(props) {
    super(props);
    this.userProfile = getUserProfile();
    this.transactionId = window.location.pathname.split("/").pop();
    this.updateObj.TrainBookingUpdate.txid = this.transactionId;
    this.state = {
      trainInvoice: undefined,
      passengers: [],
      isLoading: false,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      },
      original_eticket_url: "",
      pnr: "",
      ticketnumber: "",
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    const data = await getTrainTicket(this.transactionId);
    const trainInvoice = data.TrainInvoice;
    const passengers = Array.isArray(trainInvoice.passengers[0].TrainPassenger)
      ? trainInvoice.passengers[0].TrainPassenger
      : [trainInvoice.passengers[0].TrainPassenger];
    this.setState({
      passengers,
      trainInvoice,
      isLoading: false,
      pnr: trainInvoice.trainrecord.pnr,
      ticketnumber: trainInvoice.trainrecord.ticketnumber,
      original_eticket_url: trainInvoice.trainrecord.original_eticket_url,
    });
  }

  handleChange = (event, index) => {
    const { name, value } = event.target;
    let passengers = [...this.state.passengers];
    passengers[index][name] = value;
    this.setState({
      passengers
    });
  };

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  handleUpdateQueue = async () => {
    this.updateObj.TrainBookingUpdate.trainpax[0].TrainPassenger = this.state.passengers;
    this.updateObj.TrainBookingUpdate.pnr = this.state.pnr;
    this.updateObj.TrainBookingUpdate.ticketnumber = this.state.ticketnumber;
    this.updateObj.TrainBookingUpdate.original_eticket_url = this.lpoReceiprtUrl ? this.lpoReceiprtUrl : "";
    const response = await updateBusOrTrainQueueBooking(
      this.updateObj,
      "updatetrainbooking"
    );
    if (response.ResponseStatus.status === "OK") {
      this.setState({
        snackBar: {
          isOpen: true,
          message: response.ResponseStatus.message,
          variant: "success"
        }
      });
    } else {
      this.setState({
        snackBar: {
          isOpen: true,
          message: response.ResponseStatus.message,
          variant: "error"
        }
      });
    }
  };

  handleUploadReceipt = event => {
    var eve = { ...event };
    this.uploadFile = eve.target.files[0];
  };

  handleUploadAction = async () => {
    this.setState({
      isLoading: true
    });
    if (this.uploadFile !== undefined) {
      let formData = new FormData();
      formData.append("authtoken", "");
      formData.append("corporateid", this.userProfile.corporateid);
      formData.append("expenseid", "");
      formData.append("fullpath", this.uploadFile, this.uploadFile.name);
      formData.append("name", "lpoemail");
      formData.append("profileid", this.userProfile.profileid);
      formData.append("receiptname", "");
      formData.append("reportid", "");
      const uploadResponse = await uploadLpoAttachment(formData);
      this.lpoReceiprtUrl = uploadResponse.ApiStatus.Status === "OK" ? uploadResponse.ApiStatus.Result : undefined;
      if (this.lpoReceiprtUrl === undefined) {
        this.setState({
          snackBar: {
            isOpen: true,
            message: "Failed to upload the attachment. Please try again.",
            variant: "error"
          }
        });
      } else {
        this.setState({
          snackBar: {
            isOpen: true,
            message: "Attachment has been uploaded successfully.",
            variant: "success"
          }
        });
      }
    } else {
      this.setState({
        snackBar: {
          isOpen: true,
          message: "Please browse attachment and click on upload button",
          variant: "error"
        }
      });
    }
    this.setState({
      isLoading: false
    });
  };

  render() {
    const { trainInvoice } = this.state;
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        {this.state.trainInvoice ? (
          <React.Fragment>
            <Grid container spacing={16} className="mb-16">
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="caption">Origin</Typography>
                <Typography>{trainInvoice.trainrecord.fromstnname}</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="caption">Destination</Typography>
                <Typography>{trainInvoice.trainrecord.tostnname}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography variant="caption">Train Name</Typography>
                <Typography>
                  {trainInvoice.trainrecord.trainname} (
                  {trainInvoice.trainrecord.trainnumber})
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography>Transaction Id</Typography>
                <Typography>{trainInvoice.trainrecord.txid}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography>Journey Date</Typography>
                <Typography>{trainInvoice.trainrecord.jdate}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography>Pax. Name</Typography>
                <Typography>{trainInvoice.trainrecord.paxname}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography>Pax. EMail</Typography>
                <Typography>{trainInvoice.trainrecord.paxemail}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography>Amount</Typography>
                <Typography>{trainInvoice.trainrecord.totalfare}</Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography>Booking Status</Typography>
                <Typography>
                  {trainInvoice.trainrecord.bookingstatus}
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <Typography>Booking Date</Typography>
                <Typography>{trainInvoice.trainrecord.bookingdate}</Typography>
              </Grid>
            </Grid>
            {this.state.passengers.map((p, i) => (
              <Grid container spacing={16} className="mb-16 mt-8" key={i}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography>
                    {p.title} {p.firstname} {p.lastname}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="paxsno"
                    label="Pax. No"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={p.paxsno}
                    onChange={event => this.handleChange(event, i)}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="coach"
                    label="Coach"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={p.coach}
                    onChange={event => this.handleChange(event, i)}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    fullWidth
                    name="seatno"
                    label="Seat No"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={p.seatno}
                    onChange={event => this.handleChange(event, i)}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    fullWidth
                    label="Berth"
                    InputLabelProps={{ shrink: true }}
                    name="allotedberth"
                    variant="outlined"
                    value={p.allotedberth}
                    onChange={event => this.handleChange(event, i)}
                  />
                </Grid>
              </Grid>
            ))}
            {this.state.passengers && (
            <Paper className="padding-16">
              <Grid container spacing={16} className="mb-16 mt-8">
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="pnr"
                    label="PNR"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={this.state.pnr}
                    onChange={event => this.handleInputChange(event)}
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <TextField
                    name="ticketnumber"
                    label="Ticket Number"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={this.state.ticketnumber}
                    onChange={event => this.handleInputChange(event)}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Hidden smUp>
                    <br />
                  </Hidden>
                  <div>
                    <Typography variant="h6" className="booking-header-title">
                      &nbsp; &nbsp;Upload ETicket
                    </Typography>
                  </div>
                  <Paper className="padding-16">
                    <input name="lpofile" id="lpofile" type="file" onChange={this.handleUploadReceipt} />&nbsp;&nbsp;
                    <Button onClick={this.handleUploadAction} variant="contained" color="secondary" size="small">
                      Upload
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
            )}
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleUpdateQueue}
            >
              Update Queue
            </Button>
          </React.Fragment>
        ) : (
          <div>
            <Typography align="center" variant="h6">
              No booking found for the Transaction No: {this.transactionId}
            </Typography>
          </div>
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default UpdateTrainBookingQueuePage;
