import { apiPost, getUserProfile } from "../common/CommonService";

export const getPendingSmartScans = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "viewallreceiptssmartscan",
      corporateid: getUserProfile().corporateid,
      profileid: getUserProfile().profileid
    };
    let res = await apiPost(reqObj);
    return Array.isArray(res.list[0].ExpenseReceipt)
      ? res.list[0].ExpenseReceipt
      : [res.list[0].ExpenseReceipt];
  } catch (err) {
    return [];
  }
};

export const getExpenseReports = async (profileId, corporateId) => {
  try {
    let reqObj = {
      status: "all",
      opid: "TG-EXPENSE",
      action: "viewreportsprofilecorpstatus",
      profileid: profileId,
      corporateid: corporateId
    };
    let res = await apiPost(reqObj);
    if (res && res.list && res.list[0] !== "")
      return Array.isArray(res.list[0].ExpenseReport)
        ? res.list[0].ExpenseReport
        : [res.list[0].ExpenseReport];
    return [];
  } catch (error) {
    return [];
  }
};

export const getExpenseCategories = async corporateId => {
  try {
    let reqObj = {
      opid: "TG-EXPENSE",
      action: "viewcategories",
      corporateid: corporateId
    };
    let res = await apiPost(reqObj);
    if (
      res &&
      res.list &&
      res.list[0] !== "" &&
      Array.isArray(res.list[0].Category)
    )
      return res.list[0].Category;
    return [];
  } catch (error) {
    return [];
  }
};

export const getBillableCustomers = async corporateId => {
  try {
    let reqObj = {
      opid: "TG-EXPENSE",
      action: "getbillablecustomers",
      corporateid: corporateId
    };
    let res = await apiPost(reqObj);
    if (res.list[0] === "") {
      return [];
    } else {
      return Array.isArray(res.list[0].BillableCustomer)
        ? res.list[0].BillableCustomer
        : [res.list[0].BillableCustomer];
    }
  } catch (error) {
    return [];
  }
};

export const saveAnExpense = async (obj) => {
  try {
    let reqObj = {
      opid: "TG-EXPENSE",
      action: "editsmartscanexpense",
      status: "pending",
      resultformat: "JSON",
      profileid: obj.profileid,
      receiptid:obj.receiptid,
      corporateid: obj.corporateid,
      jsondata: JSON.stringify({ Expense: obj })
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (error) {
    return undefined;
  }
};
