import React, { Component } from "react";
import { Typography } from "@material-ui/core";

class HomePage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <Typography> HomePage </Typography>
      </React.Fragment>
    );
  }
}

export default HomePage;
