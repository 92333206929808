import React, { Component } from "react";
import { Typography, Grid, Paper, TextField, Button } from "@material-ui/core";
import {
  getFlightInvoice,
  getSuppliers,
  updateFlightFare,
} from "../../../services/queues/FlightQueuesService.js";
import { Loading } from "../../../components/common/Loading";
import { formatTimeString } from "../../../components/common/Utils.js";
import CustomSnackBar from "../../../components/common/CustomSnackBar";

class FlightFareUpdatePage extends Component {
  flight = undefined;
  profile = undefined;
  txId = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      flightInvoice: undefined,
      suppliers: undefined,
      snackBar: {
        isOpen: false,
        message: "",
        variant: "",
      },
      updateObj: {
        onwardSupplier: "",
        returnSupplier: "",
      },
      isRoundTrip: false,
      onwardSectors: undefined,
      returnSectors: undefined,
      travellers: undefined,
      flightTickets: undefined,
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let sups = await getSuppliers();
    this.txId = window.location.pathname.split("/").pop();
    let data = "",
      os = [],
      rs = [];
    let res = await getFlightInvoice(this.txId);
    if (res) {
      data = res;
      this.flight = res.flightRecord;
      data.flightTickets[0].FlightTicket = Array.isArray(
        data.flightTickets[0].FlightTicket
      )
        ? data.flightTickets[0].FlightTicket
        : [data.flightTickets[0].FlightTicket];
      data.sectorRecords[0].SectorRecord = Array.isArray(
        data.sectorRecords[0].SectorRecord
      )
        ? data.sectorRecords[0].SectorRecord
        : [data.sectorRecords[0].SectorRecord];
      data.passengerRecords[0].PassengerRecord = Array.isArray(
        data.passengerRecords[0].PassengerRecord
      )
        ? data.passengerRecords[0].PassengerRecord
        : [data.passengerRecords[0].PassengerRecord];
      os = data.sectorRecords[0].SectorRecord.filter((x) => x.tripnumber === 1);
      rs = data.sectorRecords[0].SectorRecord.filter((x) => x.tripnumber === 2);
      // this.travellers = res.passengerRecords[0].PassengerRecord;
      this.profile = data.promas;
      this.gstdetails = data.gstdetails;
      let bp = this.flight.booking_provider.split(",");
      let osn = bp[0];
      let rsn = bp[1] === undefined ? osn : bp[1];
      this.setState({
        isLoading: false,
        suppliers: sups,
        flightInvoice: data,
        isRoundTrip: data.flightRecord.jtype === "OneWay" ? false : true,
        onwardSectors: os,
        returnSectors: rs,
        travellers: data.passengerRecords[0].PassengerRecord,
        flightTickets: data.flightTickets[0].FlightTicket,
        gstdetails: data.gstdetails,
        adminremarks: "",
        updateObj: {
          gds_pnr: this.flight.gds_pnr,
          onwardSupplier: osn,
          returnSupplier: rsn,
        },
      });
    } else {
      this.setState({
        isLoading: false,
        flightInvoice: undefined,
      });
    }
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  handelChange = (event) => {
    let { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (event, tripNumber, index) => {
    let eve = { ...event };
    let data = [...this.state.travellers];
    data[index][eve.target.name] = eve.target.value;
    this.setState({
      travellers: data,
    });
  };

  handleQueueUpdate = async () => {
    this.setState({
      isLoading: true,
    });
    let fares = [];
    this.state.travellers.forEach((traveller, index) => {
      fares.push({
        txid: this.txId,
        paxid: traveller.paxid,
        paxtype: "Adult",
        fare_componentid: 1,
        amount: traveller.base_fare,
        taxcode: "BSFR",
      });
      fares.push({
        txid: this.txId,
        paxid: traveller.paxid,
        paxtype: "Adult",
        fare_componentid: 2,
        amount: traveller.tax,
        taxcode: "TOTTX",
      });
      fares.push({
        txid: this.txId,
        paxid: traveller.paxid,
        paxtype: "Adult",
        fare_componentid: 3,
        amount: traveller.promo_discount,
        taxcode: "TGDISC",
      });
      fares.push({
        txid: this.txId,
        paxid: traveller.paxid,
        paxtype: "Adult",
        fare_componentid: 4,
        amount: traveller.servicetax,
        taxcode: "TGSTX",
      });
      fares.push({
        txid: this.txId,
        paxid: traveller.paxid,
        paxtype: "Adult",
        fare_componentid: 5,
        amount: traveller.tds,
        taxcode: "TGTDS",
      });
      fares.push({
        txid: this.txId,
        paxid: traveller.paxid,
        paxtype: "Adult",
        fare_componentid: 6,
        amount: traveller.yq,
        taxcode: "YQ",
      });
      fares.push({
        txid: this.txId,
        paxid: traveller.paxid,
        paxtype: "Adult",
        fare_componentid: 15,
        amount: traveller.handlingcharges,
        taxcode: "TGHC",
      });
      fares.push({
        txid: this.txId,
        paxid: traveller.paxid,
        paxtype: "Adult",
        fare_componentid: 45,
        amount: traveller.ssramount,
        taxcode: "TGSSR",
      });
    });

    let res = await updateFlightFare(this.txId, fares, this.state.adminremarks);
    let msg = "";
    let type = "success";
    if (res.ResponseStatus.status === "OK") {
      msg = res.ResponseStatus.message;
    } else {
      msg =  res.ResponseStatus.message;
      type = "error";
    }
    this.setState({
      isLoading: false,
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type,
      },
    });
  };

  getSectorsUI = (sectors, tripNumber) => {
    return sectors.map((sector, idx) => (
      <React.Fragment key={idx}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="subtitle1">
            <strong> Sector: {sector.sector_number + 1}</strong>
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <Typography variant="caption">Origin &amp; Destination</Typography>
          <Typography>
            {sector.origin} {"->"} {sector.destination}
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Typography variant="caption">Carrier</Typography>
          <Typography>
            {sector.carrier}
            {sector.flight_number}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={6}>
          <Typography variant="caption">Departure</Typography>
          <Typography>
            {sector.jdate} @ {formatTimeString(sector.departure_time)}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={6}>
          <Typography variant="caption">Arrival</Typography>
          <Typography>
            {sector.arrdate} @ {formatTimeString(sector.arrival_time)}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={8}>
            {this.state.travellers.map(
              (traveller, index) =>
                traveller.trip_number === tripNumber && (
                  <React.Fragment key={index}>
                    <Grid item lg={3} md={3} sm={3} xs={6}>
                      <Typography>{`${traveller.first_name} ${traveller.last_name}`}</Typography>
                      <Typography>{traveller.type}</Typography>
                      <Typography>{traveller.dob}</Typography>
                    </Grid>

                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Base Fare"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        name="base_fare"
                        type="number"
                        value={traveller.base_fare}
                        onChange={(event) =>
                          this.handleInputChange(event, tripNumber, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Tax Fare"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        name="tax"
                        value={traveller.tax}
                        onChange={(event) =>
                          this.handleInputChange(event, tripNumber, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Discount Fare"
                        type="number"
                        name="promo_discount"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.promo_discount}
                        onChange={(event) =>
                          this.handleInputChange(event, tripNumber, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="TDS Fare"
                        type="number"
                        name="tds"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.tds}
                        onChange={(event) =>
                          this.handleInputChange(event, tripNumber, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Service Tax Fare"
                        type="number"
                        name="servicetax"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.servicetax}
                        onChange={(event) =>
                          this.handleInputChange(event, tripNumber, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="YQ Fare"
                        type="number"
                        name="yq"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.yq}
                        onChange={(event) =>
                          this.handleInputChange(event, tripNumber, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="Handling Charge"
                        name="handlingcharges"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.handlingcharges}
                        onChange={(event) =>
                          this.handleInputChange(event, tripNumber, index)
                        }
                      />
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={6}>
                      <TextField
                        label="SSR Amount"
                        name="ssramount"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={traveller.ssramount}
                        onChange={(event) =>
                          this.handleInputChange(event, tripNumber, index)
                        }
                      />
                    </Grid>
                  </React.Fragment>
                )
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    ));
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={4} xs={8}>
            <Typography variant="h6" className="mb-8">
              Update Flight Fare
            </Typography>
          </Grid>
          <Grid item lg={9} md={9} sm={8} xs={4}>
            {this.state.flightInvoice && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  const url = `/flight-ticket/${this.txId}`;
                  window.open(url, "_blank");
                }}
              >
                View Ticket
              </Button>
            )}
          </Grid>
        </Grid>

        {this.state.flightInvoice && (
          <Grid container spacing={16}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={8}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6">Ticket Details</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Transaction Id</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.txid}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Journey Type</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.jtype}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Booking Date</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.bookingdate}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Agent Id</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.agentid}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Pax. Name</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{`${this.flight.passengername} ${this.flight.passengerlastname}`}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Pax. Contact</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.passengercontact}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Pax. Email</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.flight.passengeremail}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={8}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6">Customer Details</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Customer Name</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{`${this.profile.first_name} ${this.profile.last_name}`}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Company</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.profile.corporate_name}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Contact</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.profile.phone}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Email ID</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.profile.email}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={8}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6">GST Details</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>GST Number</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.gstdetails.gstnumber}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Company</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.gstdetails.gstcompany}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Phone</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.gstdetails.gstphone}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>Email ID</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.gstdetails.gstemail}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>GST Address</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography>{this.gstdetails.gstaddress}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Typography variant="h6"> Onward Journey </Typography>
                <br />
                <Grid container spacing={8}>
                  {this.getSectorsUI(this.state.onwardSectors, 1)}
                </Grid>
              </Paper>
            </Grid>
            {this.state.isRoundTrip === true && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Paper className="padding-16">
                  <Typography variant="h6"> Return Journey </Typography>
                  <br />
                  <Grid container spacing={8}>
                    {this.getSectorsUI(this.state.returnSectors, 2)}
                  </Grid>
                </Paper>
              </Grid>
            )}

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className="padding-16">
                <Grid container spacing={16}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      name="adminremarks"
                      value={this.state.adminremarks}
                      variant="outlined"
                      label="Admin Remarks"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.handelChange}
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleQueueUpdate}
                >
                  Update Fare
                </Button>
              </Paper>
            </Grid>
          </Grid>
        )}
        {this.state.flightInvoice === undefined && (
          <Paper className="padding-16">
            <div>
              <Typography align="center" variant="h6">
                No data found for the Transaction No: {this.txId}
              </Typography>
            </div>
          </Paper>
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default FlightFareUpdatePage;
