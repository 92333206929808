import React, { Component } from "react";
import { Typography, TextField, Grid } from "@material-ui/core";
import UserTravellerList from "../../components/user/UserTravellerList";
import { getCorporatesTravellerList } from "../../services/user/UserService";
import { Loading } from "../../components/common/Loading";
import "./UserPage.css";
import CustomSnackBar from "../../components/common/CustomSnackBar";
import { debounce } from "../../components/common/Utils";

class UserTravellerListPage extends Component {
  corpid = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      travellerList: undefined,
      filteredList: undefined,
      pagenum: 0,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  async componentDidMount() {
    this.corpid = window.location.pathname.split("/").pop();
    await this.getTravellersList(this.state.pagenum);
  }

  getTravellersList = async (pnum) => {
    this.setState({
      isLoading: true,
      pagenum: pnum
    });
    let reqData = {
      cid: this.corpid,
      pagenum: pnum
    }
    let res = await getCorporatesTravellerList(reqData);
    this.setState({
      isLoading: false,
      travellerList: res,
      filteredList: res,
    });
  };

  showSnackBar = (msg, variant) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: variant
      }
    });
  };

  showLoading = isLoading => {
    this.setState({
      isLoading: isLoading
    });
  };

  handleChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.travellerList.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredList: data
    });
  }, 500);

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography variant="h6" className="mb-8">
              Traveller's Profiles
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              variant="outlined"
              className="search-field mb-8"
              name="searchTerm"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>

        {this.state.filteredList && (
          <UserTravellerList
            showLoading={this.showLoading}
            showSnackBar={this.showSnackBar}
            travellersList={this.state.filteredList}
            getTravellersListFn= {this.getTravellersList}
            pagenum= {this.state.pagenum}
          />
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default UserTravellerListPage;

