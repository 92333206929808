import React, { Component } from "react";
import { Paper, TextField, Grid, Typography, MenuItem } from "@material-ui/core";

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: props.customerDetailsObj,
    };
  }

  handleInputChange = event => {
    let obj = {
      title: "Mr",
      firstname: "",
      lastname: "",
      gender: "",
      policynumber: "",
      paxtype: "Adult",
    };
    let evtName = event.target.name;
    let evtValue = Number(event.target.value);
    switch (evtName) {
      case "numadults":
        evtValue = (evtValue > 9) ? 9 : ((evtValue < 1) ? 1 : evtValue);
        let arr = [...this.state.customer.adultsArr];
        if (arr.length > evtValue) {
          while (arr.length > evtValue) {
            arr.pop();
          }
        } else {
          while (arr.length < evtValue) {
            arr.push(obj);
          }
        }
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            numadults: evtValue,
            adultsArr: arr
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      case "numchildren":
        evtValue = (evtValue > 9) ? 9 : ((evtValue < 0) ? 0 : evtValue);
        let arr1 = [...this.state.customer.childrenArr];
        if (arr1.length > evtValue) {
          while (arr1.length > evtValue) {
            arr1.pop();
          }
        } else {
          while (arr1.length < evtValue) {
            arr1.push({...obj, paxtype: "Child"});
          }
        }
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            numchildren: evtValue,
            childrenArr: arr1
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      default:
        break;
    }
  };

  handleFormChange = (event, index, type) => {
    switch (type) {
      case "adult":
        let data = [...this.state.customer.adultsArr];
        data[index][event.target.name] = event.target.value;
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            adultsArr: data
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      case "child":
        let data1 = [...this.state.customer.childrenArr];
        data1[index][event.target.name] = event.target.value;
        this.setState(prevState => ({
          customer: {
            ...prevState.customer,
            childrenArr: data1
          }
        }),
          () => this.props.updateChange(this.state.customer)
        );
        break;
      default:
        break;
    }
  };

  createCustomerUI = (arr, type) => {
    return arr.map((item, index) => (
      <React.Fragment key={index}>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <Typography className="capitalize">
            {type} {index + 1}
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            select
            fullWidth
            required
            name="title"
            variant="outlined"
            label="Title"
            className="select"
            InputLabelProps={{ shrink: true }}
            value={item.title}
            onChange={event => this.handleFormChange(event, index, type)}
          >
            <MenuItem value="Mr">Mr.</MenuItem>
            <MenuItem value="Mrs">Mrs.</MenuItem>
            <MenuItem value="Mstr">Mstr.</MenuItem>
            <MenuItem value="Ms">Ms.</MenuItem>
            <MenuItem value="Dr">Dr.</MenuItem>
            <MenuItem value="Fr">Fr.</MenuItem>
            <MenuItem value="Er">Er.</MenuItem>
            <MenuItem value="Capt">Capt.</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            required
            name="firstname"
            variant="outlined"
            label="First Name"
            InputLabelProps={{ shrink: true }}
            value={item.firstname}
            onChange={event => this.handleFormChange(event, index, type)}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            required
            name="lastname"
            variant="outlined"
            label="Last Name"
            InputLabelProps={{ shrink: true }}
            value={item.lastname}
            onChange={event => this.handleFormChange(event, index, type)}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            select
            required
            name="gender"
            variant="outlined"
            label="Gender"
            className="select"
            InputLabelProps={{ shrink: true }}
            value={item.gender}
            onChange={event => this.handleFormChange(event, index, type)}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={6}>
          <TextField
            fullWidth
            required
            name="policynumber"
            variant="outlined"
            label="Policy No"
            InputLabelProps={{ shrink: true }}
            value={item.policynumber}
            onChange={event => this.handleFormChange(event, index, type)}
          />
        </Grid>
      </React.Fragment>
    ));
  };

  render() {
    return (
      <Paper className="padding-16 customer-details-form">
        <Typography variant="subtitle1" className="bold mb-8">
          Select Customer Details
        </Typography>
        <hr className="full-width" />
        <Grid container spacing={16}>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              fullWidth
              name="numadults"
              label="Adults"
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ inputMode: 'numeric', pattern: '[1-6]' }}
              value={this.state.customer.numadults}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              fullWidth
              name="numchildren"
              label="Children"
              type="number"
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-2]' }}
              value={this.state.customer.numchildren}
              onChange={this.handleInputChange}
            />
          </Grid>
        </Grid>
        <br />
        <Typography variant="subtitle2" className="bold mb-8">
          Passenger Info
        </Typography>
        <hr className="full-width" />
        <Grid container spacing={16}>
          {this.createCustomerUI(this.state.customer.adultsArr, "adult")}
          {this.createCustomerUI(this.state.customer.childrenArr, "child")}
        </Grid>
      </Paper>
    );
  }
}

export default CustomerDetails;
