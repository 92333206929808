import React, { Component } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TextField,
  Hidden,
  Paper,
  Button,
  Popover
} from "@material-ui/core";
import { getHotelCancellationQueues, rejectHotelCancellation } from "../../../services/queues/HotelQueuesService";
import { blockTicket } from "../../../services/common/CommonService";
import { Loading } from "../../../components/common/Loading";
import { debounce, formatCurrency } from "../../../components/common/Utils";
import CustomSnackBar from "../../../components/common/CustomSnackBar";
import RejectDialog from "../common/RejectDialog";
class HotelCancellationQueuesPage extends Component {
  selectedRow = undefined;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hotelCancellations: undefined,
      filteredQueues: undefined,
      openPopover: false,
      anchorEl: null,
      sortType: "asc",
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      },
      showReject: false,
      rejectRemarks: "",
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    let res = await getHotelCancellationQueues();
    this.setState({
      isLoading: false,
      hotelCancellations: res,
      filteredQueues: res
    });
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  showLoading = isLoading => {
    this.setState({
      isLoading: isLoading
    });
  };

  handleChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.hotelCancellations.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredQueues: data
    });
  }, 500);

  handlePopoverOpen = (event, hotel) => {
    this.selectedRow = hotel;
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget
    });
  };

  handlePopoverClose = () => {
    this.setState({
      openPopover: false
    });
  };

  handleAction = type => {
    switch (type) {
      case "ticket":
        window.open(`/hotel-ticket/${this.selectedRow.reference_txid}`, "_blank");
        break;
      case "log":
        this.props.history.push(`/transaction-log/${this.selectedRow.txid}`);
        break;
      case "refund":
        this.props.history.push(
          `/hotel-transaction-refund/${this.selectedRow.txid}/${
            this.selectedRow.reference_txid
          }`
        );
        break;
      default:
        break;
    }
  };

  handleBlock = async () => {
    let isConfirm = window.confirm(
      "Are you sure, Do you want to block the ticket?"
    );
    if (isConfirm === true) {
      this.setState({
        isLoading: true
      });
      let reqObj = {
        txtype: "hotel",
        txid: this.selectedRow.txid,
      };
      let res = await blockTicket(reqObj);
      let msg = "",
        type = "";
      if (res) {
        if (res.ApiStatus.Status === "OK") {
          msg = res.ApiStatus.Message;
          type = "success";
        } else {
          msg = res.ApiStatus.Message;
          type = "error";
        }
      } else {
        msg = "Failed to block the status. Please try again";
        type = "error";
      }
      this.showSnackBar(msg, type);
      this.setState({
        isLoading: false,
        anchorEl: null,
        openPopover: false
      });
    }
  };

  sortBy = (property, type, sortType) => {
    let data = [...this.state.filteredQueues];
    if (type === "string") {
      if (this.state.sortType === "asc") {
        data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
        sortType = "desc";
      } else {
        data.sort((a, b) => (b[property] < a[property] ? -1 : 1));
        sortType = "asc";
      }
    } else {
      if (this.state.sortType === sortType) {
        data.sort((a, b) => new Date(b[property]) - new Date(a[property]));
        sortType = "desc";
      } else {
        data.sort((a, b) => new Date(a[property]) - new Date(b[property]));
        sortType = "asc";
      }
    }
    this.setState({
      filteredQueues: data,
      sortType: sortType
    });
  };

  showRejectDialog = () => {
    this.setState({
      showReject: true,
      anchorEl: null,
      openPopover: false
    });
  };

  closeRejectDialog = () => {
    this.setState({
      showReject: false
    });
  };

  handelRejectChange = event => {
    this.setState({
      rejectRemarks: event.target.value
    });
  };

  handleReject = async () => {
    this.setState({
      isLoading: true
    });
    let res = await rejectHotelCancellation(
      this.selectedRow.txid,
      this.state.rejectRemarks
    );
    if (res) {
      if (res.ApiStatus.Status === "OK") {
        this.showSnackBar(res.ApiStatus.Message, "success");
      } else {
        this.showSnackBar(res.ApiStatus.Message, "error");
      }
    } else {
      this.showSnackBar(
        "Failed to block the transaction. Please try again.",
        "error"
      );
    }
    this.setState({
      isLoading: false,
      showReject: false,
      anchorEl: null,
      openPopover: false
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography variant="h6" className="mb-8">
              Hotel Cancellation Queues
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              variant="outlined"
              className="search-field mb-8"
              name="searchTerm"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        <Hidden xsDown>
          <Paper className="padding-16">
            {this.state.filteredQueues && (
              <Table className="queue-table">
                <TableHead>
                  <TableRow>
                    <TableCell>TxID</TableCell>
                    <TableCell>
                      <Button
                        className="sort-label"
                        size="small"
                        onClick={() => this.sortBy("reference_txid", "string", "asc")}
                      >
                        Ref. TxID
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        className="sort-label"
                        size="small"
                        onClick={() => this.sortBy("refunddate", "date", "asc")}
                      >
                        Canx Raised Date
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        className="sort-label"
                        size="small"
                        onClick={() => this.sortBy("agentid", "string", "asc")}
                      >
                        Agent ID
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        className="sort-label"
                        size="small"
                        onClick={() => this.sortBy("blockedbyid", "string", "asc")}
                      >
                        Blocked By
                      </Button>
                    </TableCell>
                    <TableCell>Raised By</TableCell>
                    <TableCell>
                      <Button
                        className="sort-label"
                        size="small"
                        onClick={() => this.sortBy("spare4", "string", "asc")}
                      >
                        Hotel Name
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        className="sort-label"
                        size="small"
                        onClick={() => this.sortBy("spare5", "string", "asc")}
                      >
                        Pax Name
                      </Button>
                    </TableCell>
                    <TableCell>Company Name</TableCell>
                    <TableCell>
                      <Button
                        className="sort-label"
                        size="small"
                        onClick={() => this.sortBy("status", "string", "asc")}
                      >
                        Status
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        className="sort-label"
                        size="small"
                        onClick={() => this.sortBy("totalinvoice", "string", "asc")}
                      >
                        Total Invoice
                      </Button>
                    </TableCell>
                    <TableCell>Select</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.filteredQueues.map((hotel, index) => (
                    <TableRow key={index}>
                      <TableCell>{hotel.txid}</TableCell>
                      <TableCell>{hotel.reference_txid}</TableCell>
                      <TableCell>{hotel.refunddate}</TableCell>
                      <TableCell>{hotel.agentid}</TableCell>
                      <TableCell>{hotel.blockedbyid}</TableCell>
                      <TableCell />
                      <TableCell>{hotel.spare4}</TableCell>
                      <TableCell>{hotel.spare5}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>{hotel.status}</TableCell>
                      <TableCell>
                        {formatCurrency(hotel.totalinvoice)}
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          className="blue-btn"
                          onClick={event =>
                            this.handlePopoverOpen(event, hotel)
                          }
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Paper>
        </Hidden>
        <Hidden smUp>
          {this.state.filteredQueues &&
            this.state.filteredQueues.map((hotel, index) => (
              <Paper key={index} className="padding-16 mb-8">
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Tx.ID
                    </Typography>
                    <Typography>{hotel.txid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Ref. Tx.ID
                    </Typography>
                    <Typography>{hotel.reference_txid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Canx. Raised Date
                    </Typography>
                    <Typography>{hotel.refunddate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Agent ID
                    </Typography>
                    <Typography>{hotel.agentid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Blocked By
                    </Typography>
                    <Typography>{hotel.blockedbyid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Hotel
                    </Typography>
                    <Typography>{hotel.spare4}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Amount
                    </Typography>
                    <Typography>
                      {formatCurrency(hotel.totalinvoice)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Status
                    </Typography>
                    <Typography>{hotel.status}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Passenger
                    </Typography>
                    <Typography>{hotel.spare5}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      className="blue-btn"
                      onClick={event => this.handlePopoverOpen(event, hotel)}
                    >
                      Select
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          <Typography />
        </Hidden>
        <Popover
          id="hotell-queue-popper"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Button onClick={() => this.handleAction("ticket")}>Ticket</Button>
          <Button onClick={() => this.handleAction("log")}>Log</Button>
          <Button onClick={() => this.handleAction("refund")}>Refund</Button>
          <Button onClick={this.handleBlock}>Block</Button>
          <Button onClick={this.showRejectDialog}>Reject</Button>
        </Popover>
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
        {this.state.showReject && (
          <RejectDialog
            showReject={this.state.showReject}
            rejectRemarks={this.state.rejectRemarks}
            selectedRow={this.selectedRow}
            handleReject={this.handleReject}
            handelRejectChange={this.handelRejectChange}
            closeRejectDialog={this.closeRejectDialog}
            txnType='Hotel'
          />
        )}
      </React.Fragment>
    );
  }
}

export default HotelCancellationQueuesPage;
