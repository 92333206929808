import React, { Component } from "react";
import VisaInventoryMain from "../../components/inventory/visa-inventory/VisaInventoryMain";

class VisaPage extends Component {
  render() {
    return (
      <React.Fragment>
        <VisaInventoryMain />
      </React.Fragment>
    );
  }
}

export default VisaPage;
