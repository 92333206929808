import React, { Component } from "react";
import ETicket from "../../../components/queues/cab/ETicket";
import "./CabBookingTicketPage.css";

class CabBookingTicketPage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <ETicket />
      </React.Fragment>
    );
  }
}

export default CabBookingTicketPage;
