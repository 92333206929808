import React from "react";
import {
  Button,
  TextField,
  Drawer,
  Divider,
  Grid,
  Typography,
  MenuItem,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  Popover,
  Hidden,
} from "@material-ui/core";
import {
    addHotelReconfirmationDetail,
    updateHotelReconfirmationDetail,
} from "../../../services/queues/QualityCheckService";
import { withLoadingContext } from "../../common/LoadingProvider";
import { withCustomSnackBarContext } from "../../common/CustomSnackBarProvider";

class HotelReconfirmationDetail extends React.Component {
  selectedRow = undefined;
  constructor(props) {
    super(props);
    this.state = {
      openPopover: false,
      anchorEl: null,
      isDrawerOpen: false,
      isLoading: this.props.isLoading,
      txid: this.props.txId,
      recordid: "",
      hotel_confirmationno: "",
      hotel_contactno: "",
      hotel_contactperson: "",
      hotel_email: "",
      remark: "",
      hotel_confirmationuploadurl: "",
      hotel_reconfirmation_status: "",
      actionType: "",
    };
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  openCardDrawer = (isShow, action = "") => {
    this.setState({
      isDrawerOpen: isShow,
      actionType: action ? action : "",
    });
    if(!isShow){
      this.setState({
        recordid: "",
        hotel_confirmationno: "",
        hotel_contactno: "",
        hotel_contactperson: "",
        hotel_email: "",
        remark: "",
        hotel_confirmationuploadurl: "",
        hotel_reconfirmation_status: "",
      });
    }
  };

  handlePopoverOpen = (event, record) => {
    this.selectedRow = record;
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget
    });
    if(record.recordid){
      this.setState({
        recordid: record.recordid,
        hotel_confirmationno: record.hotel_confirmationno,
        hotel_contactno: record.hotel_contactno,
        hotel_contactperson: record.hotel_contactperson,
        hotel_email: record.hotel_email,
        remark: record.remark,
        hotel_confirmationuploadurl: record.hotel_confirmationuploadurl,
        hotel_reconfirmation_status: record.hotel_reconfirmation_status,
      });
    }
  };

  handlePopoverClose = () => {
    this.setState({
      openPopover: false
    });
  };

  callAddUpdateHotelReconfirmationDetail = async () => {
    this.setState({
      isLoading: true,
    });
    let jsondata = {
      HotelReconfirmationDetail:{
        txid: this.state.txid,
        hotel_confirmationno: this.state.hotel_confirmationno,
        hotel_contactno: this.state.hotel_contactno,
        hotel_contactperson: this.state.hotel_contactperson,
        hotel_email: this.state.hotel_email,
        remark: this.state.remark,
        hotel_confirmationuploadurl: this.state.hotel_confirmationuploadurl,
        hotel_reconfirmation_status: this.state.hotel_reconfirmation_status,
        ...(this.state.actionType === 'update' ? { recordid: this.selectedRow.recordid } : {}),
      }
    };
    let obj = {
      txid: this.state.txid,
      jsondata: JSON.stringify(jsondata),
    }
    let res = this.state.actionType === 'update' ? await updateHotelReconfirmationDetail(obj) : await addHotelReconfirmationDetail(obj);
    let msg = `Failed to ${this.state.actionType === 'update' ? 'update' : 'add'} hotel reconfirmation detail`;
    let mt = "error";
    try {
      if (res.ResponseStatus.statuscode === 200) {
        msg = res.ResponseStatus.message;
        mt = "success";
      } else {
        msg = res.ResponseStatus.message;
        mt = "error";
      }
      this.props.showSnackBar(msg, mt);
      this.props.showLoading(false);
      if (res.ResponseStatus.statuscode === 200) {
        this.openCardDrawer(false);
        this.handlePopoverClose();
        this.props.callHotelReconfirmationDetailsByTxid();
      }
    } catch (error) {
      this.props.showSnackBar(msg, mt);
      this.props.showLoading(false);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Paper className="padding-16">
          <Grid container>
            <Grid item lg={8} md={8} sm={8} xs={12}>
              <Typography variant="h6">Hotel Reconfirmation Detail</Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <Button
                className="right"
                variant="contained"
                size="small"
                color="primary"
                onClick={() => this.openCardDrawer(true, 'add')}
              >
                Add Hotel Reconfirmation Detail
              </Button>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <br />
          <Hidden smDown>
            <Table className="queue-table">
              <TableHead>
                <TableRow>
                <TableCell>Confirmation No.</TableCell>
                <TableCell>Contact Person/No.</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Remark</TableCell>
                <TableCell>Confirmation Uploadurl</TableCell>
                <TableCell>Reconfirmation Status</TableCell>
                <TableCell>Added By</TableCell>
                <TableCell>Added On</TableCell>
                <TableCell>Updated By</TableCell>
                <TableCell>Updated On</TableCell>
                <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.reconfirmationList &&
                this.props.reconfirmationList.length > 0 &&
                this.props.reconfirmationList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.hotel_confirmationno}</TableCell>
                    <TableCell>{item.hotel_contactperson}<br/>{item.hotel_contactno}</TableCell>
                    <TableCell>{item.hotel_email}</TableCell>
                    <TableCell>{item.remark}</TableCell>
                    <TableCell>{item.hotel_confirmationuploadurl}</TableCell>
                    <TableCell>{item.hotel_reconfirmation_status}</TableCell>
                    <TableCell>{item.addedby}</TableCell>
                    <TableCell>{item.addedon}</TableCell>
                    <TableCell>{item.updatedby}</TableCell>
                    <TableCell>{item.updatedon}</TableCell>
                    <TableCell>
                      {item.recordid && (
                        <Button
                          size="small"
                          className="blue-btn"
                          onClick={event =>
                            this.handlePopoverOpen(event, item)
                          }
                        >
                          Select
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {this.props.reconfirmationList && this.props.reconfirmationList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="6">No details have been added yet.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Hidden>

          <Hidden mdUp>
            {this.props.reconfirmationList &&
            this.props.reconfirmationList.length > 0 &&
            this.props.reconfirmationList.map((item, index) => (
              <Paper key={index} className="padding-16 mb-8">
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Confirmation No.
                    </Typography>
                    <Typography>{item.hotel_confirmationno}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Contact Person/No.
                    </Typography>
                    <Typography>{item.hotel_contactperson}</Typography>
                    <Typography>{item.hotel_contactno}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Email
                    </Typography>
                    <Typography>{item.hotel_email}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Remark
                    </Typography>
                    <Typography>{item.remark}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Confirmation Uploadurl
                    </Typography>
                    <Typography>{item.hotel_confirmationuploadurl}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Reconfirmation Status
                    </Typography>
                    <Typography>{item.hotel_reconfirmation_status}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Added By
                    </Typography>
                    <Typography>{item.addedby}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Added On
                    </Typography>
                    <Typography>{item.addedon}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Updated By
                    </Typography>
                    <Typography>{item.updatedby}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Updated On
                    </Typography>
                    <Typography>{item.updatedon}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      className="blue-btn"
                      onClick={event => this.handlePopoverOpen(event, item)}
                    >
                      Select
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Hidden>
          <Drawer
            className="custom-drawer"
            anchor="right"
            open={this.state.isDrawerOpen}
          >
            <Grid container spacing={16}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <Typography variant="h6">
                  {this.state.actionType === 'update' ? 'Update' : 'Add'} Hotel Reconfirmation Detail
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Button
                  variant="outlined"
                  color="secondary"
                  className="right"
                  onClick={() => this.openCardDrawer(false)}
                >
                  Close
                </Button>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider />
                <br />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  name="hotel_confirmationno"
                  label="hotel confirmation number"
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="hotel confirmation number"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={this.state.hotel_confirmationno}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  name="hotel_contactno"
                  label="hotel contact number"
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="hotel contact number"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={this.state.hotel_contactno}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  name="hotel_contactperson"
                  label="hotel contact person"
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="hotel contact person"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={this.state.hotel_contactperson}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  name="hotel_email"
                  label="hotel email"
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="hotel email"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={this.state.hotel_email}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  name="hotel_confirmationuploadurl"
                  label="hotel confirmation upload url"
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="hotel confirmation upload url"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={this.state.hotel_confirmationuploadurl}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  name="hotel_reconfirmation_status"
                  label="hotel reconfirmation status"
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="hotel reconfirmation status"
                  select
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={this.state.hotel_reconfirmation_status.toLowerCase()}
                  onChange={this.handleChange}
                  required
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </TextField>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  name="remark"
                  label="remark"
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="remark"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  value={this.state.remark}
                  onChange={this.handleChange}
                  required
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Button
                  onClick={this.callAddUpdateHotelReconfirmationDetail}
                  variant="contained"
                  color="secondary"
                >
                  {this.state.actionType === 'update' ? 'Update' : 'Add'}
                </Button>
              </Grid>
            </Grid>
          </Drawer>
          <Popover
            id="hotell-queue-popper"
            open={this.state.openPopover}
            anchorEl={this.state.anchorEl}
            onClose={this.handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Button onClick={() => this.openCardDrawer(true, 'update')}>Update</Button>
          </Popover>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withLoadingContext(withCustomSnackBarContext(HotelReconfirmationDetail));
