import React from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { withLoadingContext } from "../../common/LoadingProvider";
import { withCustomSnackBarContext } from "../../common/CustomSnackBarProvider";
import { updateTransactionStatus } from "../../../services/common/CommonService";

class UpdateStatus extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      remarks: "",
      status: ""
    };
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = async () => {
    this.props.loadingContext.show();
    let obj = {
      txid: this.props.txid,
      remarks: this.state.remarks,
      status: this.state.status,
      txtype: this.props.txtype
    };

    let res = await updateTransactionStatus(obj);
    if (res) {
      this.props.snackBarContext.show(res.ResponseStatus.message, "success");
    } else {
      this.props.snackBarContext.show(res.ResponseStatus.message, "error");
    }
    this.props.handlePopoverClose();
    this.props.handleClose();
    this.props.loadingContext.hide();
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          open={true}
          aria-labelledby="rollback-transaction"
        >
          <DialogTitle>Update Status: {this.props.txid}</DialogTitle>
          <hr className="full-width" />
          <DialogContent>
            <TextField
              name="status"
              label="Status"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Status"
              select
              fullWidth
              variant="outlined"
              margin="normal"
              value={this.state.status}
              onChange={this.handleInputChange}
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="inprocess">InProcess</MenuItem>
            </TextField>
            <TextField
              name="remarks"
              label="Remarks"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Remarks"
              fullWidth
              variant="outlined"
              margin="normal"
              value={this.state.remarks}
              onChange={this.handleInputChange}
            />
          </DialogContent>
          <DialogActions className="left">
            <Button onClick={this.props.handleClose} color="secondary">
              Close
            </Button>
            <Button
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
              disabled={!this.state.remarks || !this.state.status}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withLoadingContext(withCustomSnackBarContext(UpdateStatus));
