import moment from "moment";

export const ConvertTravelTime = minutes => {
  return `${Math.floor(minutes / 60)}h ${Math.floor(minutes % 60)}m`;
};

export const clearLocalStorage = () => {
  localStorage.removeItem("tgAdminProfile");
  localStorage.removeItem("tgAdminAuth");
};

export const getConnectingTime = function(arrtime, arrdate, deptime, depdate) {
  var tm = formatTimeString(arrtime).split(":");
  var at = moment(arrdate)
    .add(Number(tm[0]), "hours")
    .add(Number(tm[1]), "minutes");

  tm = formatTimeString(deptime).split(":");
  var dt = moment(depdate)
    .add(Number(tm[0]), "hours")
    .add(Number(tm[1]), "minutes");

  var duration = moment.duration(dt.diff(at)).asMinutes();
  return ConvertTravelTime(duration);
};

export const formatTimeString = function(time) {
  time = `${time}`;
  if (time.length === 3) {
    time = `0${time}`;
  }
  var hr = time.substring(0, 2);
  var min = time.substring(2);
  return `${hr}:${min}`;
};

export const getTimeFromMinutes = minutes => {
  let time = moment()
    .startOf("day")
    .add(minutes, "minutes")
    .format("HH:mm");
  return time;
};

export const formatToMonthDate = date => {
  return moment(date).format("MMM Do, YYYY");
};

export const getCurrencySymbol = () => {
  switch ("INR") {
    case "INR":
      return "₹";
    case "PHP":
      return "₱";
    default:
      return "₹";
  }
};

export const formatCurrency = val => {
  let fc = Math.round(val)
    .toString()
    .split("");
  let sl = fc.length;
  while ((sl -= 3) > 0) {
    fc.splice(sl, 0, ",");
    sl = sl - 3;
  }
  return `${getCurrencySymbol()} ${fc.join("")}`;
};

export const debounce = (func, wait) => {
  var timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export const downloadExcel = (data, reportName) => {
  var csvString = `data:application/csv,${encodeURIComponent(data)}`;
  var element = document.createElement("a");
  element.href = csvString;
  element.download = `${reportName}_Report.csv`;
  element.click();
  return true;
};
