import React from "react";
import { 
  Paper, 
  Button, 
  Typography, 
  Grid, 
  TextField, 
  MenuItem,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog
} from "@material-ui/core";
import Async from "react-select/lib/Async";
import { getSuppliers } from "../../../services/suppliers/SupplierService";
import { agentProfile, getAllCustomerslite, userBalance } from "../../../services/common/CommonService";

class AgentContactInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      agent: props.agentObj,
      suppliersList: [],
      customersListFiltered: [],
      balanceDialog: false,
      balanceDetails: {
        balance: "",
        creditLimit: "",
        due: "",
      }
    };
  }

  async componentDidMount() {
    let res = await getSuppliers();
    this.setState({
      suppliersList: res,
    });
    await this.getCustomersList();
  }
  
  async componentDidUpdate() {
    this.setState({
      agent: this.props.agentObj,
    });
  }

  getCustomersList = async () => {
    let res = await getAllCustomerslite();
    this.getCustomersListDropdown(res);
  };

  getCustomersListDropdown = (list) => {
    let customersListFiltered = [];
     for(let i=0;i<list.length;i++){
      customersListFiltered.push({
          value: list[i].corporateid, 
          label: list[i].corporateid+' ('+list[i].corporate_name+')',
        });
     }
    this.setState({
      customersListFiltered: customersListFiltered
    });
  }

  filterCustomersList = (inputValue) => {
    return this.state.customersListFiltered.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  getFilterCustomersList = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.filterCustomersList(inputValue));
      }, 1000);
    });

  handleCustomerSelect = async obj => {
    if (obj !== null) {
      this.setState(prevState => ({
        agent: {
          ...prevState.agent,
          customerid: obj.value,
        }
      }),
      () => this.props.updateChange(this.state.agent)
      );
    }
  };

  handleInputChange = event => {
    let eve = { ...event };
    this.setState(
      prevState => ({
        agent: {
          ...prevState.agent,
          [eve.target.name]: eve.target.value
        }
      }),
      () => this.props.updateChange(this.state.agent)
    );
  };

  getAgentProfile = async () => {
    const custId = this.state.agent.customerid;
    if (custId) {
      let res = await agentProfile(custId);
      let msg = `Failed to get agent details`;
      try {
        if (res.ProfileMaster) {
          this.setState(prevState => ({
            agent: {
              ...prevState.agent,
              title: res.ProfileMaster.title,
              firstname: res.ProfileMaster.first_name,
              lastname: res.ProfileMaster.last_name,
              email: res.ProfileMaster.email,
              mobile: res.ProfileMaster.mobile,
              address: res.ProfileMaster.address1,
              city: res.ProfileMaster.city,
              state: res.ProfileMaster.state,
              pincode: res.ProfileMaster.pincode
            }
          }),
            () => this.props.updateChange(this.state.agent)
          );
        } else {
          alert(msg);
        }
      } catch (error) {
        alert(msg);
      }
    } else {
      alert("Please enter value for CustomerId");
    }
  }

  getUserBalance = async () => {
    const custId = this.state.agent.customerid;
    if (custId) {
      let res = await userBalance(custId);
      let msg = `Failed to get user balance`;
      try {
        if (res.UserAccount) {
          this.setState({
            balanceDialog: true,
            balanceDetails: {
              balance: res.UserAccount.balance,
              creditLimit: res.UserAccount.credit_limit,
              due: res.UserAccount.due,
            }
          });
        } else {
          alert(msg);
        }
      } catch (error) {
        alert(msg);
      }
    } else {
      alert("Please enter value for CustomerId");
    }
  }

  handleClose = () => {
    this.setState({
      balanceDialog: false
    });
  };

  render() {
    return (
      <Paper className="padding-16 agent-contact-info-form">
        <Typography variant="subtitle1" className="bold">
          Agent Contact Info
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Async
              className="customer-id"
              placeholder="Customer ID"
              onChange={value => this.handleCustomerSelect(value)}
              loadOptions={this.getFilterCustomersList}
              isClearable
            />
          </Grid>
          <Grid item lg={8} md={8} sm={8} xs={12}>
            <Button variant="outlined" color="primary" size="small" onClick={this.getAgentProfile}>
              Get Agent
            </Button>
            &nbsp;&nbsp;
            <Button variant="outlined" color="primary" size="small" onClick={this.getUserBalance}>
              Get Balance
            </Button>
            &nbsp;&nbsp;
            <Button variant="outlined" color="secondary" size="small">
              Set Credit Limit
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              className="select"
              variant="outlined"
              fullWidth
              required
              select
              label="Salutation"
              name="title"
              InputLabelProps={{ shrink: true }}
              value={this.state.agent.title}
              onChange={this.handleInputChange}
            >
              <MenuItem value="Mr">Mr.</MenuItem>
              <MenuItem value="Mrs">Mrs.</MenuItem>
              <MenuItem value="Ms">Ms.</MenuItem>
              <MenuItem value="Dr">Dr.</MenuItem>
              <MenuItem value="Fr">Fr.</MenuItem>
              <MenuItem value="Er">Er.</MenuItem>
              <MenuItem value="Capt">Capt.</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label="First Name"
              name="firstname"
              InputLabelProps={{ shrink: true }}
              value={this.state.agent.firstname}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label="Last Name"
              name="lastname"
              InputLabelProps={{ shrink: true }}
              value={this.state.agent.lastname}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              type="email"
              label="Email"
              name="email"
              InputLabelProps={{ shrink: true }}
              value={this.state.agent.email}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              type="number"
              label="Mobile"
              name="mobile"
              InputLabelProps={{ shrink: true }}
              value={this.state.agent.mobile}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label="Address"
              name="address"
              InputLabelProps={{ shrink: true }}
              value={this.state.agent.address}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label="City"
              name="city"
              InputLabelProps={{ shrink: true }}
              value={this.state.agent.city}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label="State"
              name="state"
              InputLabelProps={{ shrink: true }}
              value={this.state.agent.state}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label="Pin Code"
              name="pincode"
              InputLabelProps={{ shrink: true }}
              value={this.state.agent.pincode}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label="Requested By"
              name="requestedby"
              InputLabelProps={{ shrink: true }}
              value={this.state.agent.requestedby}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              className="select"
              variant="outlined"
              select
              required
              fullWidth
              label="Supplier ID"
              name="supplierid"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.agent.supplierid}
              onChange={this.handleInputChange}
            >
              {this.state.suppliersList.map(option => (
                <MenuItem key={option.supplier_id} value={option.supplier_id}>
                  {option.supplier_name} ({option.supplier_id})
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <React.Fragment>
          <Dialog
            open={this.state.balanceDialog}
            aria-labelledby="user-balance"
          >
            <DialogTitle id="user-balance">Balance Details</DialogTitle>
            <hr className="full-width" />
            <DialogContent>
              <Grid container spacing={8}>
                <Grid item xs={6}>
                  <Typography variant="h6" color="secondary">Balance</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">: {this.state.balanceDetails.balance}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" color="secondary">Credit Limit</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">: {this.state.balanceDetails.creditLimit}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6" color="secondary">Due</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">: {this.state.balanceDetails.due}</Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className="left">
              <Button onClick={this.handleClose} color="secondary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Paper>
    );
  }
}

export default AgentContactInfo;
