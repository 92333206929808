import React, { Component } from "react";
import CabBillingMain from "../../components/offline-billing/cab-billing/CabBillingMain";
import "./common.css";

class CabBillingPage extends Component {
  render() {
    return (
      <React.Fragment>
        <CabBillingMain />
      </React.Fragment>
    );
  }
}

export default CabBillingPage;
