import React, { Component } from "react";
import TrainBillingMain from "../../components/offline-billing/train-billing/TrainBillingMain";
import "./common.css";

class TrainBillingPage extends Component {
  render() {
    return (
      <React.Fragment>
        <TrainBillingMain />
      </React.Fragment>
    );
  }
}

export default TrainBillingPage;
