import React, { Component } from "react";
import ExpenseMain from "../../components/expenses/ExpenseMain";

class ExpensesPage extends Component {
  render() {
    return (
      <React.Fragment>
        <ExpenseMain />
      </React.Fragment>
    );
  }
}

export default ExpensesPage;
