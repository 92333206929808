import React, { Component } from "react";
import AgentContactInfo from "../common/AgentContactInfo";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FlightDetails from "./FlightDetails";
import FlightFareBreakUp from "./FlightFareBreakUp";
import FlightCard from "./FlightCard";
import PassengerDetails from "./PassengerDetails";
import CorporateDetails from "../common/CorporateDetails";

class FlightBillingMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      agentObj: {
        title: "Mr",
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        requestedby: "",
        supplierid: ""
      },
      corporateDetailsObj: {
        costcenterid: "",
        departmentid: "",
        projectid: "",
      },
      fareObj: {
        basefare: 0,
        othertax:0,
        tax: 0
      }
    };
  }

  updateCorporateDetailsObject = obj => {
    this.setState({
      corporateDetailsObj: obj
    });
  };

  updateStateObject = obj => {
    this.setState({
      agentObj: obj,
      customerId: obj.customerid
    });
  };

  updateFareObject = obj => {
    this.setState({
      fareObj: obj
    });
  };

  handleGenerateBill = () => {
    Object.keys(this.state.agentObj).some(key => {
      if (this.state.agentObj[key] === "") {
        window.alert("Please enter value for " + key);
        return true;
      }
      return true;
    });
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h5" className="mb-8">
          Flight Offline Billing
        </Typography>
        <AgentContactInfo
          agentObj={this.state.agentObj}
          updateChange={this.updateStateObject}
        />
        <br />
        <FlightDetails />
        <br />
        <CorporateDetails 
          detailsObj={this.state.corporateDetailsObj}
          customerId={this.state.customerId}
          updateChange={this.updateCorporateDetailsObject}
        />
        <br />
        <FlightCard title="Onward Flight Details" />
        <br />
        <FlightCard title="Return Flight Details" />
        <br />
        <PassengerDetails />
        <br />
        <FlightFareBreakUp
          fareObj={this.state.fareObj}
          updateFare={this.updateFareObject}
        />
        <br />
        <div className="text-center">
          <Button
            onClick={this.handleGenerateBill}
            variant="contained"
            color="primary"
          >
            Generate Bill
          </Button>
        </div>
        <br />
        <br />
      </React.Fragment>
    );
  }
}

export default FlightBillingMain;
