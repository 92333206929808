import {
  apiPost,
  getUserAuth,
  getUserProfile,
} from "../common/CommonService";

export const getTrainBookingQueues = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "gettrainbookingqueues"
    };
    let res = await apiPost(reqObj);
    if (res.list[0] !== "" && res.list[0].TrainRecord) {
      return Array.isArray(res.list[0].TrainRecord) ? res.list[0].TrainRecord : [res.list[0].TrainRecord];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getTrainTicket = async txId => {
  try {
    let reqObj = {
      opid: "TG-GETETICKET",
      txid: txId
    };
    const res = await apiPost(reqObj);
    return res;
  } catch (error) {
    return undefined;
  }
};

export const updateBusOrTrainQueueBooking = async (obj, acitonType) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: acitonType,
      jsondata: JSON.stringify(obj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (error) {
    return {
      ResponseStatus: {
        status: "NOK",
        message: "Failed to update queue. Please try again."
      }
    };
  }
};

export const updateTrainFare = async (txnId, fares = {}, remarks) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatetrainfare",
      jsondata: JSON.stringify({
        "com.tripgain.trains.TrainBookingFareUpdate": {
          txid: txnId,
          adminremarks: remarks,
          ...fares,
        },
      }),
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const rollbackTrainTicket = async (txId, remarks) => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "rollbackticket",
      txtype: "train",
      txid: txId,
      agentid: userAuth.corporateid,
      remarks: remarks,
    };
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    }
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getTrainCancellationQueues = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "gettraincancellationqueues",
      agentid: getUserProfile().corporateid
    };
    let res = await apiPost(reqObj);
    if (res.list[0].TrainCancellations) {
      return res.list[0].TrainCancellations;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const performTrainRefund = async obj => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "performtrainrefund",
      txid: obj.txid,
      adminid: userAuth.corporateid,
      agentid: obj.agentid,
      reference_txid: obj.refTxId,
      totalrefundamount: obj.refundAmount,
      refundamount_operator: obj.canxChargeSup,
      operator_canx_charge: obj.canxChargeSup,
      canxcharge_ota: obj.canxChargeOTA,
      remarks: obj.adminRemarks,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const retryTrainBookingLive = async (data) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "retrytrainbookinglive",
      ...data,
    };
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    }
    return res;
  } catch (err) {
    return undefined;
  }
};

export const rejectTrainCancellation = async (txId, adminRemarks) => {
  try {
    let userAuth = getUserAuth();
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "rejecttraincancellation",
      agentid: userAuth.corporateid,
      txid: txId,
      remarks: adminRemarks,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const cancelTrainTicket = async reqObject => {
  try {
    let reqObj = {
      opid: "TG-TRAINCANCELLATIONREQUEST",
      ...reqObject,
    };
    let res = await apiPost(reqObj);
    return res.data;
  } catch (err) {
    return {
      ResponseStatus: {
        status: "NOK",
        message: "Failed to cancel ticket. Please try again.",
      },
    };
  }
};