import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import "./CardTransactionsPage.css";
import CTSearch from "../../components/transactions/CTSearch";
import CTTable from "../../components/transactions/CTTable";
import { getCardTransactions, getTodaysBookings } from "../../services/transactions/CardTransactionsService.js";
import { Loading } from "../../components/common/Loading";
import CustomSnackBar from "../../components/common/CustomSnackBar";
import HotelQueueTable from "../../components/queues/hotel/HotelQueueTable";
import TrainQueueTable from "../../components/queues/train/TrainQueueTable";
import BusQueueTable from "../../components/queues/bus/BusQueueTable";
import CabQueueTable from "../../components/queues/cab/CabQueueTable";

class CardTransactionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      cardTransactions: [],
      type: "flight",
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  doSearch = async searchObj => {
    if (searchObj.type === "") {
      this.showSnackBar("Please select transaction booking type", "error");
    } else {
      this.showLoading(true);
      let res = await getCardTransactions(searchObj);
      switch (searchObj.type) {
        case "getflighttransactions":
          if (res === undefined) {
            this.setState({
              type: "flight",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "flight",
              cardTransactions: Array.isArray(res.FlightRecord)
                ? res.FlightRecord
                : [res.FlightRecord]
            });
          }
          break;
        case "getflighttransactionspending":
            if (res === undefined) {
              this.setState({
                type: "flight",
                cardTransactions: []
              });
            } else {
              this.setState({
                type: "flight",
                cardTransactions: Array.isArray(res.FlightRecord)
                  ? res.FlightRecord
                  : [res.FlightRecord]
              });
            }
            break;
        case "gethoteltransactions":
          if (res === undefined) {
            this.setState({
              type: "hotel",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "hotel",
              cardTransactions: Array.isArray(res.HotelInvoice)
                ? res.HotelInvoice
                : [res.HotelInvoice]
            });
          }
          break;
        case "getbustransactions":
          if (res === undefined) {
            this.setState({
              type: "bus",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "bus",
              cardTransactions: Array.isArray(res.BusRecord)
                ? res.BusRecord
                : [res.BusRecord]
            });
          }
          break;
        case "gettraintransactions":
          if (res === undefined) {
            this.setState({
              type: "train",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "train",
              cardTransactions: Array.isArray(res.TrainRecord)
                ? res.TrainRecord
                : [res.TrainRecord]
            });
          }
          break;
        case "gettraintatkaltransactions":
          if (res === undefined) {
            this.setState({
              type: "train",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "train",
              cardTransactions: Array.isArray(res.TrainRecord)
                ? res.TrainRecord
                : [res.TrainRecord]
            });
          }
          break;
        case "getcabtransactions":
          if (res === undefined) {
            this.setState({
              type: "cab",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "cab",
              cardTransactions: Array.isArray(res.CabInvoice)
                ? res.CabInvoice
                : [res.CabInvoice]
            });
          }
          break;
        default:
          break;
      }
      this.showLoading(false);
    }
  };

  searchTodayBooking = async searchType => {
    if (searchType === "") {
      this.showSnackBar("Please select transaction booking type", "error");
    } else {
      this.showLoading(true);
      let res = await getTodaysBookings(searchType);
      switch (searchType) {
        case "gettodaystravelflights":
          if (res === undefined) {
            this.setState({
              type: "flight",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "flight",
              cardTransactions: Array.isArray(res.FlightRecord)
                ? res.FlightRecord
                : [res.FlightRecord]
            });
          }
          break;
        case "gettodayscheckinhotels":
          if (res === undefined) {
            this.setState({
              type: "hotel",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "hotel",
              cardTransactions: Array.isArray(res.HotelInvoice)
                ? res.HotelInvoice
                : [res.HotelInvoice]
            });
          }
          break;
        case "gettodaystravelbuses":
          if (res === undefined) {
            this.setState({
              type: "bus",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "bus",
              cardTransactions: Array.isArray(res.BusRecord)
                ? res.BusRecord
                : [res.BusRecord]
            });
          }
          break;
        case "gettodaystraveltrains":
          if (res === undefined) {
            this.setState({
              type: "train",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "train",
              cardTransactions: Array.isArray(res.TrainRecord)
                ? res.TrainRecord
                : [res.TrainRecord]
            });
          }
          break;
        case "unflownpendingbookings":
          if (res === undefined) {
            this.setState({
              type: "flight",
              cardTransactions: []
            });
          } else {
            this.setState({
              type: "flight",
              cardTransactions: Array.isArray(res.FlightRecord)
                ? res.FlightRecord
                : [res.FlightRecord]
            });
          }
          break;
        default:
          break;
      }
      this.showLoading(false);
    }
  };

  showLoading = loading => {
    this.setState({
      isLoading: loading
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Paper className="padding-16">
          <CTSearch doSearch={this.doSearch} searchTodayBooking={this.searchTodayBooking} />
        </Paper>
        <br />
        <br />
        {this.state.cardTransactions && (
          <React.Fragment>
            {this.state.type === "flight" && (
              <CTTable
                showSnackBar={this.showSnackBar}
                showLoading={this.showLoading}
                cardTransactions={this.state.cardTransactions}
                txtype={this.state.type}
              />
            )}
            {this.state.type === "hotel" && (
              <HotelQueueTable
                showLoading={this.showLoading}
                showSnackBar={this.showSnackBar}
                hotelQueues={this.state.cardTransactions}
                txtype={this.state.type}
              />
            )}
            {this.state.type === "train" && (
              <TrainQueueTable
                showLoading={this.showLoading}
                showSnackBar={this.showSnackBar}
                trainQueues={this.state.cardTransactions}
                txtype={this.state.type}
              />
            )}
            {this.state.type === "bus" && (
              <BusQueueTable
                showLoading={this.showLoading}
                showSnackBar={this.showSnackBar}
                busQueues={this.state.cardTransactions}
                txtype={this.state.type}
              />
            )}
            {this.state.type === "cab" && (
              <CabQueueTable
                showLoading={this.showLoading}
                showSnackBar={this.showSnackBar}
                cabQueues={this.state.cardTransactions}
                txtype={this.state.type}
              />
            )}
          </React.Fragment>
        )}

        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default CardTransactionsPage;
