import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Drawer,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
  MenuItem,
} from "@material-ui/core";
import { debounce } from "../../../components/common/Utils";
import TGSelect from "../../common/tg-select/TGSelect";
import {
  addVisaInfo,
  updateVisaInfo,
  getAllVisaInfoList,
  getAllVisaDocumentTypeList,
  getVisaInfoById,
  visaDocumentUpload,
} from "../../../services/inventory/VisaService";

class VisaInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recordid: "",
      jsonObj: undefined,
      reqObj: undefined,
      docObj: undefined,
      recordsList: [],
      filteredList: [],
      docNameList: [],
      visaTypes: props.visaTypes,
      isOpenDrawer: false,
      isEdit : false,
    };
  }

  async componentDidMount() {
    this.initJsonObject();
    await this.allVisaInfo();
    await this.allVisaDocumentType();
  }

  handleSearchChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.recordsList.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredList: data
    });
  }, 500);

  initJsonObject = () => {
    this.setState({
      jsonObj: {
        shortname: "",
        country: "",
        applyfrom: "",
        visatype: "",
        visafee: 0,
        servicefee: 0,
        vfsfee: 0,
        miscfee: 0,
        otherfee: 0,
      },
      reqObj: {
        totNoOfReq: 0,
        reqArr: [],
      },
      docObj: {
        totNoOfDoc: 0,
        uploadFileArr:[],
        docArr: [],
      },
    });
  };

  openDrawer = flag => {
    if (flag === false) {
      this.setState({
        isEdit: false,
        recordid: "",
      });
      this.initJsonObject();
    }
    this.setState({
      isOpenDrawer: flag
    });
  };

  allVisaDocumentType = async () => {
    let res = await getAllVisaDocumentTypeList();
    if(res){
      this.setState({
        docNameList: res,
      })
    }
  }

  allVisaInfo = async () => {
    this.props.showLoading(true);
    let res = await getAllVisaInfoList();
    if(res){
      this.setState({
        recordsList: res,
        filteredList: res,
      })
    }
    this.props.showLoading(false);
  }

  handleSaveOrUpdate = async isUpdate => {
    let res = undefined;
    let errMsg = "";
    let jsonObjError = 0, reqObjError = 0, docObjError = 0;
    let visaRequirementList = [], visaDocumentList = [];
    const alertMsg = "Please enter value for ";
    const { jsonObj, reqObj, docObj } = this.state;
    const country = jsonObj.country.value ? jsonObj.country.value : "";
    const applyfrom = jsonObj.applyfrom.value ? jsonObj.applyfrom.value : "";
    const detailsJsonObj = {...jsonObj, country: country, applyfrom: applyfrom}

    Object.keys(detailsJsonObj).some(key => {
      if (detailsJsonObj[key] === "") {
        window.alert(alertMsg + key);
        jsonObjError = 1;
        return true;
      }
      return false;
    });

    if(jsonObjError === 1){
      return false;
    }

    Object.keys(reqObj).some(key => {
      const keyVal = reqObj[key];
      if (["reqArr"].includes(key) && Array.isArray(keyVal) && keyVal.length > 0) {
        for (let i = 0; i < keyVal.length; i++) {
          const country_req = keyVal[i].country.value ? keyVal[i].country.value : "";
          const nationality_req = keyVal[i].nationality.value ? keyVal[i].nationality.value : "";
          const visaRequirementMaster = { ...keyVal[i], country: country_req, nationality: nationality_req };
          visaRequirementList.push({
            "VisaRequirementMaster": visaRequirementMaster
          });
        }
      }

      if (reqObj[key] === "") {
        window.alert(alertMsg + key);
        reqObjError = 1;
        return true;
      }
      return false;
    });

    visaRequirementList.forEach((item, index) => {
      Object.keys(item.VisaRequirementMaster).some(key => {
        if (item.VisaRequirementMaster[key] === "") {
          window.alert(alertMsg + key + ' Requirement ' +(index+1));
          reqObjError = 1;
          return true;
        }
        return false;
      });
    });

    if(reqObjError === 1){
      return false;
    }

    Object.keys(docObj).some(key => {
      const keyVal = docObj[key];
      if (["docArr"].includes(key) && Array.isArray(keyVal) && keyVal.length > 0) {
        for(let i=0; i<keyVal.length; i++){
          visaDocumentList.push({
            "VisaInfoDocument": keyVal[i]
          });
        }
      }

      if (docObj[key] === "") {
        window.alert(alertMsg + key);
        docObjError = 1;
        return true;
      }
      return false;
    });

    visaDocumentList.forEach((item, index) => {
      Object.keys(item.VisaInfoDocument).some(key => {
        if (item.VisaInfoDocument[key] === "") {
          window.alert(alertMsg + key + ' Document ' +(index+1));
          docObjError = 1;
          return true;
        }
        return false;
      });
    });

    if(docObjError === 1){
      return false;
    }
    
    const obj = {
      shortname: detailsJsonObj.shortname,
      country: detailsJsonObj.country,
      applyfrom: detailsJsonObj.applyfrom,
      visatype: detailsJsonObj.visatype,
      visaform: "",
      visafee: detailsJsonObj.visafee,
      servicefee: detailsJsonObj.servicefee,
      vfsfee: detailsJsonObj.vfsfee,
      miscfee: detailsJsonObj.miscfee,
      otherfee: detailsJsonObj.otherfee,
      supplierid: "",
      visarequirementlist: visaRequirementList,
      visadocumentlist: visaDocumentList,
    };

    this.props.showLoading(true);

    if (isUpdate) {
      res = await updateVisaInfo({...obj, recordid: this.state.recordid});
      errMsg = "Failed to update a visa info. Please try again.";
    } else {
      res = await addVisaInfo(obj);
      errMsg = "Failed to add a visa info. Please try again.";
    }
    if (res) {
      if (res.ResponseStatus.status === "OK") {
        this.props.showSnackBar(res.ResponseStatus.message, "success");
      } else {
        this.props.showSnackBar(res.ResponseStatus.message, "error");
      }
    } else {
      this.props.showSnackBar(errMsg, "error");
    }
    this.openDrawer(false);
    this.props.showLoading(false);
    await this.allVisaInfo();
  };

  handleDocumentChange = event => {
    let obj = {
      document_type: "",
      document_name: "",
      document_url: ""
    };
    let fileObj = {
      doc_name: undefined,
    };
    let evtValue = Number(event.target.value);
    evtValue = (evtValue > 9) ? 9 : ((evtValue < 0) ? 0 : evtValue);
    let arr = [...this.state.docObj.docArr];
    let fileArr = [...this.state.docObj.uploadFileArr];
    if (arr.length > evtValue) {
      while (arr.length > evtValue) {
        arr.pop();
        fileArr.pop();
      }
    } else {
      while (arr.length < evtValue) {
        arr.push(obj);
        fileArr.push(fileObj);
      }
    }
    this.setState(prevState => ({
      docObj: {
        ...prevState.docObj,
        totNoOfDoc: evtValue,
        docArr: arr,
        uploadFileArr: fileArr,
      }
    }));
  };

  handleRequirementChange = event => {
    let obj = {
      country: "",
      nationality: "",
      visa_type: "",
      duration_days: 1,
      requirement_type: "",
      requiremnt_type_value: "",
      requirement_ismandatory: false,
      description: ""
    };
    let evtValue = Number(event.target.value);
    evtValue = (evtValue > 9) ? 9 : ((evtValue < 0) ? 0 : evtValue);
    let arr = [...this.state.reqObj.reqArr];
    if (arr.length > evtValue) {
      while (arr.length > evtValue) {
        arr.pop();
      }
    } else {
      while (arr.length < evtValue) {
        arr.push(obj);
      }
    }
    this.setState(prevState => ({
      reqObj: {
        ...prevState.reqObj,
        totNoOfReq: evtValue,
        reqArr: arr
      }
    }));
  };

  handleDocumentInputChange = (event, index) => {
    let data = [...this.state.docObj.docArr];
    data[index][event.target.name] = event.target.value;
    this.setState(prevState => ({
      docObj: {
        ...prevState.docObj,
        docArr: data
      }
    }));
  };

  handleRequirementInputChange = (event, index) => {
    let data = [...this.state.reqObj.reqArr];
    data[index][event.target.name] = event.target.value;
    if(event.target.name === "requiremnt_type_value"){
      const reqTypeValObj = this.state.docNameList.filter((item) => item.recordid === event.target.value)
      data[index]["description"] = reqTypeValObj[0].description;
    }
    this.setState(prevState => ({
      reqObj: {
        ...prevState.reqObj,
        reqArr: data
      }
    }));
  };

  handleInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      jsonObj: {
        ...prevState.jsonObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  handleRequirementCountryInputChange = (name, val, index) => {
    let data = [...this.state.reqObj.reqArr];
    data[index][name] = val;
    this.setState(prevState => ({
      reqObj: {
        ...prevState.reqObj,
        reqArr: data
      }
    }));
  };

  handleCountryChange = (name, selectedCountry) => {
    this.setState(prevState => ({
      jsonObj: {
        ...prevState.jsonObj,
        [name]: selectedCountry !== null ? selectedCountry : "",
      },
    }));
  };

  showEdit = async (obj) => {
    this.props.showLoading(true);
    let res = await getVisaInfoById(obj.recordid);
    if (res) {
      this.openDrawer(true);
      const countryObj = this.props.countriesList.filter(r => r["value"] === obj.country);
      const applyFromObj = this.props.countriesList.filter(r => r["value"] === obj.applyfrom);
      this.setState({
        isEdit: true,
        recordid: obj.recordid,
        jsonObj: {
          ...this.state.jsonObj,
          country: countryObj,
          applyfrom: applyFromObj,
          visatype: res.visatype,
          shortname: res.shortname,
          miscfee: res.miscfee,
          otherfee: res.otherfee,
          servicefee: res.servicefee,
          vfsfee: res.vfsfee,
          visafee: res.visafee,
        }
      });
    }
    this.props.showLoading(false);
  };

  noRecordsFound = () => <div>No records found. Click on <kbd>Add Visa Info</kbd> button on the top right corner to add a visa info.</div>;

  handleUploadDocument = (event, index) => {
    let data = [...this.state.docObj.uploadFileArr];
    data[index]['doc_name'] = event.target.files[0];
    this.setState(prevState => ({
      docObj: {
        ...prevState.docObj,
        uploadFileArr: data
      }
    }));
  };

  handleUploadAction = async (index) => {
    this.props.showLoading(true);
    let data = [...this.state.docObj.docArr];
    let uploadFile = this.state.docObj.uploadFileArr[index]['doc_name'];
    if (uploadFile !== undefined) {
      let formData = new FormData();
      formData.append("authtoken", "");
      formData.append("fullpath", uploadFile, uploadFile.name);
      const uploadResponse = await visaDocumentUpload(formData);
      const receiptUrl = uploadResponse && uploadResponse.ApiStatus.Status === "OK" ? uploadResponse.ApiStatus.Result : undefined;
      if (receiptUrl === undefined) {
        this.props.showSnackBar("Failed to upload the attachment. Please try again.", "error");
      } else {
        this.props.showSnackBar("Attachment has been uploaded successfully.", "success");
      }
      data[index]['document_url'] = receiptUrl ? receiptUrl : '';
    } else {
      data[index]['document_url'] = '';
      this.props.showSnackBar("Please browse attachment and click on upload button", "error");
    }
    this.setState(prevState => ({
      docObj: {
        ...prevState.docObj,
        docArr: data
      }
    }));
    this.props.showLoading(false);
  };

  createRequirementUI = (arr) => {
    return arr.map((item, index) => (
      <React.Fragment key={index}>
        <hr className="full-width" />
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <TGSelect
            className="mt-16"
            name="country"
            label="Country"
            dropDownKey={index}
            options={this.props.countriesList}
            defaultValue={item.country}
            onChange={this.handleRequirementCountryInputChange}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <TGSelect
            className="mt-16"
            name="nationality"
            label="Nationality"
            dropDownKey={index}
            options={this.props.countriesList}
            defaultValue={item.nationality}
            onChange={this.handleRequirementCountryInputChange}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={6} xs={12}>
          <TextField
            select
            fullWidth
            required
            className="select"
            label="Visa Type"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            name="visa_type"
            value={item.visa_type}
            onChange={event => this.handleRequirementInputChange(event, index)}
          >
            {this.state.visaTypes.map((item, i) => (
              <MenuItem key={i} value={item}>{item}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={1} md={1} sm={6} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            required
            type="number"
            label="Duration"
            name="duration_days"
            InputLabelProps={{ shrink: true }}
            value={item.duration_days}
            onChange={event => this.handleRequirementInputChange(event, index)}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <TextField
            select
            fullWidth
            required
            className="select"
            label="Requirement Type"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            name="requirement_type"
            value={item.requirement_type}
            onChange={event => this.handleRequirementInputChange(event, index)}
          >
            <MenuItem value="document">Document</MenuItem>
            <MenuItem value="info">Info</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={2} md={2} sm={6} xs={12}>
          <TextField
            select
            fullWidth
            required
            className="select"
            label="Requirement Value"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            name="requiremnt_type_value"
            value={item.requiremnt_type_value}
            onChange={event => this.handleRequirementInputChange(event, index)}
          >
            {this.state.docNameList.map((item, i) => (
              <MenuItem key={i} value={item.recordid}>{item.document_name}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={1} md={1} sm={6} xs={12}>
          <TextField
            select
            fullWidth
            required
            className="select"
            label="Mandatory"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            name="requirement_ismandatory"
            value={item.requirement_ismandatory}
            onChange={event => this.handleRequirementInputChange(event, index)}
          >
            <MenuItem value="true">True</MenuItem>
            <MenuItem value="false">False</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={1} md={1} sm={6} xs={12}>
          <TextField
            disabled
            fullWidth
            required
            label="Description"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            name="description"
            value={item.description}
          />
        </Grid>
      </React.Fragment>
    ));
  };

  createDocumentUI = (arr) => {
    return arr.map((item, index) => (
      <React.Fragment key={index}>
        <hr className="full-width" />
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextField
            select
            fullWidth
            required
            className="select"
            label="Document Type"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            name="document_type"
            value={item.document_type}
            onChange={event => this.handleDocumentInputChange(event, index)}
          >
            <MenuItem value="checklist">Check List</MenuItem>
            <MenuItem value="form">Form</MenuItem>
            <MenuItem value="sampledoc">Sample Document</MenuItem>
            <MenuItem value="masterdoc">Master Document</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <TextField
            select
            fullWidth
            required
            className="select"
            label="Document Name"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            name="document_name"
            value={item.document_name}
            onChange={event => this.handleDocumentInputChange(event, index)}
          >
            {this.state.docNameList.map((item, i) => (
              <MenuItem key={i} value={item.document_name}>{item.document_name}</MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Paper>
            <input name="file" type="file" onChange={event => this.handleUploadDocument(event, index)} />&nbsp;&nbsp;
            <Button
              onClick={() => this.handleUploadAction(index)}
              variant="contained"
              color="secondary"
              size="small"
            >
              Upload
            </Button>
          </Paper>
        </Grid>
      </React.Fragment>
    ));
  };

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={16} className="mb-8">
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Typography variant="h6"> Visa Info </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Button
              className="right"
              variant="contained"
              size="small"
              color="primary"
              onClick={() => this.openDrawer(true)}
            >
              Add Visa Info
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              fullWidth
              name="searchTerm"
              onChange={this.handleSearchChange}
              variant="outlined"
              label="Search"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Hidden smDown>
          <Paper className="padding-16">
            <Table className="auto-table">
              <TableHead>
                <TableRow>
                  <TableCell>Visa Type</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Short Name</TableCell>
                  <TableCell>Visa Fee</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.filteredList &&
                  this.state.filteredList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.visatype}</TableCell>
                      <TableCell>{item.country}</TableCell>
                      <TableCell>{item.shortname}</TableCell>
                      <TableCell>{item.visafee}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => this.showEdit(item)}
                          className="blue-btn"
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.filteredList &&
                  this.state.filteredList.length === 0 && (
                    <TableRow>
                      <TableCell colSpan="8">{this.noRecordsFound()}</TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden mdUp>
          {this.state.filteredList &&
            this.state.filteredList.map((item, index) => (
              <Paper className="padding-16 mb-8 relative" key={index}>
                <Grid container spacing={8}>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Visa Type
                    </Typography>
                    <Typography>{item.visatype}</Typography>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Country
                    </Typography>
                    <Typography>{item.country}</Typography>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Short Name
                    </Typography>
                    <Typography>{item.shortname}</Typography>
                  </Grid>
                  <Grid item sm={4} xs={6}>
                    <Typography variant="caption" className="caption">
                      Serice Fee
                    </Typography>
                    <Typography>{item.servicefee}</Typography>
                  </Grid>
                </Grid>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => this.showEdit(item)}
                  className="blue-btn"
                >
                  Edit
                </Button>
              </Paper>
            ))}
          {this.state.filteredList && this.state.filteredList.length === 0 && (
            <div>
              <Typography align="center">{this.noRecordsFound()}</Typography>
            </div>
          )}
          <Typography />
        </Hidden>
        <Drawer
          className="full-width-drawer"
          open={this.state.isOpenDrawer}
          anchor="right"
        >
          {this.state.jsonObj && (
            <React.Fragment>
              <Grid container spacing={8}>
                <Grid item lg={6} md={6} sm={6} xs={8}>
                  <Typography variant="h6">Add Visa Info</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={4}>
                  <Button
                    className="right"
                    onClick={() => this.openDrawer(false)}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    Close
                  </Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <hr className="full-width" />
                </Grid>
              </Grid>
              <Paper className="padding-16 visa-details-form">
                <Typography variant="subtitle1" className="bold mb-8">
                  Visa Details
                </Typography>
                <hr className="full-width" />
                <Grid container spacing={16}>
                  <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      label="Visa Name"
                      name="shortname"
                      InputLabelProps={{ shrink: true }}
                      value={this.state.jsonObj.shortname}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TGSelect
                      className="mt-16"
                      name="country"
                      label="Select Country"
                      options={this.props.countriesList}
                      defaultValue={this.state.jsonObj.country}
                      onChange={this.handleCountryChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TGSelect
                      className="mt-16"
                      name="applyfrom"
                      label="Apply To"
                      options={this.props.countriesList}
                      defaultValue={this.state.jsonObj.applyfrom}
                      onChange={this.handleCountryChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                      select
                      fullWidth
                      required
                      className="select"
                      label="Visa Type"
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      name="visatype"
                      value={this.state.jsonObj.visatype}
                      onChange={this.handleInputChange}
                    >
                      {this.state.visaTypes.map((item, i) => (
                        <MenuItem key={i} value={item}>{item}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      label="Visa Fee"
                      name="visafee"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ inputMode: 'numeric', pattern: '[1-9]', min: 0 }}
                      value={this.state.jsonObj.visafee}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      label="Service Fee"
                      name="servicefee"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ inputMode: 'numeric', pattern: '[1-9]', min: 0 }}
                      value={this.state.jsonObj.servicefee}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      label="VFS Fee"
                      name="vfsfee"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ inputMode: 'numeric', pattern: '[1-9]', min: 0 }}
                      value={this.state.jsonObj.vfsfee}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      label="Misc Fee"
                      name="miscfee"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ inputMode: 'numeric', pattern: '[1-9]', min: 0 }}
                      value={this.state.jsonObj.miscfee}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      type="number"
                      label="Other Fee"
                      name="otherfee"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ inputMode: 'numeric', pattern: '[1-9]', min: 0 }}
                      value={this.state.jsonObj.otherfee}
                      onChange={this.handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <br />
              <Paper className="padding-16 visa-details-form">
                <Typography variant="subtitle1" className="bold mb-8">
                  Visa Requirement
                </Typography>
                <hr className="full-width" />
                <Grid container spacing={16}>
                  <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <TextField
                      fullWidth
                      name="totNoOfReq"
                      label="Number Of Requirement"
                      type="number"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ inputMode: 'numeric', pattern: '[1-9]' }}
                      value={this.state.reqObj.totNoOfReq}
                      onChange={this.handleRequirementChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
                  {this.createRequirementUI(this.state.reqObj.reqArr)}
                </Grid>
              </Paper>
              <br />
              <Paper className="padding-16 visa-details-form">
                <Typography variant="subtitle1" className="bold mb-8">
                  Visa Document
                </Typography>
                <hr className="full-width" />
                <Grid container spacing={16}>
                  <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <TextField
                      fullWidth
                      name="totNoOfDoc"
                      label="Number Of Document"
                      type="number"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ inputMode: 'numeric', pattern: '[1-9]' }}
                      value={this.state.docObj.totNoOfDoc}
                      onChange={this.handleDocumentChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
                  {this.createDocumentUI(this.state.docObj.docArr)}
                </Grid>
              </Paper>
              <br />
              <Grid item lg={12} md={12} sm={12} xs={12} className="text-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleSaveOrUpdate(this.state.isEdit)}
                >
                  {this.state.isEdit ? "Update" : "Save"}
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Drawer>
      </React.Fragment>
    );
  }
}

export default VisaInfo;
