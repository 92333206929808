import React, { Component } from "react";
import { Typography, TextField, Grid } from "@material-ui/core";
import FlightQueueTable from "../../../components/queues/flight/FlightQueueTable";
import { getFlightBookingQueues } from "../../../services/queues/FlightQueuesService";
import { Loading } from "../../../components/common/Loading";
import "./FlightBookingQueuesPage.css";
import CustomSnackBar from "../../../components/common/CustomSnackBar";
import { debounce } from "../../../components/common/Utils";

class FlightBookingQueuesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      bookingQueues: undefined,
      filteredQueues: undefined,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  async componentDidMount() {
    await this.getFlightBookingQueuesList();
  }

  getFlightBookingQueuesList = async () => {
    this.setState({
      isLoading: true
    });
    let res = await getFlightBookingQueues();
    this.setState({
      isLoading: false,
      bookingQueues: res,
      filteredQueues: res
    });
  };

  showSnackBar = async (msg, variant) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: variant
      }
    });
    if (variant === "success") {
      await this.getFlightBookingQueuesList();
    }
  };

  showLoading = isLoading => {
    this.setState({
      isLoading: isLoading
    });
  };

  handleChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.bookingQueues.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredQueues: data
    });
  }, 500);

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography variant="h6" className="mb-8">
              Flight Booking Queue
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              variant="outlined"
              className="search-field mb-8"
              name="searchTerm"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>

        {this.state.filteredQueues && (
          <FlightQueueTable
            showLoading={this.showLoading}
            showSnackBar={this.showSnackBar}
            bookingQueues={this.state.filteredQueues}
          />
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default FlightBookingQueuesPage;
