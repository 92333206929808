import React, { Component } from "react";
import { withCustomSnackBarContext } from "../../common/CustomSnackBarProvider";
import { withLoadingContext } from "../../common/LoadingProvider";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import {
  getAllCorporateTravellers,
  getCardDetailsByCardId,
  registerCard
} from "../../../services/inventory/CardsService";
import {getUserProfile} from '../../../services/common/CommonService'

class RegisterCard extends Component {
  userProfile = undefined;
  constructor(props) {
    super(props);
    this.userProfile = getUserProfile();
    this.state = {
      isError: false,
      isPanError: false,
      isAadhaarError: false,
      hasKyc: false,
      registerObj: {
        recordid: "",
        profileid: "",
        corporateid: "",
        proxynumber: "",
        alias: "",
        cardid: this.props.card.cardid,
        title: "",
        first_name: "",
        last_name: "",
        dob: "",
        gender: "",
        mobile_number: "",
        email_address: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        postalcode: "",
        mail_address1: "",
        mail_address2: "",
        mail_city: "",
        mail_state: "",
        mail_country: "",
        mail_postalcode: "",
        idtype: "AADHAAR",
        kycdocs: [
          {
            KycDocument: [
              { docType: "AADHAAR", docNo: "", docExpiry: "", docUrl: "" },
              { docType: "PAN", docNo: "", docExpiry: "", docUrl: "" },
              { docType: "PASSPORT", docNo: "", docExpiry: "", docUrl: "" }
            ]
          }
        ],
        kycStatus: "FULL_KYC",
        eKycRefNo: "1234",
        idnumber: "",
        expirydate: "",
        countryofissue: "INDIA",
        kitNo: "",
        otherjson: "",
        addedby: "",
        addedon: "",
        customerid: ""
      },
      cardObj: undefined,
      showCTList: false,
      ctList: []
    };
  }

  handleChange = event => {
    const eve = { ...event };
    this.setState(prevState => ({
      registerObj: {
        ...prevState.registerObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  async componentDidMount() {
    let data1 = await getCardDetailsByCardId(this.props.card.cardid);
    let data = await getAllCorporateTravellers(this.props.card.corporateid);
    this.setState(prevState => ({
      registerObj: {
        ...prevState.registerObj,
        kitNo: data1.proxynumber,
        proxynumber: data1.proxynumber,
        alias: data1.proxynumber
      },
      cardObj: data1,
      ctList: data
    }));
  }

  showEmployees = isShow => {
    this.setState({
      showCTList: isShow
    });
  };

  isValidPanCard = panNo => {
    const regEx = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    const isMatch = panNo.match(regEx);
    if (isMatch) {
      return true;
    } else {
      return false;
    }
  };

  isValidAadharCard = aadhar => {
    let regEx1 = /^\d{12}$/;
    let regEx2 = /^\d{16}$/;

    if (aadhar.match(regEx1)) {
      return true;
    } else if (aadhar.match(regEx2)) {
      return true;
    } else {
      return false;
    }
  };

  handleCTSelect = employee => {
    this.setState(prevState => ({
      registerObj: {
        ...prevState.registerObj,
        title: employee.title,
        first_name: employee.first_name,
        last_name: employee.last_name,
        mobile_number: employee.mobile,
        email_address: employee.email,
        dob: employee.spldate1,
        gender: employee.gender === "male" ? "M" : "F",
        address1: employee.address1 === "NA" ? "" : employee.address1,
        address2: employee.address2 === "NA" ? "" : employee.address2,
        city: employee.city === "NA" ? "" : employee.city,
        state: employee.state === "NA" ? "" : employee.state,
        country: employee.country,
        postalcode: employee.pincode === 0 ? "" : employee.pincode,
        profileid: employee.profileid,
        corporateid: employee.corporateid,
        customerid: employee.profileid
      },
      showCTList: false
    }));
  };

  handleKycChagne = (event, index) => {
    const val = event.target.value;
    let data = [...this.state.registerObj.kycdocs[0].KycDocument];
    data[index][event.target.name] = val;
    if (data[index].docType === "PAN") {
      if (!this.isValidPanCard(val)) {
        this.setState({
          isPanError: true
        });
      } else {
        this.setState({
          isPanError: false
        });
      }
    } else if (data[index].docType === "AADHAAR") {
      if (!this.isValidAadharCard(val)) {
        this.setState({
          isAadhaarError: true
        });
      } else {
        this.setState(prevState => ({
          registerObj: {
            ...prevState.registerObj,
            idnumber: val,
            kycdocs: [
              {
                KycDocument: data
              }
            ]
          },
          hasKyc: true,
          isAadhaarError: false
        }));
      }
    }
    this.setState(prevState => ({
      registerObj: {
        ...prevState.registerObj,
        kycdocs: [
          {
            KycDocument: data
          }
        ]
      }
    }));
  };

  handleRegister = async () => {
    if (
      this.state.registerObj.title === "" ||
      this.state.registerObj.first_name === "" ||
      this.state.registerObj.last_name === "" ||
      this.state.registerObj.email_address === "" ||
      this.state.registerObj.mobile_number === "" ||
      this.state.registerObj.dob === "" ||
      this.state.registerObj.address1 === "" ||
      this.state.registerObj.address2 === "" ||
      this.state.registerObj.city === "" ||
      this.state.registerObj.state === "" ||
      this.state.registerObj.country === "" ||
      this.state.registerObj.pincode === ""
    ) {
      this.setState({
        isError: true
      });
    } else if (this.state.hasKyc === false) {
      window.alert("Please enter AADHAAR document information.");
    } else {
      if (this.state.isPanError) {
        return false;
      } else {
        this.props.loadingContext.show();
        let res = await registerCard(this.state.registerObj);
        if (res) {
          this.props.snackBarContext.show(
            "Card has been registered successfully.",
            "success"
          );
        } else {
          this.props.snackBarContext.show(
            "Failed to register the card.",
            "error"
          );
        }
        this.props.loadingContext.hide();
        this.setState({
          isError: false
        });
        this.props.showRegisterDrawer(false, undefined, true);
      }
    }
  };

  render() {
    return (
      <div>
        <Grid container spacing={16}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography variant="h5">Register Card</Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              className="right"
              onClick={() =>
                this.props.showRegisterDrawer(false, undefined, false)
              }
            >
              Close
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Title"
              name="title"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.title}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.registerObj.title === "" && (
              <FormHelperText className="invalid-text">
                Title is required
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="Fist Name"
              name="first_name"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.first_name}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.registerObj.first_name === "" && (
              <FormHelperText className="invalid-text">
                First Name is required
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="Last Name"
              name="last_name"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.last_name}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.registerObj.last_name === "" && (
              <FormHelperText className="invalid-text">
                Last Name is required.
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => this.showEmployees(true)}
            >
              Pick
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Email"
              name="email_address"
              type="email"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.email_address}
              onChange={this.handleChange}
            />
            {this.state.isError &&
              this.state.registerObj.email_address === "" && (
                <FormHelperText className="invalid-text">
                  Email is required.
                </FormHelperText>
              )}
          </Grid>{" "}
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Mobile Number"
              name="mobile_number"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.mobile_number}
              onChange={this.handleChange}
            />
            {this.state.isError &&
              this.state.registerObj.mobile_number === "" && (
                <FormHelperText className="invalid-text">
                  Mobile No. is required.
                </FormHelperText>
              )}
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              label="Date Of Birth"
              name="dob"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.dob}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.registerObj.dob === "" && (
              <FormHelperText className="invalid-text">
                Date Of Birth is required.
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="Address Line 1"
              name="address1"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.address1}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.registerObj.address1 === "" && (
              <FormHelperText className="invalid-text">
                Address Line 1 is required.
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="Address Line 2"
              name="address2"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.address2}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.registerObj.address2 === "" && (
              <FormHelperText className="invalid-text">
                Address Line 2 is required.
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="City"
              name="city"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.city}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.registerObj.city === "" && (
              <FormHelperText className="invalid-text">
                City is required.
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="State"
              name="state"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.state}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.registerObj.state === "" && (
              <FormHelperText className="invalid-text">
                State is required.
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="Country"
              name="country"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.country}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.registerObj.country === "" && (
              <FormHelperText className="invalid-text">
                Country is required.
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="PIN Code"
              type="number"
              name="postalcode"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.postalcode}
              onChange={this.handleChange}
            />
            {this.state.isError && this.state.registerObj.postalcode === "" && (
              <FormHelperText className="invalid-text">
                PIN Code is required.
              </FormHelperText>
            )}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              readOnly
              variant="outlined"
              label="KIT No"
              InputLabelProps={{ shrink: true }}
              value={this.state.registerObj.kitNo}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="subtitle1" color="secondary">
              KYC Documents
            </Typography>
          </Grid>
          {this.state.registerObj.kycdocs[0].KycDocument.map((kyc, index) => (
            <React.Fragment key={index}>
              {index === 0 && (
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Typography>AADHAAR NO.</Typography>
                  <small>(Mandatory)</small>
                </Grid>
              )}
              {index === 1 && (
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Typography>PAN Card</Typography>
                </Grid>
              )}
              {index === 2 && (
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Typography>PASSPORT</Typography>
                </Grid>
              )}
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <TextField
                  type="text"
                  fullWidth
                  variant="outlined"
                  label="Document No"
                  name="docNo"
                  InputLabelProps={{ shrink: true }}
                  value={kyc.docNo}
                  onChange={event => this.handleKycChagne(event, index)}
                />
                {kyc.docType === "PAN" && this.state.isPanError && (
                  <FormHelperText className="invalid-text">
                    Please Enter Valid Pan Card No.
                  </FormHelperText>
                )}
                {kyc.docType === "AADHAAR" && this.state.isAadhaarError && (
                  <FormHelperText className="invalid-text">
                    Please Enter Valid Aadhaar No.
                  </FormHelperText>
                )}
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                {index === 2 && (
                  <TextField
                    type="date"
                    fullWidth
                    variant="outlined"
                    label="Expiry Date"
                    name="docExpiry"
                    InputLabelProps={{ shrink: true }}
                    value={kyc.docExpiry}
                    onChange={event => this.handleKycChagne(event, index)}
                  />
                )}
              </Grid>
            </React.Fragment>
          ))}
          <Grid item lg={12} md={12} sm={12} xs={12} className="mt-8">
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={this.handleRegister}
            >
              Register
            </Button>
          </Grid>
        </Grid>
        <Drawer
          open={this.state.showCTList}
          anchor="right"
          className="ct-select-drawer"
        >
          <Grid container spacing={16}>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography variant="h5">Register Card</Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                className="right"
                onClick={() => this.showEmployees(false)}
              >
                Close
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider />
            </Grid>
            {this.state.ctList.map((ct, index) => (
              <React.Fragment key={index}>
                <Grid item lg={10} md={10} sm={10} xs={10}>
                  <Typography variant="subtitle1">
                    {ct.title} {ct.first_name} {ct.last_name}
                  </Typography>
                  <Typography variant="subtitle2">{ct.email}</Typography>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => this.handleCTSelect(ct)}
                  >
                    Select
                  </Button>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Drawer>
      </div>
    );
  }
}

export default withCustomSnackBarContext(withLoadingContext(RegisterCard));
