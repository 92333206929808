import React from "react";
import ReactDOM from "react-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import blue from "@material-ui/core/colors/blue";
import deepOrange from "@material-ui/core/colors/deepOrange";
import red from "@material-ui/core/colors/red";
import App from "./App";
import "./index.css";
import { LoadingProvider } from "./components/common/LoadingProvider";
import { CustomSnackBarProvider } from "./components/common/CustomSnackBarProvider";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: blue["500"],
      main: "#0c385f"
    },
    secondary: {
      light: "#ff844c",
      main: deepOrange["500"]
    },
    error: red
  },
  typography: {
    useNextVariants: true,
    fontSize: 12.5,
    fontFamily:
      "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI','Helvetica Neue',Arial,sans-serif"
  }
});

var Application = () => {
  return (
    <CustomSnackBarProvider>
      <LoadingProvider>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </MuiThemeProvider>
      </LoadingProvider>
    </CustomSnackBarProvider>
  );
};
ReactDOM.render(<Application />, document.getElementById("root"));
