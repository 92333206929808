import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { getRuleMasters, getRuleMastersDetails, deleteRuleMasters } from "../../services/rulemasters/RuleMastersService";
import { Loading } from "../common/Loading";
import { debounce } from "../common/Utils";
import CustomSnackBar from "../common/CustomSnackBar";
import AddOrUpdateRuleMasters from "./AddOrUpdateRuleMasters";

class ListRuleMasters extends Component {
  sortType = "asc";
  isEdit = false;
  constructor(props) {
    super(props);
    this.state = {
      ruleMasters: undefined,
      filteredRuleMasters: undefined,
      isLoading: false,
      isAddRuleMasters: false,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      },
      isError: false,
      ruleMastersObj: undefined
    };
  }

  initializeObj = () => {
    this.setState({
      ruleMastersObj: {
        carrier: "",
        carriername: "",
        origin: "",
        destination: "",
        classofbooking: "",
        cabinclass: "",
        adultfee: 0,
        childfee: 0,
        infantfee: 0,
        specialinstructions: "",
        ruletype: "",
        canxresc: "",
        domint: "",
        faretype: "",
        fromtime: "",
        totime: ""
      }
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  showLoading = loading => {
    this.setState({
      isLoading: loading
    });
  };

  async componentDidMount() {
    this.initializeObj();
    await this.getRuleMastersList();
  }

  getRuleMastersList = async () => {
    this.showLoading(true);
    let res = await getRuleMasters();
    this.setState({
      ruleMasters: res,
      filteredRuleMasters: res
    });
    this.showLoading(false);
  };

  handleSearchChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.ruleMasters.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredRuleMasters: data
    });
  }, 500);

  sortBy = (property, type) => {
    let data = [...this.state.filteredRuleMasters];
    if (type === this.sortType) {
      data.sort((a, b) => (a[property] > b[property] ? -1 : 1));
      this.sortType = "desc";
    } else {
      data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
      this.sortType = "asc";
    }
    this.setState({
      filteredRuleMasters: data
    });
  };

  handleEdit = async item => {
    this.showLoading(true);
    let res = await getRuleMastersDetails(item.recordid);
    this.isEdit = true;
    this.setState({
      ruleMastersObj: res ? res : item,
      isLoading: false,
      isAddRuleMasters: true
    });
  };

  handleAddRuleMasters = () => {
    this.setState({
      isAddRuleMasters: true
    });
  };

  handleDelete = async (recordid, index) => {
    let isConfirm = window.confirm(
      "Are you sure, do you want to delete this 'Rule'?"
    );
    if (isConfirm === true) {
      let data = [...this.state.ruleMasters];
      data.splice(index, 1);
      if (recordid !== "" || recordid !== undefined) {
        this.showLoading(true);
        let res = await deleteRuleMasters(recordid);
        if (res) {
          if (res.ResponseStatus.status === "OK") {
            this.showSnackBar(res.ResponseStatus.message, "success");
          } else {
            this.showSnackBar(res.ResponseStatus.message, "error");
          }
        } else {
          this.showSnackBar(
            "Failed to delete the rule. Pelase try again.",
            "error"
          );
        }
        this.showLoading(false);
      }
      this.setState({
        filteredRuleMasters: data
      });
    }
  };

  closeDrawer = () => {
    this.isEdit = false;
    this.initializeObj();
    this.setState({
      isAddRuleMasters: false
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Typography variant="h6" className="mb-8">
              Rule Masters
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Button
              className="right"
              variant="contained"
              color="primary"
              onClick={this.handleAddRuleMasters}
            >
              Add Rule Masters
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              className="cred-key mb-8"
              name="searchTerm"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleSearchChange}
            />
          </Grid>
        </Grid>
        <Table className="table-auto">
          <TableHead>
            <TableRow>
              <TableCell>
                <Button onClick={() => this.sortBy("carriername", "asc")}>
                  Carrier Name
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("cabinclass", "asc")}>
                  Cabin Class
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("domint", "asc")}>
                  Domint
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("origin", "asc")}>
                  Origin
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("destination", "asc")}>
                  Destination
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("ruletype", "asc")}>
                  Rule Type
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("faretype", "asc")}>
                  Fare Type
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("canxresc", "asc")}>
                  Canxresc
                </Button>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.filteredRuleMasters &&
              this.state.filteredRuleMasters.map((sup, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{sup.carriername}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.cabinclass}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.domint}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.origin}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.destination}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.ruletype}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.faretype}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.canxresc}</Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => this.handleEdit(sup)}
                      className="blue-btn"
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => this.handleDelete(sup.recordid, index)}
                      className="blue-btn"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {this.state.filteredRuleMasters &&
              this.state.filteredRuleMasters.length === 0 && (
                <TableRow>
                  <TableCell colSpan="3">No rule found.</TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>

        {this.state.isAddRuleMasters && (
          <AddOrUpdateRuleMasters
            closeDrawer={this.closeDrawer}
            showLoading={this.showLoading}
            showSnackBar={this.showSnackBar}
            ruleMastersObj={this.state.ruleMastersObj}
            getRuleMastersList={this.getRuleMastersList}
            isEdit={this.isEdit}
          />
        )}

        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleSnackBarClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ListRuleMasters;
