import React, { Component } from "react";
import {Paper, Typography, Grid, TextField} from "@material-ui/core";

class FareBreakUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fareObj: props.fareObj
    };
  }

  handleChange = event => {
    let eve = { ...event };
    this.setState(
      prevState => ({
        fareObj: {
          ...prevState.fareObj,
          [eve.target.name]: eve.target.value
        }
      }),
      () => this.updateTotalInvoice()
    );
  };

  updateTotalInvoice = () => {
    const { wpServiceTax, basefare, wpServiceCharge, pgcharges, agentservicecharge } = this.state.fareObj;
    this.setState(
      prevState => ({
        fareObj: {
          ...prevState.fareObj,
          totalFare: Number(wpServiceTax) + Number(basefare) + Number(wpServiceCharge) + Number(pgcharges) + Number(agentservicecharge),
        }
      }),
      () => this.props.updateFare(this.state.fareObj)
    );
  }

  render() {
    return (
      <Paper className="padding-16 farebreakup-form">
        <Typography variant="subtitle1" className="bold">
          Fare Breakup
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              required
              type="number"
              name="basefare"
              label="Base Fare (Rs)"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.fareObj.basefare}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              required
              name="wpServiceCharge"
              type="number"
              label="Service Charges(IRCTC) (Rs)"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.fareObj.wpServiceCharge}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              required
              name="wpServiceTax"
              type="number"
              label="Service Tax(IRCTC) (Rs)"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.fareObj.wpServiceTax}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              required
              name="pgcharges"
              type="number"
              label="Processing Fee (Rs)"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.fareObj.pgcharges}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              required
              name="agentservicecharge"
              type="number"
              label="Agent Service Fee (Rs)"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.fareObj.agentservicecharge}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={12}>
            <TextField
              variant="outlined"
              readOnly
              required
              fullWidth
              name="totalFare"
              type="number"
              label="Total Invoice (Rs)"
              InputLabelProps={{
                shrink: true
              }}
              value={this.state.fareObj.totalFare}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default FareBreakUp;
