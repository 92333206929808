import React, { Component } from "react";
import ETicket from "../../../components/queues/bus/ETicket";
import "./BusBookingTicketPage.css";

class BusBookingTicketPage extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <ETicket />
      </React.Fragment>
    );
  }
}

export default BusBookingTicketPage;
