import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import {
  getAirlines,
  getPromoCodes,
  getPromoCodeDetails,
  deletePromoCode,
} from "../../services/promocode/PromoCodeService";
import { Loading } from "../common/Loading";
import { debounce } from "../common/Utils";
import CustomSnackBar from "../common/CustomSnackBar";
import AddOrUpdatePromoCode from "./AddOrUpdatePromoCode";

class ListPromoCodes extends Component {
  sortType = "asc";
  selectedPromoCode = undefined;
  isEdit = false;
  constructor(props) {
    super(props);
    this.state = {
      promoCodes: undefined,
      filteredPromoCodes: undefined,
      isLoading: false,
      isAddPromoCode: false,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      },
      airlinesList: [],
      isError: false,
      promoCodeObj: undefined
    };
  }

  initializePromoCodeObj = () => {
    this.setState({
      promoCodeObj: {
        promoname: "",
        promocode: "",
        promo_value: "",
        promo_value_unit: "",
        value_component: "all",
        promo_type: "",
        promo_subtype: "all",
        requires_login: "",
        promo_startdate: "",
        promo_enddate: "",
        promo_status: "",
        singleuse: "",
        available_promocodes: "",
        addedby: "",
        addedon: "",
        remarks: "",
        recalloncancellation: "",
        freeshipping: "",
        spare1: "",
        spare2: "",
        cutandpay: "",
        cashback_rules: "",
        perpax: "",
        owrt_separate: "",
        minimum_fare: "",
        exclude_carriers: "",
        channel: "",
        dminusdays: 0,
        dplusdays: 0,
        statuscode: "",
        statusmessage: ""
      }
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  showLoading = loading => {
    this.setState({
      isLoading: loading
    });
  };

  async componentDidMount() {
    this.initializePromoCodeObj();
    await this.getPromoCodesList();
    await this.getAirlinesList();
  }

  getAirlinesList = async () => {
    this.showLoading(true);
    let res = await getAirlines();
    this.setState({
      airlinesList: res
    });
    this.showLoading(false);
  };

  getPromoCodesList = async () => {
    this.showLoading(true);
    let res = await getPromoCodes();
    this.setState({
      promoCodes: res,
      filteredPromoCodes: res
    });
    this.showLoading(false);
  };

  handleSearchChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.promoCodes.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredPromoCodes: data
    });
  }, 500);

  sortBy = (property, type) => {
    let data = [...this.state.filteredPromoCodes];
    if (type === this.sortType) {
      data.sort((a, b) => (a[property] > b[property] ? -1 : 1));
      this.sortType = "desc";
    } else {
      data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
      this.sortType = "asc";
    }
    this.setState({
      filteredPromoCodes: data
    });
  };

  handleEdit = async promocode => {
    this.showLoading(true);
    let res = await getPromoCodeDetails(promocode);
    this.selectedPromoCode = res;
    this.isEdit = true;
    this.setState({
      promoCodeObj: res,
      isLoading: false,
      isAddPromoCode: true
    });
  };

  handleAddPromoCode = () => {
    this.setState({
      isAddPromoCode: true
    });
  };

  handleDelete = async (promocode, index) => {
    let isConfirm = window.confirm(
      "Are you sure, do you want to delete this 'Promo Code'?"
    );
    if (isConfirm === true) {
      let data = [...this.state.promoCodes];
      data.splice(index, 1);
      if (promocode !== "" || promocode !== undefined) {
        this.showLoading(true);
        let res = await deletePromoCode(promocode);
        if (res) {
          if (res.ResponseStatus.status === "OK") {
            this.showSnackBar(res.ResponseStatus.message, "success");
          } else {
            this.showSnackBar(res.ResponseStatus.message, "error");
          }
        } else {
          this.showSnackBar(
            "Failed to delete the Promo Code. Pelase try again.",
            "error"
          );
        }
        this.showLoading(false);
      }
      this.setState({
        filteredPromoCodes: data
      });
    }
  };

  closeDrawer = () => {
    this.isEdit = false;
    this.initializePromoCodeObj();
    this.setState({
      isAddPromoCode: false
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Typography variant="h6" className="mb-8">
              Promo Codes
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Button
              className="right"
              variant="contained"
              color="primary"
              onClick={this.handleAddPromoCode}
            >
              Add Promo Code
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              className="cred-key mb-8"
              name="searchTerm"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleSearchChange}
            />
          </Grid>
        </Grid>
        <Table className="table-auto">
          <TableHead>
            <TableRow>
              <TableCell>
                <Button onClick={() => this.sortBy("promoname", "asc")}>
                  Promo Name
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("promocode", "asc")}>
                  Promo Code
                  </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("promo_type", "asc")}>
                  Promo Type
                  </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("promo_value", "asc")}>
                  Promo Value
                  </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => this.sortBy("promo_status", "asc")}>
                  Promo Status
                  </Button>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.filteredPromoCodes &&
              this.state.filteredPromoCodes.map((sup, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{sup.promoname}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.promocode}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.promo_type}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.promo_value}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{sup.promo_status}</Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => this.handleEdit(sup.promocode)}
                      className="blue-btn"
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => this.handleDelete(sup.promocode, index)}
                      className="blue-btn"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {this.state.filteredPromoCodes &&
              this.state.filteredPromoCodes.length === 0 && (
                <TableRow>
                  <TableCell colSpan="3">No promo code found.</TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>

        {this.state.isAddPromoCode && (
          <AddOrUpdatePromoCode
            closeDrawer={this.closeDrawer}
            showLoading={this.showLoading}
            showSnackBar={this.showSnackBar}
            airlinesList={this.state.airlinesList}
            promoCodeObj={this.state.promoCodeObj}
            getPromoCodesList={this.getPromoCodesList}
            isEdit={this.isEdit}
          />
        )}

        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleSnackBarClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ListPromoCodes;
