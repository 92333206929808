import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Popover,
  Paper,
  Grid,
  Typography,
  Hidden,
  TextField,
} from "@material-ui/core";
import FileExcel from "mdi-material-ui/FileExcel";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router-dom";
import { debounce, downloadExcel } from "../../common/Utils";
import { rollbackTrainTicket } from "../../../services/queues/TrainQueuesService";
import { blockTicket, getViewRemarks } from "../../../services/common/CommonService";
import RollbackTransaction from "../common/RollbackTransaction";
import UpdateStatus from "../common/UpdateStatus";
import RetryBooking from "../common/RetryBooking";
import TransactionReviewLog from "../common/TransactionReviewLog";
import MapBooking from "../../transactions/MapBooking";

class TrainQueueTable extends React.Component {
  selectedRow = undefined;
  allTrainQueues = [];
  distinctAgentidArr = [];
  constructor(props) {
    super(props);
    this.allTrainQueues = props.trainQueues;
    this.state = {
      totalAmount: 0,
      openPopover: false,
      anchorEl: null,
      isShowStatus: false,
      isShowRetry: false,
      isShowRollback: false,
      isShowMapping: false,
      trainQueues: props.trainQueues,
      sortType: "asc",
      filterByCompany: "all",
      searchText: "",
      isReviewDrawerOpen: false,
      transactionRemarkLog: [],
    };
  }

  componentDidMount() {
    this.calculateTotalAmount();
    this.getDistinctAgentID();
  }

  componentDidUpdate(prevProps) {
    if (this.props.trainQueues !== prevProps.trainQueues) {
      this.allTrainQueues = this.props.trainQueues;
      this.setState(
        {
          trainQueues: this.props.trainQueues
        },
        () => {
          this.calculateTotalAmount();
          this.getDistinctAgentID();
        }
      );
    }
  }

  getDistinctAgentID = () => {
    this.distinctAgentidArr = [...new Map(this.props.trainQueues.map(item =>
      [item["agentid"], item])).values()];
  }

  handlePopoverOpen = (event, hotel) => {
    this.selectedRow = hotel;
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget
    });
  };

  handlePopoverClose = () => {
    this.setState({
      openPopover: false
    });
  };

  handleAction = type => {
    switch (type) {
      case "ticket":
        window.open(`/train-ticket/${this.selectedRow.txid}`, "_blank");
        break;
      case "irctceticket":
        window.open(`${this.selectedRow.original_eticket_url}`, "_blank");
        break;
      case "update":
        this.props.history.push(`/update-train-queue/${this.selectedRow.txid}`);
        break;
      case "updateFare":
        this.props.history.push(`/update-train-fare/${this.selectedRow.txid}`);
        break;
      case "qualityCheck":
        this.props.history.push(`/quality-check/train/${this.selectedRow.txid}`);
        break;
      default:
        break;
    }
  };

  handleRollback = async data => {
    if (data) {
      this.props.showLoading(true);
      let res = await rollbackTrainTicket(this.selectedRow.txid, data);
      if (res) {
        if (res.ResponseStatus.status === "OK") {
          this.props.showSnackBar(res.ResponseStatus.message, "success");
        } else {
          this.props.showSnackBar(res.ResponseStatus.message, "error");
        }
      } else {
        this.props.showSnackBar(
          "Failed to rollback the transaction. Please try again.",
          "error"
        );
      }
      this.closeRollbackDialog();
      this.props.showLoading(false);
      this.handlePopoverClose();
    }
  };

  showLoading = flag => {
    this.props.showLoading(flag);
  };

  openRollbackDialog = () => {
    this.setState({
      isShowRollback: true
    });
  };

  closeRollbackDialog = () => {
    this.setState({
      isShowRollback: false
    });
  };

  openStatusDialog = () => {
    this.setState({
      isShowStatus: true
    });
  };

  closeStatusDialog = () => {
    this.setState({
      isShowStatus: false
    });
  };

  openRetryDialog = () => {
    this.setState({
      isShowRetry: true
    });
  };

  closeRetryDialog = () => {
    this.setState({
      isShowRetry: false
    });
  };

  sortBy = (property, type, sortType) => {
    let data = [...this.state.trainQueues];
    if (type === "string") {
      if (this.state.sortType === "asc") {
        data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
        sortType = "desc";
      } else {
        data.sort((a, b) => (b[property] < a[property] ? -1 : 1));
        sortType = "asc";
      }
    } else {
      if (this.state.sortType === sortType) {
        data.sort((a, b) => new Date(b[property]) - new Date(a[property]));
        sortType = "desc";
      } else {
        data.sort((a, b) => new Date(a[property]) - new Date(b[property]));
        sortType = "asc";
      }
    }
    this.setState({
      trainQueues: data,
      sortType: sortType
    });
  };

  handleSearchChange = event => {
    const { value } = event.target;
    this.setState({
      searchText: value,
    });
    if (value === "") {
      this.setState({
        trainQueues: this.allTrainQueues
      });
    } else {
      this.setSearchResults(value);
    }
  };

  setSearchResults = debounce(val => {
    let data = this.allTrainQueues.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    if (this.state.filterByCompany !== "all") {
      data = data.filter(r => r["agentid"] === this.state.filterByCompany);
    }
    this.setState(
      {
        trainQueues: data
      },
      () => this.calculateTotalAmount()
    );
  }, 500);

  calculateTotalAmount = () => {
    const totalAmount = this.state.trainQueues.reduce((acc, cur) => {
      acc += Math.round(cur["totalfare"]);
      return acc;
    }, 0);
    this.setState({
      totalAmount
    });
  };

  downloadExcel = () => {
    this.props.showLoading(true);
    let header = [];
    for (var prop in this.state.trainQueues[0]) {
      header.push(prop);
    }
    let output = header.join(',')+"\r\n";
    output += this.state.trainQueues.map((item, index) => {
      let transactions = [];
      for (let val of header) {
        let str = (item[val] ? item[val] : "").toString().replace(/,/g, "");
        transactions.push(str);
      }
      return transactions;
    }).join("\r\n");
    downloadExcel(output, "Train_Transactions");
    this.props.showLoading(false);
  };
 
  handleFilterByCompany = event => {
    const val = event.target.value;
    this.setState({
        filterByCompany: val,
      },
      () => this.handleFilter()
    );
  };
 
  handleFilter = () => {
    let data = [...this.allTrainQueues];
    if (this.state.filterByCompany !== "all") {
      data = data.filter(r => r["agentid"] === this.state.filterByCompany);
    }
    if (this.state.searchText) {
      data = this.allTrainQueues.filter(o =>
        Object.keys(o).some(k =>
          o[k]
          .toString()
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase())
        )
      );
    }
    this.setState({
        trainQueues: data,
      },
      () => {
        this.calculateTotalAmount();
      }
    );
  };

  handleBlock = async (txId) => {
    let isConfirm = window.confirm(
      "Are you sure, Do you want to block the ticket?"
    );
    if (isConfirm === true) {
      this.props.showLoading(true);
      let reqObj = {
        txtype: "traincanx",
        txid: txId,
      };
      let res = await blockTicket(reqObj);
      if (res) {
        if (res.ApiStatus.Status === "OK") {
          this.props.showSnackBar(res.ApiStatus.Message, "success");
        } else {
          this.props.showSnackBar(res.ApiStatus.Message, "error");
        }
      }
      this.props.showLoading(false);
      this.handlePopoverClose();
    }
  };

  handleViewReviews = async (txId) => {
    this.props.showLoading(true);
    let res = await getViewRemarks(txId);
    try {
      if (res) {
        let remarkArr = Array.isArray(res.TransactionRemarkLog)
          ? res.TransactionRemarkLog
          : [res.TransactionRemarkLog];
        this.setState({
          transactionRemarkLog: remarkArr,
          isReviewDrawerOpen: true,
        });
      } else {
        this.props.showSnackBar("No reviews found", "success");
        this.setState({
          transactionRemarkLog: [],
          isReviewDrawerOpen: true,
        });
      }
    }
    catch (e) {
      this.props.showSnackBar("Error!", "error");
      this.setState({
        transactionRemarkLog: [],
        isReviewDrawerOpen: false,
      });
    }
    this.props.showLoading(false);
    this.handlePopoverClose();
  };

  openReviewCardDrawer = (isShow) => {
    this.setState({
      isReviewDrawerOpen: isShow,
    });
  };

  handleEdit = async (txid) => {
    this.showLoading(true);
    this.setState({
      isShowMapping: true,
    });
    this.showLoading(false);
  };

  closeDrawer = () => {
    this.setState({
      isShowMapping: false,
    });
  };

  getTags = (tags) => {
    if (tags) {
      return (<Typography variant="span" className="red-text-container">
          (<Typography variant="span" className="red-text">
            {tags}
          </Typography>)
        </Typography>);
    } else {
      return '';
    }
  }

  render() {
    return (
      <React.Fragment>
        <Hidden xsDown>
          <Paper className="padding-16">
            <Grid container spacing={16}>
              <Grid item lg={1} md={3} sm={3} xs={3}>
                <Typography variant="caption">Total Amount</Typography>
                <Typography variant="h5">{this.state.totalAmount}</Typography>
              </Grid>
              <Grid item lg={1} md={3} sm={3} xs={3}>
                <Typography variant="caption">Total Transactions</Typography>
                <Typography variant="h5">{this.state.trainQueues ? this.state.trainQueues.length : 0 }</Typography>
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button variant="contained" color="primary" title="Download Excel File" onClick={this.downloadExcel} size="small" className="right">
                  <FileExcel />
                </Button>
              </Grid>
              <Grid item lg={2} md={8} sm={8} xs={8}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  name="filterByStatus"
                  label="Filter By Company"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.filterByCompany}
                  onChange={this.handleFilterByCompany}
                >
                  <MenuItem value="all"> All</MenuItem>
                  {this.distinctAgentidArr.map((val, key) => (
                    <MenuItem key={key} value={val.agentid}>{`${val.agent_company} (${val.agentid})`}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <TextField
                  name="searchText"
                  variant="outlined"
                  label="Search"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={this.state.searchText}
                  onChange={this.handleSearchChange}
                />
              </Grid>
            </Grid>
            <Table className="queue-table">
              <TableHead>
                <TableRow>
                  <TableCell>Txid</TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("bookingdate", "date", "asc")}
                    >
                      Tx. Date
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("tripid", "string", "asc")}
                    >
                      Tripid
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("agent_company", "string", "asc")}
                    >
                      Company
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("paxname", "string", "asc")}
                    >
                      Pax.Name
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("bookedbyid", "string", "asc")}
                    >
                      BookedByID
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("jdate", "date", "asc")}
                    >
                      Journey Date
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("trainname", "string", "asc")}
                    >
                      Train Name
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("pnr", "string", "asc")}
                    >
                      PNR
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("ticketnumber", "string", "asc")}
                    >
                      Ticket Number
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("totalinvoice", "string", "asc")}
                    >
                      InvAmount
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("bookingstatus", "string", "asc")}
                    >
                      BookingStatus
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("blockedbyid", "string", "asc")}
                    >
                      BlockedBy
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("issupplierpurchaseupdated", "string", "asc")}
                    >
                      QC Status
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("tatkalrequested", "string", "asc")}
                    >
                      TatkalRequested
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("istatkalapproved", "string", "asc")}
                    >
                      IsTatkalApproved
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("tatkaldate", "date", "asc")}
                    >
                      TatkalDate
                    </Button>
                  </TableCell>
                  <TableCell>Select</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.trainQueues &&
                  this.state.trainQueues.map((train, index) => (
                    <TableRow key={index}>
                      <TableCell>{train.txid}</TableCell>
                      <TableCell>{train.bookingdate}</TableCell>
                      <TableCell>
                        <Button
                          component="button"
                          variant="body2"
                          onClick={() => window.open(`/trip-ticket/${train.tripid}`, "_blank")}
                        >
                          {train.tripid}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {train.agent_company} ({train.agentid})
                      </TableCell>
                      <TableCell>{train.paxname} { this.getTags(train.tags)}</TableCell>
                      <TableCell>{train.bookedbyid}</TableCell>
                      <TableCell>{train.jdate}</TableCell>
                      <TableCell>
                        <Typography>
                          {train.trainname} ({train.trainnumber})
                        </Typography>
                        <Typography>
                          {train.fromstncode} -> {train.tostncode}
                        </Typography>
                      </TableCell>
                      <TableCell>{train.pnr}</TableCell>
                      <TableCell>{train.ticketnumber}</TableCell>
                      <TableCell>{Math.round(train.totalinvoice)}</TableCell>
                      <TableCell>{train.bookingstatus}</TableCell>
                      <TableCell>{train.blockedbyid}</TableCell>
                      <TableCell>{train.issupplierpurchaseupdated}</TableCell>
                      <TableCell>{train.tatkalrequested ? "true" : "false"}</TableCell>
                      <TableCell>{train.istatkalapproved ? "true" : "false"}</TableCell>
                      <TableCell>{train.tatkaldate}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          className="blue-btn"
                          onClick={event =>
                            this.handlePopoverOpen(event, train)
                          }
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.trainQueues && this.state.trainQueues.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="12">No records found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden smUp>
          {this.state.trainQueues &&
            this.state.trainQueues.map((train, index) => (
              <Paper key={index} className="padding-16 mb-8">
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      TxID
                    </Typography>
                    <Typography>{train.txid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Tx. Date
                    </Typography>
                    <Typography>{train.bookingdate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Tripid
                    </Typography>
                    <Typography>
                      <Button
                        component="button"
                        variant="body2"
                        onClick={() => window.open(`/trip-ticket/${train.tripid}`, "_blank")}
                      >
                        {train.tripid}
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Train Name
                    </Typography>
                    <Typography>
                      {train.trainname} ({train.trainnumber})
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Journey Date
                    </Typography>
                    <Typography>{train.jdate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Amount
                    </Typography>
                    <Typography>{train.totalfare}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Status
                    </Typography>
                    <Typography>{train.bookingstatus}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      PNR
                    </Typography>
                    <Typography>{train.pnr}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      BookingStatus
                    </Typography>
                    <Typography>{train.bookingstatus}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Passenger
                    </Typography>
                    <Typography>{train.paxname} { this.getTags(train.tags)}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      QC Status
                    </Typography>
                    <Typography>{train.issupplierpurchaseupdated}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      TatkalRequested
                    </Typography>
                    <Typography>{train.tatkalrequested ? "true" : "false"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      IsTatkalApproved
                    </Typography>
                    <Typography>{train.istatkalapproved ? "true" : "false"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      TatkalDate
                    </Typography>
                    <Typography>{train.tatkaldate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      className="blue-btn"
                      onClick={event => this.handlePopoverOpen(event, train)}
                    >
                      Select
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </Hidden>
        <Popover
          id="hotell-queue-popper"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Button onClick={() => this.handleAction("ticket")}>Ticket</Button>
          <Button onClick={() => this.handleAction("irctceticket")}>IRCTC eTicket</Button>
          <Button onClick={() => this.handleAction("update")}>Update</Button>
          <Button onClick={() => this.handleAction("updateFare")}>Update Fare</Button>
          <Button onClick={this.openStatusDialog}>Update Status</Button>
          <Button onClick={() => this.handleAction("qualityCheck")}>Quality Check</Button>
          <Button onClick={() => this.handleViewReviews(this.selectedRow.txid)}>View Remarks</Button>
          <Button onClick={this.openRollbackDialog}>Rollback</Button>
          <Button onClick={this.openRetryDialog}>Retry Train Booking</Button>
          <Button onClick={() => this.handleBlock(this.selectedRow.txid)}>Block</Button>
          <Button onClick={() => this.handleEdit(this.selectedRow.txid)}>
            Mapping
          </Button>
        </Popover>
        {this.state.isShowRollback && (
          <RollbackTransaction
            handleClose={this.closeRollbackDialog}
            handleSubmit={this.handleRollback}
          />
        )}
        {this.state.isShowStatus && (
          <UpdateStatus
            handleClose={this.closeStatusDialog}
            txtype="train"
            txid={this.selectedRow.txid}
            handlePopoverClose={this.handlePopoverClose}
          />
        )}
        {this.state.isShowRetry && (
          <RetryBooking
            handleClose={this.closeRetryDialog}
            txtype="train"
            txid={this.selectedRow.txid}
            handlePopoverClose={this.handlePopoverClose}
          />
        )}
        {this.state.isShowMapping && (
          <MapBooking
            closeDrawer={this.closeDrawer}
            showLoading={this.showLoading}
            showSnackBar={this.props.showSnackBar}
            agentid={this.selectedRow.agentid}
            txid={this.selectedRow.txid}
            txtype={this.props.txtype}
          />
        )}
        {this.state.isReviewDrawerOpen && (
          <TransactionReviewLog
            isReviewDrawerOpen={this.state.isReviewDrawerOpen}
            openReviewCardDrawer={this.openReviewCardDrawer}
            transactionRemarkLog={this.state.transactionRemarkLog}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(TrainQueueTable);
