import React, { Component } from "react";
import QualityCheck from "../../../components/queues/common/QualityCheck";

class QualityCheckPage extends Component {
  render() {
    return (
      <div className="main-container">
        <QualityCheck />
      </div>
    );
  }
}

export default QualityCheckPage;
