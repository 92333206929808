import React, { Component } from "react";
import { Typography, Button } from "@material-ui/core";
import AgentContactInfo from "../common/AgentContactInfo";
import CorporateDetails from "../common/CorporateDetails";
import CustomerDetails from "./CustomerDetails";
import FareBreakUp from "./FareBreakUp";
import TrainDetails from "./TrainDetails";
import { offlineTrainBooking } from "../../../services/offline-billing/TrainOfflineService";

class TrainBillingMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      agentObj: {
        customerid: "",
        title: "Mr",
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        requestedby: "",
        supplierid: ""
      },
      trainDetailsObj: {
        origin: "",
        destination: "",
        toStnCode: "",
        jdate: "",
        trainclass: "",
        quota: "",
        trainNumber: "",
        trainName: "",
        departureTime: "",
        arrivalTime: "",
        duration: "",
        distance: "",
        pnr: "",
      },
      corporateDetailsObj: {
        costcenterid: "",
        departmentid: "",
        projectid: "",
      },
      customerDetailsObj: {
        numadults: 1,
        numchildren: 0,
        adultsArr: [
          {
            title: "Mr",
            firstname: "",
            lastname: "",
            age: "",
            gender: "",
            seatpref: "",
            seatno: "",
            coach: "",
            bedrollrequired: "",
            paxtype: "",
          }
        ],
        childrenArr: [],
      },
      fareObj: {
        basefare: 0,
        wpServiceTax: 0,
        wpServiceCharge: 0,
        pgcharges: 0,
        agentservicecharge: 0,
        totalFare: 0,
      }
    };
  }

  updateCorporateDetailsObject = obj => {
    this.setState({
      corporateDetailsObj: obj
    });
  };

  updateTrainDetailsObject = obj => {
    this.setState({
      trainDetailsObj: obj
    });
  };

  updateCustomerDetailsObject = obj => {
    this.setState({
      customerDetailsObj: obj
    });
  };

  updateStateObject = obj => {
    this.setState({
      agentObj: obj,
      customerId: obj.customerid
    });
  };

  updateFareObject = obj => {
    this.setState({
      fareObj: obj
    });
  };

  handleGenerateBill = async () => {
    let passengers = [];
    let agentObjError = 0, customerDetailsObjError = 0, trainDetailsObjError = 0, fareObjError = 0;
    const alertMsg = "Please enter value for ";
    const { agentObj, trainDetailsObj, customerDetailsObj, corporateDetailsObj, fareObj } = this.state;
    const trainPassengerObj = {
      "title": "",
      "firstname": "",
      "lastname": "",
      "gender": "",
      "age": '',
      "mealpref": "",
      "seatpref": "",
      "nationality": "INDIAN",
      "concession": "INDIAN",
      "bedrollrequired": false,
      "insurancerequired": false,
      "allotedberth": "",
      "currentStatus": "",
      "paxsno": "",
      "paxtype": "",
      "paxmobile": "",
      "paxemail": "",
      "idcardtype": "",
      "idcardnumber": "",
      "profileid": "",
      "seatno": "",
      "coach": ""
    };

    Object.keys(agentObj).some(key => {
      if (agentObj[key] === "") {
        window.alert(alertMsg + key);
        agentObjError = 1;
        return true;
      }
      return false;
    });

    if(agentObjError === 1){
      return false;
    }

    Object.keys(customerDetailsObj).some(key => {
      const keyVal = customerDetailsObj[key];
      if (["adultsArr", "childrenArr"].includes(key) && Array.isArray(keyVal) && keyVal.length > 0) {
        for(let i=0; i<keyVal.length; i++){
          passengers.push({
            "TrainPassenger": {
              ...trainPassengerObj,
              ...keyVal[i],
            }
          });
        }
      }
      
      if (customerDetailsObj[key] === "") {
        window.alert(alertMsg + key);
        customerDetailsObjError = 1;
        return true;
      }
      return false;
    });

    if(customerDetailsObjError === 1){
      return false;
    }

    Object.keys(trainDetailsObj).some(key => {
      if (trainDetailsObj[key] === "" || trainDetailsObj[key] === "select") {
        window.alert(alertMsg + key);
        trainDetailsObjError = 1;
        return true;
      }
      return false;
    });

    if(trainDetailsObjError === 1){
      return false;
    }

    Object.keys(fareObj).some(key => {
      if (fareObj[key] === "") {
        window.alert(alertMsg + key);
        fareObjError = 1;
        return true;
      }
      return false;
    });

    if(fareObjError === 1){
      return false;
    }

    let origin = "";
    let destination = "";
    let boardingPointLabel = "";
    let boardingPointValue = "";
    try {
      origin = {
        "locationname": trainDetailsObj.origin ? trainDetailsObj.origin.label : "",
        "locationcode": trainDetailsObj.origin ? trainDetailsObj.origin.value : "",
      };
      destination = {
        "locationname": trainDetailsObj.destination ? trainDetailsObj.destination.label : "",
        "locationcode": trainDetailsObj.destination ? trainDetailsObj.destination.value : "",
      };
      boardingPointLabel = trainDetailsObj.toStnCode ? trainDetailsObj.destination.label : "";
      boardingPointValue = trainDetailsObj.toStnCode ? trainDetailsObj.destination.value : "";
    }
    catch (err) {
      console.log(err.message);
    }

    const jsondata = {
      "TrainBookingRequest": {
        "fareresult": {
          "corporateid": agentObj.customerid,
          "farereq": {
            "trainno": trainDetailsObj.trainNumber,
            "trainclass": trainDetailsObj.trainclass,
            "jdate": trainDetailsObj.jdate,
            "origin": origin,
            "destination": destination,
            "quota": trainDetailsObj.quota,
            "profileid": "",
          },
          "totalcollectible": 0,
          "Distance": "",
          "baseFare": fareObj.basefare,
          "reservationCharge": 0,
          "superfastCharge": 0,
          "fuelAmount": 0,
          "totalConcession": 0,
          "serviceTax": 0,
          "tatkalFare": 0,
          "otherCharge": 0,
          "cateringCharge": 0,
          "totalFare": fareObj.basefare,
          "wpServiceCharge": fareObj.wpServiceCharge,
          "wpServiceTax": fareObj.wpServiceTax,
          "agentServiceCharge": fareObj.agentservicecharge,
          "pgCharges": fareObj.pgcharges,
          "approvalRequired": false,
          "inpolicy": true,
          "policyid": "",
          "supplierid": agentObj.supplierid,
        },
        "selectedtrain": {
          "trainNumber": trainDetailsObj.trainNumber,
          "trainName": trainDetailsObj.trainName,
          "fromStnCode": origin.locationcode,
          "toStnCode": destination.locationcode,
          "arrivalTime": trainDetailsObj.arrivalTime,
          "departureTime": trainDetailsObj.departureTime,
          "distance": trainDetailsObj.distance,
          "duration": trainDetailsObj.duration,
          "inpolicy": true,
          "policyid": "",
          "quota": trainDetailsObj.quota,
          "originname": origin.locationname,
          "destinationname": destination.locationname
        },
        "availrow": {
          "availablityDate": "",
          "availablityStatus": "",
          "reasonType": "",
          "Reason": "",
          "availablityType": ""
        },
        "jdate": trainDetailsObj.jdate,
        "trainpassengers": passengers,
        "corporatedetails": {
          "corporateid": agentObj.customerid,
          "profileid": "",
          "tcbookingrequestid": "",
          "tcbookingid": "",
          "tcprofileid": "",
          "policyid": "",
          "inpolicy": "",
          "policy_reasonid": "",
          "policy_reason_remarks": "",
          "costcenterid": corporateDetailsObj.costcenterid,
          "departmentid": corporateDetailsObj.departmentid,
          "customerid": "",
          "billabletype": "",
          "projectid": corporateDetailsObj.projectid,
          "autoexpense": "",
          "bookingfor": "official",
          "travelreason": "",
          "approvalid": "",
          "txid": "",
          "triprequestid": "",
          "employeeid": "",
          "bookedbyid": ""
        },
        "bookingtype": "b2e",
        "numseats": Number(customerDetailsObj.adultsArr.length) + Number(customerDetailsObj.childrenArr.length),
        "tripid": "",
        "paymentmode": "cashcard",
        "pgname": "",
        "corporateid": agentObj.customerid,
        "contact_email": agentObj.email,
        "contact_mobile": agentObj.mobile,
        "tripitemid": "",
      }
    }

    let obj = { jsondata: JSON.stringify(jsondata) };
    let res = await offlineTrainBooking(obj, trainDetailsObj.pnr);
    let msg = `Failed to generate bill`;
    try {
      if (res.FlightBookingResponse.bookingstatus === 200) {
        msg = res.FlightBookingResponse.message;
      } else {
        msg = res.ApiStatus.Message;
      }
      alert(msg);
    } catch (error) {
      alert(msg);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h5" className="mb-8">
          Train Offline Billing
        </Typography>
        <AgentContactInfo
          agentObj={this.state.agentObj}
          updateChange={this.updateStateObject}
        />
        <br />
        <TrainDetails 
          detailObj={this.state.trainDetailsObj}
          updateChange={this.updateTrainDetailsObject}
        />
        <br />
        <CorporateDetails 
          detailsObj={this.state.corporateDetailsObj}
          customerId={this.state.customerId}
          updateChange={this.updateCorporateDetailsObject}
        />
        <br />
        <CustomerDetails 
          customerDetailsObj={this.state.customerDetailsObj}
          customerId={this.state.customerId}
          updateChange={this.updateCustomerDetailsObject}
        />
        <br />
        <FareBreakUp
          fareObj={this.state.fareObj}
          updateFare={this.updateFareObject}
        />
        <br />
        <div className="text-center">
          <Button
            onClick={this.handleGenerateBill}
            variant="contained"
            color="primary"
          >
            Generate Bill
          </Button>
        </div>
        <br />
        <br />
      </React.Fragment>
    );
  }
}

export default TrainBillingMain;
