import qs from "query-string";
const API_PATH = process.env.REACT_APP_API_PATH;

export const getUserAuth = () => {
  let obj = localStorage.getItem("tgAdminAuth");
  return JSON.parse(obj);
};

export const getUserProfile = () => {
  let obj = localStorage.getItem("tgAdminProfile");
  return JSON.parse(obj);
};

function getSearchParams(params) {
  if (params) {
    var userAuth = getUserAuth();
    if (userAuth) {
      params.authtoken = userAuth.authtoken;
    }
    return qs.stringify(params);
  }
}

export const apiPost = async data => {
  let url = `${API_PATH}servicedispatch.jsp`;
  let response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: getSearchParams(data)
  });
  return await response.json();
  // if (data.hasOwnProperty("ApiStatus")) {
  //   if (
  //     data.Status === "NOK" &&
  //     data.StatusCode === 300 &&
  //     data.Result ===
  //       "Your session might have timed out. Please re-login and continue."
  //   ) {
  //     clearLocalStorage();
  //   }
  //   return data;
  // }
  // return data;
};

export const apiPostWithoutToken = async data => {
  let url = `${API_PATH}servicedispatch.jsp`;
  let response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: qs.stringify(data)
  });
  return await response.json();
};

export const apiPostOtherRoute = async (routeName, data) => {
  let url = `${API_PATH}${routeName}`;
  let response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: getSearchParams(data)
  });
  return await response.json();
};

export const uploadLpoAttachment = async obj => {
  let userAuth = getUserAuth();
  try {
    obj.authtoken = userAuth.authtoken;
    let url = `${API_PATH}lpoupload.jsp`;
    let response = await fetch(url, {
      method: "POST",
      body: obj,
    });
    return await response.json();
  } catch (error) {
    return undefined;
  }
};

export const updateTransactionStatus = async (data) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatetransactionstatus",
      ...data,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const blockTicket = async (obj = {}) => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "blockticket",
      ...obj,
      agentid: userAuth.corporateid,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getViewRemarks = async (txid) => {
  try {
    let obj = {
      opid: "TG-SUPERADMIN",
      action: "gettransactionremarks",
      txid: txid,
    };

    let res = await apiPost(obj);
    if (res.hasOwnProperty("null") || res.list[0] === "") {
      return undefined;
    } else {
      return res.list[0];
    }
  } catch (err) {
    return undefined;
  }
};

export const getGrades = async (corporateid) => {
  try {
    let obj = {
      opid: "TG-GETGRADES",
      corporateid: corporateid,
    };

    let res = await apiPost(obj);
    if (res.list[0] !== "") {
      return Array.isArray(res.list[0].Grade) ? res.list[0].Grade : [res.list[0].Grade];
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export const getAllProjects = async (corporateid) => {
  try {
    let obj = {
      opid: "TG-EXPENSE",
      action: "viewprojectwithcorpid",
      profileid: getUserProfile().profileid,
      corporateid: corporateid,
    };

    let res = await apiPost(obj);
    if (res.list[0] === "") {
      return [];
    } else {
      return Array.isArray(res.list[0].Projects) ? res.list[0].Projects : [res.list[0].Projects];
    }
  } catch (error) {
    return [];
  }
};

export const getAllDepartments = async (corporateid) => {
  try {
    let obj = {
      opid: "TG-EXPENSE",
      action: "viewdepartmentwithcorporateid",
      corporateid: corporateid,
    };
    
    let res = await apiPost(obj);
    if (res.list[0] === "") {
      return [];
    } else {
      return Array.isArray(res.list[0].CorporateDepartment) ? res.list[0].CorporateDepartment : [res.list[0].CorporateDepartment];
    }
  } catch (error) {
    return [];
  }
};

export const getAllCostCenters = async (corporateid) => {
  try {
    let obj = {
      opid: "TG-EXPENSE",
      action: "viewcostcenterwithcorpid",
      corporateid: corporateid,
    };
    
    let res = await apiPost(obj);
    if (res.list[0] === "") {
      return [];
    } else {
      return Array.isArray(res.list[0].CostCenter) ? res.list[0].CostCenter : [res.list[0].CostCenter];
    }
  } catch (error) {
    return [];
  }
};

export const getCountries = async () => {
  try {
    let obj = {
      opid: "TG-GENERIC",
      act: "getcountries",
    };
    
    let res = await apiPost(obj);
    return res.list[0].CountryCode;
  } catch (error) {
    return [];
  }
};

export const getEntitiesForCorporate = async (corporateid) => {
  try {
    let obj = {
      opid: "TG-EXPENSE",
      corporateid: corporateid,
      action: "getentitiesforcorporate",
    };

    const res = await apiPost(obj);
    const list = res.list[0];
    return list === "" ? [] : Array.isArray(list.EntityMaster) ? list.EntityMaster : [list.EntityMaster];
  } catch (error) {
    return [];
  }
};

export const getStatesAndCities = async countryCode => {
  try {
    let obj = {
      opid: "TG-GENERIC",
      act: "getcities",
      countrycode: countryCode,
    };

    const res = await apiPost(obj);
    const data = res.list[0];
    if (data !== "") {
      return Array.isArray(data.TgPlaceObject) ? data.TgPlaceObject : [data.TgPlaceObject];
    }
    return [];
  } catch (erro) {
    return [];
  }
};

export const getAllCustomerslite = async () => {
  try {
    let obj = {
      opid: "TG-SUPERADMIN",
      action: "getallcustomerslite",
    };
    let res = await apiPost(obj);
    if (res.list[0].ProfileMasterLite) {
      return Array.isArray(res.list[0].ProfileMasterLite)
        ? res.list[0].ProfileMasterLite
        : [res.list[0].ProfileMasterLite];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const agentProfile = async (corporateid) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "viewprofile",
      corporateid: corporateid,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const userBalance = async (corporateid) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getuserbalance",
      corporateid: corporateid,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getCities = async (searchTerm) => {
  try {
    let reqObj = {
      opid: "TGTS",
      actioncode: "SEARCHCITIES",
      searchkey: searchTerm,
    };
    const res = await apiPost(reqObj);
    if (res.list[0] !== "") {
      const result = Array.isArray(res.list[0].TgPlaceObject) ? res.list[0].TgPlaceObject : [res.list[0].TgPlaceObject];
      var places = result.map(x => {
        return {
          value: x.locationcode,
          label: `${x.locationname} (${x.locationcode})`,
        };
      });
      return places;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};