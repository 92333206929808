import React, { Component } from "react";
import { Typography, Grid, TextField } from "@material-ui/core";
import HotelQueueTable from "../../../components/queues/hotel/HotelQueueTable";
import { getHotelBookingQueues } from "../../../services/queues/HotelQueuesService.js";
import { Loading } from "../../../components/common/Loading.js";
import "./HotelBookingQueuesPage.css";
import CustomSnackBar from "../../../components/common/CustomSnackBar";
import { debounce } from "../../../components/common/Utils";

class HotelBookingQueuesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hotelQueues: undefined,
      filteredQueues: undefined,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    let res = await getHotelBookingQueues();
    this.setState({
      isLoading: false,
      hotelQueues: res,
      filteredQueues: res
    });
  }

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  showLoading = isLoading => {
    this.setState({
      isLoading: isLoading
    });
  };

  
  handleChange = event => {
    let val = event.target.value;
    this.filterResults(val);
  };

  filterResults = debounce(val => {
    let data = this.state.hotelQueues.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      filteredQueues: data
    });
  }, 500);

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography variant="h6" className="mb-8">
            Hotel Booking Queues
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <TextField
              variant="outlined"
              className="search-field mb-8"
              name="searchTerm"
              label="Search"
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>
        {this.state.filteredQueues && (
          <HotelQueueTable
            showLoading={this.showLoading}
            showSnackBar={this.showSnackBar}
            hotelQueues={this.state.filteredQueues}
          />
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default HotelBookingQueuesPage;
