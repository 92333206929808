import React, { Component } from "react";
import moment from "moment";
import { Typography, Paper, Grid, TextField, MenuItem } from "@material-ui/core";
import DayPickerInput from "react-day-picker/DayPickerInput";
import Async from "react-select/lib/Async";
import "react-day-picker/lib/style.css";
import { getCities } from "../../../services/common/CommonService";

class TrainDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailObj: props.detailObj,
    };
  }

  handleInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      detailObj: {
        ...prevState.detailObj,
        [eve.target.name]: eve.target.value
      }
    }),
      () => this.props.updateChange(this.state.detailObj)
    );
  };

  handleDateChange = (day, name) => {
    this.setState(prevState => ({
      detailObj: {
        ...prevState.detailObj,
        [name]: day
      }
    }),
      () => this.props.updateChange(this.state.detailObj)
    );
  };

  filterStationList = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.getFilteredStationList(inputValue));
      }, 1000);
    });

  getFilteredStationList = async (inputValue) => {
    if (inputValue.length > 2) {
      let stationListResult = await getCities(inputValue);
      return stationListResult.filter(i =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  };

  handlePlaceSelect = async (obj, type) => {
    if (obj !== null) {
      this.setState(prevState => ({
        detailObj: {
          ...prevState.detailObj,
          [type]: obj,
        }
      }),
      () => this.props.updateChange(this.state.detailObj)
      );
    }
  };

  render() {
    const { detailObj } = this.state;
    return (
      <Paper className="padding-16 visa-details-form">
        <Typography variant="subtitle1" className="bold">
          Train Details
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <Async
              placeholder="From"
              autoload={false}
              value={detailObj.origin}
              onChange={value => this.handlePlaceSelect(value, 'origin')}
              loadOptions={this.filterStationList}
              clearable={true}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <Async
              placeholder="To"
              autoload={false}
              value={detailObj.destination}
              onChange={value => this.handlePlaceSelect(value, 'destination')}
              loadOptions={this.filterStationList}
              clearable={true}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <Async
              placeholder="Boarding Point"
              autoload={false}
              value={detailObj.toStnCode}
              onChange={value => this.handlePlaceSelect(value, 'toStnCode')}
              loadOptions={this.filterStationList}
              clearable={true}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <DayPickerInput
              placeholder="Journey Date"
              className="full-width"
              value={detailObj.jdate}
              dayPickerProps={{ disabledDays: { before: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "jdate")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              select
              className="select"
              variant="outlined"
              name="trainclass"
              label="Class"
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.trainclass}
              onChange={this.handleInputChange}
            >
              <MenuItem value="SL">SL - Sleeper Class</MenuItem>
              <MenuItem value="1A">1A - First AC</MenuItem>
              <MenuItem value="2A">2A - Second AC</MenuItem>
              <MenuItem value="3A">3A - Third AC</MenuItem>
              <MenuItem value="FC">FC - First class</MenuItem>
              <MenuItem value="2S">2S - 2S Class</MenuItem>
              <MenuItem value="3E">3E - 3E Class</MenuItem>
              <MenuItem value="CC">CC - AC Chair Car</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              select
              className="select"
              variant="outlined"
              name="quota"
              label="Quota"
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.quota}
              onChange={this.handleInputChange}
            >
              <MenuItem value="GN">GN - General</MenuItem>
              <MenuItem value="TK">TK - Tatkal</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="trainNumber"
              label="Train Number"
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.trainNumber}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="trainName"
              label="Train Name"
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.trainName}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="departureTime"
              label="Departure Time"
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.departureTime}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="arrivalTime"
              label="Arrival Time"
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.arrivalTime}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="duration"
              label="Duration"
              InputLabelProps={{
                shrink: true
              }}
              type="number"
              value={detailObj.duration}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="distance"
              label="Distance"
              InputLabelProps={{
                shrink: true
              }}
              type="number"
              value={detailObj.distance}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="pnr"
              label="PNR"
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.pnr}
              onChange={this.handleInputChange}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default TrainDetails;
