import moment from "moment";
import { apiPost, getUserProfile } from "../common/CommonService";

export const getCardTransactions = async searchObj => {
  try {
    let obj = {
      opid: "TG-SUPERADMIN",
      action: searchObj.type,
      fromdate: moment(searchObj.from).format("YYYY-MM-DD"),
      todate: moment(searchObj.to).format("YYYY-MM-DD")
    };

    let res = await apiPost(obj);
    if (res.hasOwnProperty("null") || res.list[0] === "") {
      return undefined;
    } else {
      return res.list[0];
    }
  } catch (err) {
    return undefined;
  }
};

export const searchCorporatetravellersLite = async searchObj => {
  try {
    let obj = {
      opid: "TG-SEARCHCORPORATETRAVELLERSLITE",
      corporateid: searchObj.corporateid ? searchObj.corporateid : getUserProfile().corporateid,
      searchby: searchObj.searchBy ? searchObj.searchBy : "wildcard",
      searchval: searchObj.searchTxt
    };

    let res = await apiPost(obj);
    if (res.hasOwnProperty("null") || res.ProfileCollection === "" || res.ProfileCollection.profiles[0] === "") {
      return [];
    } else {
      if(res.ProfileCollection.profiles[0].ProfileLite instanceof Array)
      return res.ProfileCollection.profiles[0].ProfileLite;
      else{
        return [res.ProfileCollection.profiles[0].ProfileLite];
      }
    }
  } catch (err) {
    return [];
  }
};

export const mapOfflineBooking = async searchObj => {
  try {
    let obj = {
      opid: "TG-SUPERADMIN",
      action: "mapofflinebooking",
      txtype: searchObj.txtype,
      txid:searchObj.txid,
      inpolicy: searchObj.inpolicy,
      customerprofileid: searchObj.customerprofileid
    };

    let res = await apiPost(obj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    } else {
      return res;
    }
  } catch (err) {
    return undefined;
  }
};

export const getTodaysBookings = async actionType => {
  try {
    let obj = {
      opid: "TG-SUPERADMIN",
      action: actionType,
    };

    let res = await apiPost(obj);
    if (res.hasOwnProperty("null") || res.list[0] === "") {
      return undefined;
    } else {
      return res.list[0];
    }
  } catch (err) {
    return undefined;
  }
};