import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Divider from "@material-ui/core/Divider";
import AddCard from "./AddCard";
import {
  getAllCorporates,
  getAllCards,
  getBalanceForCard
} from "../../../services/inventory/CardsService";
import { Loading } from "../../common/Loading";
import { formatCurrency } from "../../common/Utils";
import RegisterCard from "./RegisterCard";

class CardsMain extends Component {
  corporateMap = [];
  availableBalance = 0;
  constructor(props) {
    super(props);
    this.state = {
      isDrawerOpen: false,
      corporateList: [],
      allCards: undefined,
      isLoading: false,
      selectedCard: undefined,
      isView: false,
      isRegister: false,
      card: {
        cardid: "",
        proxynumber: "",
        alias: "",
        provider: "",
        corporateid: "",
        addedon: "",
        addedby: "SUPER",
        status: "active",
        assignedto: "",
        masked_card: "",
        expirydate: ""
      }
    };
  }

  openCardDrawer = isShow => {
    if (isShow === false) {
      this.getAllCards();
    }
    this.setState({
      isDrawerOpen: isShow
    });
  };

  async componentDidMount() {
    this.setState({
      isLoading: true
    });
    let res = await getAllCorporates();
    await this.getAllCards();
    let list = res.map(x => {
      return {
        corporateid: x.corporateid,
        corporate_name: x.corporate_name,
        email: x.email
      };
    });
    this.corporateMap = res.reduce((acc, cur) => {
      acc[cur.corporateid] = cur.corporate_name;
      return acc;
    }, {});
    this.setState({
      corporateList: list,
      isLoading: false
    });
  }

  getAllCards = async () => {
    this.setState({
      isLoading: true
    });
    let cards = await getAllCards();
    this.setState({
      allCards: cards,
      isLoading: false
    });
  };

  showCard = async (isShow, card) => {
    if (isShow) {
      let res = await getBalanceForCard(card.cardid);
      this.availableBalance = res;
    }
    this.setState({
      isView: isShow,
      selectedCard: card
    });
  };

  showRegisterDrawer = async (isShow, card, isRefresh) => {
    if (isShow) {
    }
    this.setState({
      isRegister: isShow,
      selectedCard: card
    });
  };

  render() {
    return (
      <Paper className="padding-16">
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={8} md={8} sm={8} xs={6}>
            <Typography variant="h6"> Cards </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={6}>
            <Button
              className="right"
              variant="contained"
              size="small"
              color="primary"
              onClick={() => this.openCardDrawer(true)}
            >
              Add Card
            </Button>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Corporate</TableCell>
              <TableCell>Card No.</TableCell>
              <TableCell>Expiry Date</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.allCards &&
              this.state.allCards.length > 0 &&
              this.state.allCards.map((card, index) => (
                <TableRow key={index}>
                  <TableCell>{this.corporateMap[card.corporateid]}</TableCell>
                  <TableCell>{card.masked_card}</TableCell>
                  <TableCell>{card.expirydate}</TableCell>
                  <TableCell>{card.provider}</TableCell>
                  <TableCell>{card.status}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => this.showRegisterDrawer(true, card, false)}
                    >
                      Register
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => this.showCard(true, card)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {this.state.allCards && this.state.allCards.length === 0 && (
              <TableRow>
                <TableCell colSpan="6">No cards have been added yet.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Drawer
          anchor="right"
          className="view-card-drawer"
          open={this.state.isRegister}
        >
          <RegisterCard
            card={this.state.selectedCard}
            showRegisterDrawer={this.showRegisterDrawer}
          />
        </Drawer>
        <Drawer
          className="add-card-drawer"
          anchor="right"
          open={this.state.isDrawerOpen}
        >
          <AddCard
            corporateList={this.state.corporateList}
            card={this.state.card}
            openCardDrawer={this.openCardDrawer}
          />
        </Drawer>
        <Drawer
          className="view-card-drawer"
          open={this.state.isView}
          anchor="right"
        >
          <Grid container spacing={16}>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography variant="h5"> Card Details </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                className="cc-card-close-btn right"
                variant="outlined"
                color="secondary"
                onClick={() => this.showCard(false, undefined)}
              >
                Close
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider />
            </Grid>
          </Grid>
          {this.state.selectedCard && (
            <div className="card-design">
              <Typography variant="subtitle1" className="card-provider">
                {this.state.selectedCard.provider}
              </Typography>
              <Typography variant="h6">
                {this.corporateMap[this.state.selectedCard.corporateid]}
              </Typography>
              <Typography variant="subtitle1" className="bold">
                {this.state.selectedCard.masked_card}
              </Typography>
              <div className="d-flex">
                <div>
                  <Typography variant="caption">Expires on</Typography>
                  <Typography variant="subtitle2">
                    {this.state.selectedCard.expirydate}
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption">Status</Typography>
                  <Typography
                    variant="subtitle2"
                    className={this.state.selectedCard.status}
                  >
                    {this.state.selectedCard.status}
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption">Alias</Typography>
                  <Typography variant="subtitle2">
                    {this.state.selectedCard.alias}
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption">Proxy Number</Typography>
                  <Typography variant="subtitle2">
                    {this.state.selectedCard.proxynumber}
                  </Typography>
                </div>
              </div>
              <div className="card-divider">
                <Typography className="text-center" variant="caption">
                  Balance
                </Typography>
                <Typography className="card-balance">
                  {formatCurrency(this.availableBalance)}
                </Typography>
              </div>
            </div>
          )}
        </Drawer>
      </Paper>
    );
  }
}

export default CardsMain;
