import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Popover,
  Paper,
  Grid,
  Typography,
  Hidden,
  TextField,
} from "@material-ui/core";
import FileExcel from "mdi-material-ui/FileExcel";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router-dom";
import EmailTicket from "../common/EmailTicket";
import { debounce, downloadExcel } from "../../common/Utils";
import { rollbackCabTicket } from "../../../services/queues/CabQueuesService";
import { blockTicket, getViewRemarks } from "../../../services/common/CommonService";
import RollbackTransaction from "../common/RollbackTransaction";
import TransactionReviewLog from "../common/TransactionReviewLog";
import MapBooking from "../../transactions/MapBooking";

class CabQueueTable extends React.Component {
  selectedRow = undefined;
  allCabQueues = [];
  distinctAgentidArr = [];
  constructor(props) {
    super(props);
    this.allCabQueues = props.cabQueues;
    this.state = {
      totalAmount: 0,
      openPopover: false,
      anchorEl: null,
      isShowRollback: false,
      isShowEmail: false,
      isShowMapping: false,
      cabQueues: props.cabQueues,
      sortType: "asc",
      filterByCompany: "all",
      searchText: "",
      isReviewDrawerOpen: false,
      transactionRemarkLog: [],
    };
  }

  componentDidMount() {
    this.calculateTotalAmount();
    this.getDistinctAgentID();
  }

  componentDidUpdate(prevProps) {
    if (this.props.cabQueues !== prevProps.cabQueues) {
      this.allCabQueues = this.props.cabQueues;
      this.setState(
        {
          cabQueues: this.props.cabQueues
        },
        () => {
          this.calculateTotalAmount();
          this.getDistinctAgentID();
        }
      );
    }
  }

  getDistinctAgentID = () => {
    this.distinctAgentidArr = [...new Map(this.props.cabQueues.map(item =>
      [item["agentid"], item])).values()];
  }

  handlePopoverOpen = (event, hotel) => {
    this.selectedRow = hotel;
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget
    });
  };

  handlePopoverClose = () => {
    this.setState({
      openPopover: false
    });
  };

  handleAction = type => {
    switch (type) {
      case "ticket":
        window.open(`/cab-ticket/${this.selectedRow.txid}`, "_blank");
        break;
      case "update":
        this.props.history.push(`/update-cab-queue/${this.selectedRow.txid}`);
        break;
      case "updateFare":
        this.props.history.push(`/update-cab-fare/${this.selectedRow.txid}`);
        break;
      case "qualityCheck":
        this.props.history.push(`/quality-check/cab/${this.selectedRow.txid}`);
        break;
      default:
        break;
    }
  };

  sendEmail = () => {
    this.setState({
      isShowEmail: true
    });
  };

  closeEmail = () => {
    this.setState({
      isShowEmail: false
    });
  };

  showLoading = flag => {
    this.closeEmail();
    this.props.showLoading(flag);
  };

  handleRollback = async data => {
    if (data) {
      this.props.showLoading(true);
      let res = await rollbackCabTicket(this.selectedRow.txid, data);
      if (res) {
        if (res.ResponseStatus.status === "OK") {
          this.props.showSnackBar(res.ResponseStatus.message, "success");
        } else {
          this.props.showSnackBar(res.ResponseStatus.message, "error");
        }
      } else {
        this.props.showSnackBar(
          "Failed to rollback the transaction. Please try again.",
          "error"
        );
      }
      this.closeRollbackDialog();
      this.props.showLoading(false);
      this.handlePopoverClose();
    }
  };

  openRollbackDialog = () => {
    this.setState({
      isShowRollback: true
    });
  };

  closeRollbackDialog = () => {
    this.setState({
      isShowRollback: false
    });
  };

  sortBy = (property, type, sortType) => {
    let data = [...this.state.cabQueues];
    if (type === "string") {
      if (this.state.sortType === "asc") {
        data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
        sortType = "desc";
      } else {
        data.sort((a, b) => (b[property] < a[property] ? -1 : 1));
        sortType = "asc";
      }
    } else {
      if (this.state.sortType === sortType) {
        data.sort((a, b) => new Date(b[property]) - new Date(a[property]));
        sortType = "desc";
      } else {
        data.sort((a, b) => new Date(a[property]) - new Date(b[property]));
        sortType = "asc";
      }
    }
    this.setState({
      cabQueues: data,
      sortType: sortType
    });
  };

  handleSearchChange = event => {
    const { value } = event.target;
    this.setState({
      searchText: value,
    });
    if (value === "") {
      this.setState({
        cabQueues: this.allCabQueues
      });
    } else {
      this.setSearchResults(value);
    }
  };

  setSearchResults = debounce(val => {
    let data = this.allCabQueues.filter(o =>
      Object.keys(o).some(k =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    if (this.state.filterByCompany !== "all") {
      data = data.filter(r => r["agentid"] === this.state.filterByCompany);
    }
    this.setState(
      {
        cabQueues: data
      },
      () => this.calculateTotalAmount()
    );
  }, 500);

  calculateTotalAmount = () => {
    const totalAmount = this.state.cabQueues.reduce((acc, cur) => {
      acc += Math.round(cur["totalfarequote"]);
      return acc;
    }, 0);
    this.setState({
      totalAmount
    });
  };

  downloadExcel = () => {
    this.props.showLoading(true);
    let header = [];
    for (var prop in this.state.cabQueues[0]) {
      header.push(prop);
    }
    let output = header.join(',')+"\r\n";
    output += this.state.cabQueues.map((item, index) => {
      let transactions = [];
      for (let val of header) {
        let str = (item[val] ? item[val] : "").toString().replace(/,/g, "");
        transactions.push(str);
      }
      return transactions;
    }).join("\r\n");
    downloadExcel(output, "Cab_Transactions");
    this.props.showLoading(false);
  };

  handleFilterByCompany = event => {
    const val = event.target.value;
    this.setState({
        filterByCompany: val,
      },
      () => this.handleFilter()
    );
  };

  handleFilter = () => {
    let data = [...this.allCabQueues];
    if (this.state.filterByCompany !== "all") {
      data = data.filter(r => r["agentid"] === this.state.filterByCompany);
    }
    if (this.state.searchText) {
      data = this.allCabQueues.filter(o =>
        Object.keys(o).some(k =>
          o[k]
          .toString()
          .toLowerCase()
          .includes(this.state.searchText.toLowerCase())
        )
      );
    }
    this.setState({
        cabQueues: data,
      },
      () => {
        this.calculateTotalAmount();
      }
    );
  };

  handleBlock = async (txId) => {
    let isConfirm = window.confirm(
      "Are you sure, Do you want to block the ticket?"
    );
    if (isConfirm === true) {
      this.props.showLoading(true);
      let reqObj = {
        txtype: "cabcanx",
        txid: txId,
      };
      let res = await blockTicket(reqObj);
      if (res) {
        if (res.ApiStatus.Status === "OK") {
          this.props.showSnackBar(res.ApiStatus.Message, "success");
        } else {
          this.props.showSnackBar(res.ApiStatus.Message, "error");
        }
      }
      this.props.showLoading(false);
      this.handlePopoverClose();
    }
  };

  handleViewReviews = async (txId) => {
    this.props.showLoading(true);
    let res = await getViewRemarks(txId);
    try {
      if (res) {
        let remarkArr = Array.isArray(res.TransactionRemarkLog)
          ? res.TransactionRemarkLog
          : [res.TransactionRemarkLog];
        this.setState({
          transactionRemarkLog: remarkArr,
          isReviewDrawerOpen: true,
        });
      } else {
        this.props.showSnackBar("No reviews found", "success");
        this.setState({
          transactionRemarkLog: [],
          isReviewDrawerOpen: true,
        });
      }
    }
    catch (e) {
      this.props.showSnackBar("Error!", "error");
      this.setState({
        transactionRemarkLog: [],
        isReviewDrawerOpen: false,
      });
    }
    this.props.showLoading(false);
    this.handlePopoverClose();
  };

  openReviewCardDrawer = (isShow) => {
    this.setState({
      isReviewDrawerOpen: isShow,
    });
  };

  handleEdit = async (txid) => {
    this.showLoading(true);
    this.setState({
      isShowMapping: true,
    });
    this.showLoading(false);
  };

  closeDrawer = () => {
    this.setState({
      isShowMapping: false,
    });
  };

  getTags = (tags) => {
    if (tags) {
      return (<Typography variant="span" className="red-text-container">
          (<Typography variant="span" className="red-text">
            {tags}
          </Typography>)
        </Typography>);
    } else {
      return '';
    }
  }

  render() {
    return (
      <React.Fragment>
        <Hidden xsDown>
          <Paper className="padding-16">
            <Grid container spacing={16}>
              <Grid item lg={1} md={3} sm={3} xs={3}>
                <Typography variant="caption">Total Amount</Typography>
                <Typography variant="h5">{this.state.totalAmount}</Typography>
              </Grid>
              <Grid item lg={1} md={3} sm={3} xs={3}>
                <Typography variant="caption">Total Transactions</Typography>
                <Typography variant="h5">{this.state.cabQueues ? this.state.cabQueues.length : 0 }</Typography>
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button variant="contained" color="primary" title="Download Excel File" onClick={this.downloadExcel} size="small" className="right">
                  <FileExcel />
                </Button>
              </Grid>
              <Grid item lg={2} md={8} sm={8} xs={8}>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  name="filterByStatus"
                  label="Filter By Company"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={this.state.filterByCompany}
                  onChange={this.handleFilterByCompany}
                >
                  <MenuItem value="all"> All</MenuItem>
                  {this.distinctAgentidArr.map((val, key) => (
                    <MenuItem key={key} value={val.agentid}>{`${val.agent_company} (${val.agentid})`}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <TextField
                  name="searchText"
                  variant="outlined"
                  label="Search"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={this.state.searchText}
                  onChange={this.handleSearchChange}
                />
              </Grid>
            </Grid>
            <Table className="queue-table">
              <TableHead>
                <TableRow>
                  <TableCell>Txid</TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("txdate", "date", "asc")}
                    >
                      Tx. Date
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("tripid", "string", "asc")}
                    >
                      Tripid
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("agent_company", "string", "asc")}
                    >
                      Company
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("paxname", "string", "asc")}
                    >
                      Pax.Details
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("bookedbyid", "string", "asc")}
                    >
                      BookedByID
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("pickpdate", "date", "asc")}
                    >
                      Journey Date
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("selected_carname", "string", "asc")}
                    >
                      Cab Name
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("pickuppoint", "string", "asc")}
                    >
                      Pickuppoint
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("tripdestination", "string", "asc")}
                    >
                      Droppoint
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("totalfarequote", "string", "asc")}
                    >
                      InvAmount
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("bookingstatus", "string", "asc")}
                    >
                      BookingStatus
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("blockedby", "string", "asc")}
                    >
                      Blocked By
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("issupplierpurchaseupdated", "string", "asc")}
                    >
                      QC Status
                    </Button>
                  </TableCell>
                  <TableCell>Select</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.cabQueues &&
                  this.state.cabQueues.map((cab, index) => (
                    <TableRow key={index}>
                      <TableCell>{cab.txid}</TableCell>
                      <TableCell>{cab.txdate}</TableCell>
                      <TableCell>
                        <Button
                          component="button"
                          variant="body2"
                          onClick={() => window.open(`/trip-ticket/${cab.tripid}`, "_blank")}
                        >
                          {cab.tripid}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {cab.agent_company} ({cab.agentid})
                      </TableCell>
                      <TableCell>
                        <Typography>{cab.paxname} { this.getTags(cab.tags)}</Typography>
                        <Typography>{cab.paxemail} - {cab.paxmobile}</Typography>
                      </TableCell>
                      <TableCell>{cab.bookedbyid}</TableCell>
                      <TableCell>{cab.pickpdate}</TableCell>
                      <TableCell>
                        <Typography>{cab.selected_carname}  ({cab.selected_cartype})</Typography>
                        <Typography>{cab.triptype}</Typography>
                      </TableCell>
                      <TableCell>{cab.pickuppoint}</TableCell>
                      <TableCell>{cab.tripdestination}</TableCell>
                      <TableCell>{Math.round(cab.totalfarequote)}</TableCell>
                      <TableCell>{cab.bookingstatus}</TableCell>
                      <TableCell>{cab.blockedby}</TableCell>
                      <TableCell>{cab.issupplierpurchaseupdated}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          className="blue-btn"
                          onClick={event =>
                            this.handlePopoverOpen(event, cab)
                          }
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.cabQueues && this.state.cabQueues.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="12">No records found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden smUp>
          {this.state.cabQueues &&
            this.state.cabQueues.map((cab, index) => (
              <Paper key={index} className="padding-16 mb-8">
                <Grid container spacing={8}>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      TxID
                    </Typography>
                    <Typography>{cab.txid}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Tx. Date
                    </Typography>
                    <Typography>{cab.txdate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Tripid
                    </Typography>
                    <Typography>
                      <Button
                        component="button"
                        variant="body2"
                        onClick={() => window.open(`/trip-ticket/${cab.tripid}`, "_blank")}
                      >
                        {cab.tripid}
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Cab Details
                    </Typography>
                    <Typography>
                      {cab.selected_carname} ({cab.selected_cartype})
                    </Typography>
                    <Typography>{cab.triptype}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Journey Date
                    </Typography>
                    <Typography>{cab.dateofjourney}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Amount
                    </Typography>
                    <Typography>{cab.totalfarequote}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Pickuppoint 
                    </Typography>
                    <Typography>{cab.pickuppoint}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Droppoint
                    </Typography>
                    <Typography>{cab.tripdestination}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      BookingStatus
                    </Typography>
                    <Typography>{cab.bookingstatus}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Passenger Details
                    </Typography>
                    <Typography>{cab.paxname} { this.getTags(cab.tags)}</Typography>
                    <Typography>{cab.paxemail} - {cab.paxmobile}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      Blocked By
                    </Typography>
                    <Typography>{cab.blockedby}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      QC Status
                    </Typography>
                    <Typography>{cab.issupplierpurchaseupdated}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      className="blue-btn"
                      onClick={event => this.handlePopoverOpen(event, cab)}
                    >
                      Select
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
        </Hidden>
        <Popover
          id="hotell-queue-popper"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Button onClick={() => this.handleAction("ticket")}>Ticket</Button>
          <Button onClick={() => this.handleAction("updateFare")}>Update Fare</Button>
          <Button onClick={() => this.handleAction("qualityCheck")}>Quality Check</Button>
          <Button onClick={() => this.handleViewReviews(this.selectedRow.txid)}>View Remarks</Button>
          <Button onClick={this.openRollbackDialog}>Rollback</Button>
          <Button onClick={() => this.handleBlock(this.selectedRow.txid)}>Block</Button>
          <Button onClick={this.sendEmail}>Email</Button>
          <Button onClick={() => this.handleEdit(this.selectedRow.txid)}>
            Mapping
          </Button>
        </Popover>
        {this.state.isShowEmail && (
          <EmailTicket
            opId="TG-CABCONFIRMATION"
            txid={this.selectedRow.txid}
            showSnackBar={this.props.showSnackBar}
            showLoading={this.showLoading}
            handleClose={this.closeEmail}
            showEmail={true}
          />
        )}
        {this.state.isShowRollback && (
          <RollbackTransaction
            handleClose={this.closeRollbackDialog}
            handleSubmit={this.handleRollback}
          />
        )}
        {this.state.isShowMapping && (
          <MapBooking
            closeDrawer={this.closeDrawer}
            showLoading={this.showLoading}
            showSnackBar={this.props.showSnackBar}
            agentid={this.selectedRow.agentid}
            txid={this.selectedRow.txid}
            txtype={this.props.txtype}
          />
        )}
        {this.state.isReviewDrawerOpen && (
          <TransactionReviewLog
            isReviewDrawerOpen={this.state.isReviewDrawerOpen}
            openReviewCardDrawer={this.openReviewCardDrawer}
            transactionRemarkLog={this.state.transactionRemarkLog}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(CabQueueTable);
