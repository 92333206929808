import { apiPost, apiPostWithoutToken } from "../common/CommonService";

export const createSupplier = async (obj, userId) => {
  try {
    let jsonObj = {
      reportstoid: "TG1",
      agentid: "",
      userid: userId,
      age: "0",
      contact_number: "",
      address1: "NA",
      address2: "NA",
      city: "NA",
      state: "NA",
      country: "india",
      pincode: "0",
      company: "",
      pan_number: "NA",
      system_roleid: "9",
      user_roleid: "1000",
      user_rolename: "NA",
      status: "active",
      createdon: "",
      addedby: "SELF",
      name_pancard: "",
      logopath: "",
      user_type: "supplier",
      groupname: "",
      system_groupname: "",
      branchname: "",
      departmentname: "",
      designation: "",
      employeeid: "",
      employee_grade: "",
      keyaccmanager: "",
      isqueuestaff: "",
      iskeyaccmanager: "",
      queuestafflevel: "",
      profileid: "",
      gender: "Male",
      birthdate: "",
      facebookid: "",
      totalrewards: 0,
      otherinfo: "",
      finance_id: "",
      clientid: "",
      user_category: "supplier",
      passwordpolicy: "",
      ...obj
    };
    let reqObj = {
      opid: "TG-ADDDIRECTUSER",
      pushtoken: null,
      jsondata: JSON.stringify({ UserProfile: jsonObj })
    };
    let res = await apiPostWithoutToken(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getAllCustomers = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getallcustomers",
      usertype: "supplier"
    };
    let res = await apiPost(reqObj);
    if (res.list[0].ProfileMaster) {
      return Array.isArray(res.list[0].ProfileMaster)
        ? res.list[0].ProfileMaster
        : [res.list[0].ProfileMaster];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getAllCustomersAdmin = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getallcustomers",
      usertype: "customer"
    };
    let res = await apiPost(reqObj);
    if (res.list[0].ProfileMaster) {
      return Array.isArray(res.list[0].ProfileMaster)
        ? res.list[0].ProfileMaster
        : [res.list[0].ProfileMaster];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const addSupplier = async obj => {
  try {
    let jsonObj = {
      Supplier: {
        supplier_id: "",
        supplier_contact_im: "",
        supplier_contact_im2: "",
        supplier_account_details1: "",
        supplier_account_details2: "",
        supplier_remarks: "",
        supplier_ledgername: "",
        supplier_creditlimit: 0,
        supplier_balance: 0,
        supplier_due: 0,
        supplier_owner: "",
        createdon: "",
        hasApi: false,
        supplier_branch: "",
        ...obj
      }
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "addsupplier",
      jsondata: JSON.stringify(jsonObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const editSupplier = async obj => {
  try {
    Object.keys(obj).forEach(k => {
      if (
        k !== "hasApi" &&
        k !== "supplier_balance" &&
        k !== "supplier_creditlimit" &&
        k !== "supplier_due"
      ) {
        obj[k] = "" + obj[k];
      }
    });
    let jsosObj = {
      Supplier: obj
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatesupplier",
      jsondata: JSON.stringify(jsosObj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getSuppliers = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getsuppliers"
    };
    let res = await apiPost(reqObj);
    if (res.list[0].SupplierObj) {
      return res.list[0].SupplierObj;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getSupplierDetails = async supId => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getsupplierdetails",
      supplierid: supId
    };
    let res = await apiPost(reqObj);
    return res.Supplier;
  } catch (err) {
    return undefined;
  }
};

export const getSupplierCredentials = async supId => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getsuppliercredentials",
      supplierid: supId
    };
    let res = await apiPost(reqObj);
    if (res.list[0] !== "") {
      return res.list[0].SupplierCredential;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const addOrUpdateSupplierCredentials = async (
  obj,
  supId,
  actionType
) => {
  try {
    let scl = {
      SupplierCredentialList: {
        creds: [
          {
            SupplierCredential: obj
          }
        ],
        supplierid: supId
      }
    };
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: actionType,
      jsondata: JSON.stringify(scl)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const deleteSupplierCredential = async (supId, recordId) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "deletesuppliercredentials",
      supplierid: supId,
      recordid: recordId
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};
