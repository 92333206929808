import React, { Component } from "react";
import {
  Drawer,
  Button,
  Grid,
  Typography,
  TextField,
  IconButton
} from "@material-ui/core";
import { Delete } from "mdi-material-ui";
import {
  addOrUpdateSupplierCredentials,
  deleteSupplierCredential
} from "../../services/suppliers/SupplierService";

class AddOrUpdateCredentials extends Component {
  supId = undefined;
  supName = undefined;
  constructor(props) {
    super(props);
    this.state = {
      credsList: props.credsList
    };
    this.supId = props.supId;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.credsList !== this.props.credsList) {
      this.setState({
        credsList: this.props.credsList
      });
    }
  }

  handleSaveCredentials = async () => {
    let isError = false;
    this.state.credsList.forEach(ele => {
      if (
        ele.supplier_credential_key === "" ||
        ele.supplier_credential_value === ""
      ) {
        isError = true;
      }
    });
    if (!isError) {
      this.props.showLoading(true);
      let res = await addOrUpdateSupplierCredentials(
        this.state.credsList,
        this.supId,
        "addsuppliercredentials"
      );
      if (res) {
        if (res.ResponseStatus.status === "OK") {
          this.props.showSnackBar(res.ResponseStatus.message, "success");
          this.props.closeDrawer();
        } else {
          this.props.showSnackBar(res.ResponseStatus.message, "error");
        }
      } else {
        this.props.showSnackBar(
          "Failed to add credentials. Please try again.",
          "error"
        );
      }
      this.props.showLoading(false);
    } else {
      this.props.showSnackBar(
        "Please fill all credentials keys and values.",
        "error"
      );
    }
  };

  handleDelete = async (recordId, index) => {
    let isConfirm = window.confirm(
      "Are you sure, do you want to delete credentials?"
    );
    if (isConfirm === true) {
      let data = [...this.state.credsList];
      data.splice(index, 1);
      if (recordId !== "" || recordId !== undefined) {
        this.props.showLoading(true);
        let res = await deleteSupplierCredential(this.supId, recordId);
        if (res) {
          if (res.ResponseStatus.status === "OK") {
            this.props.showSnackBar(res.ResponseStatus.message, "success");
          } else {
            this.props.showSnackBar(res.ResponseStatus.message, "error");
          }
        } else {
          this.props.showSnackBar(
            "Failed to delete the Credentials. Pelase try again.",
            "error"
          );
        }
        this.props.showLoading(false);
      }
      this.setState({
        credsList: data
      });
    }
  };

  handleAdd = () => {
    let newCred = {
      recordid: "",
      supplier_id: this.supId,
      supplier_credential_key: "",
      supplier_credential_value: "",
      supplier_name: "",
      branchName: "",
      apiname: "",
      addedon: ""
    };
    let data = [...this.state.credsList];
    data.push(newCred);
    this.setState({
      credsList: data
    });
  };

  handleCredChange = (event, index) => {
    let data = [...this.state.credsList];
    data[index][event.target.name] = event.target.value;
    this.setState({
      credsList: data
    });
  };

  render() {
    return (
      <Drawer className="creds-drawer" open={true} anchor="right">
        <Grid container spacing={8}>
          <Grid item lg={6} md={6} sm={6} xs={8}>
            <Typography variant="h6">{this.supName} Credentials</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={4}>
            <Button
              className="right"
              onClick={this.props.closeDrawer}
              size="small"
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <hr className="full-width" />
          </Grid>
          <Grid item lg={5} md={5} sm={5} xs={5}>
            <Typography>Key</Typography>
          </Grid>
          <Grid item lg={5} md={5} sm={5} xs={5}>
            Value
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2} />
          {this.state.credsList &&
            this.state.credsList.map((cred, index) => (
              <React.Fragment key={index}>
                <Grid item lg={5} md={5} sm={5} xs={5}>
                  <TextField
                    className="cred-key"
                    fullWidth
                    variant="outlined"
                    placeholder="Key"
                    name="supplier_credential_key"
                    value={cred.supplier_credential_key}
                    onChange={event => this.handleCredChange(event, index)}
                  />
                </Grid>
                <Grid item lg={5} md={5} sm={5} xs={5}>
                  <TextField
                    className="cred-password"
                    fullWidth
                    variant="outlined"
                    placeholder="Password"
                    name="supplier_credential_value"
                    value={cred.supplier_credential_value}
                    onChange={event => this.handleCredChange(event, index)}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                  <IconButton
                    className="right"
                    onClick={() => this.handleDelete(cred.recordid, index)}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
          {this.state.credsList &&
            this.state.credsList.length === 0 && (
              <React.Fragment>
                <br />
                <br />
                <Typography align="center"> No credentails found.</Typography>
                <br />
                <br />
              </React.Fragment>
            )}
          <Grid item lg={4} md={4} sm={4} xs={2} />
          <Grid item lg={4} md={4} sm={4} xs={2} />
          <Grid item lg={4} md={4} sm={4} xs={8}>
            <Button
              onClick={this.handleAdd}
              variant="contained"
              className="right"
              color="secondary"
            >
              Add Credentials
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <br />
            <Typography className="mb-8" variant="caption">
              Click on Save Credentials button to persist the updated or newly
              added credentials.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSaveCredentials}
            >
              Save Credentials
            </Button>
            <br />
            <br />
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default AddOrUpdateCredentials;
