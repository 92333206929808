import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { formatCurrency } from "../../common/Utils";
import { getAllPendingCorporateBalanceRequests } from "../../../services/inventory/CardsService";
import BalanceApproval from "./BalanceApproval";
import CustomSnackBar from "../../common/CustomSnackBar";
import { Loading } from "../../common/Loading";
class BalanceRequestsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestsList: undefined,
      balanceRequest: undefined,
      isDrawerOpen: false,
      isLoading: false,
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  async componentDidMount() {
    await this.getPendingRequests();
  }

  getPendingRequests = async () => {
    this.setState({
      isLoading: true
    });
    let res = await getAllPendingCorporateBalanceRequests();
    this.setState({
      isLoading: false,
      requestsList: res
    });
  };

  openBRDrawer = async (isShow, request) => {
    if (isShow === false) {
      await this.getPendingRequests();
    }
    this.setState({
      isDrawerOpen: isShow,
      balanceRequest: request
    });
  };

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Paper className="padding-16">
          <Grid container spacing={16}>
            <Grid item lg={8} md={8} sm={8} xs={6}>
              <Typography variant="h6"> Balance Request </Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={6} />
          </Grid>
          <Table className="requests-table">
            <TableHead>
              <TableRow>
                <TableCell>Bank Name</TableCell>
                <TableCell>Request ID / Date</TableCell>
                <TableCell>Account No.</TableCell>
                <TableCell>Check No. / Date</TableCell>
                <TableCell>Deposited Amount</TableCell>
                <TableCell>Approved Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.requestsList &&
                this.state.requestsList.length > 0 &&
                this.state.requestsList.map((r, index) => (
                  <TableRow key={index}>
                    <TableCell>{r.deposited_bank}</TableCell>
                    <TableCell>
                      <Typography>{r.requestid}</Typography>
                      <Typography>{r.requestdate}</Typography>
                    </TableCell>
                    <TableCell>{r.account_depositedto}</TableCell>
                    <TableCell>
                      <Typography>{r.cheque_dd_no}</Typography>
                      <Typography>{r.cheque_dd_date}</Typography>
                    </TableCell>
                    <TableCell>{formatCurrency(r.deposited_amount)}</TableCell>
                    <TableCell>{formatCurrency(r.approved_amount)}</TableCell>
                    <TableCell>{r.status}</TableCell>
                    <TableCell>
                      <Typography
                        className="approve-btn"
                        onClick={() => this.openBRDrawer(true, r)}
                      >
                        Approve Amount
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              {this.state.requestsList && this.state.requestsList.length === 0 && (
                <TableRow>
                  <TableCell colSpan="7">No approval request found.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
        {this.state.isDrawerOpen && (
          <Drawer
            className="approve-request-drawer"
            anchor="right"
            open={this.state.isDrawerOpen}
          >
            <BalanceApproval
              showSnackBar={this.showSnackBar}
              request={this.state.balanceRequest}
              openBRDrawer={this.openBRDrawer}
            />
          </Drawer>
        )}
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleSnackBarClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default BalanceRequestsMain;
