import React, { Component } from "react";
import { Typography, Button } from "@material-ui/core";
import AgentContactInfo from "../common/AgentContactInfo";
import CustomerDetails from "../common/CustomerDetails";
import FareBreakUp from "../common/FareBreakUp";
import VisaDetails from "./VisaDetails";
import { offlineVisaBooking } from "../../../services/offline-billing/VisaOfflineService";
import CorporateDetails from "../common/CorporateDetails";

class VisaBillingMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: "",
      agentObj: {
        customerid: "",
        title: "Mr",
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        requestedby: "",
        supplierid: ""
      },
      visaDetailsObj: {
        appliedcountry: "",
        visatype: "Other",
        validfrom: "",
        validto: "",
        applicationdate: "",
        visastatus: "pending",
        visareference: "",
        adminremarks: ""
      },
      corporateDetailsObj: {
        costcenterid: "",
        departmentid: "",
        projectid: "",
      },
      customerDetailsObj: {
        numadults: 1,
        numchildren: 0,
        numinfants: 0,
        adultsArr: [
          {
            title: "Mr",
            firstname: "",
            lastname: "",
            paxtype: ""
          }
        ],
        childrenArr: [],
        infantArr: []
      },
      fareObj: {
        basefare: 0,
        tax: 0,
        servicefee: 0,
        totalinvoice: 0,
      }
    };
  }

  updateCorporateDetailsObject = obj => {
    this.setState({
      corporateDetailsObj: obj
    });
  };

  updateVisaDetailsObject = obj => {
    this.setState({
      visaDetailsObj: obj
    });
  };

  updateCustomerDetailsObject = obj => {
    this.setState({
      customerDetailsObj: obj
    });
  };

  updateStateObject = obj => {
    this.setState({
      agentObj: obj,
      customerId: obj.customerid
    });
  };

  updateFareObject = obj => {
    this.setState({
      fareObj: obj
    });
  };

  handleGenerateBill = async () => {
    let passengers = [];
    let agentObjError = 0, customerDetailsObjError = 0, visaDetailsObjError = 0, fareObjError = 0;
    const alertMsg = "Please enter value for ";
    const { agentObj, visaDetailsObj, customerDetailsObj, corporateDetailsObj, fareObj } = this.state;

    Object.keys(agentObj).some(key => {
      if (agentObj[key] === "") {
        window.alert(alertMsg + key);
        agentObjError = 1;
        return true;
      }
      return false;
    });

    if(agentObjError === 1){
      return false;
    }

    Object.keys(customerDetailsObj).some(key => {
      const keyVal = customerDetailsObj[key];
      if (["adultsArr", "childrenArr", "infantArr"].includes(key) && Array.isArray(keyVal) && keyVal.length > 0) {
        for(let i=0; i<keyVal.length; i++){
          passengers.push({
            "FlightPassenger": keyVal[i]
          });
        }
      }
      
      if (customerDetailsObj[key] === "") {
        window.alert(alertMsg + key);
        customerDetailsObjError = 1;
        return true;
      }
      return false;
    });

    if(customerDetailsObjError === 1){
      return false;
    }

    Object.keys(visaDetailsObj).some(key => {
      if (visaDetailsObj[key] === "" || visaDetailsObj[key] === "select") {
        window.alert(alertMsg + key);
        visaDetailsObjError = 1;
        return true;
      }
      return false;
    });

    if(visaDetailsObjError === 1){
      return false;
    }

    Object.keys(fareObj).some(key => {
      if (fareObj[key] === "") {
        window.alert(alertMsg + key);
        fareObjError = 1;
        return true;
      }
      return false;
    });

    if(fareObjError === 1){
      return false;
    }

    const jsondata ={
      "TgVisaBookingRequest": {
        "passengers": passengers,
        "corporatedetails": {
          "corporateid": agentObj.customerid,
          "profileid": "530",
          "tcbookingrequestid": "",
          "tcbookingid": "",
          "tcprofileid": "",
          "policyid": "",
          "inpolicy": "",
          "policy_reasonid": "",
          "policy_reason_remarks": "",
          "costcenterid": corporateDetailsObj.costcenterid,
          "departmentid": corporateDetailsObj.departmentid,
          "customerid": "",
          "billabletype": "",
          "projectid": corporateDetailsObj.projectid,
          "autoexpense": "",
          "bookingfor": "official",
          "travelreason": "",
          "approvalid": "",
          "txid": "",
          "triprequestid": "",
          "employeeid": "",
          "bookedbyid": ""
        },
        "visarecommendation": {
          "sno": 1,
          "appliedcountry": visaDetailsObj.appliedcountry,
          "typeofvisa": visaDetailsObj.visatype,
          "startdate": visaDetailsObj.validfrom,
          "enddate": visaDetailsObj.validto,
          "appliedon": visaDetailsObj.applicationdate,
          "visastatus": visaDetailsObj.visastatus,
          "visareference": visaDetailsObj.visareference,
          "duration": "",
          "adminremarks": visaDetailsObj.adminremarks,
          "supplierid": agentObj.supplierid,
          "requestedby": "testing",
          "tgfulfilled": true,
          "inpolicy": true,
          "approvalrequired": false,
          "policyid": "",
          "policyaction": "allowedwithreason",
          "totalfare": fareObj.basefare,
          "totaldiscount": 0,
          "servicefee": fareObj.servicefee,
          "servicetax": fareObj.tax,
          "totalnet": fareObj.totalinvoice,
          "inventoryid": ""
        },
        "bookingtype": "b2e",
        "numadults": customerDetailsObj.numadults,
        "numchildren": customerDetailsObj.numchildren,
        "numinfants": customerDetailsObj.numinfants,
        "address": "testing address",
        "tripid": "",
        "promocode": "",
        "promovalue": "",
        "promosuccess": "false",
        "paymentmode": "cashcard",
        "pgname": "",
        "corporateid": agentObj.customerid,
        "contact_email": agentObj.email,
        "contact_mobile": agentObj.mobile,
        "tripitemid": ""
      }
    };

    let obj = { jsondata: JSON.stringify(jsondata) };
    let res = await offlineVisaBooking(obj);
    let msg = `Failed to generate bill`;
    try {
      if (res.FlightBookingResponse.bookingstatus === 200) {
        msg = res.FlightBookingResponse.message;
      } else {
        msg = res.FlightBookingResponse.message;
      }
      alert(msg);
    } catch (error) {
      alert(msg);
    }
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h5" className="mb-8">
          Visa Offline Billing
        </Typography>
        <AgentContactInfo
          agentObj={this.state.agentObj}
          updateChange={this.updateStateObject}
        />
        <br />
        <VisaDetails 
          visaDetailsObj={this.state.visaDetailsObj}
          updateChange={this.updateVisaDetailsObject}
        />
        <br />
        <CorporateDetails 
          detailsObj={this.state.corporateDetailsObj}
          customerId={this.state.customerId}
          updateChange={this.updateCorporateDetailsObject}
        />
        <br />
        <CustomerDetails 
          customerDetailsObj={this.state.customerDetailsObj}
          updateChange={this.updateCustomerDetailsObject}
        />
        <br />
        <FareBreakUp
          fareObj={this.state.fareObj}
          updateFare={this.updateFareObject}
        />
        <br />
        <div className="text-center">
          <Button
            onClick={this.handleGenerateBill}
            variant="contained"
            color="primary"
          >
            Generate Bill
          </Button>
        </div>
        <br />
        <br />
      </React.Fragment>
    );
  }
}

export default VisaBillingMain;
