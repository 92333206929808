import React, { Component } from "react";
import {
  Hidden,
  Grid,
  Button,
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "mdi-material-ui/Menu";
import ChevronDown from "mdi-material-ui/ChevronDown";
import File from "mdi-material-ui/File";
import { BrowserRouter } from "react-router-dom";
import CustomSnackBar from "../common/CustomSnackBar";
import { Loading } from "../common/Loading";
import FlightReconciliation from "./FlightReconciliation";
import FlightReconcileTable from "./FlightReconcileTable";
import { viewAllReconcileMaster } from "../../services/reconciliation/ReconciliationService";

class Reconciliation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: 1,
      anchorEl: null,
      isLoading: false,
      reconcileList: [],
      snackBar: {
        isOpen: false,
        message: "",
        variant: ""
      }
    };
  }

  componentDidMount() {
    this.handleItemClick(1, "flight");
  }

  showLoading = loading => {
    this.setState({
      isLoading: loading
    });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleItemClick(itemId, txt) {
    this.setState({ selectedItem: itemId, anchorEl: null });
    window.location.hash = txt;
  }

  showSnackBar = (msg, type) => {
    this.setState({
      snackBar: {
        isOpen: true,
        message: msg,
        variant: type
      }
    });
  };

  handleClose = () => {
    this.setState({
      snackBar: {
        isOpen: false
      }
    });
  };

  renderSwitch(tab) {
    switch (tab) {
      case 1:
        return (
          <div>
            <FlightReconciliation 
              doSearch={this.doSearch}
              showLoading={this.showLoading}
              showSnackBar={this.showSnackBar}
            />
            <br/>
            <FlightReconcileTable 
              reconcileList={this.state.reconcileList}
              showLoading={this.showLoading}
              showSnackBar={this.showSnackBar}
            />
          </div>
        );
      case 2:
        return <div>Hotel</div>;
      case 3:
        return <div>Train</div>;
      case 4:
        return <div>Bus</div>;
      case 5:
        return <div>Cab</div>;
      default:
        return <div>Invalid</div>;
    }
  }

  getMenu = () => {
    return (
      <div>
        <MenuItem className={`${this.state.selectedItem === 1 && "active-menu"}`} onClick={() => this.handleItemClick(1, "flight")}>
          <ListItemIcon>
            <File />
          </ListItemIcon>
          <ListItemText primary="Flight" />
        </MenuItem>
        <MenuItem className={`${this.state.selectedItem === 2 && "active-menu"}`} onClick={() => this.handleItemClick(2, "hotel")}>
          <ListItemIcon>
            <File />
          </ListItemIcon>
          <ListItemText primary="Hotel" />
        </MenuItem>
        <MenuItem className={`${this.state.selectedItem === 3 && "active-menu"}`} onClick={() => this.handleItemClick(3, "train")}>
          <ListItemIcon>
            <File />
          </ListItemIcon>
          <ListItemText primary="Train" />
        </MenuItem>
        <MenuItem className={`${this.state.selectedItem === 4 && "active-menu"}`} onClick={() => this.handleItemClick(4, "bus")}>
          <ListItemIcon>
            <File />
          </ListItemIcon>
          <ListItemText primary="Bus" />
        </MenuItem>
        <MenuItem className={`${this.state.selectedItem === 5 && "active-menu"}`} onClick={() => this.handleItemClick(5, "cab")}>
          <ListItemIcon>
            <File />
          </ListItemIcon>
          <ListItemText primary="Cab" />
        </MenuItem>
      </div>
    );
  };

  doSearch = async searchObj => {
    this.setState({ showLoading: true });
    let res = await viewAllReconcileMaster(searchObj);
    if(res["com.tripgain.accounting.vobs.ReconcilationMaster"]){
      this.setState({
        reconcileList: res["com.tripgain.accounting.vobs.ReconcilationMaster"],
      })
    } else {
      this.showSnackBar("No record found!", "error");
    }
    this.setState({ showLoading: false });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading && <Loading />}
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <div className="jram">
              <Hidden mdUp>
                <Button
                  className="secondary-bg"
                  fullWidth
                  aria-owns={this.state.anchorEl ? "menu" : null}
                  aria-haspopup="true"
                  color="secondary"
                  onClick={this.handleClick}
                >
                  <MenuIcon color="secondary" /> &nbsp; Menu
                  <ChevronDown color="secondary" />
                </Button>
                <Menu
                  id="menu"
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                >
                  {this.getMenu()}
                </Menu>
              </Hidden>
              <Hidden smDown>
                <MenuList role="menu">{this.getMenu()}</MenuList>
              </Hidden>
            </div>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <BrowserRouter>
              {this.renderSwitch(this.state.selectedItem)}
            </BrowserRouter>
          </Grid>
        </Grid>
        {this.state.snackBar.isOpen && (
          <CustomSnackBar
            isOpen={this.state.snackBar.isOpen}
            message={this.state.snackBar.message}
            variant={this.state.snackBar.variant}
            onClose={this.handleClose}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Reconciliation;
