import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  getPendingSmartScans,
  getExpenseReports,
  getExpenseCategories,
  getBillableCustomers,
  saveAnExpense
} from "../../../services/expenses/ExpenseService";
import SmartScanList from "./SmartScanList";
import "./SmartScanMain.css";
import { getCurrencySymbol } from "../../common/Utils";
import { withLoadingContext } from "../../common/LoadingProvider";
import { withCustomSnackBarContext } from "../../common/CustomSnackBarProvider";

class SmartScanMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isOpenDrawer: false,
      selectedExpense: undefined,
      categories: [],
      expenseReports: [],
      customersList: [],
      expense: {
        customerid: "",
        isbillable: "false",
        isreimbursable: "false",
        receipturl: "",
        reportid: "",
        category: "",
        expense_date: "",
        total_amount: "",
        currency: "INR",
        billing_type: "true",
        tags: "",
        expense_name: "",
        isdistance: "false",
        istime: "false",
        isregular: "true",
        total_distance: 0,
        distance_unit: "",
        total_hours: 0,
        hour_rate: 0,
        distance_rate: 0,
        status: "pending",
        issplit: "false",
        profileid: "",
        corporateid: "",
        projectid: "",
        costcenterid: "",
        departmentid: "",
        addedon: "",
        syncedtoerp: "false",
        receiptid: "",
        erpip: ""
      }
    };
  }

  async componentDidMount() {
    this.props.loadingContext.show();
    let res = await getPendingSmartScans();
    this.setState({
      list: res
    });
    this.props.loadingContext.hide();
  }

  handleShowDrawer = (isShow, item) => {
    if (!isShow) {
      this.setState({
        isOpenDrawer: false,
        selectedExpense: undefined
      });
    } else {
      this.setState(
        prevState => ({
          isOpenDrawer: isShow,
          selectedExpense: item,
          expense: {
            ...prevState.expense,
            receipturl: item.receipt_url,
            profileid: item.profileid,
            receiptid: item.receiptid,
            corporateid: item.corporateid
          }
        }),
        () => this.getData()
      );
    }
  };

  getData = async () => {
    this.props.loadingContext.show();
    await this.getCategories();
    await this.getReports();
    await this.getBillableCustomers();
    this.props.loadingContext.hide();
  };

  handleChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      expense: {
        ...prevState.expense,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  handleChangeCB = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      expense: {
        ...prevState.expense,
        [eve.target.name]: String(eve.target.checked)
      }
    }));
  };

  getReports = async () => {
    let expenseReports = await getExpenseReports(
      this.state.selectedExpense.profileid,
      this.state.selectedExpense.corporateid
    );
    this.setState({ expenseReports });
  };

  getCategories = async () => {
    let categories = await getExpenseCategories(
      this.state.selectedExpense.corporateid
    );
    this.setState({ categories });
  };

  getBillableCustomers = async () => {
    let res = await getBillableCustomers(
      this.state.selectedExpense.corporateid
    );
    this.setState({
      customersList: res
    });
  };

  handleAddExpense = async () => {
    this.props.loadingContext.show();
    let res = await saveAnExpense(this.state.expense);
    if (res && res.ApiStatus.Status === "OK") {
      this.props.snackBarContext.show(
        "Expense has been saved successfully.",
        "success"
      );
      this.setState({
        isOpenDrawer: false
      });
    } else {
      this.props.snackBarContext.show(
        "Failed to save the expense. Please try again.",
        "error"
      );
    }
    this.props.loadingContext.hide();
  };

  handleImageClick = imageUrl => {
    window.open(imageUrl, "_blank");
  };

  render() {
    return (
      <React.Fragment>
        <Typography variant="h6" className="mb-16">
          Smart Scan Expenses
        </Typography>
        <SmartScanList
          list={this.state.list}
          editExpense={this.handleShowDrawer}
        />
        <Drawer
          anchor="right"
          open={this.state.isOpenDrawer}
          className="edit-expense-drawer"
        >
          <Grid container spacing={16}>
            <Grid item lg={8} md={8} sm={8} xs={8}>
              <Typography variant="h6">Edit Expense</Typography>
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                className="right"
                onClick={() => this.handleShowDrawer(false, undefined)}
              >
                Close
              </Button>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                autoComplete="off"
                fullWidth
                required
                variant="outlined"
                name="merchant_name"
                margin="normal"
                label="Merchant Name"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.expense.merchant_name || ""}
                onChange={this.handleChange}
              />
              <TextField
                autoComplete="off"
                fullWidth
                required
                variant="outlined"
                type="number"
                name="total_amount"
                margin="normal"
                label="Amount"
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCurrencySymbol()}
                    </InputAdornment>
                  )
                }}
                value={this.state.expense.total_amount || ""}
                onChange={this.handleChange}
              />
              <TextField
                autoComplete="off"
                fullWidth
                required
                variant="outlined"
                type="date"
                name="expense_date"
                margin="normal"
                label="Expense Date"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.expense.expense_date || ""}
                onChange={this.handleChange}
              />
              <TextField
                autoComplete="off"
                fullWidth
                required
                variant="outlined"
                name="comment"
                margin="normal"
                label="Notes"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.expense.comment || ""}
                onChange={this.handleChange}
              />
              <TextField
                fullWidth
                required
                select
                margin="normal"
                variant="outlined"
                label="Category"
                name="category"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.expense.category}
                onChange={this.handleChange}
              >
                {this.state.categories &&
                  this.state.categories.map(cat => {
                    return (
                      <MenuItem key={cat.categoryid} value={cat.categoryname}>
                        <ListItemText
                          primary={cat.categoryname}
                          secondary={cat.description}
                        />
                      </MenuItem>
                    );
                  })}
              </TextField>
              <TextField
                fullWidth
                required
                select
                margin="normal"
                variant="outlined"
                label="Report"
                name="reportid"
                InputLabelProps={{
                  shrink: true
                }}
                value={this.state.expense.reportid}
                onChange={this.handleChange}
              >
                {this.state.expenseReports &&
                  this.state.expenseReports.map(report => {
                    return (
                      <MenuItem key={report.reportid} value={report.reportid}>
                        <ListItemText
                          primary={report.report_name}
                          secondary={report.comment}
                        />
                      </MenuItem>
                    );
                  })}
              </TextField>
              <FormControlLabel
                name="isbillable"
                control={
                  <Switch
                    checked={
                      this.state.expense.isbillable === "true" ? true : false
                    }
                    value={this.state.expense.isbillable}
                    onChange={this.handleChangeCB}
                  />
                }
                label="Billable"
              />
              <TextField
                disabled={this.state.expense.isbillable === "false"}
                select
                fullWidth
                name="customerid"
                variant="outlined"
                label="Select Billable Customer"
                InputLabelProps={{ shrink: true }}
                value={this.state.expense.customerid}
                onChange={this.handleChange}
              >
                {this.state.customersList.map((c, i) => (
                  <MenuItem value={c.customerid} key={i}>
                    <ListItemText
                      primary={c.companyname}
                      secondary={c.companycode}
                    />
                  </MenuItem>
                ))}
              </TextField>
              <FormControlLabel
                name="isreimbursable"
                control={
                  <Switch
                    checked={
                      this.state.expense.isreimbursable === "true"
                        ? true
                        : false
                    }
                    value={this.state.expense.isreimbursable}
                    onChange={this.handleChangeCB}
                  />
                }
                label="Reimbursable"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <div className="expense-upload-container">
                <br />
                <div
                  className="cursor"
                  title="Click to view it in new tab"
                  onClick={() =>
                    this.handleImageClick(this.state.expense.receipturl)
                  }
                >
                  {this.state.expense.receipturl !== "" && (
                    <img
                      src={this.state.expense.receipturl}
                      width="100"
                      alt="Receipt"
                      className="receipt-url"
                    />
                  )}
                </div>
                <div className="text-center">
                  <input
                    name="file"
                    id="flat-button-file"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    value={this.state.expense.file || ""}
                    onChange={this.handleUploadReceipt}
                  />
                </div>
              </div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.handleAddExpense}
              >
                Add Expense
              </Button>
            </Grid>
          </Grid>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default withCustomSnackBarContext(withLoadingContext(SmartScanMain));
