import { apiPost, getUserAuth } from "../common/CommonService";

export const getFlightBookingQueues = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getflightbookingqueues",
    };
    let res = await apiPost(reqObj);
    if (res.list[0].FlightRecord) {
      return Array.isArray(res.list[0].FlightRecord)
        ? res.list[0].FlightRecord
        : [res.list[0].FlightRecord];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getFlightInvoice = async (txid) => {
  try {
    let reqObj = {
      opid: "TG-GETETICKET",
      txid: txid,
    };
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    }
    return res.FlightInvoice;
  } catch (err) {
    return undefined;
  }
};

export const getFlightCancellationQueues = async (status) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getflightcancellationqueues",
      status: status,
    };
    let resp = await apiPost(reqObj);
    if (resp.list[0] !== "") {
      return resp.list;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

export const getSuppliers = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getsuppliers",
    };
    let res = await apiPost(reqObj);
    if (res.list[0].SupplierObj) {
      return res.list[0].SupplierObj;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getSuppliersById = async (oid) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getsuppliers",
      ownerid: oid,
    };
    let res = await apiPost(reqObj);
    if (res.list[0].SupplierObj) {
      return res.list[0].SupplierObj;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const rollbackTicket = async (txId, remarks) => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "rollbackticket",
      txtype: "flight",
      txid: txId,
      agentid: userAuth.corporateid,
      remarks: remarks,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const performFlightRefund = async (refundObj) => {
  try {
    let userAuth = getUserAuth();
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "performflightrefund",
      txid: refundObj.txId,
      adminid: userAuth.corporateid,
      agentid: refundObj.agentId,
      reference_txid: refundObj.refTxId,
      pgtransactionid: "test",
      canxpaxstr: refundObj.paxStr,
      totalrefundamount: refundObj.refundAmount,
      canxcharge_ota: refundObj.canxChargeOTA,
      canxcharge_airline: refundObj.canxChargeSup,
      remarks: refundObj.adminRemarks,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const updateFlightBooking = async (obj) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updateflightbooking",
      ...obj,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const sendEmail = async (obj) => {
  try {
    let res = await apiPost(obj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getFlightCancellationDetails = async (txId) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getflightcancellationdetails",
      txid: txId,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const updateFlightCancellationStatus = async (
  txId,
  adminRemarks,
  status
) => {
  try {
    let userAuth = getUserAuth();
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "upateflightcancellationstatus",
      agentid: userAuth.corporateid,
      txid: txId,
      adminremarks: adminRemarks,
      status: status,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const rejectFlightCancellation = async (txId, adminRemarks) => {
  try {
    let userAuth = getUserAuth();
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "rejectflightcancellation",
      agentid: userAuth.corporateid,
      txid: txId,
      remarks: adminRemarks,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const updateFlightFare = async (txnId, fares, remarks) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updateflightfare",
      jsondata: JSON.stringify({
        PassengerFareList: {
          txid: txnId,
          remark: remarks,
          passengerfare: [
            {
              PassengerFare: fares,
            },
          ],
        },
      }),
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const cancelFlightTicket = async (obj) => {
  try {
    let reqObj = {
      opid: "TG-FLIGHTCANCELLATIONREQUEST",
      ...obj,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getRescheduledSearchFlights = async (txId, onwardDate, returnDate) => {
  try {
    let reqObj = {
      opid: "TGFS",
      actioncode: "SEARCHFLIGHTSRESC",
      txid: txId,
      onwarddate: onwardDate,
      returndate: returnDate,
    };
    let res = await apiPost(reqObj);
    if (res.FlightSearchResponseMeta) {
      return res.FlightSearchResponseMeta;
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const flightRescheduleRequest = async (obj) => {
  try {
    let reqObj = {
      opid: "TG-FLIGHTRESCREQUEST",
      ...obj,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};