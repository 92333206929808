import React, { Component } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button,
  Select,
  TextField,
  FormHelperText,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import {
  addPromoCode,
  editPromoCode
} from "../../services/promocode/PromoCodeService";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import moment from "moment";

let mandatoryFields = ['promoname', 'promocode', 'promo_value', 'promo_type', 'promo_subtype', 'promo_startdate', 'promo_enddate'];
class AddOrUpdatePromoCode extends Component {
  isEdit = false;
  constructor(props) {
    super(props);
    this.isEdit = props.isEdit;
    this.state = {
      promoCodeObj: props.promoCodeObj,
      airlinesList: props.airlinesList,
      isError: false
    };
  }

  handelFormInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      promoCodeObj: {
        ...prevState.promoCodeObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  mandatoryFieldsReq = key => {
    return mandatoryFields.includes(key) ? { required: true } : {};
  }

  isValidForm = () => {
    let isError = false;
    Object.keys(this.state.promoCodeObj).forEach(key => {
      if (this.state.promoCodeObj[key] === "" && mandatoryFields.includes(key)) {
        this.setState({
          isError: true
        });
        isError = true;
      }
    });
    return isError;
  };

  editPromoCode = async () => {
    let isError = this.isValidForm();
    if (!isError) {
      this.props.showLoading(true);
      let res = await editPromoCode(this.state.promoCodeObj);
      if (res) {
        if (res.ResponseStatus.status === "OK") {
          this.props.showSnackBar(res.ResponseStatus.message, "success");
          this.props.closeDrawer();
          this.props.getPromoCodesList();
        } else {
          this.props.showSnackBar(res.ResponseStatus.message, "error");
        }
      } else {
        this.props.showSnackBar(
          "Failed to update the promo. Please try again.",
          "error"
        );
      }
      this.props.showLoading(false);
    }
  };

  addPromoCode = async () => {
    let isError = this.isValidForm();
    if (!isError) {
      this.props.showLoading(true);
      let res = await addPromoCode(this.state.promoCodeObj);
      if (res) {
        if (res.ResponseStatus.status === "OK") {
          this.props.showSnackBar(res.ResponseStatus.message, "success");
          this.props.closeDrawer();
          await this.props.getPromoCodesList();
        } else {
          this.props.showSnackBar(res.ResponseStatus.message, "error");
        }
      } else {
        this.props.showSnackBar(
          "Failed to add promo. Please try again.",
          "error"
        );
      }
      this.props.showLoading(false);
    }
  };

  handleDateChange = (day, name) => {
    this.setState(prevState => ({
      promoCodeObj: {
        ...prevState.promoCodeObj,
        [name]: day
      }
    }));
  };

  render() {
    return (
      <Drawer open={true} anchor="right" className="add-promo">
        <Grid container spacing={16}>
          <Grid item lg={6} md={6} sm={6} xs={8}>
          {this.isEdit === true ? (
            <Typography variant="h6"> Update Promo Code </Typography>
          ) : (
            <Typography variant="h6"> Add Promo Code </Typography>
          )}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={4}>
            <Button
              onClick={this.props.closeDrawer}
              className="right"
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <hr className="full-width" />
          </Grid>
          {Object.keys(this.state.promoCodeObj).map((property, index) => (
            <Grid item lg={6} md={6} sm={6} xs={12} key={index}>
              {property === "promo_type" ? (
                <React.Fragment>
                  <InputLabel>promo type</InputLabel>
                  <Select
                    required
                    fullWidth
                    name={property}
                    value={this.state.promoCodeObj[property] + ""}
                    onChange={this.handelFormInputChange}
                  >
                    <MenuItem value="FlightsDomestic">Flights Domestic</MenuItem>
                    <MenuItem value="FlightsInternational">Flights International</MenuItem>
                    <MenuItem value="Buses">Buses</MenuItem>
                    <MenuItem value="Trains">Trains</MenuItem>
                    <MenuItem value="Cabs">Cabs</MenuItem>
                    <MenuItem value="Holidays">Holidays</MenuItem>
                    <MenuItem value="ECommerce">ECommerce</MenuItem>
                    <MenuItem value="Hotels">Hotels</MenuItem>
                  </Select>
                  {this.state.isError &&
                    this.state.promoCodeObj[property] === "" && (
                      <FormHelperText className="error-text">
                        This field is required.
                      </FormHelperText>
                    )}
                </React.Fragment>
              ) : property === "promo_subtype" ? (
                <React.Fragment>
                  <InputLabel>promo subtype</InputLabel>
                  <Select
                    required
                    fullWidth
                    name={property}
                    value={this.state.promoCodeObj[property]}
                    onChange={this.handelFormInputChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {this.state.airlinesList.map((carrier, index) => (
                      <MenuItem key={index} value={carrier.airline_code}>
                        {`${carrier.airline_code} - ${carrier.airline_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                  {this.state.isError &&
                    this.state.promoCodeObj[property] === "" && (
                      <FormHelperText className="error-text">
                        This field is required.
                      </FormHelperText>
                    )}
                </React.Fragment>

              ) : property === "promo_value_unit" ? (
                <React.Fragment>
                  <InputLabel>promo value unit</InputLabel>
                  <Select
                    fullWidth
                    name={property}
                    value={this.state.promoCodeObj[property]}
                    onChange={this.handelFormInputChange}
                  >
                    <MenuItem value="percentage">Percentage</MenuItem>
                    <MenuItem value="flat">Flat</MenuItem>
                  </Select>
                </React.Fragment>

              ) : property === "value_component" ? (
                <React.Fragment>
                  <InputLabel>value component</InputLabel>
                  <Select
                    fullWidth
                    name={property}
                    value={this.state.promoCodeObj[property]}
                    onChange={this.handelFormInputChange}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="basefare">basefare</MenuItem>
                    <MenuItem value="tax">tax</MenuItem>
                    <MenuItem value="yq">yq</MenuItem>
                    <MenuItem value="gross">gross</MenuItem>
                    <MenuItem value="baseyq">baseyq</MenuItem>
                    <MenuItem value="flat">flat</MenuItem>
                  </Select>
                  {this.state.isError &&
                    this.state.promoCodeObj[property] === "" && (
                      <FormHelperText className="error-text">
                        This field is required.
                      </FormHelperText>
                    )}
                </React.Fragment>

              ) : ["singleuse", "requires_login", "recalloncancellation", "perpax", "owrt_separate", "freeshipping", "cutandpay"].includes(property) ? (
                <React.Fragment>
                  <InputLabel>{property.replace(/_/g, " ")}</InputLabel>
                  <Select
                    fullWidth
                    name={property}
                    value={this.state.promoCodeObj[property]}
                    onChange={this.handelFormInputChange}
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </React.Fragment>

              ) : (
                <React.Fragment>
                  {property === "promo_status" ? (
                    <React.Fragment>
                      <InputLabel>promo status</InputLabel>
                      <Select
                        fullWidth
                        name={property}
                        value={this.state.promoCodeObj[property]}
                        onChange={this.handelFormInputChange}
                      >
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">InActive</MenuItem>
                      </Select>
                    </React.Fragment>
                  ) : ["promo_startdate", "promo_enddate"].includes(property) ? (
                    <React.Fragment>
                      <DayPickerInput
                        required
                        placeholder={property.replace(/_/g, " ")}
                        className="full-width"
                        value={this.state.promoCodeObj[property]}
                        name={property}
                        dayPickerProps={{ disabledDays: { before: new Date() } }}
                        onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), property)}
                      />
                    </React.Fragment>
                  ) : ["dminusdays", "dplusdays"].includes(property) ? (
                    <React.Fragment>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="number"
                        name={property}
                        label={property.replace(/_/g, " ")}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={this.state.promoCodeObj[property]}
                        onChange={this.handelFormInputChange}
                      />
                    </React.Fragment>
                  ) : (
                    <TextField
                      {...this.mandatoryFieldsReq(property)}
                      fullWidth
                      variant="outlined"
                      name={property}
                      label={property.replace(/_/g, " ")}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.promoCodeObj[property]}
                      onChange={this.handelFormInputChange}
                    />
                  )}
                  {this.state.isError && mandatoryFields.includes(property) &&
                    this.state.promoCodeObj[property] === "" && (
                      <FormHelperText className="error-text">
                        This field is required.
                      </FormHelperText>
                    )}
                </React.Fragment>
              )}
            </Grid>
          ))}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {this.isEdit === true ? (
              <Button
                onClick={this.editPromoCode}
                variant="contained"
                color="secondary"
              >
                Update Promo Code
              </Button>
            ) : (
              <Button
                onClick={this.addPromoCode}
                variant="contained"
                color="primary"
              >
                Add Promo Code
              </Button>
            )}
            <br />
            <br />
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default AddOrUpdatePromoCode;
