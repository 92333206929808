import React from "react";
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Hidden,
  Paper,
  Grid,
  TextField,
  Popover,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { debounce } from "../common/Utils";
import RollbackTransaction from "../queues/common/RollbackTransaction";
import UpdateStatus from "../queues/common/UpdateStatus";
import RetryBooking from "../queues/common/RetryBooking";
import { blockTicket, getViewRemarks } from "../../services/common/CommonService";
import EmailTicket from "../queues/common/EmailTicket";
import { cancelHotelTicket } from "../../services/queues/HotelQueuesService";
import TransactionReviewLog from "../queues/common/TransactionReviewLog";

class SearchTable extends React.Component {
  allTripList = [];
  blockActionObj = {
    'train': "traincanx",
    'hotel': "hotel",
    'flight': "flight",
    'bus': "buscanx",
    'cab': "cabcanx",
    'trip': "tripcanx",
  };
  emailTicketOpidObj = {
    'train': "TG-TRAINCONFIRMATION",
    'hotel': "TG-HOTELCONFIRMATION",
    'flight': "TG-FLIGHTCONFIRMATION",
    'bus': "TG-BUSCONFIRMATION",
    'cab': "TG-CABCONFIRMATION",
    'trip': "TG-TRIPCONFIRMATION",
  };
  constructor(props) {
    super(props);
    this.allTripList = props.tripList;
    this.state = {
      sortType: "asc",
      tripList: props.tripList,
      serviceType: props.serviceType,
      totalAmount: 0,
      searchText: "",
      openPopover: false,
      anchorEl: null,
      isShowRollback: false,
      isShowStatus: false,
      isShowRetry: false,
      isShowEmail: false,
      isReviewDrawerOpen: false,
      transactionRemarkLog: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.tripList !== prevProps.tripList) {
      this.allTripList = this.props.tripList;
      this.setState({
        tripList: this.props.tripList,
        serviceType: this.props.serviceType,
      });
    }
  }

  handlePopoverOpen = (event, record) => {
    this.selectedRow = record;
    this.setState({
      openPopover: true,
      anchorEl: event.currentTarget
    });
  };

  handlePopoverClose = () => {
    this.setState({
      openPopover: false
    });
  };

  openRollbackDialog = () => {
    this.setState({
      isShowRollback: true
    });
  };

  closeRollbackDialog = () => {
    this.setState({
      isShowRollback: false
    });
  };

  openStatusDialog = () => {
    this.setState({
      isShowStatus: true
    });
  };

  closeStatusDialog = () => {
    this.setState({
      isShowStatus: false
    });
  };

  openRetryDialog = () => {
    this.setState({
      isShowRetry: true
    });
  };

  closeRetryDialog = () => {
    this.setState({
      isShowRetry: false
    });
  };

  sendEmail = () => {
    this.setState({
      isShowEmail: true,
    });
  };

  closeEmail = () => {
    this.setState({
      isShowEmail: false,
    });
  };

  handleSearchChange = (event) => {
    const { value } = event.target;
    this.setState({
      searchText: value,
    });
    if (value === "") {
      this.setState({
        tripList: this.allTripList,
      });
    } else {
      this.setSearchResults(value);
    }
  };

  setSearchResults = debounce((val) => {
    let data = this.allTripList.filter((o) =>
      Object.keys(o).some((k) =>
        o[k]
          .toString()
          .toLowerCase()
          .includes(val.toLowerCase())
      )
    );
    this.setState({
      tripList: data,
    });
  }, 500);

  sortBy = (property, type, sortType) => {
    let data = [...this.state.tripList];
    if (type === "string") {
      if (this.state.sortType === "asc") {
        data.sort((a, b) => (b[property] > a[property] ? -1 : 1));
        sortType = "desc";
      } else {
        data.sort((a, b) => (b[property] < a[property] ? -1 : 1));
        sortType = "asc";
      }
    } else {
      if (this.state.sortType === sortType) {
        data.sort((a, b) => new Date(b[property]) - new Date(a[property]));
        sortType = "desc";
      } else {
        data.sort((a, b) => new Date(a[property]) - new Date(b[property]));
        sortType = "asc";
      }
    }
    this.setState({
      tripList: data,
      sortType: sortType
    });
  };

  handleBlock = async (txId) => {
    let isConfirm = window.confirm(
      "Are you sure, Do you want to block the ticket?"
    );
    if (isConfirm === true) {
      this.props.showLoading(true);
      let reqObj = {
        txtype: this.blockActionObj[this.selectedRow.servicetype],
        txid: txId,
      };
      let res = await blockTicket(reqObj);
      if (res) {
        if (res.ApiStatus.Status === "OK") {
          this.props.showSnackBar(res.ApiStatus.Message, "success");
        } else {
          this.props.showSnackBar(res.ApiStatus.Message, "error");
        }
      }
      this.props.showLoading(false);
      this.handlePopoverClose();
    }
  };

  handelHotelCancel = async () => {
    let isConfirm = window.confirm(
      "Are you sure, Do you want to cancel this hotel transaction?"
    );
    if (isConfirm === true) {
      this.props.showLoading(true);
      var res = await cancelHotelTicket(this.selectedRow.tripid);
      var data = "",
        type = "";
      if (res !== undefined) {
        data = res.ApiStatus.Message;
        if (res.ApiStatus.Status === "NOK") {
          type = "error";
        } else {
          type = "success";
        }
      } else {
        data = "Failed to cancel hotel. Please try again.";
        type = "error";
      }
      this.props.showLoading(false);
      this.props.showSnackBar(data, type);
    }
    this.setState({
      anchorEl: null,
      openPopover: false
    });
  };

  handleAction = type => {
    let stype = this.selectedRow.servicetype;
    switch (type) {
      case "ticket":
        window.open(`/${stype}-ticket/${this.selectedRow.tripid}`, "_blank");
        break;
      case "log":
        this.props.history.push(`/transaction-log/${this.selectedRow.tripid}`);
        break;
      case "irctceticket":
        window.open(`${this.selectedRow.original_eticket_url}`, "_blank");
        break;
      case "update":
        this.props.history.push(`/update-${stype}-queue/${this.selectedRow.tripid}`);
        break;
      case "updateFare":
        this.props.history.push(`/update-${stype}-fare/${this.selectedRow.tripid}`);
        break;
      case "qualityCheck":
        this.props.history.push(`/quality-check/${stype}/${this.selectedRow.tripid}`);
        break;
      default:
        break;
    }
  };

  handleViewReviews = async (txId) => {
    this.props.showLoading(true);
    let res = await getViewRemarks(txId);
    try {
      if (res) {
        let remarkArr = Array.isArray(res.TransactionRemarkLog)
          ? res.TransactionRemarkLog
          : [res.TransactionRemarkLog];
        this.setState({
          transactionRemarkLog: remarkArr,
          isReviewDrawerOpen: true,
        });
      } else {
        this.props.showSnackBar("No reviews found", "success");
        this.setState({
          transactionRemarkLog: [],
          isReviewDrawerOpen: true,
        });
      }
    }
    catch (e) {
      this.props.showSnackBar("Error!", "error");
      this.setState({
        transactionRemarkLog: [],
        isReviewDrawerOpen: false,
      });
    }
    this.props.showLoading(false);
    this.handlePopoverClose();
  };

  openReviewCardDrawer = (isShow) => {
    this.setState({
      isReviewDrawerOpen: isShow,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Hidden xsDown>
          <Paper className="padding-16">
            <Grid container spacing={16}>
              <Grid item lg={8} md={8} sm={8} xs={8}>
                &nbsp;
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <TextField
                  name="searchText"
                  variant="outlined"
                  label="Search"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={this.state.searchText}
                  onChange={this.handleSearchChange}
                />
              </Grid>
            </Grid>
            <Table className="queue-table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("tripid", "string", "asc")}
                    >
                      tripid
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("paxname", "string", "asc")}
                    >
                      paxname
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("sector", "string", "asc")}
                    >
                      sector
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("servicetype", "string", "asc")}
                    >
                      servicetype
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("origin", "string", "asc")}
                    >
                      origin
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("destination", "string", "asc")}
                    >
                      destination
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("bookingstatus", "string", "asc")}
                    >
                      bookingstatus
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("jdate", "date", "asc")}
                    >
                      jdate
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      className="sort-label"
                      size="small"
                      onClick={() => this.sortBy("totalamount", "string", "asc")}
                    >
                      totalamount
                    </Button>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tripList &&
                  this.state.tripList.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Button
                          component="button"
                          variant="body2"
                          onClick={() => window.open(`/trip-ticket/${item.tripid}`, "_blank")}
                        >
                          {item.tripid}
                        </Button>
                      </TableCell>
                      <TableCell>{item.paxname}</TableCell>
                      <TableCell>{item.sector}</TableCell>
                      <TableCell>{item.servicetype}</TableCell>
                      <TableCell>{item.origin}</TableCell>
                      <TableCell>{item.destination}</TableCell>
                      <TableCell>{item.bookingstatus}</TableCell>
                      <TableCell>{item.jdate}</TableCell>
                      <TableCell>{item.totalamount}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          className="blue-btn"
                          onClick={event =>
                            this.handlePopoverOpen(event, item)
                          }
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                {this.state.tripList.length === 0 && (
                  <TableRow>
                    <TableCell colSpan="10">No records found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Hidden>
        <Hidden smUp>
          {this.state.tripList &&
            this.state.tripList.map((item, index) => (
              <Paper className="padding-16 mb-8" key={index}>
                <Grid container spacing={16}>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      tripid
                    </Typography>
                    <Typography>
                      <Button
                        component="button"
                        variant="body2"
                        onClick={() => window.open(`/trip-ticket/${item.tripid}`, "_blank")}
                      >
                        {item.tripid}
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      paxname
                    </Typography>
                    <Typography>{item.paxname}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      sector
                    </Typography>
                    <Typography>{item.sector}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      servicetype
                    </Typography>
                    <Typography>{item.servicetype}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      origin
                    </Typography>
                    <Typography>{item.origin}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      destination
                    </Typography>
                    <Typography>{item.destination}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      bookingstatus
                    </Typography>
                    <Typography>{item.bookingstatus}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      jdate
                    </Typography>
                    <Typography>{item.jdate}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" className="caption">
                      totalamount
                    </Typography>
                    <Typography>{item.totalamount}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      size="small"
                      className="blue-btn"
                      onClick={event => this.handlePopoverOpen(event, item)}
                    >
                      Select
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          {this.state.tripList && (
            <div>
              <Typography align="center">
                No records found. Please change your search criteria and try
                again.
              </Typography>
            </div>
          )}
        </Hidden>
        <Popover
          id="hotell-queue-popper"
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Button onClick={() => this.handleAction("ticket")}>Ticket</Button>
          {['flight'].includes(this.state.serviceType) && (
            <Button onClick={() => this.handleAction("log")}>Log</Button>
          )}
          {['hotel'].includes(this.state.serviceType) && (
          <Button onClick={this.handelHotelCancel}>Cancel</Button>
          )}
          {['flight', 'train', 'hotel'].includes(this.state.serviceType) && (
            <Button onClick={() => this.handleAction("update")}>Update</Button>
          )}
          <Button onClick={() => this.handleAction("updateFare")}>Update Fare</Button>
          { ['train', 'hotel', 'bus'].includes(this.state.serviceType) && (
            <Button onClick={this.openStatusDialog}>Update Status</Button>
          )}
          <Button onClick={() => this.handleAction("qualityCheck")}>Quality Check</Button>
          <Button onClick={() => this.handleViewReviews(this.selectedRow.txid)}>View Remarks</Button>
          <Button onClick={this.openRollbackDialog}>Rollback</Button>
          {['train'].includes(this.state.serviceType) && (
            <React.Fragment>
              <Button onClick={() => this.handleAction("irctceticket")}>IRCTC eTicket</Button>
              <Button onClick={this.openRetryDialog}>Retry Train Booking</Button>
            </React.Fragment>
          )}
          <Button onClick={() => this.handleBlock(this.selectedRow.tripid)}>Block</Button>
          { ['flight', 'hotel', 'bus', 'cab'].includes(this.state.serviceType) && (
            <Button onClick={this.sendEmail}>Email</Button>
          )}
        </Popover>
        {this.state.isShowRollback && (
          <RollbackTransaction
            handleClose={this.closeRollbackDialog}
            handleSubmit={this.handleRollback}
          />
        )}
        {this.state.isShowStatus && (
          <UpdateStatus
            handleClose={this.closeStatusDialog}
            txtype={this.state.serviceType}
            txid={this.selectedRow.tripid}
            handlePopoverClose={this.handlePopoverClose}
          />
        )}
        {this.state.isShowRetry && (
          <RetryBooking
            handleClose={this.closeRetryDialog}
            txid={this.selectedRow.tripid}
            handlePopoverClose={this.handlePopoverClose}
          />
        )}
        {this.state.isShowEmail && (
          <EmailTicket
            opId={this.emailTicketOpidObj[this.state.serviceType]}
            txid={this.selectedRow.tripid}
            showSnackBar={this.props.showSnackBar}
            showLoading={this.props.showLoading}
            handleClose={this.closeEmail}
            showEmail={true}
          />
        )}
        {this.state.isReviewDrawerOpen && (
          <TransactionReviewLog
            isReviewDrawerOpen={this.state.isReviewDrawerOpen}
            openReviewCardDrawer={this.openReviewCardDrawer}
            transactionRemarkLog={this.state.transactionRemarkLog}
          />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(SearchTable);
