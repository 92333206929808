import React, { Component } from "react";
import {
  Drawer,
  Grid,
  Typography,
  Button,
  Select,
  TextField,
  FormHelperText,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import {
  addSupplier,
  editSupplier
} from "../../services/suppliers/SupplierService";

class AddOrUpdateSupplier extends Component {
  isEdit = false;
  constructor(props) {
    super(props);
    this.isEdit = props.isEdit;
    this.state = {
      supplierObj: props.supplierObj,
      agentsList: props.agentsList,
      isError: false
    };
  }

  handelFormInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      supplierObj: {
        ...prevState.supplierObj,
        [eve.target.name]: eve.target.value
      }
    }));
  };

  isValidForm = () => {
    let isError = false;
    Object.keys(this.state.supplierObj).forEach(key => {
      if (this.state.supplierObj[key] === "") {
        this.setState({
          isError: true
        });
        isError = true;
      }
    });
    return isError;
  };

  editSupplier = async () => {
    let isError = this.isValidForm();
    if (!isError) {
      this.props.showLoading(true);
      let res = await editSupplier(this.state.supplierObj);
      if (res) {
        if (res.ResponseStatus.status === "OK") {
          this.props.showSnackBar(res.ResponseStatus.message, "success");
          this.props.closeDrawer();
          this.props.getSuppliersList();
        } else {
          this.props.showSnackBar(res.ResponseStatus.message, "error");
        }
      } else {
        this.props.showSnackBar(
          "Failed to update the supplier. Please try again.",
          "error"
        );
      }
      this.props.showLoading(false);
    }
  };

  addSupplier = async () => {
    let isError = this.isValidForm();
    if (!isError) {
      this.props.showLoading(true);
      let res = await addSupplier(this.state.supplierObj);
      if (res) {
        if (res.ResponseStatus.status === "OK") {
          this.props.showSnackBar(res.ResponseStatus.message, "success");
          this.props.closeDrawer();
          await this.props.getSuppliersList();
        } else {
          this.props.showSnackBar(res.ResponseStatus.message, "error");
        }
      } else {
        this.props.showSnackBar(
          "Failed to add supplier. Please try again.",
          "error"
        );
      }
      this.props.showLoading(false);
    }
  };

  render() {
    return (
      <Drawer open={true} anchor="right" className="add-supplier">
        <Grid container spacing={16}>
          <Grid item lg={6} md={6} sm={6} xs={8}>
            <Typography variant="h6"> Add Supplier </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={4}>
            <Button
              onClick={this.props.closeDrawer}
              className="right"
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <hr className="full-width" />
          </Grid>
          {Object.keys(this.state.supplierObj).map((property, index) => (
            <Grid item lg={6} md={6} sm={6} xs={12} key={index}>
              {property === "belongstocustomer" ? (
                <React.Fragment>
                  <InputLabel>Belongs to Customer</InputLabel>
                  <Select
                    required
                    fullWidth
                    name={property}
                    value={this.state.supplierObj[property] + ""}
                    onChange={this.handelFormInputChange}
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </React.Fragment>
              ) : property === "agentid" ? (
                  <React.Fragment>
                    <InputLabel>Agent Id</InputLabel>
                    <Select
                      required
                      fullWidth
                      name={property}
                      value={this.state.supplierObj[property]}
                      onChange={this.handelFormInputChange}
                    >
                      {this.state.agentsList.map((agent, index) => (
                        <MenuItem key={index} value={agent.profileid}>
                          {`${agent.first_name} ${agent.last_name}`}
                        </MenuItem>
                      ))}
                    </Select>
                    {this.state.isError &&
                      this.state.supplierObj[property] === "" && (
                        <FormHelperText className="error-text">
                          This field is required.
                        </FormHelperText>
                      )}
                  </React.Fragment>
                
              ) : (
                <React.Fragment>
                  {property === "supplier_status" ? (
                    <React.Fragment>
                      <InputLabel>supplier status</InputLabel>
                      <Select
                        required
                        fullWidth
                        name={property}
                        value={this.state.supplierObj[property]}
                        onChange={this.handelFormInputChange}
                      >
                        <MenuItem value="active">active</MenuItem>
                        <MenuItem value="inactive">InActive</MenuItem>
                      </Select>
                    </React.Fragment>
                  ) : (
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name={property}
                      label={property.replace(/_/g, " ")}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={this.state.supplierObj[property]}
                      onChange={this.handelFormInputChange}
                    />
                  )}
                  {this.state.isError &&
                    this.state.supplierObj[property] === "" && (
                      <FormHelperText className="error-text">
                        This field is required.
                      </FormHelperText>
                    )}
                </React.Fragment>
              )}
            </Grid>
          ))}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {this.isEdit === true ? (
              <Button
                onClick={this.editSupplier}
                variant="contained"
                color="secondary"
              >
                Update Supplier
              </Button>
            ) : (
              <Button
                onClick={this.addSupplier}
                variant="contained"
                color="primary"
              >
                Add Supplier
              </Button>
            )}
            <br />
            <br />
          </Grid>
        </Grid>
      </Drawer>
    );
  }
}

export default AddOrUpdateSupplier;
