import {
  apiPost,
  getUserAuth,
  getUserProfile,
} from "../common/CommonService";

export const getBusBookingQueues = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getbusbookingqueues"
    };
    let res = await apiPost(reqObj);
    if (res.list[0].BusRecord) {
      return Array.isArray(res.list[0].BusRecord) ? res.list[0].BusRecord : [res.list[0].BusRecord];
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
};

export const getBusTicket = async txId => {
  try {
    let reqObj = {
      opid: "TG-GETETICKET",
      txid: txId
    };
    const res = await apiPost(reqObj);
    return res;
  } catch (error) {
    return undefined;
  }
};

export const updateBusOrTrainQueueBooking = async (obj, acitonType) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: acitonType,
      jsondata: JSON.stringify(obj)
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (error) {
    return {
      ResponseStatus: {
        status: "NOK",
        message: "Failed to update queue. Please try again."
      }
    };
  }
};

export const updateBusFare = async (txnId, fares = {}, remarks) => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "updatebusfare",
      jsondata: JSON.stringify({
        "com.tripgain.buses.BusBookingFareUpdate": {
          txid: txnId,
          adminremarks: remarks,
          ...fares,
        }
      }),
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const rollbackBusTicket = async (txId, remarks) => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "rollbackticket",
      txtype: "bus",
      txid: txId,
      agentid: userAuth.corporateid,
      remarks: remarks,
    };
    let res = await apiPost(reqObj);
    if (res.hasOwnProperty("null")) {
      return undefined;
    }
    return res;
  } catch (err) {
    return undefined;
  }
};

export const getBusCancellationQueues = async () => {
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "getbuscancellationqueues",
      agentid: getUserProfile().corporateid
    };
    let res = await apiPost(reqObj);
    if (res.list[0].BusCancellation) {
      return res.list[0].BusCancellation;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const performBusRefund = async obj => {
  let userAuth = getUserAuth();
  try {
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "performbusrefund",
      txid: obj.txid,
      adminid: userAuth.corporateid,
      agentid: obj.agentid,
      reference_txid: obj.refTxId,
      totalrefundamount: obj.refundAmount,
      operator_canx_charge: obj.canxChargeSup,
      canx_charge: obj.canxChargeOTA,
      remarks: obj.adminRemarks,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const rejectBusCancellation = async (txId, adminRemarks) => {
  try {
    let userAuth = getUserAuth();
    let reqObj = {
      opid: "TG-SUPERADMIN",
      action: "rejectbuscancellation",
      agentid: userAuth.corporateid,
      txid: txId,
      remarks: adminRemarks,
    };
    let res = await apiPost(reqObj);
    return res;
  } catch (err) {
    return undefined;
  }
};

export const cancelBusTicket = async (txId, seats, paxIds) => {
  try {
    let userAuth = getUserAuth();
    let reqObj = {
      opid: "TG-BUSCANCELLATIONREQUEST",
      reftxid: txId,
      corporateid: userAuth.corporateid,
      profileid: userAuth.profileid,
      busseats: seats,
      paxids: paxIds,
    };
    let res = await apiPost(reqObj);
    if (res.data.ApiStatus.Status === "OK") {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};