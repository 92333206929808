import React, { Component } from "react";
import moment from "moment";
import { Typography, Paper, Grid, TextField, MenuItem } from "@material-ui/core";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

class VisaDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailObj: props.detailObj
    };
  }

  handleInputChange = event => {
    let eve = { ...event };
    this.setState(prevState => ({
      detailObj: {
        ...prevState.detailObj,
        [eve.target.name]: eve.target.value
      }
    }),
      () => this.props.updateChange(this.state.detailObj)
    );
  };

  handleDateChange = (day, name) => {
    this.setState(prevState => ({
      detailObj: {
        ...prevState.detailObj,
        [name]: day
      }
    }),
      () => this.props.updateChange(this.state.detailObj)
    );
  };

  render() {
    const { detailObj } = this.state;
    return (
      <Paper className="padding-16 visa-details-form">
        <Typography variant="subtitle1" className="bold">
          Insurance Details
        </Typography>
        <hr className="full-width mb-16" />
        <Grid container spacing={16}>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="insurance_name"
              label="Insurance Name"
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.insurance_name}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <DayPickerInput
              placeholder="Policy Issue Date"
              className="full-width"
              value={detailObj.policyissuedate}
              // dayPickerProps={{ disabledDays: { after: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "policyissuedate")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <DayPickerInput
              placeholder="Start Date"
              className="full-width"
              value={detailObj.startdate}
              // dayPickerProps={{ disabledDays: { before: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "startdate")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <DayPickerInput
              placeholder="End Date"
              className="full-width"
              value={detailObj.enddate}
              // dayPickerProps={{ disabledDays: { before: new Date() } }}
              onDayChange={day => this.handleDateChange(moment(day).format("YYYY-MM-DD"), "enddate")}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="insurance_details"
              label="Insurance Detail"
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.insurance_details}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="productdescription"
              label="Insurance Description"
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.productdescription}
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <TextField
              fullWidth
              select
              required
              name="jtype"
              variant="outlined"
              label="Insurance Type"
              className="select"
              InputLabelProps={{ shrink: true }}
              value={detailObj.jtype}
              onChange={this.handleInputChange}
            >
              <MenuItem value="domestic">Domestic</MenuItem>
              <MenuItem value="international">International</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="adminremarks"
              label="Admin Remarks"
              multiline
              rows={2}
              InputLabelProps={{
                shrink: true
              }}
              value={detailObj.adminremarks}
              onChange={this.handleInputChange}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default VisaDetails;
